import React from 'react';
import { BtnAjout, BtnSearch, BtnSubmit, InputText, Tableau } from '../../components';
import { Space, Tooltip, } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import "./../patients/patient.css";
import { useCollapsed } from '../dashBoard/CollapsedContext';

const colomnsRoles= [
    {
      title: 'Nom role',
      dataIndex: 'nomsrole',
      key: 'nomsrole',
      className: 'colonText',
    },
    {
      title: 'Options',
      key: 'options',
      render: () => (
        <Space size="large">
          <Tooltip title="Editer">
              <EditFilled style={{color: "#4096FF", fontSize:"15px"}} />
            </Tooltip>

            <Tooltip title="Supprimer">
              <DeleteFilled style={{color:"#E00B0B", fontSize:"15px"}} />
            </Tooltip>
        </Space>
      ),
      className: 'colonText',
    },
  ];
  const dataRoles = [
    {
      key: '1',
      nomsrole: 'Role 1',
    },
    {
      key: '2',
      nomsrole: 'Role 2',
    },
    {
      key: '3',
      nomsrole: 'Role 3',
    },
    {
        key: '4',
        nomsrole: 'Role 4',
      },
      {
        key: '5',
        nomsrole: 'Role 5',
      },
      {
        key: '6',
        nomsrole: 'Role 6',
      },
    
  ];


const Roles = () => {

  const collapsed = useCollapsed();


    return (
        <div>
            <div className="buttons-container" style={{ marginLeft: collapsed ? 192 : 90 }} >
            <BtnAjout _width="460px" _title="Ajouter un role"
           form = {<form>
                   <div >
                            <InputText
                                width_='"380px'
                                height_="45px"
                                type="text"
                                placeholder_=" Veuillez saisir le nom de votre spécialité"
                                marginLeft_="7px"
                                marginTop_="12px"
                            />
                            <BtnSubmit
                                        type="submit"
                                        disable='none'
                                        width_="90px"
                                        margin_='15px 327px -3px'
                                        bgNormalColor="#78953E"
                                        buttonText="Valider"
                                        color="#fff"
                                        boldColor={700}
                                        height_="37px"
                                        borderRadius_="0.5rem"
                                        border_="none"
                                        bgHoverColor="#4589C7"
                                    />
                    </div>
            </form>}
           />
          <BtnSearch margin_="5px 0px -11px -40px" height_="39px" bgNormalColor="#ffffff" border_="1.5px solid #1677FF"  />
          </div>
            
            <Tableau columns={colomnsRoles} 
                data={dataRoles ? [...dataRoles].reverse() : []}
                title={() => <div style={{ textAlign: 'left', fontWeight:"bold", marginTop:"-6px" }}>Roles</div>}  /> 
            

        </div>
    );
};

export default Roles;