import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Crée une action asynchrone pour créer une Pathologie
export const createPathologie = createAsyncThunk(
  "creatingPathologie/creatingPathologieData",
  async (pathologieData, { rejectWithValue }) => {
    try {
      // Appel API pour créer une Pathologie avec les données fournies
      const { data } = await api.createPathologie(pathologieData);
      return data; // Retourne les données en cas de succès
    } catch (error) {
      // En cas d'erreur, récupère le message d'erreur de la réponse
      const errorMessage = error?.response?.data?.message || "Une erreur s'est produite";
      // Utilise rejectWithValue pour renvoyer l'erreur à l'appelant
      return rejectWithValue(errorMessage);
    }
  }
);

// Crée un slice pour gérer l'état de création de Pathologie
export const createPathologieSlice = createSlice({
  name: "creatingPathologie",
  initialState: {
    data: {}, // Stocke les données de la Pathologie créée
    isSuccess: false, // Indique si la création a réussi
    loading: false, // Indique si une création est en cours
    error: null, // Stocke l'erreur s'il y en a une
  },
  extraReducers: (builder) => {
    builder
      // Gestion de l'état pendant que la création est en cours
      .addCase(createPathologie.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.error = null;
      })
      // Gestion de l'état lorsque la création réussit
      .addCase(createPathologie.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = true;
        state.data = payload;
        state.error = null;
      })
      // Gestion de l'état lorsque la création échoue
      .addCase(createPathologie.rejected, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = payload; // Stocke le message d'erreur
      });
  },
});

export default createPathologieSlice.reducer;
