import { Input } from 'antd';
import React from 'react';

export const InputText = ({
  size_,
  label,
  className,
  name,
  placeholder_,
  marginTop_,
  marginRight_,
  marginLeft_,
  type,
  width_,
  border_,
  prefix_,
  suffix_,
  height_,
  borderRadius_,
  value,
  required,
  onChange
}) => {

  return (
    <div>
      <Input 
        size={size_}
        required={required}
        label={label}
        className={`input-text ${className}`}
        name={name}
        placeholder={typeof placeholder_ === 'string' ? placeholder_ : ''}
        type={type}
        style={{
          fontFamily: ["Poppins", "sans-serif"].join(","),
          width: width_,
          marginLeft: marginLeft_,
        //   borderRadius: "0 || borderRadius_",
          borderWidth: "1.5px",
          height: height_,
          borderRadius: 0 || borderRadius_,
          marginRight: marginRight_,
          marginTop: marginTop_,
        }}
        prefix={prefix_}
        suffix={suffix_}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputText;
