import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Fonction pour récupérer un Patient depuis l'API
export const fetchPatient = createAsyncThunk(
  "patient/getPatient", // Renommer en "patient" pour la cohérence
  async (id) => {
    try {
      const response = await api.getOnePatient(id); // Utiliser l'id dans l'appel API
      console.log("API Response:", response); // Vérifiez la réponse de l'API si besoin
      return response.data; // S'assurer que `data` est bien présent dans la réponse
    } catch (error) {
      console.error("Error fetching Patient:", error);
      throw error; // Lancer l'erreur pour être capturée dans le reducer
    }
  }
);

// Création du slice pour les patients
export const patientSlice = createSlice({
  name: "patient", // Renommer en "patient" pour la cohérence
  initialState: {
    data: {}, // 'data' peut être un objet vide si vous récupérez un seul patient
    isSuccess: false,
    loading: false,
    error: null, // Ajouter un champ pour stocker l'erreur
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatient.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.error = null; // Réinitialiser l'erreur lors du chargement
      })
      .addCase(fetchPatient.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = true;
        state.data = payload;
      })
      .addCase(fetchPatient.rejected, (state, { error }) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = error.message; // Stocker le message d'erreur
      });
  },
});

// Exporter le réducteur pour l'utiliser dans le store
export default patientSlice.reducer;
