import React, { useState } from 'react';
import { Table, Select } from 'antd';
import "./tableau.css";

const { Option } = Select;

const Tableau = ({ columns, data, height_, rowClassName,title, width_  ,onclick_}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1); // Réinitialiser à la première page lorsque la taille de la page change
  };

  // Modifier les colonnes pour appliquer la classe .colItemsTableau au titre
  const modifiedColumns = columns.map(column => ({
    ...column,
    title: <span className="colItemsTableau">{column.title}</span>
  }));

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <div>
          <Select value={pageSize} onChange={handlePageSizeChange} style={{ width: 100, height:"38px", marginRight:"8px", marginTop: "13px", }}>
            <Option value={5}>5</Option>
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={100}>100</Option>
          </Select>
          <span style={{ color: '#4096FF' }}>Éléments </span>
        </div>
      </div>
      <Table title={title} className="tableauTable" 
        style={{ height: height_, width: width_}}
        columns={modifiedColumns}
        onclick={onclick_}
        dataSource={data}
        rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
        pagination={{ 
          current: currentPage, 
          pageSize: pageSize,
          onChange: (page) => setCurrentPage(page),
        }}
      />
    </div>
  );
};

export default Tableau;
