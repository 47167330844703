import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

export const fetchDiagnosticsPatient = createAsyncThunk(
  "DiagnosticsPatient/getDiagnosticsPatient",
  async (id) => {
    try {
        
      const response = await api.getAllDiagnosticsPatient(id);
      
      const listeDiagnosticsPatient = response.data; 
      return listeDiagnosticsPatient; 
      
    } catch (error) {
      throw error; 
    }
  }
);

export const diagnosticsPatientSlice = createSlice({
    name: "DiagnosticsPatient",
    initialState: {
      data: [], 
      isSuccess: false,
      loading: false,
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchDiagnosticsPatient.pending, (state) => {
          state.loading = true;
          state.isSuccess = false;
        })
        .addCase(fetchDiagnosticsPatient.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.isSuccess = true;
          state.data = payload;
        })
        .addCase(fetchDiagnosticsPatient.rejected, (state) => {
          state.loading = false;
          state.isSuccess = false;
        });
    },
  });
  
export default diagnosticsPatientSlice.reducer;
