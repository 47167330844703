import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";


// Fonction pour récupérer les antécédents d'un patient depuis l'API
export const fetchAntecedentsPatient = createAsyncThunk(
    "AntecedentsPatient/getAntecedentsPatient",
    async (PatientId) => {
      try {
        const response = await api.getAllAntecedentsPatient(PatientId);
        return response.data; // Renvoyer directement la réponse 'data'
      } catch (error) {
        // Capture l'erreur et renvoie un message personnalisé
        return (error.response?.data?.message || "Erreur lors de la récupération des antécédents");
      }
    }
  );

  export const antecedentsPatientSlice = createSlice({
    name: "AntecedentsPatient",
    initialState: {
      data: {}, // Peut-être mieux d'utiliser un tableau si tu attends une liste d'antécédents
      isSuccess: false,
      loading: false,
      error: null,
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchAntecedentsPatient.pending, (state) => {
          state.loading = true;
          state.isSuccess = false;
          state.error = null; // Réinitialiser l'erreur en cas de nouvelle tentative
        })
        .addCase(fetchAntecedentsPatient.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.isSuccess = true;
          state.data = payload; // Stocker les données reçues
          state.error = null; // Aucune erreur si la requête est réussie
        })
        .addCase(fetchAntecedentsPatient.rejected, (state, { payload }) => {
          state.loading = false;
          state.isSuccess = false;
          state.error = payload; // Capturer le message d'erreur
        });
    },
  });
  
  // Exporter le réducteur pour l'utiliser dans le store
  export default antecedentsPatientSlice.reducer;