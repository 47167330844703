import React from 'react';

export const BtnSubmit = ({ padding_, disabledColor, title_, disabled, float_, className, prefix, type_, borderRadius_, border_, width_, margin_, bgNormalColor, bgHoverColor, buttonText, color, boldColor, height_, position_, top_, onClick, left_ ,htmlType}) => {

    const buttonStyle = {
        title: title_,
        width: width_,
        margin: margin_,
        padding: padding_,
        backgroundImage: disabled || bgNormalColor,
        // color: color,
        fontWeight: boldColor,
        height: height_,
        position: position_,
        top: top_,
        border: border_,
        borderRadius: borderRadius_,
        cursor: 'pointer',
        backgroundColor: disabled || bgNormalColor,
        color: disabled ? disabledColor : color,
        float: float_,
        left: left_

    };

    return (
        <button

            className={`input-text ${className}`}
            htmlType={htmlType}
            type={type_}
            style={buttonStyle}
            disabled={disabled}
            onClick={disabled ? undefined : onClick}

            onMouseOver={(e) => e.target.style.backgroundColor = bgHoverColor}
            onMouseOut={(e) => e.target.style.backgroundColor = bgNormalColor}
        >
            {prefix && <span style={{ marginRight: '8px', fontSize: "15px", }}>{prefix}</span>}
            {buttonText}
        </button>
    );
};

