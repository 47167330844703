import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../../redux/RegistreSlice';
import "../registre/registre.css";
import { BtnSubmit, InputText, ModalComponent, SelectInput } from '../../../components';
import { Col, notification, Row } from 'antd';
import { SelectTitreOptions } from '../../../service';
import { useFormik } from 'formik';
import { fetchSpecialite } from "../../../redux/specialiteSlice";
import { fetchCentre } from '../../../redux/centreSlice';
import { fetchUtilisateur } from '../../../redux/UtilisateurSlice';
// import { useNavigate } from 'react-router-dom';

const Registre = ({ _open, onCancel }) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [refreshTable, setRefreshTable] = useState(false); 
  // const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchSpecialite());
  }, [dispatch,refreshTable]);

  const specialites = useSelector((state) => state.specialite.data);

  // Récupérer les centres depuis l'API
  useEffect(() => {
    dispatch(fetchCentre());
  }, [dispatch,refreshTable]);
  const [error, setError] = useState('');
  

  const centres = useSelector((state) => state.centre.data);

  const onSubmit = async (values, actions) => {
    const result = await dispatch(register({
      values,
      roles: ["Medecin"],
      Titre: values.Titre,
      centres: values.centres,
      SpecialiteId: values.SpecialiteId
    }));

    if (!values.Titre) {
      setError('Le titre est requis'); // Mettre à jour l'état d'erreur
      notification.error({
        message: 'Erreur',
        description: 'Veuillez sélectionner un titre.',
        duration: 4,
      });
      return; // Stoppe la soumission du formulaire
    }
    if (!values.centres) {
      setError('Le centre est requis'); 
      notification.error({
        message: 'Erreur',
        description: 'Veuillez sélectionner un centre.',
        duration: 4,
      });
      return; 
    }
    if (!values.SpecialiteId) {
      setError('La spécialité est requise'); 
      notification.error({
        message: 'Erreur',
        description: 'Veuillez sélectionner une spécialité.',
        duration: 4,
      });
      return; 
    }

    if (values.Password !== values.Password_confirmation) {
      notification.error({
        message: "Erreur",
        description: "Les mots de passe ne correspondent pas.",
        duration: 6,
      });
      return;
    }

    if (result.meta.requestStatus === 'fulfilled') {
      notification.success({
        message: "Inscription réussie",
        description: result.payload.message,
        duration: 6
      });
      setRefreshTable((prev) => !prev);
      dispatch(fetchUtilisateur());
      actions.resetForm();
      onCancel();
    } else {
      notification.error({
        message: "Erreur d'inscription",
        description: result.payload,
        duration: 6
      });
    }
  };

  const { values, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      Prenom: "",
      Nom: "",
      Username: "",
      Email: "",
      Password: "",
      Password_confirmation: "",
      Titre: "",
      roles: "",
      centres: "",
      SpecialiteId: "",
    },
    onSubmit,
  });

  // Fonction pour réinitialiser le formulaire et les notifications
  const handleCloseModal = () => {
    resetForm(); // Réinitialiser le formulaire
    onCancel(); // Appeler la fonction de fermeture du modal
  };

 
  const handleChangeTitre = (value) => {
    setFieldValue("Titre", value);
    setError('');
  };

  const specialitesOptions = specialites?.data?.map((specialite) => ({
    label: specialite.NomSpecialite,
    value: specialite.id,
  }));

  const centresOptions = centres?.data?.map((centre) => ({
    label: centre.NomCentre,
    value: centre.NomCentre,
  }));

  return (
    <>
      <ModalComponent 
      _maskClosable={false}
        _top="70px"
        _open={_open}
        onCancel={handleCloseModal} // Utilisez handleCloseModal pour fermer le modal et réinitialiser le formulaire
        _title="Formulaire d'inscription"
        _width="680px"
        form={
          <form onSubmit={handleSubmit} >
            <Row>
              <Col span={24}>
                <p className="inputRegistre">Titre</p>
                <SelectInput 
                  // required 
                  name="Titre" 
                  placeholder_="Titre" 
                  options={SelectTitreOptions} 
                  value={values.Titre} 
                  onChange={handleChangeTitre} 
                />
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <p className="inputRegistre">Prénom</p>
                <InputText 
                  required 
                  value={values.Prenom} 
                  name="Prenom" 
                  type="text" 
                  placeholder_="Prénom" 
                  onChange={handleChange} 
                />
              </Col>
              <Col span={12}>
                <p className="inputRegistre">Nom</p>
                <InputText 
                  required 
                  value={values.Nom} 
                  name="Nom" 
                  type="text" 
                  placeholder_="Nom" 
                  onChange={handleChange} 
                />
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <p className="inputRegistre">Username</p>
                <InputText 
                  required 
                  value={values.Username} 
                  name="Username" 
                  type="text" 
                  placeholder_="Identifiant" 
                  onChange={handleChange} 
                />
              </Col>
              <Col span={12}>
                <p className="inputRegistre">Email</p>
                <InputText 
                  required 
                  value={values.Email} 
                  name="Email" 
                  type="email" 
                  placeholder_="exemple@gmail.com" 
                  onChange={handleChange} 
                />
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <p className="inputRegistre">Mot de passe</p>
                <InputText 
                  required 
                  value={values.Password} 
                  name="Password" 
                  type="password" 
                  placeholder_="Mot de passe" 
                  onChange={handleChange} 
                />
              </Col>
              <Col span={12}>
                <p className="inputRegistre">Confirmer mot de passe</p>
                <InputText 
                  required 
                  value={values.Password_confirmation} 
                  name="Password_confirmation" 
                  type="password" 
                  placeholder_="Confirmer mot de passe" 
                  onChange={handleChange} 
                />
              </Col>
            </Row>
            
            <Row gutter={10}>
              <Col span={12}>
                <p className="inputRegistre">Centre</p>
                <SelectInput 
                  required 
                  name="centres" 
                  placeholder_="Centre" 
                  options={centresOptions} 
                  value={values.centres} 
                  onChange={(value) => setFieldValue("centres", value)} 
                />
              </Col>
              <Col span={12}>
                <p className="inputRegistre">Spécialité</p>
                <SelectInput 
                  required 
                  name="SpecialiteId" 
                  placeholder_="Spécialité" 
                  options={specialitesOptions} 
                  value={values.SpecialiteId} 
                  onChange={(value) => setFieldValue("SpecialiteId", value)} 
                />
              </Col>
            </Row>
            <br/>
            <br/>
            <BtnSubmit
              className="btnSubmitRegistre"
              type_="submit"
              bgNormalColor="#78953E"
              bgHoverColor="#4589C7"
              buttonText="S'inscrire"
              position_="relative"
            />
          </form>
        }
      />
    </>
  );
};

export default Registre;
