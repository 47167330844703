import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Fonction pour récupérer les Medecins depuis l'API
export const fetchMedecins = createAsyncThunk(
  "Medecins/getMedecins",
  async () => {
    try {
      const response = await api.getAllMedecins();
      const listeMedecins = response.data; 

    //   console.log("API Response:", response); // Vérifiez la réponse de l'API
      return listeMedecins; // Assurez-vous que `data` est bien présent
      
    } catch (error) {
    //   console.error("Error fetching Medecins:", error);
      throw error; // Lancer l'erreur pour être capturée dans le reducer
    }
  }
);

// Création du slice pour les Medecins
export const medecinsSlice = createSlice({
    name: "Medecins",
    initialState: {
      data: {}, // Assurez-vous que 'data' est initialisé comme un tableau vide
      isSuccess: false,
      loading: false,
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchMedecins.pending, (state) => {
          state.loading = true;
          state.isSuccess = false;
        })
        .addCase(fetchMedecins.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.isSuccess = true;
          state.data = payload;
        })
        .addCase(fetchMedecins.rejected, (state) => {
          state.loading = false;
          state.isSuccess = false;
        });
    },
  });
  
// Exporter le réducteur pour l'utiliser dans le store
export default medecinsSlice.reducer;
