// Redux Slice pour la création de fiche RCP
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Thunk asynchrone pour créer une fiche RCP
export const createDiagniosticRcp = createAsyncThunk(
    "diagniosticRcp/create",
    async (ficheData) => {
        try {
            // Appel de l'API pour créer diagniostic RCP avec les données fournies
            const response = await api.createDiagniosticRcp(ficheData);
            return response.data;
        } catch (error) {
            // Récupère le message d'erreur si l'appel échoue
            const errorMessage = error?.response?.data?.message;
            return errorMessage; // Renvoie le message d'erreur
        }
    }
);

// Slice Redux pour gérer l'état de la création de fiche RCP
export const CreateDiagniosticRcpSlice = createSlice({
    name: "diagniosticRcp",
    initialState: {
        data: {},          // Stocke les données de diagniostic RCP créée
        loading: false,      // Indique si la création est en cours
        isSuccess: false,    // Indique si la création a réussi
        error: null,         // Stocke un message d'erreur s'il y a une erreur
    },
    reducers: {
        // Si tu as besoin d'ajouter d'autres reducers pour gérer l'état localement, tu peux les ajouter ici
    },
    extraReducers: (builder) => {
        builder
            // Cas pending (lorsque la requête est en cours)
            .addCase(createDiagniosticRcp.pending, (state) => {
                state.loading = true;    // Active le chargement
                state.isSuccess = false; // Réinitialise l'état de succès
                state.error = null;      // Réinitialise l'erreur
            })
            // Cas fulfilled (lorsque la requête réussit)
            .addCase(createDiagniosticRcp.fulfilled, (state, { payload }) => {
                state.loading = false;   // Désactive le chargement
                state.isSuccess = true;  // Indique que la création a réussi
                state.data = payload;    // Stocke les données retournées
                state.error = null;      // Réinitialise les erreurs
            })
            // Cas rejected (lorsque la requête échoue)
            .addCase(createDiagniosticRcp.rejected, (state, { payload }) => {
                state.loading = false;   // Désactive le chargement
                state.isSuccess = false; // Indique que la création a échoué
                state.error = payload;   // Stocke le message d'erreur
            });
    },
});

// Export du reducer pour l'intégrer dans le store Redux
export default CreateDiagniosticRcpSlice.reducer;
