import React, { useEffect, useState } from 'react';
import { BtnSearch, BtnSubmit, InputText, ModalComponent, Tableau } from '../../components';
import { Col, notification, Row, Select, Space, Tooltip } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import './../patients/patient.css';
import { useCollapsed } from '../dashBoard/CollapsedContext';
import { fetchAntecedant } from '../../redux/AntecedantSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTypeAntecedant } from '../../redux/TypeAntecedantSlice';
import { createAntecedant } from '../../redux/CreateAntecedantSlice';
import { useNavigate } from 'react-router-dom';

const colomnsAntecedant = [
    {
        title: 'Nom antécédent',
        dataIndex: 'NomAntecedent',
        key: 'NomAntecedent',
        className: 'colonText',
        width:'40%',
    },
    {
        title: 'Type antécédent',
        dataIndex: 'TypeAnts',
        key: 'TypeAnts',
        className: 'colonText',
        render: (typeAnts) => (
            <div>
                {typeAnts.map((type, index) => (
                    <p key={index} style={{ fontSize: '12px', margin: 0 }}>
                        {type.Name}
                    </p>
                ))}
            </div>
        ),
    },
    {
        title: 'Options',
        key: 'options',
        render: () => (
            <Space size="large">
                <Tooltip title="Editer">
                    <EditFilled style={{ color: "#4096FF", fontSize: "15px" }} />
                </Tooltip>

                <Tooltip title="Supprimer">
                    <DeleteFilled style={{ color: "#E00B0B", fontSize: "15px" }} />
                </Tooltip>
            </Space>
        ),
        className: 'colonText',
    },
];

const Antecedant = () => {
    const [selectTypeValue, setSelectTypeValue] = useState(null);
    const [NomAntecedent, setNomAntecedent] = useState("");
        const [open, setOpen] = useState(false); 
        const [refreshTable, setRefreshTable] = useState(false); 
        const handleOpen  = () => {
          setOpen(true);
          // setNomAntecedent("");
          // setSelectTypeValue(null);
      };
      const onCancel  = () => {
        setOpen(false);
        // Réinitialise les champs du formulaire après enregistrement
        setNomAntecedent("");
        setSelectTypeValue([]);
    };
    const handleChangeType = (value) => {
        setSelectTypeValue(value);
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { data: dataAntecedant, loading } = useSelector((state) => state.antecedant.data);

    useEffect(() => {
        dispatch(fetchAntecedant());
    }, [dispatch,refreshTable]);

    const collapsed = useCollapsed();

    const dataTypeAntecedant = useSelector((state) => state.typeAntecedant.data);
    useEffect(() => {
        dispatch(fetchTypeAntecedant());
    }, [dispatch,refreshTable]);

    const SelectTypeAntecedantOptions = Array.isArray(dataTypeAntecedant.data)
        ? dataTypeAntecedant.data.map((typeantecedant) => ({
              label: typeantecedant.Name,
              value: typeantecedant.Name,
          }))
        : [];

        const handleSubmitForm = async (e) => {
          e.preventDefault();
          let antecedantData = {
            NomAntecedent: NomAntecedent,
            types: selectTypeValue,
          };
        
          try {
            const result = await dispatch(createAntecedant(antecedantData)).unwrap(); // Unwrap pour accéder directement à la réponse
            notification.success({
              message: "Création d'antécédent réussie",
              description: result.message,
              duration: 6,
            });
            setRefreshTable((prev) => !prev);
            // Ferme le modal après enregistrement
            onCancel(); // Appel correct de la fonction pour fermer le modal
          } catch (err) {
            notification.error({
              message: "Erreur d'enregistrement",
              description: err.message || "Une erreur est survenue",
              duration: 6,
            });
          }
        };
        

    return (
        <div className="main">
            <div className="buttons-container" style={{ marginLeft: collapsed ? 192 : 90 }}>
                
               <BtnSubmit type_="button"
                  onClick={handleOpen}
                  className="btnAjouter"
                  width_="100px"
                  margin_="0px 65px -10px 655px"
                  buttonText="Ajouter"
                  />
                    <ModalComponent 
                    _maskClosable={false}
                    onCancel={onCancel}
                    _top=""
                    _open={open} 
                    _title="Ajouter un antécédent"
                    _width="450px" 
                    form=
                    {
                      <form onSubmit={handleSubmitForm}>
                      <Row>
                          <Col span={24}>
                              <p>Type d'antécédent</p>
                              <Select size="large"
                                  required
                                  placeholder="Veuillez choisir vos antécédents"
                                  mode="multiple"
                                  name="Name"
                                  options={SelectTypeAntecedantOptions}
                                  value={selectTypeValue}
                                  onChange={handleChangeType}
                                  style={{ width: '100%' }}
                              />
                          </Col>
                      </Row>
                      <Row>
                          <Col span={24}>
                              <p>Nom antécédent</p>
                              <InputText size_="large"
                                  type="text"
                                  value={NomAntecedent}
                                  required
                                  name="NomAntecedent"
                                  placeholder_="Veuillez saisir un antécédent"
                                  onChange={(e) => setNomAntecedent(e.target.value)}
                              />
                          </Col>
                      </Row>
                      <br />
                      <BtnSubmit
                          type_="submit"
                          width_="6.5vw"
                          float_="right"
                          bgNormalColor="#78953E"
                          buttonText="Valider"
                          color="#fff"
                          boldColor={700}
                          height_="37px"
                          borderRadius_="0.5rem"
                          border_="none"
                          bgHoverColor="#4589C7"
                      />
                      <br />
                      <br />
                      </form>
                    }  
                    />


                <BtnSearch margin_="5px 0px -11px -40px" height_="39px" bgNormalColor="#ffffff" border_="1.5px solid #1677FF" allowClear />
            </div>

            <Tableau
                columns={colomnsAntecedant}
                data={dataAntecedant ? [...dataAntecedant].reverse() : []}
                title={() => (
                    <div style={{ textAlign: 'left', fontWeight: 'bold', marginTop: '-6px' }}>
                        antécédent
                    </div>
                )}
                loading={loading}
            />
        </div>
    );
};

export default Antecedant;
