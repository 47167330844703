import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

export const createAjoutParticipantRCP = createAsyncThunk(
    "creatingAjoutParticipantRCP/creatingAjoutParticipantRCPData",
    async ({rcpId,userIds}, { rejectWithValue }) => {
      try {
        // Envoie le tableau des IDs au backend
        const { data } = await api.createAjoutParticipantRCP(userIds,rcpId);
        return data; // Retourne les données en cas de succès
      } catch (error) {
        const errorMessage = error?.response?.data?.message || "Erreur lors de l'envoi des participants";
        return rejectWithValue(errorMessage); // Renvoie l'erreur
      }
    }
  );
  

// Slice pour la gestion de l'ajout des participants
export const createAjoutParticipantRCPSlice = createSlice({
  name: "creatingAjoutParticipantRCP",
  initialState: {
    data: {}, // Stocke les données de la création des participants RCP
    isSuccess: false, // Indique si la création a réussi
    loading: false, // Indique si une création est en cours
    error: null, // Stocke l'erreur s'il y en a une
  },
  extraReducers: (builder) => {
    builder
      // Gestion de l'état pendant que la création est en cours
      .addCase(createAjoutParticipantRCP.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.error = null;
      })
      // Gestion de l'état lorsque la création réussit
      .addCase(createAjoutParticipantRCP.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = true;
        state.data = payload;
        state.error = null;
      })
      // Gestion de l'état lorsque la création échoue
      .addCase(createAjoutParticipantRCP.rejected, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = payload; // Stocke le message d'erreur
      });
  },
});

// Export du reducer par défaut
export default createAjoutParticipantRCPSlice.reducer;
