import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Action asynchrone pour mettre à jour les données du AntecedentP
export const updateAntecedentP = createAsyncThunk(
    "updatingAntecedentP/updateAntecedentPData", // Renommé pour la clarté
    async ({ id, body }) => { // Recevez un objet avec id et body
        try {
            // Envoie l'ID du AntecedentP et les données au backend
            const { data } = await api.updateAntecedentP(id, body);
            return data; // Retourne les données en cas de succès
        } catch (error) {
            const errorMessage = error?.response?.data?.message;
            return Promise.reject(errorMessage); // Renvoie l'erreur
        }
    }
);

// Slice pour la gestion de la mise à jour des AntecedentPs
export const updateAntecedentPSlice = createSlice({
    name: "updatingAntecedentP",
    initialState: {
        data: {}, 
        isSuccess: false, 
        loading: false, 
        error: null, 
    },
    extraReducers: (builder) => {
        builder
            // Gestion de l'état pendant que la mise à jour est en cours
            .addCase(updateAntecedentP.pending, (state) => {
                state.loading = true;
                state.isSuccess = false;
                state.error = null;
            })
            // Gestion de l'état lorsque la mise à jour réussit
            .addCase(updateAntecedentP.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.isSuccess = true;
                state.data = payload; 
                state.error = null;
            })
            .addCase(updateAntecedentP.rejected, (state, { payload }) => {
                state.loading = false;
                state.isSuccess = false;
                state.error = payload; // Stocke le message d'erreur
            });
    },
});

// Export du reducer par défaut
export default updateAntecedentPSlice.reducer;
