import React, { useState, useEffect } from 'react';
import { BtnAjout, BtnSearch, BtnSubmit, InputText, ModalComponent, } from '../../components';
import { Avatar, Button, Card, Col, DatePicker, message, Modal, notification, Row, Select, Space, Table, Tabs, Tooltip, Typography } from 'antd';
import "../../components/tableaux/tableau.css";
import "../rcp/rcp.css";
import { CalendarOutlined, DeleteFilled, FolderOutlined, IdcardOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { useCollapsed } from '../dashBoard/CollapsedContext';
import { fetchMedecins } from '../../redux/MedecinSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedecinsInvite } from '../../redux/MedecinInviteSlice';
import { fetchSpecialite } from '../../redux/specialiteSlice';
import { fetchPatientsRCP } from '../../redux/PatientsRCPSlice';
import { fetchPatients } from '../../redux/patientsSlice';
import { fetchRcps } from '../../redux/RcpsSlice';
import { createRCP } from '../../redux/CreateRCPSlice';
import dayjs from 'dayjs';
import { createAjoutParticipantRCP } from '../../redux/AjoutParticipantRCPSlice';
import { createAjoutPatientRCP } from '../../redux/AjoutPatientRCPSlice';
import Fiche from '../../components/Fiche & Diagnistique/Fiche';
import Diagniostique from '../../components/Fiche & Diagnistique/Diagniostique';
import { deletePatientRcp } from '../../redux/ArchivPatientRcpSlice';
import { deleteParticipantRcp } from '../../redux/DeleteParticipantRcpSlice';
import PdfView from '../../DocumentPrint/PdfView';

const onChange = (key) => {
  console.log(key);
};
const Rcp = () => {
  const collapsed = useCollapsed();
  const [open, setOpen] = useState();
  const [DateRcp, setDateRcp] = useState(null);
  const [IntituleRcp, setIntituleRcp] = useState();
  const [Description, setDescription] = useState();
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchValueRCP, setSearchValueRCP] = useState('');
  const [searchValueParticipant, setSearchValueParticipant] = useState('');
  const [openModalConfirmDeletPatientRCP, setOpenModalConfirmDeletPatientRCP] = useState(false);
  const [openModalConfirmDeletParticipantRCP, setOpenModalConfirmDeletParticipantRCP] = useState(false);
  const [idPatientDelet, setIdPatientDelet] = useState(null);
  const [idParticipantDelet, setIdParticipantDelet] = useState(null);
  const [activeTabParticipant, setActiveTabParticipant] = useState('2');



  // const [activeTabPatients, setActiveTabPatients] = useState('2');

  const userData =JSON.parse(localStorage.getItem('user'));
  const NomComplet = userData.user.Prenom + ' ' + userData.user.Nom
const role = userData.user.roles.map(item => item.name).join(', ');

  const handleOpenModalConfirmDeletPatientRCP = (record) => {
    setIdPatientDelet(record.id);
    setOpenModalConfirmDeletPatientRCP(true);
  }
  const handleCancelDeletPatient = () => {
    setOpenModalConfirmDeletPatientRCP(false);
    setIdPatientDelet(null);
    // rcpId=null;
  }
  const handleOpenModalConfirmDeletParticipantRCP = (record) => {
    setIdParticipantDelet(record.id);
    setOpenModalConfirmDeletParticipantRCP(true);
  }
  const handleCancelDeletParticipant = () => {
    setOpenModalConfirmDeletParticipantRCP(false);
    setIdParticipantDelet(null);
    // rcpId=null;
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const onCancel = () => {
    setIntituleRcp("");
    setDescription("");
    setDateRcp(null);
    setResponsableRcp("");
    setUserId("");
    setOpen(false);

  };
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState({
    IntituleRcp: '',
    Description: '',
    DateRcp: '',
    joinUrl: '',
    startUrl: '',
    ResponsableRcp: '',
    rcpId: null,
    Patient: '',
    Participant: '',

  });
  const [selectedRowId, setSelectedRowId] = useState(null);
  const { data: dataRcps } = useSelector((state) => state.rcps.data);

  useEffect(() => {
    dispatch(fetchRcps());
  }, [dispatch]);

  useEffect(() => {
    if (dataRcps && dataRcps.length > 0) {
      const lastIndex = dataRcps.length - 1; // Obtenir l'index du dernier élément
      setSelectedRowId(dataRcps[lastIndex].id); // Sélectionner la dernière ligne
      handleOpenRCP(dataRcps[lastIndex]); // Ouvrir la dernière ligne par défaut
    }
  }, [dataRcps]);


  // Récupérer les données des patients RCP depuis le store
  const { data: dataPatientsRCP } = useSelector((state) => state.patientsRCP.data);
  const { data: dataPatients } = useSelector((state) => state.patients.data);
  const rcpId = selectedData.rcpId;
  useEffect(() => {
    // dispatch(fetchPatients());
    dispatch(fetchPatientsRCP(rcpId));
  }, [dispatch, rcpId]);
  const [ordoneeListPatientsRCP, setOrdoneeListPatientsRCP] = useState([]);
   // Extraire les IDs des médecins invités pour comparaison
  const invitedPatientsIds = ordoneeListPatientsRCP.map((patient) => patient.id);
  // Fonction pour vérifier si une ligne doit être inactivée
  const getRowClassNamePatient = (record) => {
    return invitedPatientsIds.includes(record.id) ? 'row-inactive' : '';
  };

  const rowSelectionPatient = {
    selectedRowKeys: selectedPatients,
    onChange: (selectedRowKeys) => {
      setSelectedPatients(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: invitedPatientsIds.includes(record.id),
    }),
  };
  const tableRowPatientStyles = {
    '.row-inactive': {
      backgroundColor: '#f0f0f0',
      color: '#aaa',
      cursor: 'not-allowed',
    },
  };


  useEffect(() => {
    if (rcpId) {
      dispatch(fetchPatientsRCP(rcpId));
      dispatch(fetchMedecinsInvite(rcpId));
    }
  }, [dispatch, rcpId]);
  
  
  useEffect(() => {
    
    if (dataPatientsRCP) {
      const sortedPatients = [...dataPatientsRCP].sort((a, b) => (b.RcpPatients?.id || 0) - (a.RcpPatients?.id || 0));
      setOrdoneeListPatientsRCP(sortedPatients);
    }
   
  }, [dataPatientsRCP]);

  

  const { data: dataMedecins } = useSelector((state) => state.medecins.data);
  const { data: dataMedecinsInvite } = useSelector((state) => state.medecinsInvite.data || []);
  useEffect(() => {
    dispatch(fetchMedecins());
    dispatch(fetchMedecinsInvite(rcpId)); // Passez l'ID du RCP pour récupérer les invités
  }, [dispatch, rcpId]);


  const [ordoneeListParticipantsRCP, setOrdoneeListParticipantsRCP] = useState([]);

  useEffect(() => {
    if (dataMedecinsInvite) {
      const sortedParticipants = [...dataMedecinsInvite].sort((a, b) => (b.MedecinParticipants?.id || 0) - (a.MedecinParticipants?.id || 0));
      setOrdoneeListParticipantsRCP(sortedParticipants);
    }
  }, [dataMedecinsInvite]);

  // Extraire les IDs des médecins invités pour comparaison
const invitedMedecinsIds = ordoneeListParticipantsRCP.map((medecin) => medecin.id);

// Fonction pour vérifier si une ligne doit être inactivée
const getRowClassName = (record) => {
  return invitedMedecinsIds.includes(record.id) ? 'row-inactive' : '';
};

const rowSelectionParticipant = {
  selectedRowKeys: selectedParticipants,
  onChange: (selectedRowKeys) => {
    setSelectedParticipants(selectedRowKeys);
  },
  // Désactiver la sélection pour les médecins invités
  getCheckboxProps: (record) => ({
    disabled: invitedMedecinsIds.includes(record.id),
  }),
};
// Style pour les lignes inactives
const tableRowParticipantStyles = {
'.row-inactive': {
  backgroundColor: '#f0f0f0',
  color: '#aaa',
  cursor: 'not-allowed',
},
};

  // Récupérer les spécialités depuis l'API
  const { data: dataSpecialite } = useSelector((state) => state.specialite.data);
  useEffect(() => {
    dispatch(fetchSpecialite());
  }, [dispatch]);

  const colomnsParticipantsActifs = [
    {
      title: 'Participant',
      key: 'NomComplet',
      className: 'colonText',
      width: '25%',
      render: (text, record) => (
        <>
          <div style={{ display: "flex", alignItems: "left", gap: "8px", marginLeft: "-15px" }}>
            <Avatar size="middle" style={{ marginTop: "3px" }}>{record.Prenom.charAt(0).toUpperCase()}{record.Nom.charAt(0).toUpperCase()}</Avatar>
            <div>
              <a>{record.Prenom + ' ' + record.Nom}</a><br />
              <div style={{}}>
                {record.Centres && record.Centres.map((centre, index) => (
                  <p key={index} style={{ fontSize: '12px', margin: 0 }}>
                    {centre.NomCentre}
                  </p>
                ))}
              </div>
            </div>
          </div>

        </>
      ),
    },
    {
      title: 'Contact info',
      key: 'Email',
      dataIndex: 'Email',
      className: 'colonText',
      width: '10%',

    },
    {
      title: 'Date de création',
      key: 'createdAt',
      dataIndex: 'createdAt',
      className: 'colonText',
      width: 150,
      // render: (createdAt) => {
      //   const formattedDate = createdAt.slice(0, 10);
      //   return `${formattedDate}`;
      // },
      render: (createdAt) => {
        // Convertir la date en objet Date
        const date = new Date(createdAt);

        // Options pour formater la date (jour mois année)
        const options = { day: 'numeric', month: 'long', year: 'numeric' };

        // Formater la date en français
        return date.toLocaleDateString('fr-FR', options);
      },
    },
    {
      title: 'Rôle',
      dataIndex: 'Roles',
      key: 'Roles',
      className: 'colonText',
      render: (Roles) => (
        <div>
          {Roles.map((role, index) => (
            <p key={index} style={{ fontSize: '12px', margin: 0 }}>
              {role.name}
            </p>
          ))}
        </div>
      ),
    },
    {
      title: 'Profession',
      key: 'specialite',
      dataIndex: 'specialite',
      className: 'colonText',
      width: '8%',
      render: (specialite) => (
        <p style={{ fontSize: '12px', margin: 0 }}>
          {specialite?.NomSpecialite || ''}
        </p>
      ),
    },

  ];
  // Modal fichier rcp
  const [showModal, setShowModal] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedRecordResume, setSelectedRecordResume] = useState(null);

  const handleCancel = () => {
    setShowResume(false);
    setSelectedRecord(null);
  };

  const handleCancelFiche = () => {
    setShowModal(false);
    setSelectedRecord(null);
  };

  const handleOpenModal = (record) => {
    setShowModal(true);
    setSelectedRecord(record);
  };
  const handleOpenResume = (record) => {
    setShowResume(true);
    setSelectedRecordResume(record);
    
  };

  const colomnsParticipantsInvite = [
    {
      title: 'Participant',
      key: 'NomComplet',
      className: 'colonText',
      render: (text, record) => (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "8px", marginLeft: "-15px" }}>
            <Avatar size="small" style={{ fontSize: "10px" }}>{record.Prenom.charAt(0).toUpperCase()}{record.Nom.charAt(0).toUpperCase()}</Avatar>
            <div>
              <a>{record.Prenom + ' ' + record.Nom}</a><br />
              <div style={{}}>
                {record.Centres && record.Centres.map((centre, index) => (
                  <p key={index} style={{ fontSize: '12px', margin: 0 }}>
                    {centre.NomCentre}
                  </p>
                ))}
              </div>
            </div>
          </div>

        </>
      ),
    },
    {
      title: 'Contact info',
      key: 'Email',
      dataIndex: 'Email',
      className: 'colonText',

    },
    {
      title: 'Joining date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      className: 'colonText',
      // render: (createdAt) => {
      //   // Extraire uniquement la partie de la date (AAAA-MM-JJ)
      //   const formattedDate = createdAt.slice(0, 10);
      //   // Retourner la date formatée comme une chaîne
      //   return `${formattedDate}`;
      // },
      render: (createdAt) => {
        // Convertir la date en objet Date
        const date = new Date(createdAt);

        // Options pour formater la date (jour mois année)
        const options = { day: 'numeric', month: 'long', year: 'numeric' };

        // Formater la date en français
        return date.toLocaleDateString('fr-FR', options);
      },
      width: 150,
    },

    {
      title: 'Profession',
      key: 'SpecialiteId',
      dataIndex: 'SpecialiteId',
      className: 'colonText',
      render: (specialiteId) => {
        const specialite = dataSpecialite.find((item) => item.id === specialiteId);
        return specialite ? specialite.NomSpecialite : '';
      },
    },

    ...((role === "Admin") || (NomComplet === selectedData.ResponsableRcp)
        ? 
        [
          {
            title: 'Options',
            key: 'options',
            render: (record) => (
                  <div>
                      <Button style={{ background: "#E00B0B", color: "#fff", fontSize: "15px" }}
                        onClick={() => handleOpenModalConfirmDeletParticipantRCP(record)}>
                        Retirer
                      </Button>                
                    </div>
            ),
            className: 'colonText',
          },
        ]
        : 
        []
      ),


  ];
 
  // const handleInvitePatientClick = async (e) => {
  //   e.preventDefault();

  //   if (selectedPatients.length > 0) {
  //     const payload = {
  //       rcpId: selectedData.rcpId,
  //       patientIds: selectedPatients,
  //     };
  //     try {
  //       const result = await dispatch(createAjoutPatientRCP(payload)).unwrap();
  //       notification.success({
  //         message: "Invitation envoyée avec succès",
  //         description: result.message,
  //         duration: 5,
  //       });

  //       setSelectedPatients([]);
  //       setSearchValue('');
  //       await dispatch(fetchPatientsRCP(rcpId));
  //       setActiveTabPatients('1');
  //       onCancel();
  //     } catch (error) {
  //       notification.error({
  //         message: "Erreur lors de l'envoi de l'invitation",
  //         description: error.message || "Une erreur est survenue lors de l'invitation des patients.",
  //         duration: 5,
  //       });
  //     }
  //   } else {
  //     notification.warning({
  //       message: "Aucun participant sélectionné",
  //       description: "Veuillez sélectionner des patients avant d'envoyer une invitation.",
  //       duration: 5,
  //     });
  //   }
  // };

  const handleInviteParticipantClick = async (e) => {
    e.preventDefault();

    if (selectedParticipants.length > 0) {
      const payload = {
        rcpId: selectedData.rcpId,
        userIds: selectedParticipants,
      };
      try {
        const result = await dispatch(createAjoutParticipantRCP(payload)).unwrap();
        notification.success({
          message: "Invitation envoyée avec succès",
          description: result.message,
          duration: 5,
        });
        setSelectedParticipants([]); // Vider les participants sélectionnés
        await dispatch(fetchMedecinsInvite(rcpId));
        setActiveTabParticipant('1'); // Passer à l'onglet "Participants invités"
        onCancel();
      } catch (error) {
        // Notification d'erreur
        notification.error({
          message: "Erreur lors de l'envoi de l'invitation",
          description: error.message || "Une erreur est survenue lors de l'invitation des participants.",
          duration: 5,
        });
      }
    } else {
      // Notification d'avertissement si aucun participant n'est sélectionné
      notification.warning({
        message: "Aucun participant sélectionné",
        description: "Veuillez sélectionner des participants avant d'envoyer une invitation.",
        duration: 5,
      });
    }
  };

  const handleDeletPatientRcp = async (idPatientDelet) => {
    const rcpId = selectedData.rcpId;

    try {
      const result = await dispatch(deletePatientRcp({ rcpId, idPatientDelet })).unwrap();
      notification.success({
        message: "Retirer ce patient de cette RCP",
        description: result.message,
        duration: 5,
      });

      await dispatch(fetchPatientsRCP(rcpId));  // Passer l'ID de la RCP
      handleCancelDeletPatient();

      // setOpenModalConfirmDeletPatientRCP(false);
    } catch (error) {
      notification.error({
        message: "Erreur lors de la suppression",
        description: error.message,
        duration: 5,
      });
    }

  };

  const handleDeletParticipantRcp = async (idParticipantDelet) => {
    const rcpId = selectedData.rcpId;

    try {
      const result = await dispatch(deleteParticipantRcp({ rcpId, idParticipantDelet })).unwrap();
      notification.success({
        message: "Retirer ce participant de cette RCP",
        description: result.message,
        duration: 5,
      });

      await dispatch(fetchMedecinsInvite(rcpId));  // Passer l'ID de la RCP
      handleCancelDeletParticipant();
    } catch (error) {
      notification.error({
        message: "Erreur lors de l'ejection du participant",
        description: error.message,
        duration: 5,
      });
    }

  };


  const colomnsPatientsInviteRCP = [
    {
      title: 'Participant',
      key: 'NomComplet',
      className: 'colonText',
      width: '30%',
      render: (text, record) => (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "8px", marginLeft: "-15px" }}>
            <Avatar size="small" style={{ fontSize: "10px" }}>{record.Prenom.charAt(0).toUpperCase()}{record.Nom.charAt(0).toUpperCase()}</Avatar>
            <div>
              <a>{record.Prenom + ' ' + record.Nom}</a><br />
              <div style={{}}>
                {record.Centres && record.Centres.map((centre, index) => (
                  <p key={index} style={{ fontSize: '12px', margin: 0 }}>
                    {centre.NomCentre}
                  </p>
                ))}
              </div>
            </div>
          </div>

        </>
      ),
    },
    {
      title: 'Contact info',
      key: 'Email',
      dataIndex: 'Email',
      className: 'colonText',
      width: '25%',

    },
    {
      title: 'Date de création',
      key: 'DateCreation',
      dataIndex: 'DateCreation',
      className: 'colonText',
      width: '25%',
      render: (createdAt) => {
        // Convertir la date en objet Date
        const date = new Date(createdAt);

        // Options pour formater la date (jour mois année)
        const options = { day: 'numeric', month: 'long', year: 'numeric' };

        // Formater la date en français
        return date.toLocaleDateString('fr-FR', options);
      },
    },

    {
      title: 'Profession',
      key: 'Professsion',
      dataIndex: 'Professsion',
      className: 'colonText',
      width: '16%',
    },
    {
      title: 'Options',
      // width: '80%',
      render: (record) => (
        <div style={{ display: "flex", gridGap: "20px", alignItems: "center" }}>
            <Button onClick={() => handleOpenResume(record)}>Résumé</Button>
        </div>
      ),
      className: 'colonText',
    },

    ...((role === "Admin") || (NomComplet === selectedData.ResponsableRcp)
    ? 
    [
    {
      title: '',
      // width: '80%',
      render: (record) => (
        <div>
            
              <div>
                <Button style={{ background: "#E00B0B", color: "#fff", fontSize: "15px" }}
                  onClick={() => handleOpenModalConfirmDeletPatientRCP(record)}>
                  Retirer
                </Button>                
              </div>

        </div>
      ),
    }
    ]
    : 
    []
  ),


  ];
  // const columnsPatients = [
  //   {
  //     title: 'Participant',
  //     key: 'NomComplet',
  //     className: 'colonText',
  //     width: '30%',
  //     render: (text, record) => (
  //       <>
  //         <div style={{ display: "flex", alignItems: "center", gap: "8px", marginLeft: "-15px" }}>
  //           <Avatar size="small" style={{ fontSize: "10px" }}>{record.Prenom.charAt(0).toUpperCase()}{record.Nom.charAt(0).toUpperCase()}</Avatar>
  //           <div>
  //             <a>{record.Prenom + ' ' + record.Nom}</a><br />
  //             <div style={{}}>
  //               {record.Centres && record.Centres.map((centre, index) => (
  //                 <p key={index} style={{ fontSize: '12px', margin: 0 }}>
  //                   {centre.NomCentre}
  //                 </p>
  //               ))}
  //             </div>
  //           </div>
  //         </div>

  //       </>
  //     ),
  //   },
  //   {
  //     title: 'Contact info',
  //     key: 'Email',
  //     dataIndex: 'Email',
  //     className: 'colonText',
  //     width: '25%',

  //   },
  //   {
  //     title: 'Date de création',
  //     key: 'DateCreation',
  //     dataIndex: 'DateCreation',
  //     className: 'colonText',
  //     width: '25%',

  //   },
  //   {
  //     title: 'Profession',
  //     key: 'Profession',
  //     dataIndex: 'Professsion',
  //     className: 'colonText',
  //     width: '16%',
  //     // render: (text, record) => {
  //     //   return record.Professsion ||record.ProfesssionPere || record.ProfesssionMere ; 
  //     // },
  //   },



  // ];


  // Filtrer les participants invités
  const filtrDataMedecinsInvite = ordoneeListParticipantsRCP?.filter(item => {
    const nomComplet = `${item.Prenom} ${item.Nom}`.toLowerCase();
    const specialite = dataSpecialite.find((spec) => spec.id === item.SpecialiteId);
    const profession = specialite ? specialite.NomSpecialite.toLowerCase() : ''; // Accéder au nom de la spécialité
    const joiningDate = new Date(item.createdAt).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' }); // Formater la date pour la comparaison
    return (
      nomComplet.includes(searchValueParticipant.toLowerCase()) ||
      item.Email.toLowerCase().includes(searchValueParticipant.toLowerCase()) ||
      joiningDate.includes(searchValueParticipant.toLowerCase()) || // Recherche par date d'entrée
      profession.includes(searchValueParticipant.toLowerCase()) // Recherche par profession
    );
  });


  const filtrDataMedecins = dataMedecins?.filter(item => {
    const nomComplet = `${item.Prenom} ${item.Nom}`.toLowerCase();
    const roles = item.Roles.map(role => role.name.toLowerCase()).join(' '); // Convertir les rôles en chaîne
    const specialite = item.specialite ? item.specialite.NomSpecialite.toLowerCase() : ''; // Accéder au nom de la spécialité
    const joiningDate = new Date(item.createdAt).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' }); // Formater la date
    return (
      nomComplet.includes(searchValueParticipant.toLowerCase()) || // Recherche par nom complet
      item.Email.toLowerCase().includes(searchValueParticipant.toLowerCase()) || // Recherche par email
      specialite.includes(searchValueParticipant.toLowerCase()) || // Recherche par profession
      joiningDate.includes(searchValueParticipant.toLowerCase()) || // Recherche par date d'entrée
      roles.includes(searchValueParticipant.toLowerCase()) // Recherche par rôle
    );
  });


  const filtrDataRCP = dataRcps?.filter(item => {
    // S'assurer que item.IntituleRcp n'est pas null ou undefined
    const intituleRcp = item.IntituleRcp || ''; // Valeur par défaut vide
    const dateRcp = item.DateRcp || ''; // Valeur par défaut vide

    // Conversion de la date en format "dd/mm/yyyy"
    const formattedDateRcp = new Date(dateRcp).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    // Vérifie que searchValueRCP n'est pas null ou undefined
    const searchValue = searchValueRCP ? searchValueRCP.toLowerCase() : '';
    // Recherche par intitulé et par date
    return (
      intituleRcp.toLowerCase().includes(searchValue) ||
      formattedDateRcp.includes(searchValue)
    );
  });


  const itemsTabParticipants = [
    {
      key: '1',
      label: <div style={{ marginLeft: "25px", fontSize: "12px", color: "#1677FF" }}>Participants invités</div>,
      children: <>
        <Table
          columns={colomnsParticipantsInvite}
          // dataSource={filtrDataMedecinsInvite ? [...filtrDataMedecinsInvite].reverse() : []}
          dataSource={filtrDataMedecinsInvite}
          // dataSource={filteredData.length > 0 ? filteredData : dataMedecinsInvite}
          // dataSource={filtrDataMedecinsInvite ? [...filtrDataMedecinsInvite].slice().reverse() : []}
          rowKey={(record) => record.id}
        />
      </>
    },
    {
      key: '2',
      label: <div style={{ marginLeft: "25px", fontSize: "12px", color: "#1677FF" }}>Participants actifs</div>,
      children: <>
        <Table
          rowSelection={rowSelectionParticipant}
          columns={colomnsParticipantsActifs}
          // dataSource={filtrDataMedecins}
          dataSource={filtrDataMedecins ? [...filtrDataMedecins].reverse() : []}
          rowKey={(record) => record.id}
          rowClassName={getRowClassName}
          style={tableRowParticipantStyles}        
        />
      </>
    }
  ];

  const filtrDataPatientsInvites = searchValue
    ? ordoneeListPatientsRCP?.filter(item => {
      const nomComplet = `${item.Prenom} ${item.Nom}`.toLowerCase();
      return (
        nomComplet.includes(searchValue.toLowerCase()) ||
        item.Email.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.Professsion.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.DateCreation.toLowerCase().includes(searchValue.toLowerCase())
      );
    })
    : ordoneeListPatientsRCP; // Si searchValue est vide, retourner toutes les données

  // Filtrer les données pour les Patients
  // const filtrDataPatients = dataPatients?.filter(item => {
  //   const nomComplet = `${item.Prenom} ${item.Nom}`.toLowerCase();
  //   return (
  //     nomComplet.includes(searchValue.toLowerCase()) ||
  //     item.Email.toLowerCase().includes(searchValue.toLowerCase()) ||
  //     item.Professsion.toLowerCase().includes(searchValue.toLowerCase()) ||
  //     item.DateCreation.toLowerCase().includes(searchValue.toLowerCase())
  //   );
  // });

  const itemsTabPatients = [
    {
      key: '1',
      label: <div style={{ marginLeft: "25px", fontSize: "12px", color: "#1677FF" }}>Patients invités</div>,
      children:
        <>
          <Table
            columns={colomnsPatientsInviteRCP}
            // dataSource={ordoneeList}
            dataSource={filtrDataPatientsInvites}
            // dataSource={filtrDataPatientsInvites ? [...filtrDataPatientsInvites].reverse() : []}
            // dataSource={dataPatientsRCP ? [...dataPatientsRCP].reverse() : []}
            rowKey={(record) => record.id}
          />
        </>
    },
    // {
    //   key: '2',
    //   label: <div style={{ marginLeft: "25px", fontSize: "12px", color: "#1677FF" }}>Patients</div>,
    //   children: <>
    //     <Table
    //       rowSelection={rowSelectionPatient}
    //       columns={columnsPatients}
    //       dataSource={filtrDataPatients ? [...filtrDataPatients].reverse() : []}
    //       // dataSource={dataPatients}
    //       rowKey={(record) => record.id}
    //       style={tableRowPatientStyles}
    //       rowClassName={getRowClassNamePatient}
    //     />
    //   </>
    // }
  ];

  // Assurez-vous que handleOpenRCP est défini pour gérer le clic et récupérer l'ID
  const handleOpenRCP = (record) => {
    setSelectedData({
      IntituleRcp: record.IntituleRcp,
      Description: record.Description,
      DateRcp: record.DateRcp,
      joinUrl: record.joinUrl,
      ResponsableRcp: record.ResponsableRcp,
      startUrl: record.startUrl,
      rcpId: record.id
    })
    setSelectedRowId(record.id);
   

  };

  const columnsListRCP = [
    {
      key: '',
      dataIndex: '',
      IntituleRcp: '',
      Description: '',
      DateRcp: '',
      ResponsableRcp: '',
      joinUrl: '',
      render: (text, record) => (
        <div
          // onClick={() => handleOpenRCP(record)}
          style={{ display: "flex", alignItems: "left", justifyContent: "left", }}
        >
          <Card style={{ backgroundColor: '#F5F5F5', width: "6vw", height: "9vh", }}>
            <div style={{ fontSize: "10px", marginTop: "-23px", }}>
              <div style={{ fontWeight: "normal", }}>{new Date(record.DateRcp).toLocaleDateString('fr-FR', { weekday: 'long' }).slice(0, 3).toUpperCase()}</div>
              <strong style={{ fontSize: "15px", marginLeft: "1px", }}>{String(new Date(record.DateRcp).toLocaleDateString('fr-FR', { day: 'numeric' })).padStart(2, '0')}</strong>
              <div style={{ fontWeight: "normal", }}>{new Date(record.DateRcp).toLocaleDateString('fr-FR', { month: 'long' }).slice(0, 3).toUpperCase()}</div>
            </div>
          </Card>

          <>
            <div className="divContainDescrip">  <div className="rcsDescript">{record.IntituleRcp}</div>
              {/* <div className="divSessionTerm">Session terminée</div> */}
              <br />
              <div className="divHeur">
                {new Date(record.DateRcp).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' }).replace(':', 'h ') + 'mn'}
              </div>
            </div>

          </>
        </div>
      ),
    },
  ];
  const { Title, Text } = Typography;
  const items = [
    {
      key: '1',
      label: 'Détails',
      icon: <FolderOutlined />,
      children: (
        <Card
          size="small"
          title="Informations sur la RCP"
          bordered={true}
          headStyle={{ backgroundColor: '#faf9f9' }}
          style={{ width: 850, borderWidth: '3px', borderStyle: 'solid', marginTop: "30px" }}
        >
          <div style={{ padding: '5px', textAlign: 'left' }}>
            {/* Section des détails de la RCP */}
            <h4 style={{ color: 'rgb(33, 82, 255)', marginBottom: '1px', marginTop: '0px' }}>
            </h4>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', }}>
              <p><strong>Intitulé :</strong> {selectedData.IntituleRcp}</p>
              <p><strong>Description :</strong> {selectedData.Description}</p>
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', }}>
              <p><strong>Date : </strong>
                {new Date(selectedData.DateRcp).toLocaleDateString('fr-FR', { weekday: 'long', }) + ' '}
                {String(new Date(selectedData.DateRcp).toLocaleDateString('fr-FR', { day: 'numeric' })) + ' '}
                {new Date(selectedData.DateRcp).toLocaleDateString('fr-FR', { month: 'long' })}
              </p>
              <p><strong>Heure : </strong>
                {new Date(selectedData.DateRcp).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' }).replace(':', 'h ') + 'mn'}
              </p>

            </div>
            <p><strong>Responsable de la RCP : </strong>
              {selectedData.ResponsableRcp}
            </p>

            {((role === "Admin") || (NomComplet === selectedData.ResponsableRcp)) && (
              <div style={{ display: 'grid', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                <p>
                  <strong>Lien de démarrage : </strong>
                  <a href={selectedData.startUrl} target="_blank" rel="noopener noreferrer">
                    Cliquez ici pour démarrer la réunion
                  </a>
                </p>
              </div>
            )}


            <p><strong>Lien d'invitation :</strong>
              <a>{selectedData.joinUrl}</a>
            </p>
          </div>
        </Card>
      ),
    },
    {
      key: '2',
      label: 'Patients',
      icon: <UserOutlined />,
      children: <div>
        <div style={{ alignItems: "center", display: "flex", gap: "7.75rem" }}>
          <h4 style={{ float: "left", padding: "0px", marginTop: "3px", marginLeft: "10px", marginRight: "275px" }}>Gérer les patients</h4><br /><br />
          {/* <div style={{marginLeft:"20px"}}> */}
          <BtnSearch
            bgNormalColor="#ffffff"
            border_="1.5px solid #1677FF"
            onChange_={(e) => setSearchValue(e.target.value)}
            allowClear
          />
          {/* <Button
            onClick={handleInvitePatientClick}
            size="middle"
            style={{ fontSize: "12px", color: "#1677FF", border: "1.5px solid #1677FF", }} variant="filled">
            <div>Envoyer une invitation</div>
          </Button> */}
          {/* </div> */}

        </div>
        <Tabs
          // defaultActiveKey="1"
          // activeKey={activeTabPatients}
          // onChange={setActiveTabPatients}
          onChange={onChange}
          items={itemsTabPatients}
        />
      </div>,
    },
    {
      key: '3',
      label: 'Participants',
      icon: <TeamOutlined />,
      children:
        <>
          <div style={{ alignItems: "center", display: "flex", gap: "2rem" }}>
            <h4 style={{ float: "left", padding: "0px", marginTop: "3px", marginLeft: "10px", marginRight: "275px" }}>Gérer les participants</h4><br /><br />
            <BtnSearch
              bgNormalColor="#ffffff"
              border_="1.5px solid #1677FF"
              onChange_={(e) => setSearchValueParticipant(e.target.value)}
              allowClear

            />
            
        {((role === "Admin") || (NomComplet === selectedData.ResponsableRcp)) && (
            <Button
              onClick={handleInviteParticipantClick}
              size="middle"
              style={{ fontSize: "12px", color: "#1677FF", border: "1.5px solid #1677FF", }} variant="filled">
              <div>Envoyer une invitation</div>
            </Button>
          )}

          </div>
          <Tabs
            defaultActiveKey="1"
            activeKey={activeTabParticipant}
            onChange={setActiveTabParticipant}
            items={itemsTabParticipants}
          />
        </>,
    },


  ];

  // Fonction handleSubmit pour gérer la soumission du formulaire 
  const handleSubmitRCP = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page à la soumission du formulaire

    // Création d'un objet specialiteData contenant le nom d specialite
    let RCPData = {
      IntituleRcp: IntituleRcp,
      Description: Description,
      DateRcp: DateRcp,
      userId: userId,
      ResponsableRcp: ResponsableRcp,
    };
    try {
      const result = await dispatch(createRCP(RCPData)).unwrap(); // Unwrap pour accéder directement à la réponse
      notification.success({
        message: "Création de la RCP réussie",
        description: result.message,
        duration: 6,
      });
      // Ferme le modal après enregistrement
      onCancel(); // Appel correct de la fonction pour fermer le modal
      await dispatch(fetchRcps());
    }
    catch (err) {
      notification.error({
        message: "Erreur d'enregistrement",
        description: err.message,
        duration: 6,
      });
    }
  };

  const handleBack = () => {
    setShowResume(false);
    };
    const [userId, setUserId] = useState();
    const [ResponsableRcp, setResponsableRcp] = useState();

    const handleChangeResponsableRcp = (value) => {
      setUserId(value);
    
      // Trouver le label correspondant à la valeur sélectionnée
      const selectedOption = ResponsableRcpOptions.find(
        (option) => option.value === value
      );
      
      if (selectedOption) {
        setResponsableRcp(selectedOption.label); // Utiliser le label
      }
    };
    const ResponsableRcpOptions = dataMedecins?.map((medecins) => ({
      label: medecins.Prenom + " " + medecins.Nom,
      value: medecins.id,
    }));

  return (
    <div>
{!showResume ? (
    <div>
      <div className="buttons-containerRCP" style={{ marginLeft: collapsed ? 192 : 90 }}>
        <BtnSubmit type_="button"
          onClick={handleOpen}
          className="btnAjouter"
          width_="100px"
          margin_="0px 65px 80px 655px"
          buttonText="Ajouter"
        />
        <ModalComponent
          _maskClosable={false}
          _width="500px"
          _marginTop="-200px"
          _top="90px"
          onCancel={onCancel}
          _open={open}
          _title="Ajouter un RCP"
          form=
          {
            <form>
              <Row>

                <Col span={24}>
                  <p>Intitulé de la RCP</p>
                  <InputText
                    required="required"
                    type="text"
                    placeholder_="Intitulé de la RCP"
                    name={IntituleRcp}
                    value={IntituleRcp}
                    onChange={(e) => setIntituleRcp(e.target.value)}
                  />
                </Col>
                <Col span={24}>
                  <p>Description</p>
                  <InputText required
                    type="text" placeholder_="Description"
                    name={Description}
                    value={Description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {/* <TimePicker style={{width: '100%',}} defaultValue={dayjs('15:00', format)} format={format} /> */}
                </Col>

                <Col span={24}>
                  <p>Date de la RCP</p>
                  <DatePicker
                    value={DateRcp !== null ? dayjs(DateRcp) : null}
                    format="YYYY-MM-DD HH:mm"
                    placeholder_="Date du RCP"
                    name="DateRcp"
                    style={{ width: '100%' }}
                    showTime
                    onChange={(DateRcp, dateString) => {
                      setDateRcp(dateString);
                    }}
                  /></Col>
              </Row> 
              <Row>
                <Col span={24}>
                  <p>Responsable de la RCP</p>
                    <Select style={{width: '100%',}}
                      showSearch
                      placeholder="Choisir un médecin responsable"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={ResponsableRcpOptions}
                      value={userId}
                      onChange={handleChangeResponsableRcp}
                    />

                </Col>
              </Row>
              
              <br /><br />

              <BtnSubmit float_="right"
                type_="button"
                width_="80px"
                bgNormalColor="#78953E"
                buttonText="Enregistrer"
                color="#fff"
                boldColor={700}
                height_="35px"
                border_="none"
                position_="relative"
                top_="-28px"
                borderRadius_="0.5rem"
                onClick={handleSubmitRCP}
              />
            </form>
          }
        />
        <BtnSearch
          margin_="-65px 0px 80px -40px"
          height_="39px"
          bgNormalColor="#ffffff"
          border_="1.5px solid #1677FF"
          onChange_={(e) => setSearchValueRCP(e.target.value)}
          allowClear
        />
      </div>
      <Card
        size="small"
        title={<strong><p style={{ float: "left", padding: "0px", }}>Liste des RCP</p></strong>}
        bordered={true}
        headStyle={{ backgroundColor: '#faf9f9', }}
        style={{ borderWidth: '3px', borderStyle: 'solid', marginTop: "-65px" }}
      >

        <div style={{ display: "flex", marginTop: "-2vh", }}>
          <div>
            <div style={{ background: "#ffff", }} >
              <Table
                onRow={(record) => ({
                  onClick: () => handleOpenRCP(record),
                  className: record.id === selectedRowId ? 'selected-row' : '',
                })}
                columns={columnsListRCP}
                dataSource={filtrDataRCP ? [...filtrDataRCP].reverse() : []}
                // dataSource={filtrDataRCP}
                pagination={false}
                scroll={{
                  y: 450, // Hauteur pour le défilement vertical 
                }}
                showHeader={false}
                style={{ padding: "0px" }}
                className="custom-table"
              />
            </div>
          </div>

          <div className="tabs-container">
            <Tabs
              className="tabsItems"
              type="card"
              size="large"
              defaultActiveKey={1}
              items={items}
              onChange={onChange}
            />
          </div>
        </div>
      </Card>


    </div>
) :(
  <>
  
          <div>
          <BtnSubmit 
            float_="right"
            margin_="50px 0px 20px"
            buttonText="Retour" border_="none" color="#ffff"
            type_="button" width_="7vw" height_="5.5vh" borderRadius_="0.2rem"
            bgNormalColor="linear-gradient( 310deg, rgb(33, 202, 253), rgb(33, 82, 255))"
            onClick={handleBack}
            />
          </div>

      <div>
      <PdfView  PatientId={selectedRecordResume?.id}/>
      </div>

  </>
)
}
    
<ModalComponent
_maskClosable={false}
_width="1000px"
_top="20px"
onCancel={handleCancelFiche}
_open={showModal}
_title="Fiche Patient"
form={<Fiche data={selectedRecord} RcpId={rcpId} close={handleCancelFiche} />}
/>


        <Modal
          maskClosable={false}
          closable={false}
          title="Supprimer un patient d'un RCP"
          open={openModalConfirmDeletPatientRCP}
          onOk={() => handleDeletPatientRcp(idPatientDelet)}
          onCancel={handleCancelDeletPatient}
          okText="Oui"
          cancelText="Non">
          <p>Voulez-vous retirer ce patient de cette réunion?</p>
        </Modal>

        <Modal
          maskClosable={false}
          closable={false}
          title="Retirer un participant d'un RCP"
          open={openModalConfirmDeletParticipantRCP}
          onOk={() => handleDeletParticipantRcp(idParticipantDelet)}
          onCancel={handleCancelDeletParticipant}
          okText="Oui"
          cancelText="Non">
          <p>Voulez-vous retirer le participant de cette réunion?</p>
        </Modal>

</div>

  );
};

export default Rcp;