import React, { createContext, useContext } from 'react';

const CollapsedContext = createContext();

export const useCollapsed = () => useContext(CollapsedContext);

export const CollapsedProvider = ({ children, collapsed }) => (
  <CollapsedContext.Provider value={collapsed}>
    {children}
  </CollapsedContext.Provider>
);
