import React, { useEffect, useState } from 'react';
import { Avatar, Button, Col, Form, Input, Layout, notification, Row,  } from 'antd';
import { BellOutlined, DownOutlined, EyeInvisibleOutlined, EyeTwoTone, LockOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'; 
import CustomDropdown from '../DropDown/Dropdown';
import ModalComponent from '../modal/ModalComponent';
import { BtnSubmit } from '../button/BtnSubmit';
import { updateDetailsUser } from '../../redux/ModifierDetailsUserSlice';
import { useDispatch,  } from 'react-redux';
import { updatePassword } from '../../redux/ModifierPasswordSlice';
import "./topbar.css";
import { jwtDecode } from 'jwt-decode';


const { Header } = Layout;
export const Topbar = ({ collapsed, toggleCollapsed,  }) => {
  // const [selectedValue, setSelectedValue] = useState(null);
        const [open, setOpen] = useState(false);
        const [OpenModalMDP, setOpenModalMDP] = useState(false);
        const [newPassword, setNewPassword] = useState("");
        const [newPassword_confirmation, setNewPassword_confirmation] = useState("");
        const [currentPassword, setCurrentPassword] = useState();
        const [InfoUser, setInfoUser] = useState(false);

        const handleOpenModalMDP = () => {
            setOpenModalMDP(true);
          };
          const handleCloseModalMDP = () => {
            setOpenModalMDP(false);
            setCurrentPassword("");
            setNewPassword("");
            setNewPassword_confirmation("");
          };
          const handleOpenInfoUser = (e) => {
            e.preventDefault();
            setInfoUser(true);
          };
          const handleCloseInfoUser = (e) => {
            // e.preventDefault();
            setInfoUser(false);
          };
      const navigate = useNavigate(); 
      const [user, setUser] = useState("");
      const onLogoutClick = () => {
        localStorage.removeItem("user");
        setUser(null);
        navigate('/');
      };

  // Vérification si le token est expiré
  useEffect(() => {
      const token = localStorage.getItem("token"); // Récupère le token depuis le localStorage

      if (token) {
          const decodedToken = jwtDecode(token); // Décodage du token
          const currentTime = Date.now() / 1000; // Temps actuel en secondes

          if (decodedToken.exp < currentTime) {
              console.log("Token expiré. Déconnexion automatique.");
              onLogoutClick(); // Appelle la fonction de déconnexion
          }
      }
  }, []); // Exécute au chargement du composant

        const openProfil = () => {
          setOpen(true);
        };
        const onCloseProfil = () => {
          setOpen(false);
        };
      const SelectUserOptions = [
        { label: <div onClick={openProfil}>Profil</div>, 
          value: 'profil',
          icon:<div className="divIcon" onClick={openProfil}>
            <Avatar shape="square" size="large"
                style={{
                  backgroundColor: '#E6F4FF',
                  color:"#2163FF",
                  fontSize:"20px",
                  marginLeft:"-8px",
                    }}
              icon={<UserOutlined />}
              /> 
          </div>},

        { label:( <div onClick={onLogoutClick}>Se déconnecter</div>), 
          value: 'deconnexion', 
          icon:( <div className="divIcon" onClick={onLogoutClick}> 
          <UploadOutlined shape="square"  
          style={{backgroundColor: '#e3fbb8', color:"#A1BE6A", fontSize:"20px", marginLeft:"-8px",borderRadius:"0.4rem",transform:"rotate(-90deg)",
        padding:"9px",}} />
      </div> ),
        }
      ];
      const userData = JSON.parse(localStorage.getItem('user')) || {};
      const userId = userData.user.id;
      const [InputDataProfil, setInputDataProfil] = useState({
        Titre: userData?.user?.Titre || '',
        Prenom: userData?.user?.Prenom || '',
        Nom: userData?.user?.Nom || ''
      });

      const handleChangeInputProfil = (e) => {
          const {name, value} = e.target;
          setInputDataProfil({
            ...InputDataProfil,
            [name]:value
          });
      };
      const dispatch = useDispatch();

        const handleSubmit = async (e) => {
          e.preventDefault(); // Empêche le rechargement de la page à la soumission du formulaire
          // Création d'un objet updatedData contenant les informations mises à jour
          let updatedData = {
            Titre: InputDataProfil.Titre,
            Prenom: InputDataProfil.Prenom,
            Nom: InputDataProfil.Nom,
          };
          try {
            const result = await dispatch(updateDetailsUser({ id: userId, updatedData })).unwrap() // Unwrap pour accéder directement à la réponse
             .then(response => {
                  // Mise à jour de l'état userData avec les données mises à jour
                  notification.success({
                    message: 'Profil mis à jour',
                    description: 'Vos informations ont été mises à jour avec succès.',
                  });
                })
            handleCloseInfoUser();
          } catch (err) {
            notification.error({
              message: "Erreur de mise à jour",
              description: err.message || "Une erreur est survenue lors de la mise à jour.",
              duration: 5,
            });
          }
        };

        const handleSubmitPassword = async (e) => {
          e.preventDefault(); // Empêche le rechargement de la page à la soumission du formulaire
          // Vérifie si les nouveaux mots de passe correspondent
          if (newPassword !== newPassword_confirmation) {
            notification.error({
              message: "Erreur de validation",
              description: "Le nouveau mot de passe et le mot de passe de confirmation doivent être identique.",
              duration: 6,
            });
            return; // Arrête l'exécution si les mots de passe ne correspondent pas
          }
          // Création d'un objet updatedPassword contenant les informations mises à jour
          const updatedPassword = {
            currentPassword: currentPassword,
            newPassword: newPassword,
            newPassword_confirmation: newPassword_confirmation,
          };
          try {
            // Utilisation de dispatch avec unwrap pour obtenir la réponse directement
            const result = await dispatch(updatePassword({ id: userId, updatedPassword })).unwrap();
            // Afficher une notification de succès
            notification.success({
              message: 'Mot de passe mis à jour',
              description: 'Vos informations ont été mises à jour avec succès.',
            });
            handleCloseModalMDP();
          } catch (err) {
            // Afficher une notification d'erreur
            notification.error({
              message: "Erreur de mise à jour",
              description: err.message || "Une erreur est survenue lors de la mise à jour.",
              duration: 5,
            });
          }
        };
        


  return (
    <>
    <Layout>
      <Header className={`headerTopbar ${collapsed ? 'collapsed' : ''}`}
       collapsed={collapsed}
       style={{
        marginLeft: collapsed ? 80 : 180,
      }}
       >
        <div >
          <Button type="text" onClick={toggleCollapsed} style={{ marginTop: "24px", marginLeft: '-35px', width: '5px', height: '20px', color:"#fff", fontSize:"16px" }} >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </div>
              <>
                        <div className="divContain" style={{}}>
                              <div className="divBellIcon">
                                <BellOutlined />
                              </div>
                              <div>
                              <Avatar className="bAvatar" >{userData.user.Prenom.charAt(0).toUpperCase()}</Avatar>
                              </div>
                        </div>
                    <div className='divContainNameConnect'>
                      <CustomDropdown options={SelectUserOptions}
                      children={
                          <>
                          {/* onClick={onLogoutClick} */}
                          <div className="divDropdwn">{userData.user.Prenom} <DownOutlined type="down"  /></div>
                          </> } />
                    </div>
              </>
      </Header>
    </Layout>
          <ModalComponent 
            _open={open}
            _closable={true} // Supprime l'icône de fermeture
            _maskClosable={false} // Permet de fermer en cliquant en dehors du modal
            onCancel={onCloseProfil}
            _title={
                    <>
                       <strong> <p style={{marginBottom:"-10px", fontSize:"20px"}}> Mon profil</p></strong>
                        <p style={{fontSize:"12px", paddingBottom:"25px",}}>Gérez ici vos informations personnelles</p>
                       
                    </>}
            _width="70vw"
            _height=""
             _top="61px"
            form={
              <form>
                    <div>
                          {InfoUser ? (
                        <BtnSubmit 
                                float_="right"
                                width_="7.6vw"
                                height_="6vh"
                                type_="submit"
                                margin_="-75px 0 0"
                                bgNormalColor="linear-gradient( 310deg, rgb(33, 82, 255), rgb(33, 212, 253))"
                                buttonText='Enregistrer'
                                color="#fff"
                                boldColor={500}
                                border_="none"
                                borderRadius_="0.5rem"
                                onClick={handleSubmit}
                              /> ):(
                            <BtnSubmit 
                                float_="right"
                                width_="7.6vw"
                                height_="6vh"
                                type_="submit"
                                margin_="-75px 0 0"
                                bgNormalColor="linear-gradient( 310deg, rgb(33, 82, 255), rgb(33, 212, 253))"
                                buttonText='Modifier'
                                color="#fff"
                                boldColor={500}
                                border_="none"
                                borderRadius_="0.5rem"
                                onClick={handleOpenInfoUser}
                              />)}
                        </div>
                        <div style={{ 
                            backgroundColor: "#f3f8fa", 
                            borderRadius: "1rem", 
                            border: "1.3px solid #d9d9d9", // Ajoute une bordure
                            padding: "35px", // Espace intérieur de la bordure
                            position: "relative", // Position relative pour manipuler le titre
                          }}>
                          <table>
                            {/* Le titre de la section */}
                            <div style={{
                              position: "absolute", 
                              top: "-15px", 
                              left: "18px", 
                              backgroundColor: "#fff", 
                              padding: "0 3px", 
                              fontWeight: "bold", 
                              fontSize: "16px",
                              borderRadius:"0.5rem"
                            }}>
                              Informations générales
                            </div>
                            <div style={{display:"flex",gridGap:"215px", marginLeft:"8px"}}>
                              <div style={{ marginRight:"35px"}}>
                                    <div style={{marginBottom:"8px"}} >Titre</div>
                                    {!InfoUser ? (<div style={{marginRight:"100px",marginLeft:"3px" }} ><strong>{InputDataProfil.Titre}</strong></div>
                                      ):(<Input placeholder="Entrez votre titre"
                                      name='Titre' value={InputDataProfil.Titre} 
                                      onChange={handleChangeInputProfil}
                                      style={{width:"250px" }} />)}
                                </div>
                                <div>
                                    <div style={{marginLeft:"-5px", marginBottom:"8px"}}>Email</div>
                                    {!InfoUser ? (<div style={{marginLeft:"-5px"}}> <strong>{userData.user.Email}</strong></div>
                                      ):(<Input disabled placeholder="Entrez votre email"
                                      name='Email' value={userData.user.Email}
                                      // onChange={handleChangeInputProfil}
                                       style={{width:"250px", marginLeft:"-5px" }} />)}
                                </div>
                            </div>
                                <div style={{display:"flex",gridGap:"215px", marginLeft:"8px",}}>
                                    <div style={{ marginRight:"35px",marginTop:"50px"}}>
                                      <div style={{marginBottom:"8px"}}>Prénom</div>
                                       {!InfoUser ? (<div style={{marginLeft:"",marginRight:"100px"}}> <strong>{InputDataProfil.Prenom}</strong></div>
                                        ):(<Input placeholder="Entrez le prénom" name='Prenom' value={InputDataProfil.Prenom}
                                          onChange={handleChangeInputProfil}
                                         style={{width:"250px" }} />)}
                                     </div>
                                    <div style={{marginTop:"50px",marginLeft:"-10px"}}>
                                        <div style={{marginBottom:"8px"}} >Nom</div>
                                        {!InfoUser ? (<div style={{}} ><strong>{InputDataProfil.Nom}</strong></div>
                                          ):(<Input placeholder="Entrez votre nom" name='Nom' value={InputDataProfil.Nom} 
                                            onChange={handleChangeInputProfil}
                                           style={{width:"250px" }} />)}
                                    </div>
                                    </div>
                          </table>
                          <div style={{padding:"5px", alignItems:"center", display:"flex", gap:"5px", marginLeft:"4px", marginTop:"20px"}}>
                            <Button onClick={handleOpenModalMDP} size="middle" style={{fontSize:"15px", backgroundColor:"#dcf3fc", color:"#21D2FD",padding:"5px", border:"none" }} variant="filled">
                              <LockOutlined />
                              <div>Changer de mot de passe</div>
                            </Button>
                          </div>
                    </div><br/><br/><br/>
              </form>}
          />
          <ModalComponent 
          _title={<div style={{fontSize:"15px"}}>Modifier le mot de passe</div>}
             _open={OpenModalMDP}
             onCancel={handleCloseModalMDP}
             _width="36vw"
            _height=""
             _top="80px"
             form={<Form>
             <br/>
                    <div style={{padding:"5px"}}>
                    <div style={{padding:"10px"}}>
                    <Row>
                      <Col span={24}>
                          <Input.Password required
                          size='large'
                            placeholder="Ancien mot de passe"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            value={currentPassword}
                            name="currentPassword"
                            onChange={(e) => setCurrentPassword(e.target.value)}
                          />
                          </Col>
                          </Row><br/>
                    <Row>
                      <Col span={24}>
                          <Input.Password required
                          size='large'
                            // className="inputText"
                            placeholder="Nouveau mot de passe"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            value={newPassword}
                            name="newPassword"
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                          </Col>
                          </Row><br/>
                          <Row>
                          <Col span={24}>
                          <Input.Password required
                          size='large'
                            placeholder="Confirmer le nouveau mot de passe"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            value={newPassword_confirmation}
                            name="newPassword_confirmation"
                            onChange={(e) => setNewPassword_confirmation(e.target.value)}
                          />
                          </Col>
                          </Row><br/>
                          </div>
                          <Row>
                          <Col span={24}>
                          <BtnSubmit
                            float_="right"
                            width_="7.6vw"
                            height_="6vh"
                            type_="submit"
                            bgNormalColor="linear-gradient( 310deg, rgb(33, 82, 255), rgb(33, 212, 253))"
                            buttonText="Enregistrer"
                            color="#fff"
                            boldColor={500}
                            border_="none"
                            borderRadius_="0.5rem"
                            onClick={handleSubmitPassword}
                          />
                      </Col>
                    </Row>
                    </div>
                  </Form>}
          />
    </>
  );
};

export default Topbar;
