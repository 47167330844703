import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './search.css';

const { Search } = Input;

export const BtnSearch = ({type_, size_,borderRadius_,bgNormalColor,boldColor,bgHoverColor,color_, width_, margin_, height_ , position_, border_, onClick_, onChange_,allowClear}) => {
   
    const buttonStyle = {
        width: width_ || '200px',
        margin: margin_,
        backgroundColor: bgNormalColor,
        color: color_,
        fontWeight: boldColor,
        height: height_,
        position: position_,
        border: border_,
        borderRadius: borderRadius_,
        cursor: 'pointer',
        size: size_,
        
        
       
    };
   
   
    return (
        <div>
            <Input 
                onClick= {onClick_}
                onChange={onChange_}
                className='InpSpan'
                placeholder="Rechercher" 
                prefix={<SearchOutlined className="iconBtnSearch" />}
                type={type_}
            style={buttonStyle}
            onMouseOver={(e) => e.target.style.backgroundColor = bgHoverColor}
            onMouseOut={(e) => e.target.style.backgroundColor = bgNormalColor}
            allowClear={allowClear}
                
            />
        </div>
    );
};
