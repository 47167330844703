import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

export const fetchFichesRCP = createAsyncThunk(
  "FichesRCP/getFichesRCP",
  async (id) => {
    try {
        
      const response = await api.getAllFichesRCP(id);
      
      const listeFichesRCP = response.data; 
      return listeFichesRCP; 
      
    } catch (error) {
      throw error; 
    }
  }
);

export const fichesRCPSlice = createSlice({
    name: "FichesRCP",
    initialState: {
      data: [], 
      isSuccess: false,
      loading: false,
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchFichesRCP.pending, (state) => {
          state.loading = true;
          state.isSuccess = false;
        })
        .addCase(fetchFichesRCP.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.isSuccess = true;
          state.data = payload;
        })
        .addCase(fetchFichesRCP.rejected, (state) => {
          state.loading = false;
          state.isSuccess = false;
        });
    },
  });
  
export default fichesRCPSlice.reducer;
