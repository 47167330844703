import React, { useEffect } from 'react';
import { fetchResumePatient } from '../redux/ResumePatientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import ResumePatient from '../views/patients/ResumePatient';

const PdfView = ({PatientId}) => {
       const dataResumePatient = useSelector((state) => state.resumePatient.data);
    // Fetch medecin from API
    const dispatch = useDispatch();
    // console.log(dataResumePatient);
        
    useEffect(() => {
      dispatch(fetchResumePatient(PatientId));
    }, [dispatch, PatientId]);
    return (
        <div style={{marginTop:"70px",width:"100%",height:"100vh",overflow:"hidden"}}>
            <PDFViewer fileName="Summary.pdf" style={{width:"100%",height:"100%"}}>
                <ResumePatient inputData={dataResumePatient}/>
            </PDFViewer>            
        </div>
    );
};

export default PdfView;