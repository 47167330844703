import React, { useEffect, useState } from 'react';
import { BtnAjout, BtnSearch, BtnSubmit, InputText, ModalComponent, SelectInput, Tableau } from '../../components';
import { Col, Modal, notification, Row, Space, Tooltip, } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import "./../patients/patient.css";
import { useCollapsed } from '../dashBoard/CollapsedContext';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPathologies } from '../../redux/PathologiesSlice';
import { fetchSpecialite } from '../../redux/specialiteSlice';
import { createPathologie } from '../../redux/CreatePathologieSlice';
import { updatePathologie } from '../../redux/UpdatePathologieSlice';
import { updateArchivPathologie } from '../../redux/ArchivPathologieSlice';


const Pathologie = () => {
  const [refreshTable, setRefreshTable] = useState(false);
  const collapsed = useCollapsed();

  const dispatch = useDispatch();
  const { data: dataPathologies, loading, isSuccess } = useSelector((state) => state.pathologies.data);
  
  useEffect(() => {
    dispatch(fetchPathologies());
  }, [dispatch, refreshTable]);

  const [open, setOpen] = useState(false);
  const [NomPathologie, setNomPathologie] = useState(); 
  const [SpecialiteValue, setSpecialiteValue] = useState(null);
  const [openUpdateForm, setOpenUpdateForm] = useState("");
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [updatingDataId, setUpdatingDataId] = useState();
  const [pathologieData, setPathologieData] = useState();
  const [UpdatePathologiedata, setUpdatePathologiedata] = useState(null);
  const [SpecialiteId, setSpecialiteId] = useState();
  const [openModalConfirmArchiv, setOpenModalConfirmArchiv] = useState(false);
  const [ArchivPathologieId, setArchivPathologieId] = useState(null);
  const [error, setError] = useState('');


  const handleOpenModalConfirmArchiv = (record) => {
    setArchivPathologieId(record.id);
    setOpenModalConfirmArchiv(true);
  }
  const handleCancelArchiv = () => {
    setOpenModalConfirmArchiv(false);
    setArchivPathologieId(null);
    }

  const handleOpen  = () => {
    setOpen(true);
    };
    const onCancel  = () => {
      setOpen(false);
       // Réinitialise les champs du formulaire après enregistrement
      setNomPathologie("");
      setSpecialiteValue("");
  };
  const onCloseUpdateForm = () => {
    setOpenUpdateForm(false);
    setNomPathologie("");
    setSpecialiteId("");
};
const handleOpenModalConfirmation = (record) => {
  setUpdatePathologiedata(record);
  setOpenModalConfirmation(true);
}
const handleCancel = () => {
  setOpenModalConfirmation(false);
  setUpdatePathologiedata(null);
  }
    // Get specialities from Redux state
    const { data: dataSpecialite } = useSelector((state) => state.specialite.data);
     // Fetch specialities from API on component mount
     useEffect(() => {
      dispatch(fetchSpecialite());
    }, [dispatch]);
    
     // Fonction pour obtenir le nom du centre par son ID
     const getNomSpecialiteById = (id) => {
      const specialite = dataSpecialite?.find((item) => item.id === id); 
      return specialite ? specialite.NomSpecialite : null; 
    };
   
    const colomnsPathologies = [
      {
        title: 'Nom de la pathologie',
        dataIndex: 'NomPathologie',
        key: 'NomPathologie',
        className: 'colonText',
        width: 500,
      },
      {
        title: 'Spécialité',
        dataIndex: 'SpecialiteId',
        key: 'SpecialiteId',
        className: 'colonText',
        render: (SpecialiteId) => {
          const nomSpecialite = getNomSpecialiteById(SpecialiteId); // Utilisez la fonction pour obtenir le nom de la spécialité
          return <span>{nomSpecialite}</span>; // Afficher le nom ou un texte par défaut
        },
        width: 400,
      },
      {
        title: 'Options',
        key: 'options',
        render: (record) => (
          <Space size="large">
            <Tooltip title="Editer">
            <EditFilled 
                      style={{ color: "#4096FF", fontSize: "15px" }} 
                      onClick={() => handleOpenModalConfirmation(record)}
                       />
              </Tooltip>
              <Tooltip title="Archiver">
            <DeleteFilled style={{ color: '#E00B0B', fontSize: '15px' }} 
            onClick={() => handleOpenModalConfirmArchiv(record)}/>
          </Tooltip>
          </Space>
        ),
        className: 'colonText',
      },
    ];
      // Mapper les spécialités
   // Mapper les spécialités pour le SelectInput
      const specialitesOptions = (dataSpecialite || []).map((specialite) => ({
        label: specialite.NomSpecialite, // Assurez-vous que 'NomSpecialite' est correct
        value: specialite.id, // Assurez-vous que 'id' est correct
      }));
    const handleChangeSpecialite = (value) =>{     
      setSpecialiteValue(value);
    }
      // Fonction handleSubmit pour gérer la soumission du formulaire 
      const handleSubmit = async (e) => {
        e.preventDefault(); // Empêche le rechargement de la page à la soumission du formulaire

        // Création d'un objet specialiteData contenant le nom d specialite
        let pathologieData = {
          NomPathologie: NomPathologie,
          SpecialiteId:SpecialiteValue,
        };
        if (!SpecialiteValue) {
          setError('La spécialité est requis'); 
          notification.error({
            message: 'Erreur',
            description: 'Veuillez sélectionner la spécialité.',
            duration: 4,
          });
          return; 
        }

        try {
          const result = await dispatch(createPathologie(pathologieData)).unwrap(); // Unwrap pour accéder directement à la réponse
          notification.success({
            message: "Création de pathologie réussie",
            description: result.message,
            duration: 6,
          });
          await dispatch(fetchPathologies());
          // Ferme le modal après enregistrement
          onCancel(); 
        }
        catch (err) {
          notification.error({
            message: "Erreur d'enregistrement",
            description: err.message,
            duration: 6,
          });
        }
      };

      const [searchValue, setSearchValue] = useState('');

      const filtrData = dataPathologies?.filter(item => {
        const nomSpecialite = getNomSpecialiteById(item.SpecialiteId);
        return (
          item.NomPathologie.toLowerCase().includes(searchValue.toLowerCase()) ||
          nomSpecialite.toLowerCase().includes(searchValue.toLowerCase()) 
        );
      });

      const [inputData, setInputData] = useState({
        NomPathologie: '',
        SpecialiteId: '',
        
      });
    
      const handleOpenUpdateForm = (record) => {
        setOpenModalConfirmation(false);
        setOpenUpdateForm(true);
        setInputData(
        {
          NomPathologie: record.NomPathologie || '',
          SpecialiteId: record.SpecialiteId || '',
          
        });
    
      setUpdatingDataId(record.id);
      };
      const handleChange = (value,field) => {
        setInputData({
          ...inputData, 
          [field]: value, 
        });
      };
    
      const handleUpdateForm = async (e) => {
        e.preventDefault();
        try {
          const result = await dispatch(updatePathologie({ id:updatingDataId,body:inputData })).unwrap();
          notification.success({
            message: "Patient mis à jour avec succès",
            description: result.message,
            duration: 6,
          });
          setPathologieData(inputData);
          setOpenUpdateForm(false);
          await dispatch(fetchPathologies());  
        } catch (err) {
          notification.error({
            message: "Erreur de mise à jour",
            description: err.message,
            duration: 6,
          });
        }
      };

      const handleArchivPathologie = async (id) => {
        try {
          const result = await dispatch(updateArchivPathologie(id)).unwrap();
          notification.success({
            message: "Pathologie archivé avec succès",
            description: result.message,
            duration: 6,
          });
          await dispatch(fetchPathologies());
          setOpenModalConfirmArchiv(false);  
        } catch (errorMessage) {
          notification.error({
            message: "Erreur de mise à jour",
            description: errorMessage.message,
            duration: 6,
          });
        }
      };
    

    return (
        <div className='main'>
          <div className="buttons-container" style={{ marginLeft: collapsed ? 192 : 90 }}>
          <BtnSubmit 
                  type_="button"
                  onClick={handleOpen}
                  className="btnAjouter"
                  width_="100px"
                  margin_="0px 65px -10px 640px"
                  buttonText="Ajouter"
                  />
                    <ModalComponent _maskClosable={false}
                     onCancel={onCancel}
                    _open={open} 
                    _width="420px" 
                    _title= "Ajouter une pathologie"
                    form=
                    {
                      <form 
                      onSubmit={handleSubmit}
                      >
                        <Row>
                          <Col span={24}>
                          <p>Pathologie</p>
                              <InputText 
                                  size_="large"
                                  required 
                                  value={NomPathologie} 
                                  onChange={(e) => setNomPathologie(e.target.value)}
                                  name="NomPathologie" 
                                  type="text"
                                  placeholder_=" Veuillez saisir le nom de la pathologie"
                              />
                          </Col>
                          <Col span={24}>
                          <p>Spécialité</p>
                                  <SelectInput required 
                                      size_="large"
                                      name="SpecialiteId" 
                                      placeholder_="Veuillez choisir la spécialité"
                                      options={specialitesOptions}
                                      value={SpecialiteValue}
                                      onChange={handleChangeSpecialite}
                                  />
                          </Col>
                        </Row>
                       
                        <br/>
                                    <BtnSubmit
                                          type_="submit"
                                          width_="90px"
                                          bgNormalColor="#78953E"
                                          buttonText="Valider"
                                          color="#fff"
                                          boldColor={700}
                                          height_="37px"
                                          borderRadius_="0.5rem"
                                          border_="none"
                                          bgHoverColor="#4589C7"
                                          float_="right"
                                /><br/><br/>
                        </form>
                                }  
                      />
            
            <BtnSearch margin_="5px 0px -11px -40px" height_="39px" bgNormalColor="#ffffff" border_="1.5px solid #1677FF" 
            onChange_={(e) => setSearchValue(e.target.value)} 
            allowClear/>
          </div>
            
            <Tableau columns={colomnsPathologies} 
             data={filtrData ? [...filtrData].reverse() : []}                 
             title={() => <div style={{ textAlign: 'left', fontWeight:"bold", marginTop:"-6px" }}>Pathologie</div>} 
             loading={loading} /> 


<ModalComponent 
                    _maskClosable={false}
                    onCancel={onCloseUpdateForm}
                    _open={openUpdateForm} 
                    _width="420px" 
                    _title="Ajouter une spécialité"
                    form=
                    {<form onSubmit={handleUpdateForm}>    
                          <Row>
                          <Col span={24}>
                          <p>Pathologie</p>
                              <InputText 
                                  size_="large"
                                  required 
                                  name="NomPathologie" 
                                  type="text"
                                  placeholder_=" Veuillez saisir le nom de la pathologie"
                                  onChange={(e) => handleChange(e.target.value, 'NomPathologie')}  
                                  value={inputData.NomPathologie || ""}
                              />
                          </Col>
                          <Col span={24}>
                          <p>Spécialité</p>
                                  <SelectInput required 
                                      size_="large"
                                      name="SpecialiteId" 
                                      placeholder_="Veuillez choisir la spécialité"
                                      options={specialitesOptions}
                                      onChange={(value) => handleChange(value, 'SpecialiteId')}  
                                      value={inputData.SpecialiteId || ""}
                                  />
                          </Col>
                        </Row>
                       
                        <br/>
                                    <BtnSubmit
                                          type_="submit"
                                          width_="90px"
                                          bgNormalColor="#78953E"
                                          buttonText="Valider"
                                          color="#fff"
                                          boldColor={700}
                                          height_="37px"
                                          borderRadius_="0.5rem"
                                          border_="none"
                                          bgHoverColor="#4589C7"
                                          float_="right"
                                /><br/><br/>
                      </form>
                                }  
                                />
            <Modal
              maskClosable={false}
              closable={false}
              title="Modifier une pathologie" 
              open={openModalConfirmation} 
              onOk={() => handleOpenUpdateForm(UpdatePathologiedata)}       
              onCancel={handleCancel}
              okText="Oui" 
              cancelText="Non">
              <p>Voulez-vous modifier cette pathologie?</p>
            </Modal>

            <Modal
              maskClosable={false}
              closable={false}
              title="Archiver un centre" 
              open={openModalConfirmArchiv} 
              onOk={() => handleArchivPathologie(ArchivPathologieId)}       
              onCancel={handleCancelArchiv}
              okText="Oui" 
              cancelText="Non">
              <p>Voulez-vous archiver ce centre?</p>
            </Modal>

        </div>
    );
};

export default Pathologie;