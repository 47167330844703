import React, { useState, useEffect } from 'react';
import { Avatar, Breadcrumb, Divider, Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import './sideBar.css';
import logo from '../../assets/image/logo.png';
import { CalendarFilled, ContactsFilled, HomeFilled, IdcardFilled, SettingFilled, SmileFilled } from '@ant-design/icons';

const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState('');

  useEffect(() => {
    // Déterminer la clé sélectionnée en fonction du chemin actuel
    const pathToKey = {
      '/dashboard': '1',
      '/dashboard/patient': '2',
      '/dashboard/rcp': '3',
      '/dashboard/medecin': '4',
      '/dashboard/utilisateurs': '51',
      '/dashboard/roles': '52',
      '/dashboard/centre': '61',
      '/dashboard/specialite': '62',
      '/dashboard/pathologie': '63',
      '/dashboard/antecedant': '64',
      '/dashboard/typeantecedant': '65',
    };

    setSelectedKey(pathToKey[location.pathname] || '1');
  }, [location.pathname]);

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  };

  const userData =JSON.parse(localStorage.getItem('user'));
  const userName = userData.user.Prenom;
  const Name = userData.user.Nom;
  const role = userData.user.roles.map(item => item.name).join(', ');
  console.log(role);

  // const itemSideBar = [
  //   {
  //     key: '1',
  //     icon: <HomeFilled style={{ fontSize: "13px" }} />,
  //     label: <Link to="/dashboard">Accueil</Link>,
  //   },
  //   {
  //     key: '2',
  //     icon: <IdcardFilled style={{ fontSize: "13px" }} />,
  //     label: <Link to="/dashboard/patient">Patients</Link>,
  //   },
  //   {
  //     key: '3',
  //     icon: <CalendarFilled style={{ fontSize: "13px" }} />,
  //     label: <Link to="/dashboard/rcp">RCP</Link>,
  //   },
  //   {
  //     key: '4',
  //     icon: <SmileFilled style={{ fontSize: "13px" }} />,
  //     label: <Link to="/dashboard/medecin">Médecins</Link>,
  //   },
  //   {
  //     key: '5',
  //     icon: <ContactsFilled style={{ fontSize: "13px" }} />,
  //     // label: 'Utilisateurs',
  //     label: <Link to="/dashboard/utilisateurs">Utilisateurs</Link>,
  //     // children: [
  //     //   {
  //     //     key: '51',
  //     //     label: <Link to="/dashboard/utilisateurs">Utilisateurs</Link>,
  //     //   },
  //       // {
  //       //   key: '52',
  //       //   label: <Link to="/dashboard/roles">Roles</Link>,
  //       // },
  //     // ],
  //   },
  //   {
  //     key: '6',
  //     icon: <SettingFilled style={{ fontSize: "13px" }} />,
  //     label: 'Paramètres',
  //     children: [
  //       {
  //         key: '61',
  //         label: <Link to="/dashboard/centre">Centre</Link>,
  //       },
  //       {
  //         key: '62',
  //         label: <Link to="/dashboard/specialite">Spécialité</Link>,
  //       },
  //       {
  //         key: '63',
  //         label: <Link to="/dashboard/pathologie">Pathologie</Link>,
  //       },
  //       // {
  //       //   key: '64',
  //       //   label: <Link to="/dashboard/antecedant">Antécédant</Link>,
  //       // },
  //       // {
  //       //   key: '65',
  //       //   label: <Link to="/dashboard/typeantecedant">Type antécédant</Link>,
  //       // },
  //     ],
  //   },
  // ];

  const itemSideBar = [
    {
      key: '1',
      icon: <HomeFilled style={{ fontSize: "13px" }} />,
      label: <Link to="/dashboard">Accueil</Link>,
    },
    {
      key: '2',
      icon: <IdcardFilled style={{ fontSize: "13px" }} />,
      label: <Link to="/dashboard/patient">Patients</Link>,
    },
    {
      key: '3',
      icon: <CalendarFilled style={{ fontSize: "13px" }} />,
      label: <Link to="/dashboard/rcp">RCP</Link>,
    },
    ...(role === 'Admin'
      ? [
          {
            key: '5',
            icon: <ContactsFilled style={{ fontSize: "13px" }} />,
            label: <Link to="/dashboard/utilisateurs">Utilisateurs</Link>,
          },
          {
            key: '6',
            icon: <SettingFilled style={{ fontSize: "13px" }} />,
            label: 'Paramètres',
            children: [
              {
                key: '61',
                label: <Link to="/dashboard/centre">Centre</Link>,
              },
              {
                key: '62',
                label: <Link to="/dashboard/specialite">Spécialité</Link>,
              },
              {
                key: '63',
                label: <Link to="/dashboard/pathologie">Pathologie</Link>,
              },
            ],
          },
        ]
      : []),
  ];
  
  
  const renderBreadcrumbItems = (key, items) => {
    for (const item of items) {
      if (item.key === key) {
        return item.label.props.children;
      }
      if (item.children) {
        const childLabel = renderBreadcrumbItems(key, item.children);
        if (childLabel) {
          return childLabel;
        }
      }
    }
    return null;
  };
  
  

  return (
    <>
    <Layout>
      <Sider
        className={`layoutSidebar ${collapsed ? 'collapsed' : ''}`}
        collapsed={collapsed}
        width={collapsed ? 50 : 180}
      >
        <div>
          <img src={logo} alt="Logo" width={collapsed ? 70 : 100} />
        </div>
        
        <div className='divAvatar'>
          {!collapsed && (
            <Avatar
              style={{
                backgroundColor: 'orange',
                verticalAlign: 'middle',
              }}
              size="large"
            >
              {userName.charAt(0).toUpperCase()}
            </Avatar>
          )}
          <div className='nameConnecte'>
            <p className='nom'>{collapsed ? userName.charAt(0).toUpperCase() + ' '  + Name.charAt(0).toUpperCase()  : userName + ' '  + Name} </p>
            
            {!collapsed && (
              <p className='divP1'>
                <span className='rond'></span>Connecté
              </p>
            )}
          </div>
        </div>
        <Divider style={{ marginTop: '-4px' }} />

        <Menu className='menuItems'
          mode="inline"
          selectedKeys={[selectedKey]}
          onClick={handleMenuClick}
          items={itemSideBar}
        >
        </Menu>
      </Sider>
      <Layout.Content
        style={{
          // minHeight:'auto',
          marginTop: "550px",
          marginLeft: collapsed ? '-100px' : 0,
          //  overflow: 'visible'
          // padding: "0 25px",
          // minHeight: "90vh",
        }}
      >
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item className="breadcrumb-item">
            {renderBreadcrumbItems(selectedKey, itemSideBar)}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Layout.Content>
    </Layout>
    </>
  );
};

export default Sidebar;
