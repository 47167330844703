import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

export const deletePatientRcp = createAsyncThunk(
    "deletingPatientRcp/deletePatientRcpData", 
    async ( {rcpId,idPatientDelet , rejectWithValue }) => {  
        try {
            const { data } = await api.deletPatientRcp(rcpId,idPatientDelet);
            return data; 
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            return rejectWithValue(errorMessage);
        }
    }
);

export const deletePatientRcpSlice = createSlice({
    name: "deletingPatientRcp",
    initialState: {
        data: {}, 
        isSuccess: false, 
        loading: false, 
        error: null, 
    },
    extraReducers: (builder) => {
        builder
            .addCase(deletePatientRcp.pending, (state) => {
                state.loading = true;
                state.isSuccess = false;
                state.error = null;
            })
            .addCase(deletePatientRcp.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.isSuccess = true;
                state.data = payload; 
                state.error = null;
            })
            .addCase(deletePatientRcp.rejected, (state, { payload }) => {
                state.loading = false;
                state.isSuccess = false;
                state.error = payload; 
            });
    },
});

export default deletePatientRcpSlice.reducer;
