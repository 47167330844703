import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Création d'une thunk asynchrone pour mettre à jour les informations d'un utilisateur
export const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async ({ id, updatedPassword }, { rejectWithValue }) => {
    try {
      // Appel API pour mettre à jour les informations d'un utilisateur avec les données fournies
      const { data } = await api.updatePassword(id, updatedPassword);
      return data; // Retourne les données en cas de succès
    } catch (error) {
      // En cas d'erreur, récupère le message d'erreur de la réponse
      const errorMessage = error?.response?.data?.message || error.message;
      // Utilise rejectWithValue pour renvoyer l'erreur à l'appelant
      return rejectWithValue(errorMessage);
    }
  }
);

// Création du slice avec l'état initial et la gestion des reducers pour chaque étape de la promesse
export const updatePasswordSlice = createSlice({
  name: "password", // Nom du slice
  initialState: {
    data: {}, // Données de l'utilisateur
    isSuccess: false, // Indique si la mise à jour a réussi
    loading: false, // Indique si l'appel API est en cours
    error: null, // Stocke le message d'erreur en cas d'échec
  },
  reducers: {}, // Si vous avez des reducers synchrones, vous pouvez les ajouter ici
  extraReducers: (builder) => {
    builder
      .addCase(updatePassword.pending, (state) => {
        state.loading = true; // Démarre le chargement
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state, { payload }) => {
        state.loading = false; // Termine le chargement
        state.isSuccess = true; // La mise à jour est réussie
        state.data = payload; // Stocke les données mises à jour
        state.error = null;
      })
      .addCase(updatePassword.rejected, (state, { payload }) => {
        state.loading = false; // Termine le chargement
        state.isSuccess = false; // Échec de la mise à jour
        state.error = payload; // Stocke le message d'erreur
      });
  },
});

export default updatePasswordSlice.reducer;
