import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Fonction pour récupérer les Medecins depuis l'API
export const fetchMedecinsInvite = createAsyncThunk(
  "MedecinsInvite/getMedecinsInvite",
  async (rcpId) => {
    try {
      const response = await api.getAllMedecinsInvite(rcpId);
      const listeMedecinsInvite = response.data; // Récupère uniquement la clé 'data'
      return listeMedecinsInvite; // Retourne la liste des médecins
    } catch (error) {
      throw error; // Capture et renvoie l'erreur
    }
  }
);

// Création du slice pour les Medecins
export const medecinsInviteSlice = createSlice({
  name: "MedecinsInvite",
  initialState: {
    data: [],
    isSuccess: false,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMedecinsInvite.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.error = null; // Réinitialiser l'erreur en cas de nouvelle tentative
      })
      .addCase(fetchMedecinsInvite.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = true;
        state.data = payload; // Stocker les données reçues
        state.error = null; // Aucune erreur si la requête est réussie
      })
      .addCase(fetchMedecinsInvite.rejected, (state, action) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = action.error.message; // Capturer le message d'erreur
      });
  },
});

// Exporter le réducteur pour l'utiliser dans le store
export default medecinsInviteSlice.reducer;
