import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Fonction pour récupérer les Patients depuis l'API
export const fetchPatients = createAsyncThunk(
  "Patients/getPatients",
  async () => {
    try {
      const response = await api.getAllPatients();
      const listePatients = response.data; 

    //   console.log("API Response:", response); // Vérifiez la réponse de l'API
      return listePatients; // Assurez-vous que `data` est bien présent
      
    } catch (error) {
    //   console.error("Error fetching Patient:", error);
      throw error; // Lancer l'erreur pour être capturée dans le reducer
    }
  }
);

// Création du slice pour les Patient
export const patientsSlice = createSlice({
    name: "Patients",
    initialState: {
      data: {}, // Assurez-vous que 'data' est initialisé comme un tableau vide
      isSuccess: false,
      loading: false,
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchPatients.pending, (state) => {
          state.loading = true;
          state.isSuccess = false;
        })
        .addCase(fetchPatients.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.isSuccess = true;
          state.data = payload;
        })
        .addCase(fetchPatients.rejected, (state) => {
          state.loading = false;
          state.isSuccess = false;
        });
    },
  });
  
// Exporter le réducteur pour l'utiliser dans le store
export default patientsSlice.reducer;
