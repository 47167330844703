import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Fonction pour récupérer les PatientsRCP en fonction de l'ID de la RCP
export const fetchPatientsRCP = createAsyncThunk( 
  "PatientsRCP/getPatientsRCP",
  async (rcpId) => {  // Passer l'ID de la RCP en paramètre
    try {
      const response = await api.getAllPatientsRCP(rcpId); // Utiliser l'ID pour l'appel API
      const listePatientsRCP = response.data; // Récupérer uniquement la clé 'data'
      return listePatientsRCP; // Retourner la liste des PatientsRCP
    } catch (error) {
      throw error; // Capturer et renvoyer l'erreur
    }
  }
);

// Création du slice pour les PatientsRCP
export const patientsRCPSlice = createSlice({
  name: "PatientsRCP",
  initialState: {
    data:[], 
    isSuccess: false,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatientsRCP.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.error = null; // Réinitialiser l'erreur en cas de nouvelle tentative
      })
      .addCase(fetchPatientsRCP.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = true;
        state.data = payload; // Stocker les données reçues
        state.error = null; // Aucune erreur si la requête est réussie
      })
      .addCase(fetchPatientsRCP.rejected, (state, action) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = action.error.message; // Capturer le message d'erreur
      });
  },
});

// Exporter le réducteur pour l'utiliser dans le store
export default patientsRCPSlice.reducer;
