import React, {useEffect, useState} from 'react';
import { BtnSearch, Tableau } from '../../components';
import "../../components/tableaux/tableau.css";
import "./../patients/patient.css";
import { useCollapsed } from '../dashBoard/CollapsedContext';
import { fetchMedecins } from '../../redux/MedecinSlice';
import { useDispatch, useSelector } from 'react-redux';



const colomnsMedecin = [
  {
    title: 'Titre',
    dataIndex: 'Titre',
    key: 'Titre',
    width:'5%',
    className: 'colonText',
  },
  {
    title: 'Prénom',
    dataIndex: 'Prenom',
    key: 'Prenom',
    className: 'colonText',
  },
  {
    title: 'Nom',
    dataIndex: 'Nom',
    key: 'Nom',
    className: 'colonText',
  },
  {
    title: 'Identifiant',
    key: 'Username',
    dataIndex: 'Username',
    className: 'colonText',
    
  },
  {
      title: 'Email',
      key: 'Email',
      dataIndex: 'Email',
      className: 'colonText',
      width:'14%',
      
    },
    {
      title: 'Spécialité',
      key: 'specialite',
      dataIndex: 'specialite',
      className: 'colonText',
      width:'8%',
      render: (specialite) => (
        <p style={{ fontSize: '12px', margin: 0 }}>
          {specialite?.NomSpecialite || ''}
        </p>
      ),
    },
    {
      title: 'Centre',
      key: 'Centres',
      dataIndex: 'Centres',
      width:'18%',
      className: 'colonText',
      render: (Centres) => (
        <div>
          {Centres.map((centre, index) => (
            <p key={index} style={{ fontSize: '12px', margin: 0 }}>
              {centre.NomCentre}
            </p>
          ))}
        </div>
      ),
    },
    // {
    //   title: 'Statut',
    //   key: 'Active',
    //   dataIndex: 'Active',
    //   render: (text, record) => (
    //     record.Active ? 'Active' : 'Inactive'
    //   ),
    // }
    

  ];
 
const Medecin = () => {
  const [refreshTable, setRefreshTable] = useState(false);
      const dispatch = useDispatch();
       // Get patients from Redux state
       const { data: dataMedecins, loading, isSuccess } = useSelector((state) => state.medecins.data);
       // Fetch medecin from API
           
       useEffect(() => {
         dispatch(fetchMedecins());
       }, [dispatch,refreshTable ]); //dataMedecins

    const collapsed = useCollapsed();

    const [searchValue, setSearchValue] = useState('');

    const filtrData = dataMedecins?.filter(item => {
      const nomSpecialite = item.specialite?.NomSpecialite || ''; // Accéder à NomSpecialite
      const nomCentres = item.Centres?.map(centre => centre.NomCentre).join(', ') || ''; // Accéder à chaque NomCentre et les joindre en une chaîne
    
      return (
        item.Titre.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.Nom.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.Email.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.Prenom.toLowerCase().includes(searchValue.toLowerCase()) ||
        nomSpecialite.toLowerCase().includes(searchValue.toLowerCase()) || // Recherche sur la spécialité
        nomCentres.toLowerCase().includes(searchValue.toLowerCase()) || // Recherche sur les centres
        item.Username.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    

   return (
       <div>
         <div className="buttons-container" style={{ marginLeft: collapsed ? 1012 : 910 }} >
           <BtnSearch 
           margin_="0px 0px -11px -55px" 
           height_="39px" 
           bgNormalColor="#ffffff" 
           border_="1.5px solid #1677FF" 
           onChange_={(e) => setSearchValue(e.target.value)}
           allowClear
           />
         </div>
         <div>
           <Tableau columns={colomnsMedecin} 
                data={filtrData ? [...filtrData].reverse() : []} 
                title={() => <div style={{ textAlign: 'left', fontWeight:"bold", marginTop:"-6px" }}>Médecin</div>} 
                loading={loading} /> 
         </div>
       </div>
   );
};

export default Medecin;