import React, { useEffect, useState } from 'react';
import { BtnAjout, BtnSearch, BtnSubmit, InputText, ModalComponent, Tableau } from '../../components';
import { Col, notification, Row, Space, Tooltip, } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import "./../patients/patient.css";
import { useCollapsed } from '../dashBoard/CollapsedContext';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTypeAntecedant } from '../../redux/TypeAntecedantSlice';
import { createTypeAntecedant } from '../../redux/CreateTypeAntecedantSlice';

      const colomnsTypeAntecedant = [
          {
            title: "Nom du type d'antécédent",
            dataIndex: 'Name',
            key: 'Name',
            className: 'colonText',
            width: '70%',
            
          },
          {
            title: 'Options',
            key: 'options',
            render: () => (
              <Space size="large">
              <Tooltip title="Editer">
                    <EditFilled style={{color: "#4096FF", fontSize:"15px"}} />
                  </Tooltip>

                  <Tooltip title="Supprimer">
                    <DeleteFilled style={{color:"#E00B0B", fontSize:"15px"}} />
                  </Tooltip>
              </Space>
            ),
            className: 'colonText',
          },
        ];
      

      const Typeantecedant = () => {
        const [refreshTable, setRefreshTable] = useState(false); 
        const [Name, setName] = useState("");
        const [open, setOpen] = useState(false); 

                const handleOpen  = () => {
                  setOpen(true);
              };
              const onCancel  = () => {
                setOpen(false);
                setName("");
              };

            const dispatch = useDispatch();
            const { data: dataTypeAntecedant, loading, isSuccess } = useSelector((state) => state.typeAntecedant.data);
            useEffect(() => {
              dispatch(fetchTypeAntecedant());
            }, [dispatch,refreshTable]);

              const collapsed = useCollapsed();

          const handleSubmitForm = async (e) => {
            e.preventDefault();
            let typeAntecedantData = {
              Name: Name,
            };
            try {
              const result = await dispatch(createTypeAntecedant(typeAntecedantData))
              .unwrap(); // Unwrap pour accéder directement à la réponse
              notification.success({
                message: "Création de type d'antécédent réussie",
                description: result.message,
                duration: 6,
              });
              setRefreshTable((prev) => !prev);
              // Réinitialise les champs du formulaire après enregistrement
              setName("");
              // Ferme le modal après enregistrement
              onCancel(); // Appel correct de la fonction pour fermer le modal
            } 
            catch (err) {
              notification.error({
                message: "Erreur d'enregistrement",
                description: err.message || "Une erreur est survenue",
                duration: 6,
              });
            }
          };

    return (
        <div className='main'>
           <div className="buttons-container" style={{ marginLeft: collapsed ? 192 : 90 }} >
              <BtnSubmit type_="button"
                  onClick={handleOpen}
                  className="btnAjouter"
                //   top_="120px"
                  width_="100px"
                  margin_="0px 65px -10px 640px"
                  buttonText="Ajouter"
                  />
                    <ModalComponent  
                    _maskClosable={false}
                    onCancel={onCancel}
                    // _top=""
                    _open={open} 
                    _title="Ajouter un type d'antécédent"
                    _width="450px" 
                    // _height={_height} 
                    form={
                      <form onSubmit={handleSubmitForm}>
                      <div>
                       <br/>
                       <Row>
                         <Col span={24}>
                           <InputText 
                              required
                              name="Name" 
                              size_="large"
                             value={Name}
                             onChange={(e) => setName(e.target.value)}
                             type="text"
                             placeholder_=" Veuillez saisir le nom du type d'antécédent"
                                 />
                         </Col>
                       </Row><br/>
                               
                               <BtnSubmit float_="right"
                                           type="submit"
                                           width_="6.5vw"
                                           bgNormalColor="#78953E"
                                           buttonText="Valider"
                                           color="#fff"
                                           boldColor={700}
                                           height_="37px"
                                           borderRadius_="0.5rem"
                                           border_="none"
                                           bgHoverColor="#4589C7"
                                       /><br/><br/>
                       </div>
                      </form>
                    }  
                    />


          <BtnSearch margin_="5px 0px -11px -40px" height_="39px" bgNormalColor="#ffffff" border_="1.5px solid #1677FF" />
          </div>
            
            <Tableau columns={colomnsTypeAntecedant} 
                data={dataTypeAntecedant ? [...dataTypeAntecedant].reverse() : []} 
                title={() => <div style={{ textAlign: 'left', fontWeight:"bold", marginTop:"-6px" }}>Type d'antécédent</div>} 
                loading={loading} /> 

        </div>
    );
};

export default Typeantecedant;