import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

export const createAjoutPatientRCP = createAsyncThunk(
    "creatingAjoutPatientRCP/creatingAjoutPatientRCPData",
    async ({rcpId,patientIds}, { rejectWithValue }) => {
      try {
        // Envoie le tableau des IDs au backend
        const { data } = await api.createAjoutPatientRCP(patientIds,rcpId);
        return data; // Retourne les données en cas de succès
      } catch (error) {
        const errorMessage = error?.response?.data?.message || "Erreur lors de l'envoi des Patients";
        return rejectWithValue(errorMessage); // Renvoie l'erreur
      }
    }
  );
  

// Slice pour la gestion de l'ajout des Patients
export const createAjoutPatientRCPSlice = createSlice({
  name: "creatingAjoutPatientRCP",
  initialState: {
    data: {}, // Stocke les données de la création des Patients RCP
    isSuccess: false, // Indique si la création a réussi
    loading: false, // Indique si une création est en cours
    error: null, // Stocke l'erreur s'il y en a une
  },
  extraReducers: (builder) => {
    builder
      // Gestion de l'état pendant que la création est en cours
      .addCase(createAjoutPatientRCP.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.error = null;
      })
      // Gestion de l'état lorsque la création réussit
      .addCase(createAjoutPatientRCP.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = true;
        state.data = payload;
        state.error = null;
      })
      // Gestion de l'état lorsque la création échoue
      .addCase(createAjoutPatientRCP.rejected, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = payload; // Stocke le message d'erreur
      });
  },
});

// Export du reducer par défaut
export default createAjoutPatientRCPSlice.reducer;
