import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
    const isAuthenticated = localStorage.getItem('user') !== null;

    // Si l'utilisateur est authentifié, rend le composant passé
    return isAuthenticated ? element : <Navigate to="/" />;
};

export default PrivateRoute;
