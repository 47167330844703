import React from 'react';
import { Modal } from 'antd';
import "./modalComponent.css";

 const ModalComponent = ({_open, onCancel,_title,_width,_height, form, _top,_maskClosable,_closable}) => {
    return (
        <div>
        <Modal    className='modalComp'
                  title={_title}
                  open={_open}
                  onCancel={onCancel}
                  footer={null}
                 width={_width}
                 height={_height}
                 style={{top:_top}}
                 maskClosable={false || _maskClosable}
                 closable={_closable}
                  >
                    {form}
              </Modal>

  </div>
    );
};


export default ModalComponent;