import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./views/auth/login/Login";
import DashBoard from "./views/dashBoard/DashBoard";
import Register from "./views/auth/registre/Registre";
import PrivateRoute from "./components/PrivateRoute";
import DetailsPatient from "./views/patients/DetailsPatient";
import Patients from "./views/patients/Patients";
import Rcp from "./views/rcp/Rcp";
import Medecin from "./views/medecin/Medecin";
import Utilisateurs from "./views/utilisateur/Utilisateurs";
import Roles from "./views/roles/Roles";
import Centre from "./views/centre/Centre";
import Specialite from "./views/specialite/Specialite";
import Pathologie from "./views/pathologie/Pathologie";
import Antecedant from "./views/antecedant/Antecedant";
import Typeantecedant from "./views/typeantecedant/Typeantecedant";
import ResetPassword from "./views/auth/ResetPassword/ResetPassword";
import ResumePatient from "./views/patients/ResumePatient";
// app.js
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />

          {/* Route protégée */}
          <Route path="/dashboard" element={<PrivateRoute element={<DashBoard />} />} >
            <Route path="rcp" element={<Rcp />} />
            <Route path="medecin" element={<Medecin />} />
            <Route path="utilisateurs" element={<Utilisateurs />} />
            <Route path="roles" element={<Roles />} />
            <Route path="centre" element={<Centre />} />
            <Route path="specialite" element={<Specialite />} />
            <Route path="pathologie" element={<Pathologie />} />
            <Route path="antecedant" element={<Antecedant />} />
            <Route path="typeantecedant" element={<Typeantecedant />} />
            {/* <Route path="accueil" element={< />} /> */}
            {/* <Route path="" element={< />} />  */}
            <Route path="patient" element={<Patients />} />
            <Route path="detailsPatient" element={<DetailsPatient />} />
          </Route>

          <Route path="resume" element={<ResumePatient />} />

          {/* <Route path="/dashboard" element={<PrivateRoute element={<DashBoard />} />} />
          <Route path="/detailsPatient" element={<DetailsPatient />} /> */}
          {/* <Route path="/dashboard" element={<DashBoard />}  /> */}
          {/* Route par défaut */}

          {/* <Route path="*" element="page not found" /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
