import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Thunk pour la création de la demande de réinitialisation de mot de passe
export const createForgotPassword = createAsyncThunk(
  "creatingForgotPassword/creatingForgotPasswordData",
  async (forgotPasswordData, { rejectWithValue }) => {
    try {
      // Appel API pour réinitialiser le mot de passe avec les données fournies
      const { data } = await api.createForgotPassword(forgotPasswordData);
      return data; // Retourne les données en cas de succès
    } catch (error) {
      // En cas d'erreur, récupère le message d'erreur de la réponse
      const errorMessage = error?.response?.data?.message || "Une erreur s'est produite";
      // Utilise rejectWithValue pour renvoyer l'erreur à l'appelant
      return rejectWithValue(errorMessage);
    }
  }
);

// Crée un slice pour gérer l'état de création de la demande de réinitialisation
export const createForgotPasswordSlice = createSlice({
  name: "creatingForgotPassword",
  initialState: {
    data: null, // Stocke les données de la demande de réinitialisation (peut être null si aucune donnée)
    isSuccess: false, // Indique si la demande a réussi
    loading: false, // Indique si une demande est en cours
    error: null, // Stocke l'erreur s'il y en a une
  },
  extraReducers: (builder) => {
    builder
      // Gestion de l'état pendant que la demande est en cours
      .addCase(createForgotPassword.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.error = null;
      })
      // Gestion de l'état lorsque la demande réussit
      .addCase(createForgotPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = true;
        state.data = payload; // Stocke les données reçues
        state.error = null;
      })
      // Gestion de l'état lorsque la demande échoue
      .addCase(createForgotPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = payload; // Stocke le message d'erreur
      });
  },
});

// Exporter le reducer pour l'utiliser dans le store
export default createForgotPasswordSlice.reducer;
