import React, { useState } from "react";
import { BtnSubmit } from "../../../components";
import { Col, Row, Input, notification } from "antd";
import imgChat from "../../../assets/image/chat (2).png";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "../login/login.css";
import { useNavigate, useParams } from "react-router-dom"; 
import { useDispatch } from "react-redux";
import { createResetPassword } from "../../../redux/ResetPasswordSlice";

const ResetPassword = () => {
  const [newPassword_confirmation, setNewPassword_confirmation] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Récupérer le token de l'URL
  const { token } = useParams();

  // Gestion de la soumission du formulaire
  const handleForgotPassword = async (e) => {
    e.preventDefault();

    // Validation des mots de passe
    if (!newPassword || !newPassword_confirmation) {
      notification.error({
        message: "Erreur",
        description: "Veuillez remplir tous les champs.",
        duration: 6,
      });
      return;
    }

    if (newPassword !== newPassword_confirmation) {
      notification.error({
        message: "Erreur",
        description: "Les mots de passe ne correspondent pas.",
        duration: 6,
      });
      return;
    }

    const PasswordData = {
      newPassword: newPassword,
      newPassword_confirmation: newPassword_confirmation,
      token: token, // Inclure le token récupéré dans les données
    };

    try {
      // Envoi des données pour réinitialisation du mot de passe
      const result = await dispatch(createResetPassword({token, PasswordData})).unwrap();
// console.log(token);
      // Notification de succès
      notification.success({
        message: "Mot de passe modifié avec succès",
        description: "Votre mot de passe a été modifié avec succès.",
        duration: 6,
      });
      // Réinitialiser les champs et redirection
      setNewPassword("");
      setNewPassword_confirmation("");
      navigate("/");
    } catch (err) {
      // Gérer les erreurs lors de la soumission
      notification.error({
        message: "Erreur lors de l'envoi",
        description: err.message || "Une erreur est survenue.",
        duration: 6,
      });
    }
  };

  return (
    <>
      <Row>
        <Col span={12}>
          <div className="divForm">
            <form className="form" onSubmit={handleForgotPassword}>
              <Row>
                <Col span={15}>
                  <div className="titreEtP">
                    <h4 className="h4Titre">Changer de mot de passe</h4>
                    <p className="pInput">Entrez votre nouveau mot de passe et confirmez-le</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={15}>
                  <Input.Password
                    required
                    className="inputText"
                    type="password"
                    placeholder="Nouveau mot de passe"
                    value={newPassword}
                    name="newPassword"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <Input.Password
                    required
                    className="inputText"
                    type="password"
                    placeholder="Confirmer le mot de passe"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    value={newPassword_confirmation}
                    name="newPassword_confirmation"
                    onChange={(e) => setNewPassword_confirmation(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={15}>
                  <BtnSubmit
                    className="btnSubmit"
                    type_="submit"
                    margin_="30px auto 0"
                    bgNormalColor="linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))"
                    bgHoverColor="#34A853"
                    buttonText="Enregistrer"
                    color="#fff"
                    boldColor={700}
                    border_="none"
                    borderRadius_="0.5rem"
                  /><br /><br />
                </Col>
              </Row>
            </form>
          </div>
        </Col>
        <Col span={7}>
          <div className="divImageLogo">
            <img src={imgChat} className="virusImg" alt="Virus" />
            <div>
              <h4 className="h4PAtt">
                <p className="h4PAttP">
                  Bienvenue sur la plateforme de réunion de concertation pluridisciplinaire digitalisée
                </p>
              </h4>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ResetPassword;
