import React from 'react';
import { Dropdown, Menu } from 'antd';

export const CustomDropdown = ({ options, placement, onMenuClick, children }) => {
    const menu = (
        <Menu onClick={onMenuClick}>
            {options.map(option => (
                <Menu.Item key={option.key} icon={option.icon}>
                    {option.label}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} placement={placement}>
           <div>{children}</div> 
        </Dropdown>
    );
};

export default CustomDropdown;