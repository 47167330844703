import React, { useState } from 'react';
import { Sidebar, Topbar } from '../../components';
import { Outlet } from 'react-router-dom';
import './dashBoard.css';
import { CollapsedProvider } from './CollapsedContext';

const DashBoard = () => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Topbar collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <Sidebar collapsed={collapsed} />

      <div className='container' style={{ marginLeft: collapsed ? '78px' : '180px', padding:"13px" }}>
        {/* Utilisation du provider pour injecter `collapsed` dans tous les composants enfants */}
        <CollapsedProvider collapsed={collapsed}>
          <Outlet /> {/* Rend les routes enfants, comme Patient.js */}
        </CollapsedProvider>
      </div>
    </div>
  );
};

export default DashBoard;
