import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export const SelectInput = ({ height_,tokenSeparators, listHeight,mode,required, name, placeholder_, options, onChange, value, size_,allowClear }) => {
  return (
    <Select tokenSeparators={tokenSeparators}
    allowClear={allowClear}
      mode={mode}
      size={size_}
      required={required}
      placeholder={placeholder_}
      name={name}
      onChange={onChange}
      value={value}
      listHeight={listHeight}
      style={{ width: '100%', height:height_ }}
    >
      {options.map(option => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default SelectInput;
