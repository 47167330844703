import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

export const fetchAgos = createAsyncThunk(
  "Agos/getAgos",
  async (PatientId) => {
    try {
      const response = await api.getAllAgos(PatientId);
    // console.log(response);
    // console.log(response.data);
      return (response); 
      
    } catch (error) {
      const errorMessage = error?.response?.data?.message 
      return (errorMessage);
    }
  }
);

export const agosSlice = createSlice({
    name: "Agos",
    initialState: {
      data: {}, 
      isSuccess: false,
      loading: false,
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchAgos.pending, (state) => {
          state.loading = true;
          state.isSuccess = false;
        })
        .addCase(fetchAgos.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.isSuccess = true;
          state.data = payload;
        })
        .addCase(fetchAgos.rejected, (state) => {
          state.loading = false;
          state.isSuccess = false;
        });
    },
  });
  
export default agosSlice.reducer;
