import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

const ResumePatient = ({ inputData }) => {
  const styles = StyleSheet.create({
    page: {
      margin: '15px',
    },
    title: {
      fontSize: 12,
      marginBottom: 10,
      textAlign: 'center',
    },
    table: {
      display: 'table',
      width: '100%',
      marginBottom: 10,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableRow1: {
      flexDirection: 'row',
    },
    tableColHeader: {
      width: '100%',
      padding: 5,
      textAlign: 'left',
      backgroundColor: '#5B9BD5',
      color: '#fff',
      fontSize: 12,
      fontWeight: 'bold',
    },
    tableCol: {
      width: '100%',
      padding: 5,
      textAlign: 'justify',
      fontSize: 10,
      backgroundColor: '#D2DEEF',
    },
    antecedent: {
      fontWeight: '400',
    },
    bulletPoint: {
      marginRight: 5,
    },
  });

  if (!inputData || !inputData.patientantecedent) {
    return <p>Chargement des données...</p>;
  }

  const antecedentsDetails = inputData.patientantecedent.map((antecedent) => ({
    antecedentMedical: antecedent.AntecedentMedical,
    antecedentChirurgical: antecedent.AntecedentChirurgical,
    antecedentFamilial: antecedent.AntecedentFamilial,
    AntecedentPerinatal: antecedent.AntecedentPerinatal,
    AntecedentTuberculosePulmonaire: antecedent.AntecedentTuberculosePulmonaire,
  }));

  const diagnosticsDetails = inputData.diagnosticsPatient.map((diagnostic) => ({
    OMS: diagnostic.PsOms,
    SignesCliniques: diagnostic.SignesCliniques,
    StadeClinique: diagnostic.StadeClinique,
    SignesParacliniques: diagnostic.SignesParacliniques,
  }
));

const fichesPatientDetails = inputData.fichesPatient.map((resultatRCP) => ({
  DecisionRcpMiseEnTraitement: resultatRCP.DecisionRcpMiseEnTraitement,
  DecisionRcpExamensComplementaires:resultatRCP.DecisionRcpExamensComplementaires,
  MotivationDecisionRcp:resultatRCP.MotivationDecisionRcp,
}));

// console.log(fichesPatientDetails)

  return (
    <Document title="RCP">
      <Page size="A4" orientation="landscape">
        <View style={styles.page}>
          <Text style={styles.title}>Résumé du patient</Text>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>Etat civil et antécédents</Text>
              <Text style={styles.tableColHeader}>Examen clinique</Text>
              <Text style={styles.tableColHeader}>Examens paracliniques</Text>
              <Text style={styles.tableColHeader}>Décision RCP</Text>
            </View>

            <View style={styles.tableRow1}>
              {/* Etat civil et antécédents */}
              <Text style={styles.tableCol}>
                {inputData.Prenom} {inputData.Nom} {"\n"}
                {"\n"}
                {inputData.Age} ans {"\n"}
                {"\n"}
                 {/* Antécédents */}
                     
                <Text style={styles.antecedent}>Antécédents: {"\n"}</Text>
                
                    {/* Antécédent Médical */}
                    <Text>
                      <Text>• Médical: </Text>
                    {antecedentsDetails.every(details => !details.antecedentMedical) ? (
                      <Text>RAS{"\n"}</Text>
                    ) : (
                      antecedentsDetails.map((details, index) => (
                        details.antecedentMedical && (
                          <Text key={index} style={{ marginLeft: 10 }}>
                            {details.antecedentMedical}{"\n"}
                          </Text>
                        )
                      ))
                    )}
                    </Text>
                    {"\n"}
                    {/* Antécédent Chirurgical */}
                    <Text>
                      <Text>• Chirurgical: </Text>
                    {antecedentsDetails.every(details => !details.antecedentChirurgical) ? (
                      <Text>RAS{"\n"}</Text>
                    ) : (
                      antecedentsDetails.map((details, index) => (
                        details.antecedentChirurgical && (
                          <Text key={index} style={{ marginLeft: 10 }}>
                            {details.antecedentChirurgical}{"\n"}
                          </Text>
                        )
                      ))
                    )}
                    </Text>
                    {"\n"}
                    {/* Antécédent Familial */}
                    <Text>
                      <Text>• Familial: </Text>
                    {antecedentsDetails.every(details => !details.antecedentFamilial) ? (
                      <Text>RAS{"\n"}</Text>
                    ) : (
                      antecedentsDetails.map((details, index) => (
                        details.antecedentFamilial && (
                          <Text key={index} style={{ marginLeft: 10 }}>
                            {details.antecedentFamilial}{"\n"}
                          </Text>
                        )
                      ))
                    )}
                    </Text>
                    {"\n"}
                    {/* Antécédent Périnatal */}
                    <Text>
                      <Text> • Périnatal: </Text>
                    {antecedentsDetails.every(details => !details.AntecedentPerinatal) ? (
                      <Text>RAS{"\n"}</Text>
                    ) : (
                      antecedentsDetails.map((details, index) => (
                        details.AntecedentPerinatal && (
                          <Text key={index} style={{ marginLeft: 10 }}>
                           {details.AntecedentPerinatal}{"\n"}
                          </Text>
                        )
                      ))
                    )}
                  </Text>
                  {"\n"}
                    {/* Antécédent Tuberculose Pulmonaire */}
                    <Text>
                      <Text>• Tuberculose Pulmonaire: </Text>
                    {antecedentsDetails.every(details => !details.AntecedentTuberculosePulmonaire) ? (
                      <Text>RAS{"\n"}</Text>
                    ) : (
                      antecedentsDetails.map((details, index) => (
                        details.AntecedentTuberculosePulmonaire && (
                          <Text key={index} style={{ marginLeft: 10 }}>
                            {details.AntecedentTuberculosePulmonaire}
                          </Text>
                        )
                      ))
                    )}
                      </Text>
                      {"\n"}
                    </Text>

              {/* Examen clinique */}
              <Text style={styles.tableCol}>
              
              <Text>
                  <Text>• OMS = </Text>
                  {
                    diagnosticsDetails.every(
                      (detailsDiagnostic) => !detailsDiagnostic.OMS || detailsDiagnostic.OMS === ''
                    ) ? (
                      <Text> RAS{"\n"}</Text>
                    ) : (
                      diagnosticsDetails.map((detailsDiagnostic, index) => (
                        <Text key={index}>
                          {detailsDiagnostic.OMS && detailsDiagnostic.OMS !== '' && (
                            <Text> {detailsDiagnostic.OMS}{"\n"}</Text>
                          )}
                        </Text>
                      ))
                    )
                  }
                </Text>

              {"\n"}

             {/* Signes Cliniques */}
             <Text>
                <Text>• Signes Cliniques:</Text>
                {diagnosticsDetails.every(
                  (detailsDiagnostic) =>
                    !Array.isArray(detailsDiagnostic.SignesCliniques) || detailsDiagnostic.SignesCliniques.length === 0
                ) ? (
                  <Text> RAS{"\n"}</Text>
                ) : (
                  diagnosticsDetails.map((detailsDiagnostic, index) => (
                    <Text key={index}>
                      {Array.isArray(detailsDiagnostic.SignesCliniques) && detailsDiagnostic.SignesCliniques.length > 0 && (
                        <Text>{detailsDiagnostic.SignesCliniques.join(', ')}{"\n"}</Text>
                      )}
                    </Text>
                  ))
                )
                }
              </Text>

              {"\n"}

             {/* Stade Clinique */}
             <Text>
                <Text>• Stade Clinique:</Text>
                {diagnosticsDetails.every(
                  (detailsDiagnostic) =>
                    !Array.isArray(detailsDiagnostic.StadeClinique) || 
                    detailsDiagnostic.StadeClinique.length === 0
                ) ? (
                  <Text> RAS{"\n"}</Text>
                ) : (
                  diagnosticsDetails.map((detailsDiagnostic, index) => (
                    <Text key={index} style={{ marginLeft: 10 }}>
                      {Array.isArray(detailsDiagnostic.StadeClinique) && 
                      detailsDiagnostic.StadeClinique.length > 0 && (
                        <Text>{detailsDiagnostic.StadeClinique.join(', ')}{"\n"}</Text>
                      )}
                    </Text>
                  ))
                )}
              </Text>


              </Text>

              {/* Examens paracliniques */}
              <Text style={styles.tableCol}>
              
                    {/* Signes paracliniques */}
                    <Text>
                    {diagnosticsDetails.every(
                      (detailsDiagnostic) =>
                        !Array.isArray(detailsDiagnostic.SignesParacliniques) || detailsDiagnostic.SignesParacliniques.length === 0
                    ) ? (
                      <Text> RAS{"\n"}</Text>
                    ) : (
                      diagnosticsDetails.map((detailsDiagnostic, index) => (
                        <Text key={index}>
                          {Array.isArray(detailsDiagnostic.SignesParacliniques) && detailsDiagnostic.SignesParacliniques.length > 0 ? (
                            detailsDiagnostic.SignesParacliniques.map((paraclinique, subIndex) => (
                              <Text key={subIndex}>
                                {paraclinique.type ? (
                                  <>
                                    <Text>• {paraclinique.type} </Text>
                                    {paraclinique.date ? (
                                      <Text>({paraclinique.date})</Text>
                                    ) : null}
                                    <Text>{'\n'}</Text>
                                  </>
                                ) : null}
                                {paraclinique.resultat ? (
                                  <Text> {paraclinique.resultat}{'\n'}</Text>
                                ) : null}{"\n"}
                                {/* Si aucune information n'est présente */}
                                {!paraclinique.type && !paraclinique.date && !paraclinique.resultat && (
                                  <Text>RAS{"\n"}</Text>
                                )}
                              </Text>
                            ))
                          ) : (
                            <Text>{"\n"}</Text>
                          )}
                        </Text>
                      ))
                    )}
                  </Text>{"\n"}
                  

                {"\n"}
              </Text>


              {/* Décision RCP */}
              <Text style={styles.tableCol}>
                  <Text>
                    {fichesPatientDetails.every(
                      (resultatRCP) =>
                        !Array.isArray(resultatRCP.DecisionRcpMiseEnTraitement) || resultatRCP.DecisionRcpMiseEnTraitement.length === 0
                    ) ? (
                      <Text> RAS{"\n"}</Text>
                    ) : (
                      fichesPatientDetails.map((resultatRCP, index) => (
                        <Text key={index}>
                          {Array.isArray(resultatRCP.DecisionRcpMiseEnTraitement) && resultatRCP.DecisionRcpMiseEnTraitement.length > 0 && (
                            <Text> • {resultatRCP.DecisionRcpMiseEnTraitement.join(', ')}{"\n"}</Text>
                          )}{"\n"}
                        </Text>
                      ))
                    )
                    }
                  </Text>
                  {"\n"}
                  {/* DecisionRcpExamensComplementaires */}
                  <Text>
                        {fichesPatientDetails.every(
                          (resultatRCP) =>
                            !resultatRCP.DecisionRcpExamensComplementaires || resultatRCP.DecisionRcpExamensComplementaires === ''
                        ) ? (
                          <Text>RAS{"\n"}</Text>
                        ) : (
                          fichesPatientDetails.map((resultatRCP, index) => (
                            <Text key={index}>
                              {resultatRCP.DecisionRcpExamensComplementaires && (
                                <Text> • {resultatRCP.DecisionRcpExamensComplementaires}{"\n"}</Text>
                              )}
                            </Text>
                          ))
                        )}
                  </Text>
                  {"\n"}
                  {/* MotivationDecisionRcp */}
                  <Text>
                        {fichesPatientDetails.every(
                          (resultatRCP) =>
                            !resultatRCP.MotivationDecisionRcp || resultatRCP.MotivationDecisionRcp === ''
                        ) ? (
                          <Text>RAS{"\n"}</Text>
                        ) : (
                          fichesPatientDetails.map((resultatRCP, index) => (
                            <Text key={index}>
                              {resultatRCP.MotivationDecisionRcp && (
                                <Text> • {resultatRCP.MotivationDecisionRcp}{"\n"}</Text>
                              )}
                            </Text>
                          ))
                        )}
                  </Text>

              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ResumePatient;
