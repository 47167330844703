import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Crée une action asynchrone pour créer une spécialité
export const createSpecialite = createAsyncThunk(
  "creatingSpecialite/creatingSpecialiteData",
  async (specialiteData, { rejectWithValue }) => {
    try {
      // Appel API pour créer une spécialité avec les données fournies
      const { data } = await api.createSpecialite(specialiteData);
      return data; // Retourne les données en cas de succès
    } catch (error) {
      // En cas d'erreur, récupère le message d'erreur de la réponse
      const errorMessage = error?.response?.data?.message || "Une erreur s'est produite";
      // Utilise rejectWithValue pour renvoyer l'erreur à l'appelant
      return rejectWithValue(errorMessage);
    }
  }
);

// Crée un slice pour gérer l'état de création de spécialité
export const createSpecialiteSlice = createSlice({
  name: "creatingSpecialite",
  initialState: {
    data: {}, // Stocke les données de la spécialité créée
    isSuccess: false, // Indique si la création a réussi
    loading: false, // Indique si une création est en cours
    error: null, // Stocke l'erreur s'il y en a une
  },
  extraReducers: (builder) => {
    builder
      // Gestion de l'état pendant que la création est en cours
      .addCase(createSpecialite.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.error = null;
      })
      // Gestion de l'état lorsque la création réussit
      .addCase(createSpecialite.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = true;
        state.data = payload;
        state.error = null;
      })
      // Gestion de l'état lorsque la création échoue
      .addCase(createSpecialite.rejected, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = payload; // Stocke le message d'erreur
      });
  },
});

export default createSpecialiteSlice.reducer;
