import React, { useState, useEffect } from 'react';
import { Button, Input, Tabs, Space, DatePicker, Avatar, Table, Modal, Col, Row, Select, Divider, message, Upload, Checkbox, notification } from 'antd';
import { BtnSubmit } from '../button/BtnSubmit';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModalComponent from '../modal/ModalComponent';
import Diagniostigue from './Diagniostique';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { createFicheRcp } from '../../redux/CreateFicheRcpSlice';
const { Option } = Select;

const { TextArea } = Input;
const { confirm } = Modal;



const Fiche = ({ data, RcpId, close }) => {
    // console.log(data);


    const [motifRcp, setMotifRcp] = useState([]);
    const [traitementRealise, setTraitementRealise] = useState([]);
    const [typeProposition, setTypeProposition] = useState([]);
    const [necessiteExam, setNecessiteExam] = useState('');
    const [miseEnTraitement, setMiseEnTraitement] = useState([]);
    const [motifDecision, setMotifDecision] = useState([]);
    const [typeChirurgie, setTypeChirurgie] = useState('');
    const [typeHepatectomie, setTypeHepatectomie] = useState('')
    const [typeTransplantation, setTypeTransplantation] = useState('');
    const [typeExereseAtypique, setTypeExereseAtypique] = useState('');
    const [TypeHepatoblasmeBasRisque, setTypeHepatoblasmeBasRisque] = useState('');
    const [typeHepatoblasmeHautRisque, setTypeHepatoblasmeHautRisque] = useState('');
    const [TypeHepatoblasmeRisqueStandard, setTypeHepatoblasmeRisqueStandard] = useState('');
    const [TypeHepatocarcinomes, setTypeHepatocarcinomes] = useState('');
    const [typeMetastatiques, setTypeMetastatiques] = useState('')
    const [TypeHCC, setTypeHCC] = useState('');



    const [resectionComplete, setResectionComplete] = useState(false);
    const [resectionIncomplete, setResectionIncomplete] = useState(false);
    const [segmentectomies, setSegmentectomies] = useState(false);
    const [hepatectomies, setHepatectomies] = useState(false);
    const [lobectomies, setLobectomies] = useState(false);
    const [enucleation, setEnucleation] = useState(false);
    const [resectionCuneiforme, setResectionCuneiforme] = useState(false);
    const [chimio, setChimio] = useState(false);
    const [cisPlatPre, setCisPlatPre] = useState(false);
    const [postOp, setPostOp] = useState(false);
    const [cispla, setCispla] = useState(false);
    const [plado, setPlado] = useState(false);
    const [cisplaChimio, setCisplaChimio] = useState(false);
    const [pladoChimio, setPladoChimio] = useState(false);
    const [carboplatine, setCarboplatine] = useState(false);
    const [adria, setAdria] = useState(false);
    const [cisplat, setCisplat] = useState(false);
    const [pladoHepatocarcinome, setPladoHepatocarcinome] = useState(false);
    const [cisplatMetastatique, setCisplatMetastatique] = useState(false);
    const [adriaMetastatique, setAdriaMetastatique] = useState(false);
    const [sorafenib, setSorafenib] = useState(false);




    const optionMotif = [
        { value: 'Avis diagnostique', label: 'Avis diagnostique' },
        { value: '⁠Avis thérapeutique', label: '⁠Avis thérapeutique' },
        ...(data.Specialite && data.Specialite === 'Pédiatrie' && data.Specialite === 'Cancers Rares' && data.Specialite === 'Urologie' && data.Organe === 'Tumeurs cerebrales' || data.Organe === 'Tumeurs orbitaires' || data.Organe === 'Tumeurs maxillofaciales' || data.Organe === 'Tumeurs mediastinales ' ? [{ value: '⁠Suivi/surveillance', label: '⁠Suivi/surveillance' }] : []),
    ];
    const optionTraitement = [
        ...(data.Specialite && data.Specialite === 'Pédiatrie' && data.Organe === 'T. Sacro-coccygienne' ? [] : [{ value: 'Radio-chimiothérapie', label: 'Radio-chimiothérapie' }]),
        { value: 'Chirurgie', label: 'Chirurgie' },

        ...(data.Specialite === 'Pédiatrie' && data.Organe === 'Foie/Pédiatrie'
            ? []
            : [
                ...(data.Specialite === 'Pédiatrie' && data.Organe === 'T. Sacro-coccygienne'
                    ? [] : [
                        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Rétropéritoine' || data.Organe === 'Oesophage') && (data.Specialite === 'Cancers Rares' && data.Organe !== 'Cortex de la glande surrénale') ? [] : [{ value: 'Curiethérapie', label: 'Curiethérapie' }]),
                        { value: 'Radiothérapie', label: 'Radiothérapie' }
                    ]
                ),
                ...(data.Specialite === 'Pédiatrie' && data.Organe === 'Rétropéritoine' && (data.Specialite === 'Cancers Rares' && data.Organe !== 'Cortex de la glande surrénale') && (data.Specialite === 'Urologie' && (data.Organe === 'Vessie /Arbre urinaire' || data.Organe === 'Testicule')) ? [] : [{ value: 'Hormonothérapie', label: 'Hormonothérapie' }]),
            ]
        ),
        { value: 'Chimiothérapie', label: 'Chimiothérapie' },

        ...(data.Specialite && data.Specialite === 'Pédiatrie' && data.Organe === 'T. Sacro-coccygienne' ? [] : [
            { value: 'Immunothérapie', label: 'Immunothérapie' },
            ...((data.Specialite === 'Pédiatrie' && data.Organe === 'Oesophage') || (data.Specialite === 'Cancers Rares' && data.Organe === 'Parathyroïde')
                ? [] : [{ value: 'Radiologie interventionnelle', label: 'Radiologie interventionnelle' }]),
            ...((data.Specialite === 'Urologie' && data.Organe === 'Rein') || (data.Specialite === 'Cancers Rares' && data.Organe === 'Parathyroïde') ? [] : [
                { value: 'Endoscopie interventionnelle', label: 'Endoscopie interventionnelle' }
            ])
        ]),

        { value: 'Soin de support', label: 'Soin de support' },

        ...(data.Specialite && (
            (data.Specialite === 'Gynécologie') ||
            (data.Specialite === 'Pédiatrie' && data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques') ||
            (data.Organe === 'CORTICOSURRENALE') ||
            (data.Organe === 'Colo-rectum') ||
            (data.Specialite === 'Hématologie') ||
            (data.Specialite === 'Thorax' && data.Organe === 'Poumon') ||
            (data.Specialite === 'Urologie' && (
                data.Organe === 'Prostate' ||
                data.Organe === 'Testicule' ||
                data.Organe === 'Penis' ||
                data.Organe === 'Surrenale' ||
                data.Organe === 'Rein'
            )) || (data.Specialite === 'Cancers Rares' && (data.Organe === 'Cortex de la glande surrénale' || data.Organe === 'Parathyroïde'))
        ) ? [{ value: 'Thérapie ciblée', label: 'Thérapie ciblée' }] : []),

        ...((data.Specialite === 'Urologie' && (data.Organe === 'Vessie /Arbre urinaire')) || (data.Specialite === 'Cancers Rares' && (data.Organe === 'Ganglion parasympathique' || data.Organe === 'Ganglion sympathique' || data.Organe === 'Medullosurrenale')) ? [
            { value: 'BCG thérapie', label: 'BCG thérapie' }
        ] : []),

        ...(data.Specialite && data.Specialite === 'Thorax' && (
            data.Organe === 'Plèvre' || data.Organe === 'Parois thoracique'
        ) ? [{ value: 'Radiothérapie thoracique', label: 'Radiothérapie thoracique' }] : []),

        ...(data.Specialite === 'Thorax' ? [
            { value: 'Radio-chimiothérapie concomitante', label: 'Radio-chimiothérapie concomitante' },
            { value: 'Radio-chimiothérapie séquentielle', label: 'Radio-chimiothérapie séquentielle' }
        ] : []),

        ...(data.Specialite === 'Cancers Rares' && (data.Organe !== 'Parathyroïde ') ? [
            { value: 'Radio fréquence', label: 'Radio fréquence' },
            { value: 'Chimio-embolisation des métastases', label: 'Chimio-embolisation des métastases' }
        ] : []),

        ...(data.Specialite === 'Cancers Rares' && (data.Organe === 'Ganglion parasympathique' || data.Organe === 'Ganglion sympathique' || data.Organe === 'Medullosurrenale') ? [
            { value: 'Radiothérapie interne vectorisée', label: 'Radiothérapie interne vectorisée' },
        ] : []),

        ...(data.Specialite && (
            data.Specialite === "Hématologie" ||
            (data.Specialite === "Onco pédiatrie" && data.Organe === "Néoplasmes des cellules histiocytaires/dendritiques") ||
            data.Organe === "CORTICOSURRENALE" ||
            data.Organe === "Colo-rectum"
        ) ? [{ value: "Greffe de moelle", label: "Greffe de moelle" }] : []),

        { value: "Autre traitement", label: "Autre traitement" },

        // Foie
        ...(data.Specialite === "Pédiatrie" && data.Organe === "Foie/Pédiatrie" ? [
            { value: "Hépatectomies réglées", label: "Hépatectomies réglées" },
            { value: "Exérèses atypiques", label: "Exérèses atypiques" },
            { value: "Transplantation", label: "Transplantation" },
            { value: "Hépatoblastome de bas risque", label: "Hépatoblastome de bas risque" },
            { value: "Hépatoblastome de risque standard", label: "Hépatoblastome de risque standard" },
            { value: "Hépatoblastome de haut risque", label: "Hépatoblastome de haut risque" },
            { value: "Hépatocarcinomes", label: "Hépatocarcinomes" },
            { value: "Métastatiques, rupture", label: "Métastatiques, rupture" },
            { value: "HCC", label: "HCC" }
        ] : []),
    ];

    const optionTypeProposition = [
        ...(data.Specialite && data.Specialite === 'Thorax' ? [] : [
            { value: "Application d’une recommandation de XXX", label: "Application d’une recommandation de XXX" },
            { value: "Application référentiel", label: "Application référentiel" }
        ]),
        { value: "Traitement hors référentiel", label: "Traitement hors référentiel" },
        { value: "Essai thérapeutique", label: "Essai thérapeutique" },
        { value: "Recours à une autre RCP", label: "Recours à une autre RCP" },

        ...(data.Specialite && data.Specialite === 'Thorax' ? [
            { value: "Application d’un référentiel international", label: "Application d’un référentiel international" },
            { value: "Application d’un référentiel national", label: "Application d’un référentiel national" }
        ] : []),
    ];

    const optionDecision = [
        { value: "Chirurgie", label: "Chirurgie" },

        ...(data.Specialite && data.Specialite === "Onco pédiatrie" && data.Organe === "Foie/Pédiatrie" ? [] :
            [
                ...(data.Specialite === "Onco pédiatrie" && data.Organe === "CORTICOSURRENAL" ? [] : [
                    ...(data.Specialite && data.Specialite === "Urologie" && (
                        data.Organe === "Rein" ||
                        data.Organe === "Vessie /Arbre urinaire" ||
                        data.Organe === "Testicule"
                    ) && (data.Specialite === 'Cancers Rares' && data.Organe !== 'Cortex de la glande surrénale') ? [] : [
                        { value: "Curiethérapie", label: "Curiethérapie" }
                    ]),
                    { value: "Radio-Chimiothérapie", label: "Radio-Chimiothérapie" }
                ]),
                { value: "Radiothérapie", label: "Radiothérapie" }
            ]
        ),

        ...(data.Specialite === "Onco pédiatrie" && data.Organe === "CORTICOSURRENALE" ? [] : [
            { value: "Immunothérapie", label: "Immunothérapie" },
            ...(data.Specialite === "Cancers Rares" && data.Organe === "Parathyroïde " ? [] : [

                { value: "Radiologie interventionnelle", label: "Radiologie interventionnelle" }
            ]),

        ]),

        ...(data.Specialite === 'Cancers Rares' && (data.Organe !== 'Parathyroïde ') ? [
            { value: 'Radio fréquence', label: 'Radio fréquence' },
            { value: 'Chimio-embolisation des métastases', label: 'Chimio-embolisation des métastases' }
        ] : []),

        ...(data.Specialite === 'Cancers Rares' && (data.Organe === 'Ganglion parasympathique' || data.Organe === 'Ganglion sympathique' || data.Organe === 'Medullosurrenale') ? [
            { value: 'Radiothérapie interne vectorisée', label: 'Radiothérapie interne vectorisée' },
        ] : []),


        { value: "Chimiothérapie", label: "Chimiothérapie" },

        (
            (data.Specialite === "Urologie" && (
                data.Organe === "Rein" ||
                data.Organe === "Vessie /Arbre urinaire" ||
                data.Organe === "Testicule" ||
                data.Organe === "Penis" ||
                data.Organe === "Surrenale"
            )) ||
            (data.Specialite === 'Cancers Rares' && data.Organe !== 'Parathyroïde')
        ) ? [] : [
            { value: "Endoscopie interventionnelle", label: "Endoscopie interventionnelle" },
            ...(data.Specialite === 'Cancers Rares' && data.Organe === 'Cortex de la glande surrénale' ? [
                { value: "Hormonothérapie", label: "Hormonothérapie" }
            ] : [])
        ],
        { value: "Soins de support", label: "Soins de support" },
        { value: "Surveillance", label: "Surveillance" },

        ...(data.Specialite && data.Specialite === 'Hématologie' ? [{ value: 'Thérapie ciblée', label: 'Thérapie ciblée' }] : []),

        ...(data.Specialite && data.Specialite === 'Thorax' ? [
            { value: 'Radio-chimiothérapie concomitante', label: 'Radio-chimiothérapie concomitante' },
            { value: 'Radio-chimiothérapie séquentielle', label: 'Radio-chimiothérapie séquentielle' }
        ] : []),

        ...(data.Specialite && (data.Specialite === 'Hématologie' || data.Specialite === 'Onco pédiatrie') ? [{ value: 'Greffe de moelle', label: 'Greffe de moelle' }] : []),

        { value: 'Autre traitement', label: 'Autre traitement' },
    ];




    // Gestion du changement d'état pour chaque checkbox
    const handleResectionCompleteChange = (e) => {
        setResectionComplete(e.target.checked);
        if (e.target.checked) {
            setTypeChirurgie('résection complète'); // Met à jour typeChirurgie si résection complète est cochée
        } else if (!resectionComplete) {
            setTypeChirurgie(''); // Réinitialise typeChirurgie si aucune case n'est cochée
        }
    };

    const handleResectionIncompleteChange = (e) => {
        setResectionIncomplete(e.target.checked);
        if (e.target.checked) {
            setTypeChirurgie('résection incomplète'); // Met à jour typeChirurgie si résection incomplète est cochée
        } else if (!resectionIncomplete) {
            setTypeChirurgie(''); // Réinitialise typeChirurgie si aucune case n'est cochée
        }
    };

    const handleChimioChange = (e) => {
        setChimio(e.target.checked);
        if (e.target.checked) {
            setTypeTransplantation(' Chimiothérapie');
        } else if (!chimio) {
            setTypeTransplantation('');
        }

    };

    // Gestion du changement d'état pour chaque checkbox typeHepatectomie
    const handleSegmentectomiesChange = (e) => {
        setSegmentectomies(e.target.checked);
        if (e.target.checked) {
            setTypeHepatectomie('Segmentectomies'); // Met à jour TypeHepatectomie si Hépatectomies est cochée
        } else if (!segmentectomies) {
            setTypeHepatectomie(''); // Réinitialise TypeHepatectomie si aucune case n'est cochée
        }
    };

    const handleHepatectomiesChange = (e) => {
        setHepatectomies(e.target.checked);
        if (e.target.checked) {
            setTypeHepatectomie('Hépatectomies droite ou gauche'); // Met à jour TypeHepatectomie si Hépatectomies est cochée
        } else if (!hepatectomies) {
            setTypeHepatectomie(''); // Réinitialise TypeHepatectomie si aucune case n'est cochée
        }
    };

    const handleLobectomiesChange = (e) => {
        setLobectomies(e.target.checked);
        if (e.target.checked) {
            setTypeHepatectomie('Lobectomies droite ou gauche');
        } else if (!lobectomies) {
            setTypeHepatectomie('');
        };
    };

    // ////////////
    const handleEnucleationChange = (e) => {
        setEnucleation(e.target.checked);
        if (e.target.checked) {
            setTypeExereseAtypique(' Énucléation');
        } else if (!enucleation) {
            setTypeExereseAtypique('');
        };
    };

    const handleResectionCuneiformeChange = (e) => {
        setResectionCuneiforme(e.target.checked);
        if (e.target.checked) {
            setTypeExereseAtypique('Résection cunéiforme');
        } else if (!resectionCuneiforme) {
            setTypeExereseAtypique('');
        };
    };

    // ////////////////
    const handleCisPlatPreChange = (e) => {
        setCisPlatPre(e.target.checked);
        if (e.target.checked) {
            setTypeHepatoblasmeBasRisque('cis-plat en pré');
        } else if (!cisPlatPre) {
            setTypeHepatoblasmeBasRisque('');
        };
    };

    const handlePostOpChange = (e) => {
        setPostOp(e.target.checked);
        if (e.target.checked) {
            setTypeHepatoblasmeBasRisque('post-op');
        } else if (!postOp) {
            setTypeHepatoblasmeBasRisque('');
        };
    };

    //////////////

    const handleCisplaChange = (e) => {
        setCispla(e.target.checked);
        if (e.target.checked) {
            setTypeHepatoblasmeRisqueStandard('cispla');
        } else if (!cispla) {
            setTypeHepatoblasmeRisqueStandard('');
        };
    };

    const handlePladoChange = (e) => {
        setPlado(e.target.checked);
        if (e.target.checked) {
            setTypeHepatoblasmeRisqueStandard('PLADO');
        } else if (!plado) {
            setTypeHepatoblasmeRisqueStandard('');
        };
    };

    const handleCisplaChimioChange = (e) => {
        setCisplaChimio(e.target.checked);
        if (e.target.checked) {
            setTypeHepatoblasmeRisqueStandard('cispla');
        } else if (!cisplaChimio) {
            setTypeHepatoblasmeRisqueStandard('');
        };
    };

    const handlePladoChimioChange = (e) => {
        setPladoChimio(e.target.checked);
        if (e.target.checked) {
            setTypeHepatoblasmeRisqueStandard('Plado');
        } else if (!pladoChimio) {
            setTypeHepatoblasmeRisqueStandard('');
        };
    };
    //////////////////


    const handleCarboplatineChange = (e) => {
        setCarboplatine(e.target.checked);
        if (e.target.checked) {
            setTypeHepatoblasmeHautRisque('carboplatine');
        } else if (!carboplatine) {
            setTypeHepatoblasmeHautRisque('');
        };
    };

    const handleAdriaChange = (e) => {
        setAdria(e.target.checked);
        if (e.target.checked) {
            setTypeHepatoblasmeHautRisque('adria');
        } else if (!adria) {
            setTypeHepatoblasmeHautRisque('');
        };
    };

    const handleCisplatChange = (e) => {
        setCisplat(e.target.checked);
        if (e.target.checked) {
            setTypeHepatoblasmeHautRisque('ciplat');
        } else if (!cisplat) {
            setTypeHepatoblasmeHautRisque('');
        };
    };

    const handlePladoHepatocarcinomeChange = (e) => {
        setPladoHepatocarcinome(e.target.checked);
        if (e.target.checked) {
            setTypeHepatocarcinomes('PLADO');
        } else if (!pladoHepatocarcinome) {
            setTypeHepatocarcinomes('');
        };
    };

    const handleCisplatMetastatiqueChange = (e) => {
        setCisplatMetastatique(e.target.checked);
        if (e.target.checked) {
            setTypeMetastatiques('cisplat');
        } else if (!cisplatMetastatique) {
            setTypeMetastatiques('');
        };
    };
    const handleAdriaMetastatiqueChange = (e) => {
        setAdriaMetastatique(e.target.checked);
        if (e.target.checked) {
            setTypeMetastatiques('Adria');
        } else if (!adriaMetastatique) {
            setTypeMetastatiques('');
        };
    };
    const handleSorafenibChange = (e) => {
        setSorafenib(e.target.checked);
        if (e.target.checked) {
            setTypeHCC('Sorafenib (Nexavar°)');
        } else if (!sorafenib) {
            setTypeHCC('');
        };
    };
    // Gestion de la soumission du formulaire

    const dispatch = useDispatch();
    const [showModalDiagniostique, setShowModalDiagniostique] = useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [dataFiche, setDataFiche] = useState([]);

    const handleCancel = () => {
        setShowModalDiagniostique(false);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Vérification des valeurs vides
        if (!motifRcp.length || !traitementRealise.length || !typeProposition || !necessiteExam || !miseEnTraitement || !motifDecision) {
            notification.warning({
                message: 'Saisir d\'abord les informations requises',
                description: 'Veuillez remplir tous les champs obligatoires avant de soumettre.',
                duration: 6,
            });
            return; // Sortir de la fonction si les champs sont vides
        }
        const inputData =
        {

            MotifRcp: motifRcp,
            TraitementRealises: traitementRealise,
            TypeProposition: typeProposition,
            DecisionRcpExamensComplementaires: necessiteExam,
            DecisionRcpMiseEnTraitement: miseEnTraitement,
            MotivationDecisionRcp: motifDecision,
            PatientId: data.id,
            RcpId: RcpId,
            UserId: data.UserId,



        };

        if (data.Specialite === 'Pédiatrie') {
            if (data.Organe === 'Tumeurs cerebrales' && traitementRealise.includes('Chirurgie')) {
                inputData.TypeChirurgie = typeChirurgie;
            }

            if (data.Organe === 'Foie/Pédiatrie') {
                // Gestion spécifique aux traitements liés à l'organe "Foie/Pédiatrie"
                if (traitementRealise.includes('Transplantation')) {
                    inputData.TypeTransplantation = typeTransplantation;
                }
                if (traitementRealise.includes('Hépatectomies réglées')) {
                    inputData.TypeHepatectomie = typeHepatectomie;
                }
                if (traitementRealise.includes('Exérèses atypiques')) {
                    inputData.TypeExereseAtypique = typeExereseAtypique;
                }
                if (traitementRealise.includes('Hépatoblastome de bas risque')) {
                    inputData.TypeHepatoblasmeBasRisque = TypeHepatoblasmeBasRisque;
                }
                if (traitementRealise.includes('Hépatoblastome de risque standard')) {
                    inputData.TypeHepatoblasmeRisqueStandard = TypeHepatoblasmeRisqueStandard;
                }
                if (traitementRealise.includes('Hépatoblastome de haut risque')) {
                    inputData.TypeHepatoblasmeHautRisque = typeHepatoblasmeHautRisque;
                }
                if (traitementRealise.includes('Hépatocarcinomes')) {
                    inputData.TypeHepatocarcinomes = TypeHepatocarcinomes;
                }
                if (traitementRealise.includes('Métastatiques, rupture')) {
                    inputData.TypeMetastatiques = typeMetastatiques;
                }
                if (traitementRealise.includes('HCC')) {
                    inputData.TypeHCC = TypeHCC;
                }
            }
        }

        // console.log(inputData);
        try {
            const reponse = await dispatch(createFicheRcp(inputData)).unwrap();
            if (reponse.data) {
                // Réinitialiser les champs après le succès
                close();
                setMotifRcp([]);
                setMiseEnTraitement([]);
                setTraitementRealise([]);
                setTypeProposition([]);
                setMotifDecision([]);
                setNecessiteExam([]);
                notification.success({
                    message: reponse.data.message,
                    duration: 6,
                });
                setDataFiche(reponse.data);

                // Confirmation du diagnostic après création réussie
                confirm({
                    title: 'Confirmation',
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <div style={{ fontSize: '18px', padding: '20px', lineHeight: '1.6' }}>
                            Êtes-vous sûr de vouloir faire un diagnostic ?
                        </div>
                    ),
                    okText: 'Confirmer',
                    cancelText: 'Annuler',
                    width: 600,
                    style: { width: '800px', height: '800px', top: '15%', transform: 'translateY(15%)' },
                    onOk() {
                        setShowModalDiagniostique(true);
                    },
                });
            }
        } catch (error) {
            // Gestion de l'erreur lors de la création
            notification.error({
                message: error,
            });
        }
    };





    useEffect(() => {
        // Réinitialiser le Select lorsque les données changent
        setMotifRcp([]);
        setMiseEnTraitement([]);
        setTraitementRealise([]);
        setTypeProposition([]);
        setMotifDecision([]);
        setNecessiteExam([])
    }, [data]);

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Divider orientation="center" plain style={{ marginTop: "30px" }}>
                    Information du Patient
                </Divider>
                <Row gutter={8}>
                    <Col span={10}>
                        <p>
                            Numéro d'identification: <span style={{ fontWeight: "bold" }}>{data.NumeroIdentification}</span></p>
                    </Col>
                    <Col span={8}>
                        <p> Nom patient:  <span style={{ fontWeight: "bold" }}> {data.Prenom + " " + data.Nom}</span></p>
                    </Col>

                    <Col span={4}>
                        <p>Date de Naissance: <span style={{ fontWeight: "bold" }}>
                            {data.Dob}
                        </span></p>
                    </Col>

                </Row>
                <Row gutter={8}>
                    <Col span={4}>
                        <p> Age: <span style={{ fontWeight: "bold" }}>
                            {data.Age}
                        </span></p>
                    </Col>
                    <Col span={4}>
                        <p>Sexe: <span style={{ fontWeight: "bold" }}>{data.Sexe} </span> </p>
                    </Col>
                    <Col span={6}>
                        <p>  Spécialité: <span style={{ fontWeight: "bold" }}>
                            {data.Specialite}

                        </span></p>
                    </Col>
                    <Col span={10}>
                        <p> Organe: <span style={{ fontWeight: "bold" }}> {data.Organe} </span>
                        </p>
                    </Col>
                </Row>
                <Divider orientation="center" plain style={{ marginTop: "30px" }}>
                    Information de la RCP
                </Divider>
                <Row gutter={6}>
                    <Col span={12}>
                        <p>Motif de RCP</p>
                        <Select
                            mode="tags"
                            name="motif"
                            value={motifRcp}
                            onChange={(value) => setMotifRcp(value)}
                            style={{ width: "470px", height: "60px", }}
                            aria-required

                        >
                            {optionMotif.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={12}>
                        <p>Traitement réalisé</p>
                        <Select
                            mode="tags"
                            name="traitement"
                            value={traitementRealise}
                            onChange={(value) => setTraitementRealise(value)}
                            style={{ width: "480px", height: "60px", }}
                        >
                            {optionTraitement.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Col>

                </Row>
                {data.Specialite === 'Pédiatrie' && data.Organe === 'Tumeurs cerebrales' && traitementRealise.includes('Chirurgie') &&
                    <div style={{ marginTop: "20px" }}>
                        <Row>
                            <Col span={12}>
                                <Checkbox
                                    checked={resectionComplete}
                                    onChange={handleResectionCompleteChange}
                                >
                                    résection complète
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "10px" }}>
                            <Col span={12}>
                                <Checkbox
                                    checked={resectionIncomplete}
                                    onChange={handleResectionIncompleteChange}
                                >
                                    résection incomplète
                                </Checkbox>
                            </Col>

                        </Row>
                    </div>
                }

                {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie/Pédiatrie' && traitementRealise.includes('Hépatectomies réglées') &&
                    <div style={{ marginTop: "20px" }}>
                        <Row>
                            <Col span={12}>
                                <Checkbox
                                    checked={segmentectomies}
                                    onChange={handleSegmentectomiesChange}
                                >
                                    Segmentectomies
                                </Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    checked={hepatectomies}
                                    onChange={handleHepatectomiesChange}
                                >
                                    Hépatectomies droite ou gauche
                                </Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    checked={lobectomies}
                                    onChange={handleLobectomiesChange}
                                >
                                    Lobectomies droite ou gauche
                                </Checkbox>
                            </Col>
                        </Row>
                    </div>
                }

                {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie/Pédiatrie' && traitementRealise.includes('Exérèses atypiques') &&
                    <div style={{ marginTop: "20px" }}>
                        <Row>
                            <Col span={12}>
                                <Checkbox
                                    checked={enucleation}
                                    onChange={handleEnucleationChange}
                                >
                                    Énucléation
                                </Checkbox>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "10px" }}>
                            <Col span={12}>
                                <Checkbox
                                    checked={resectionCuneiforme}
                                    onChange={handleResectionCuneiformeChange}
                                >
                                    Résection cunéiforme
                                </Checkbox>
                            </Col>
                        </Row>
                    </div>
                }

                {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie/Pédiatrie' && traitementRealise.includes('Transplantation') &&
                    <div style={{ marginTop: "20px" }}>
                        <Row>
                            <Col span={12}>
                                <Checkbox
                                    checked={chimio}
                                    onChange={handleChimioChange}
                                >
                                    Chimiothérapie
                                </Checkbox>
                            </Col>

                        </Row>

                    </div>
                }

                {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie/Pédiatrie' && traitementRealise.includes('Hépatoblastome de bas risque') &&
                    <div style={{ marginTop: "20px" }}>
                        <Row>
                            <Col span={12}>
                                <Checkbox
                                    checked={cisPlatPre}
                                    onChange={handleCisPlatPreChange}
                                >
                                    cis-plat en pré
                                </Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    checked={postOp}
                                    onChange={handlePostOpChange}
                                >
                                    post-op
                                </Checkbox>
                            </Col>


                        </Row>

                    </div>
                }

                {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie/Pédiatrie' && traitementRealise.includes('Hépatoblastome de risque standard') &&
                    <div style={{ marginTop: "20px" }}>
                        <p>Hépatoblastome de risque standard</p>

                        <Row>
                            <Col span={12}>
                                <Checkbox
                                    checked={cispla}
                                    onChange={handleCisplaChange}
                                >
                                    cispla
                                </Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    checked={plado}
                                    onChange={handlePladoChange}
                                >
                                    PLADO
                                </Checkbox>
                            </Col>
                        </Row>
                        <p style={{ marginTop: "10px" }}>Chimio post-o</p>
                        <Row style={{ marginTop: "10px" }}>
                            <Col span={12}>
                                <Checkbox
                                    checked={pladoChimio}
                                    onChange={handlePladoChimioChange}
                                >
                                    PLADO
                                </Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    checked={cisplaChimio}
                                    onChange={handleCisplaChimioChange}
                                >
                                    cispla
                                </Checkbox>
                            </Col>

                        </Row>
                    </div>
                }

                {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie/Pédiatrie' && traitementRealise.includes('Hépatoblastome de haut risque') &&
                    <div style={{ marginTop: "20px" }}>
                        <Row>
                            <Col span={12}>
                                <Checkbox
                                    checked={carboplatine}
                                    onChange={handleCarboplatineChange}
                                >
                                    carboplatine
                                </Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    checked={adria}
                                    onChange={handleAdriaChange}
                                >
                                    adria
                                </Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    checked={cisplat}
                                    onChange={handleCisplatChange}
                                >
                                    cisplat
                                </Checkbox>
                            </Col>

                        </Row>
                    </div>
                }
                {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie/Pédiatrie' && traitementRealise.includes('Hépatocarcinomes') &&
                    <div style={{ marginTop: "20px" }}>
                        <Row>
                            <Col span={12}>
                                <Checkbox
                                    checked={pladoHepatocarcinome}
                                    onChange={handlePladoHepatocarcinomeChange}
                                >
                                    PLADO
                                </Checkbox>                            </Col>


                        </Row>
                    </div>
                }

                {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie/Pédiatrie' && traitementRealise.includes('Métastatiques, rupture') &&
                    <div style={{ marginTop: "20px" }}>
                        <Row>
                            <Col span={12}>
                                <Checkbox
                                    checked={cisplatMetastatique}
                                    onChange={handleCisplatMetastatiqueChange}
                                >cisplat</Checkbox>
                            </Col>
                            <Col span={12}>
                                <Checkbox
                                    checked={adriaMetastatique}
                                    onChange={handleAdriaMetastatiqueChange}
                                >adria</Checkbox>
                            </Col>


                        </Row>
                    </div>
                }
                {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie/Pédiatrie' && traitementRealise.includes('HCC') &&
                    <div style={{ marginTop: "20px" }}>
                        <Row>
                            <Col span={12}>
                                <Checkbox
                                    checked={sorafenib}
                                    onChange={handleSorafenibChange}
                                >Sorafenib (Nexavar°)</Checkbox>
                            </Col>


                        </Row>
                    </div>
                }

                <Row>
                    <Col span={24}>
                        <p>Type de la proposition</p>
                        <Select
                            mode="tags"
                            name="typeProposition"
                            value={typeProposition}
                            onChange={(value) => setTypeProposition(value)}
                            style={{ width: "960px", height: "60px", }}
                        >
                            {optionTypeProposition.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <Divider orientation="center" plain style={{ marginTop: "30px" }}>
                    Décision de la RCP
                </Divider>
                <Row gutter={6}>
                    <Col span={12}>
                        <p>Nécessité d'examens complémentaires </p>
                        <Input placeholder="Nécessité d'examens complémentaires" name="necessiteExam" value={necessiteExam} onChange={(e) => setNecessiteExam(e.target.value)}
                            style={{ height: "60px", }}
                        />
                    </Col>
                    <Col span={8}>
                        <p>Mise en traitement</p>
                        <Select
                            mode="tags"
                            name="miseEnTraitement"
                            value={miseEnTraitement}
                            onChange={(value) => setMiseEnTraitement(value)}
                            style={{ width: "480px", height: "60px", }}
                        >
                            {optionDecision.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Col>

                </Row>
                <Row>
                    <Col span={24}>
                        <p>Motivation de la décision de la RCP </p>
                        <TextArea
                            value={motifDecision}
                            onChange={(e) => setMotifDecision(e.target.value)}
                            placeholder="Motivation de la décision de la RCP"
                            autoSize={{
                                minRows: 3,
                                maxRows: 5,
                            }}
                        />
                    </Col>
                    <Row >
                        <Col span={24} style={{ marginTop: "20px", }}>
                            <BtnSubmit type_="submit"
                                buttonText="Enregistrer"
                                position_="relative"
                                left_="850px"
                                border_='none'
                                padding_='10px 20px'
                                borderRadius_='0.5rem'
                                color='#fff'
                                bgNormalColor='linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))'
                            />
                        </Col>
                    </Row>

                </Row>

            </form>

            <ModalComponent
                _maskClosable={false}
                _width="1000px"
                // _marginTop="-100px"
                _top="20px"
                onCancel={handleCancel}
                _open={showModalDiagniostique}
                _title="Diagniostic"
                form={<Diagniostigue data={dataFiche} RcpId={RcpId} UserId={data?.UserId} PatientId={data?.PatientId} close={handleCancel} />}
            />

        </div>
    );
};

export default Fiche;