import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Action asynchrone pour l'enregistrement de l'utilisateur
export const register = createAsyncThunk(
  "authRegister/userRegister",
  async (userData, { rejectWithValue }) => {
    try {
      const { data } = await api.signup(userData);
       //window.location.href = "/"; Redirection après l'inscription
      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || "Une erreur est survenue";
      return rejectWithValue(errorMessage);
    }
  }
);

// Création du slice pour l'enregistrement de l'utilisateur
export const registerSlice = createSlice({
  name: "authRegister",
  initialState: {
    data: {}, // État initial pour les données de l'utilisateur
    isSuccess: false,
    message: "", // Message d'erreur ou de succès
    loading: false, // Indicateur de chargement
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.loading = true; // Indiquer que la requête est en cours
        state.isSuccess = false; // Réinitialiser le succès
      })
      .addCase(register.fulfilled, (state, { payload }) => {
        state.loading = false; // Indiquer que la requête est terminée
        state.isSuccess = true; // Mise à jour du succès
        state.data = payload; // Mettre à jour les données avec la réponse
      })
      .addCase(register.rejected, (state, { payload }) => {
        state.loading = false; // Indiquer que la requête est terminée
        state.isSuccess = false; // Mise à jour de l'échec
        state.message = payload; // Mettre à jour le message d'erreur
      });
  },
});

// Exporter le réducteur pour l'utiliser dans le store
export default registerSlice.reducer;
