import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Fonction pour récupérer les Centre depuis l'API
export const fetchAntecedant = createAsyncThunk(
  "Antecedant/getAntecedant",
  async () => {
    try {
      const response = await api.getAllAntecedants();
      return response.data; //`data` est bien présent
      

    } catch (error) {
      throw error; // Lancer l'erreur pour être capturée dans le reducer
    }
  }
);

// Création du slice pour les antecedants
export const antecedantSlice = createSlice({
    name: "Antecedant",
    initialState: {
      data: [], // 'data' est initialisé comme un tableau vide
      isSuccess: false,
      loading: false,
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchAntecedant.pending, (state) => {
          state.loading = true;
          state.isSuccess = false;
        })
        .addCase(fetchAntecedant.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.isSuccess = true;
          state.data = payload;
        })
        .addCase(fetchAntecedant.rejected, (state) => {
          state.loading = false;
          state.isSuccess = false;
        });
    },
  });
  
// Exporter le réducteur pour l'utiliser dans le store
export default antecedantSlice.reducer;
