import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Création d'une thunk asynchrone pour mettre à jour l'activation d'un utilisateur
export const updateDesactiverUser = createAsyncThunk(
  "desactiverUser/updateDesactiverUser",
  async ({ userId }, { rejectWithValue }) => {
    try {
      // Appel API pour activer l'utilisateur avec l'ID fourni
      const { data } = await api.updateDesactiverUser(userId);
      return data; // Retourne les données en cas de succès
    } catch (error) {
      // En cas d'erreur, récupère le message d'erreur de la réponse
      const errorMessage = error?.response?.data?.message || error.message;
      // Utilise rejectWithValue pour renvoyer l'erreur à l'appelant
      return rejectWithValue(errorMessage);
    }
  }
);

// Création du slice avec l'état initial et la gestion des reducers
export const updateDesactiverUserSlice = createSlice({
  name: "desactiverUser", // Nom du slice
  initialState: {
    data: {}, // Données de l'utilisateur
    isSuccess: false, // Indique si la desactivation a réussi
    loading: false, // Indique si l'appel API est en cours
    error: null, // Stocke le message d'erreur en cas d'échec
  },
  reducers: {}, // Si vous avez des reducers synchrones, vous pouvez les ajouter ici
  extraReducers: (builder) => {
    builder
      .addCase(updateDesactiverUser.pending, (state) => {
        state.loading = true; // Démarre le chargement
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(updateDesactiverUser.fulfilled, (state, { payload }) => {
        state.loading = false; // Termine le chargement
        state.isSuccess = true; // L'activation est réussie
        state.data = payload; // Stocke les données mises à jour
        state.error = null;
      })
      .addCase(updateDesactiverUser.rejected, (state, { payload }) => {
        state.loading = false; // Termine le chargement
        state.isSuccess = false; // Échec de l'activation
        state.error = payload; // Stocke le message d'erreur
      });
  },
});

export default updateDesactiverUserSlice.reducer;
