// Importation des fonctions createAsyncThunk et createSlice depuis @reduxjs/toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Importation de la bibliothèque axios pour faire des requêtes HTTP
import axios from "axios";
// Importation de BaseUrl depuis le fichier de configuration
import { BaseUrl } from "../config";

// Créez une action asynchrone pour la connexion utilisateur
export const loginUser = createAsyncThunk(
    'user/loginUser', // Le nom de l'action
    async (userData, thunkAPI) => { // La fonction asynchrone qui effectue la tâche
        try {
            // Envoi d'une requête POST à l'API de connexion avec les données utilisateur
            const response = await axios.post(`${BaseUrl}api/auth/signin`, userData);
            // Récupération des données utilisateur de la réponse
            const user = response.data;
            const token = response.data.accessToken;
            // Enregistrement du token utilisateur dans le localStorage
            localStorage.setItem('token',token);
            // Enregistrement des données utilisateur dans le localStorage
            localStorage.setItem("user", JSON.stringify(user));
           
        } catch (error) {
            // Retourne une erreur en cas d'échec
            return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Création d'un slice Redux pour l'état utilisateur
const userSlice = createSlice({
    name: 'user', // Nom du slice
    initialState: { // État initial
        loading: false, // Indique si une requête est en cours
        user: null, // Stocke les données utilisateur
        error: null, // Stocke les messages d'erreur
        
    },
    // Définition des reducers supplémentaires pour gérer les actions asynchrones
    extraReducers: (builder) => {
        builder
        .addCase(loginUser.pending, (state) => { // Action déclenchée lors de l'envoi de la requête
            state.loading = true; // Indique qu'une requête est en cours
            state.user = null; // Réinitialise les données utilisateur
            state.error = null; // Réinitialise les messages d'erreur
        })
        .addCase(loginUser.fulfilled, (state, action) => { // Action déclenchée lorsque la requête réussit
            state.loading = false; // Indique que la requête est terminée
            state.user = action.payload; // Met à jour les données utilisateur avec la réponse de la requête
            state.error = null; // Réinitialise les messages d'erreur
        })
        .addCase(loginUser.rejected, (state, action) => { // Action déclenchée lorsque la requête échoue
            state.loading = false; // Indique que la requête est terminée
            state.user = null; // Réinitialise les données utilisateur
            state.error = action.payload || action.error.message; // Met à jour le message d'erreur
        });
    }
});



// Exportation du réducteur du slice pour qu'il puisse être utilisé dans le magasin Redux
export default userSlice.reducer;
