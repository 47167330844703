import React, { useEffect, useState } from 'react';
import { BtnSearch, BtnSubmit, InputText, ModalComponent, SelectInput, Tableau } from '../../components';
import { Button, Checkbox, Col, Collapse, DatePicker, Divider, Input, Modal, notification, Row, Select, Space, Table, Tabs, Tooltip } from 'antd';
import "../../components/tableaux/tableau.css";
import {
  cavitePharynxLarynxCaviteNLevreSinusGlandOptions, ColoRectumOptions, CorticosurrenaleOptions, FacteursEnvironnementauxCavumNasopharynxOptions, FacteursEnvironnementauxOptions,
  FacteursGenetiquesOptions, FacteursInfectieuxOptions, FoiePédiatrieOptions, MediastinOptions, OesophageOptions, OSOptions, OvairColUtVaVuOptions,
  OvaireOptions, ProstateOptions, ReinOptions, RetroperitoineOptions, SeinOptions, SelectAlcoolOptions,
  SelectChanvreCannabisOptions, SelectDesirF, SelectOrganeGynecologieOptions,
  SelectOrganeHematologieOptions, SelectOrganeOncoPediatrieOptions, SelectOrganeORLOptions,
  SelectOrganeDigestifOptions, SelectOrganeThoraxOptions, SelectOrganeUrologieOptions, SelectOuiNon,
  SelectPreservation, SelectScolarisationOptions, SelectSexeOptions, SelectSituationMatOptions, SelectStatutOptions, SelectTabacOptions,
  TerrainComorbiditesOptionsAnus, TerrainComorbiditesOptionsConRectum, TerrainComorbiditesOptionsFoie,
  TerrainComorbiditesOptionsOesophage, TerrainComorbiditesOptionsVoiesBiliaire, TumeursCerebralesOptions,
  TumeursMaxilloFacialesOptions, TumeursMediastinalesOptions, TumeursOrbitairesOptions, TyroideOptions,
  TerrainComorbiditeVideOptions,
//   SelectOrganeCancersRaresOptions,
  // TerrainComorbiditesOptionsCancers,
  SelectMenopauseOptions,
  SelectOrganeCancersRaresOptions,
  SelectGrossesseEvoOptions,
} from '../../service';
import "./patient.css";
import { ArrowRightOutlined, DeleteFilled, EditFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import "../../components/button/btnAjout.css"
import { fetchSpecialite } from '../../redux/specialiteSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCentre } from '../../redux/centreSlice';
import DetailsPatient from './DetailsPatient';
import { useNavigate } from 'react-router-dom';
import { useCollapsed } from '../dashBoard/CollapsedContext';
import { createPatient } from '../../redux/CreatePatientSlice';
import { fetchPatients } from '../../redux/patientsSlice';
import { fetchMedecins } from '../../redux/MedecinSlice';
import dayjs from 'dayjs'; // Pour la gestion des dates
import { updatePatient } from '../../redux/UpdatePatientSlice';
import { updateArchivPatient } from '../../redux/ArchivPatientSlice';
import { fetchAntecedentsPatient } from '../../redux/AntecedentsPatientSlice';
import { createAntecedantPatient } from '../../redux/CreateAntecedentPatientSlice';
import { fetchAgos } from '../../redux/AGOsSlice';
import { createAGO } from '../../redux/CreateAGOSlice';
// import Fiche from '../../components/Fiche & Diagnistique/Fiche';


// import Diagniostigue from '../../components/Fiche & Diagnistique/Diagniostique';
import DiagnostiqueCreationPatient from './DiagnostiqueCreationPatient';
import { fetchRcps } from '../../redux/RcpsSlice';
import { createDiagniosticRcp } from '../../redux/CreateDiagniosticRcpSlice';

const { Option, OptGroup } = Select;
const { TextArea } = Input;

// const onChange = (key) => {
//   console.log(key);
// };
const Patients = ({ _top, _title, _width, _height, }) => {

  const [ExpositionAncienne, setExpositionAncienne] = useState("");
  const [TabagismePassif, setTabagismePassif] = useState("");
  const [TabagismeActif, setTabagismeActif] = useState("");
  const [ProfesssionMere, setProfesssionMere] = useState("");
  const [ProfesssionPere, setProfesssionPere] = useState("");
  const [PrecisionExpositionToxique, setPrecisionExpositionToxique] = useState("");
  const [TermeGrossesseEvolutive, setTermeGrossesseEvolutive] = useState("");
  const [GrossesseEvolutive, setGrossesseEvolutive] = useState("");
  const [AutresModeVie, setAutresModeVie] = useState("");
  const [ScoreG8, setScoreG8] = useState("");
  const [InfectionHpvConnu, setInfectionHpvConnu] = useState("");
  const [contraceptionOr, setContraceptionOr] = useState("");
  const [StressEnvironnementSocial, setStressEnvironnementSocial] = useState("");
  const [AgePremierRapportSexuel, setAgePremierRapportSexuel] = useState("");
  const [MultiPartenariat, setMultiPartenariat] = useState("");
  const [ModVie, setModVie] = useState("");
  const [DateCreation, setDateCreation] = useState(null);
  const [Professsion, setProfesssion] = useState("");
  const [AdresseActuelle, setAdresseActuelle] = useState("");
  const [Email, setEmail] = useState("");
  const [Dob, setDob] = useState("");
  const [Telephone, setTelephone] = useState("");
  const [AdresseHabituelle, setAdresseHabituelle] = useState("");
  const [AgeConjointe, setAgeConjointe] = useState("");
  const [Age, setAge] = useState("");
  const [Nom, setNom] = useState("");
  // const [NumeroIdentification, setNumeroIdentification] = useState("");
  const [Prenom, setPrenom] = useState("");
  const [NomCentre, setNomCentre] = useState("");
  const [CentreId, setCentreId] = useState("");
  const [Sexe, setSexe] = useState("");
  const [Specialite, setSpecialite] = useState("");
  const [Organe, setOrgane] = useState("");
  const [openForm, setOpenForm] = useState("");
  const [openUpdateForm, setOpenUpdateForm] = useState("");
  const [TerrainCommorbidites, setTerrainCommorbidites] = useState("");
  const [ExpositionToxique, setExpositionToxique] = useState("");
  const [SituationMatrimoniale, setSituationMatrimoniale] = useState("");
  const [PreservationFertiliteProposee, setPreservationFertiliteProposee] = useState("");
  const [DesirFertilite, setDesirFertilite] = useState("");
  const [TerrainCommorbiditesFacteursEnvironnementaux, setTerrainCommorbiditesFacteursEnvironnementaux] = useState("");
  const [TerrainCommorbiditesFacteursInfectieux, setTerrainCommorbiditesFacteursInfectieux] = useState("");
  const [TerrainCommorbiditesFacteursGenetiques, setTerrainCommorbiditesFacteursGenetiques] = useState([]);
  const [Tabac, setTabac] = useState("");
  const [StatusTabagismeActif, setStatusTabagismeActif] = useState("");
  const [ChanvreCannabis, setChanvreCannabis] = useState("");
  const [Alcool, setAlcool] = useState("");
  const [TerrainComorbiditesOptions, setTerrainComorbiditesOptions] = useState([]);
  const [SelectFacteursEnvironnementauxOptions, setFacteursEnvironnementauxOptions] = useState([]);
  const [SelectFacteursInfectieuxOptions, setFacteursInfectieuxOptions] = useState([]);
  const [SelectFacteursGenetiquesOptions, setFacteursGenetiquesOptions] = useState([]);
  // const [terrainComorditeCavumNasopharynx, setTerrainComorditeCavumNasopharynx] = useState("");
  const [StatutVaccinal, setStatutVaccinal] = useState("");
  const [UserId, setUserId] = useState();
  const [Scolarisation, setScolarisation] = useState("");
  const [OpenDetailPatient, setOpenDetailPatient] = useState(false);
  const collapsed = useCollapsed();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [UpdatePatientdata, setUpdatePatientdata] = useState(null);
  const [updatingDataId, setUpdatingDataId] = useState();
  // const navigate = useNavigate();
  const [openModalConfirmArchiv, setOpenModalConfirmArchiv] = useState(false);
  const [ArchivPatientId, setArchivPatientId] = useState(null);
  const [error, setError] = useState('');
  const [AntecedentMedicauxOptions, setAntecedentMedicauxOptions] = useState([]);
  const [AntecedentFamilialOptions, setAntecedentFamilialOptions] = useState([]);
  const [AntecedentChirurgicalOptions, setAntecedentChirurgicalOptions] = useState([]);
  const [AntecedentPerinatalOptions, setAntecedentPerinatalOptions] = useState([]);
  const [AntecedentTuberculosePOptions, setAntecedentTuberculosePOptions] = useState([]);
  const [AntecedentMedical, setAntecedentMedical] = useState([]);
  const [AntecedentChirurgical, setAntecedentChirurgical] = useState([]);
  const [AntecedentFamilial, setAntecedentFamilial] = useState([]);
  const [AntecedentPerinatal, setAntecedentPerinatal] = useState([]);
  const [AntecedentTuberculosePulmonaire, setAntecedentTuberculosePulmonaire] = useState([]);
  const [Menopause, setMenopause] = useState(null);
  const [EnfantVivant, setEnfantVivant] = useState();
  const [Parite, setParite] = useState();
  const [Gestite, setGestite] = useState();
  const [datePrelevement, setDatePrelevement] = useState(null);
  const [dateDiagnostic, setDateDiagnostic] = useState(null);
  const [TechniquePrelevement, setTechniquePrelevement] = useState('');
  const [EtatGeneralPatient, setEtatGeneralPatient] = useState([]);
  const [MoyenDiagnosticBCRABL, setMoyenDiagnosticBCRABL] = useState('');
  const [CytogenetiqueT, setCytogenetiqueT] = useState('');
  const [CytogenetiqueAca, setCytogenetiqueAca] = useState('');
  const [Sc, setSc] = useState('');
  const [IRMCP, setIRMCP] = useState('');
  const [IRMMP, setIRMMP] = useState('');
  const [IRMCPO, setIRMCPO] = useState('');
  const [IRMMPO, setIRMMPO] = useState('');
  const [LCR, setLCR] = useState('');
  const [ExamenAnatomopathlogique, setExamenAnatomopathlogique] = useState('');
  const [MargesResection, setMargesResection] = useState("");
  const [Medullogramme, setMedullogramme] = useState("");
  const [delaiRenduResultat, setDelaiRenduResultat] = useState(null);
  const [moyenDiagnosticCytologie, setMoyenDiagnosticCytologie] = useState([]);
  const [moyenDiagnosticCytologieResultats, setMoyenDiagnosticCytologieResultats] = useState([]);
  const [moyenDiagnosticCytogenetique, setMoyenDiagnosticCytogenetique] = useState([]);
  const [moyenDiagnosticCytogenetiqueResultats, setMoyenDiagnosticCytogenetiqueResultats] = useState([]);
  const [moyenDiagnosticImmunophénotypageSanguin, setMoyenDiagnosticImmunophénotypageSanguin] = useState('');
  const [moyenDiagnosticImmunophenotypageSanguinResultats, setMoyenDiagnosticImmunophenotypageSanguinResultats] = useState([]);
  const [moyenDiagnosticHistologie, setMoyenDiagnosticHistologie] = useState('');
  const [moyenDiagnosticHistologieResultats, setMoyenDiagnosticHistologieResultats] = useState([]);
  const [moyenDiagnosticImuunohistochimie, setMoyenDiagnosticImuunohistochimie] = useState('');
  const [moyenDiagnosticImuunohistochimieResultats, setMoyenDiagnosticImuunohistochimieResultats] = useState([]);
  const [moyenDiagnosticMedullogramme, setMoyenDiagnosticMedullogramme] = useState([]);
  const [moyenDiagnosticMedullogrammeResultats, setMoyenDiagnosticMedullogrammeResultats] = useState([]);
  const [moyenDiagnosticBiopsieOsteomedullaire, setMoyenDiagnosticBiopsieOsteomedullaire] = useState('');
  const [moyenDiagnosticBiopsieOsteomedullaireResultats, setMoyenDiagnosticBiopsieOsteomedullaireResultats] = useState([]);
  const [psOms, setPsOms] = useState('');
  const [poids, setPoids] = useState('');
  const [poidsAge, setPoidsAge] = useState('');
  const [poidsTaille, setPoidsTaille] = useState('');
  const [taille, setTaille] = useState('');
  const [tailleAge, setTailleAge] = useState('');
  const [media, setMedia] = useState('');
  const [siege, setSiege] = useState('');
  const [imcSc, setImcSc] = useState('');
  const [imcAge, setImcAge] = useState('');
  const [ALK, setALK] = useState('');
  const [CMET, setCMET] = useState('');
  const [ROSI, setROSI] = useState('');
  const [KRAS, setKRAS] = useState('');
  const [BRAF, setBRAF] = useState('');
  const [HER2, setHER2] = useState('')
  const [NTRK, setNTRK] = useState('');
  const [NRG1, setNRG1] = useState('');
  const [RET, setRET] = useState('');
  const [TA, setTA] = useState('');
  const [endoscopieDigestive, setEndoscopieDigestive] = useState('');
  const [bilanPreTherapeutique, setBilanPreTherapeutique] = useState([]);
  const [addBilanPreTherapeutique, setAddBilanPreTherapeutique] = useState('');
  const [SignesPhysiques, setSignesPhysiques] = useState([]);
  const [SignesCliniques, setSignesCliniques] = useState([]);
  const [SignesParacliniques, setSignesParacliniques] = useState([]);
  const [examParaClinique, setExamParaClinique] = useState({
      date: null,
      type: [],
      resultat: ''

  });
  const [TypeHistologies, setTypeHistologies] = useState([]);
  const [MedulloblasmeSelect, setMedulloblasmeSelect] = useState([]);
  const [GradeHistopronostique, setGradeHistopronostique] = useState([]);
  const [biologieMoleculaire, setBiologieMoleculaire] = useState('');
  const [Tils, setTils] = useState('Reins');
  const [Immunohistochimie, setImmunohistochimie] = useState('');
  const [mutationGenetique, setMutationGenetique] = useState([]);
  const [MarqueursTumoraux, setMarqueursTumoraux] = useState([])
  const [FacteursPronostiques, setFacteursPronostiques] = useState([]);
  const [StadeClinique, setStadeClinique] = useState('');
  const [StadeAnnArbor, setStadeAnnArbor] = useState('');
  const [TypeCytologique, setTypeCytologique] = useState([]);
  const [cytogenetiqueConventionnelle, setCytogenetiqueConventionnelle] = useState('');
  const [FISH, setFISH] = useState('');
  const [CytogenetiqueDel17p, setCytogenetiqueDel17p] = useState([]);
  const [BiologieMoleculaireMutationTp53, setBiologieMoleculaireMutationTp53] = useState([]);
  const [ClassificationBinet, setClassificationBinet] = useState('');
  const [StadeSokal, setStadeSokal] = useState('');
  const [ScoreHasford, setScoreHasford] = useState('');
  const [ScoreEUTOS, setScoreEUTOS] = useState('');
  const [IMWG2014, setIMWG2014] = useState('');
  const [ClassificationSalmonDiurie, setClassificationSalmonDiurie] = useState([]);
  const [ClassificationSalmonDiurieStade, setClassificationSalmonDiurieStade] = useState([]);
  const [ClassificationISS, setClassificationISS] = useState([]);
  const [ClassificationAltmann, setClassificationAltmann] = useState([]);
  const [ClassificationISSStade, setClassificationISSStade] = useState([]);
  const [ClassificationISSRevisee, setClassificationISSRevisee] = useState([]);
  const [ClassificationISSReviseeStade, setClassificationISSReviseeStade] = useState([]);
  const [ClassificationOmsSyndromesMyelodysplasiques, setClassificationOmsSyndromesMyelodysplasiques] = useState([]);
  const [NéoplasmesCellulesLangerhansAutresCellulesDndritiques, setNéoplasmesCellulesLangerhansAutresCellulesDndritiques] = useState([]);
  const [NéoplasmesHistiocytesMacrophages, setNéoplasmesHistiocytesMacrophages] = useState([]);
  const [TypeCistologie, setTypeCistologie] = useState([]);
  const [AtteinteLCR, setAtteinteLCR] = useState('');
  const [AtteinteTesticulaire, setAtteinteTesticulaire] = useState('');
  const [Hyperleucocytose, setHyperleucocytose] = useState('');
//   const [Age, setAge] = useState(null);
  const [Phenotype, setPhenotype] = useState('');
  const [FacteurGenetique, setFacteurGenetique] = useState('');
  const [CorticoSensibilite, setCorticoSensibilite] = useState('');
  const [Malade, setMalade] = useState("");
  const [tumeur, setTumeur] = useState([]);
  const [adenopathie, setAdenopathie] = useState("");
  const [Métastase, setMétastase] = useState("");
  const [MoyenDiagnostic, setMoyenDiagnostic] = useState([]);
  const [facteurPronostique, setFacteurPronostique] = useState([]);
  const [immuno, setImmuno] = useState([]);
  const [EGFR, setEGFR] = useState('');
  const [pdl1mTor, setPdl1mTor] = useState('');
  const [AfficheChexbox, setAfficheChexbox] = useState(false);
  const [AfficheChexbox1, setAfficheChexbox1] = useState(false);
  const [AfficheChexbox2, setAfficheChexbox2] = useState(false);
  const [AfficheChexbox3, setAfficheChexbox3] = useState(false);
  const [AfficheChexbox4, setAfficheChexbox4] = useState(false);
  const [AfficheChexbox5, setAfficheChexbox5] = useState(false);
  const [AfficheChexbox6, setAfficheChexbox6] = useState(false);
  const [AfficheChexbox7, setAfficheChexbox7] = useState(false);
  const [AfficheChexbox8, setAfficheChexbox8] = useState(false);
  const [AfficheChexbox9, setAfficheChexbox9] = useState(false);
  const [AfficheChexbox10, setAfficheChexbox10] = useState(false)
  const [AfficheChexbox11, setAfficheChexbox11] = useState(false);
  const [AfficheChexbox12, setAfficheChexbox12] = useState(false);
  const [AfficheChexbox13, setAfficheChexbox13] = useState(false);
  const [AfficheChexbox14, setAfficheChexbox14] = useState(false);
  const [AfficheChexbox15, setAfficheChexbox15] = useState(false);
  const [AfficheChexbox16, setAfficheChexbox16] = useState(false);
  const [AfficheChexbox17, setAfficheChexbox17] = useState(false);
  const [AfficheChexbox18, setAfficheChexbox18] = useState(false);
  const [AfficheChexbox19, setAfficheChexbox19] = useState(false);
  const [AfficheChexbox20, setAfficheChexbox20] = useState(false);
  const [AfficheChexbox21, setAfficheChexbox21] = useState(false);
  const [AfficheChexbox22, setAfficheChexbox22] = useState(false);
  const [AfficheChexbox23, setAfficheChexbox23] = useState(false);
  const [AfficheChexbox24, setAfficheChexbox24] = useState(false);
  const [AfficheChexbox25, setAfficheChexbox25] = useState(false);
  const [AfficheChexbox26, setAfficheChexbox26] = useState(false);
  const [AfficheChexbox27, setAfficheChexbox27] = useState(false);
  const [AfficheChexbox28, setAfficheChexbox28] = useState(false);
  const [AfficheChexbox29, setAfficheChexbox29] = useState(false);
  const [AfficheChexbox30, setAfficheChexbox30] = useState(false);
  const [AfficheChexbox31, setAfficheChexbox31] = useState(false);
  const [AfficheChexbox32, setAfficheChexbox32] = useState(false);
  const [AfficheChexbox33, setAfficheChexbox33] = useState(false);
  const [AfficheChexbox35, setAfficheChexbox35] = useState(false);
  const [CheckOui, setCheckOui] = useState(false);
  const [valueStade, setValueStade] = useState(false);
  const [bilanTherapeutique, setBilanTherapeutique] = useState([]);
  const [invasion, setInvasion] = useState([]);
  const [Envahissement, setEnvahissement] = useState([]);
  const [tailleTumoral, setTailleTumoral] = useState([]);
  const [ageJeune, setAgeJeune] = useState([]);
  const [taux, setTaux] = useState([]);
//   const [sexe, setSexe] = useState([]);
  const [Hemoglobine, setHemoglobine] = useState([]);
  const [facteurMoleculaire, setfacteurMoleculaire] = useState([]);
  const [medulloblasme, setMedulloblasme] = useState([]);
  const [DesirFertiliteUlterieur, setDesirFertiliteUlterieur] = useState([]);
  const [PreservationFertiliteProposer, setPreservationFertiliteProposer] = useState([]);
  const [OncoSexualite, setOncoSexualite] = useState('');
  const [OncoGenetique, setOncoGenetique] = useState('');
  const [Indication, setIndication] = useState('');
  const [Proposition, setProposition] = useState('');
  const [ScorePronostic, setScorePronostic] = useState([]);

  const [CheckboxChoice, setCheckboxChoice] = useState([]);
  const [Risque, setRisque] = useState([]);
  const [TypeTesticule, setTypeTesticule] = useState([]);
  const [TumeursGerminaux, setTumeursGerminaux] = useState([]);
  const [CatecholaminesUrinaires, setCatecholaminesUrinaires] = useState([]);
  const [OsteosarcomeMR, setOsteosarcomeMR] = useState([]);
  const [OsteosarcomeSH, setOsteosarcomeSH] = useState([]);
  const [biologie, setBiologie] = useState([]);
  const [addBiologie, setAddBiolologie] = useState('');
  const [BilanEvaluationPatient, setBilanEvaluationPatient] = useState([]);
  const [BilanEvaluationMalade, setBilanEvaluationMalade] = useState([]);
  const [Glucocorticoïdes, setGlucocorticoïdes] = useState([]);
  const [Minéralocorticoïdes, setMinéralocorticoïdes] = useState([]);
  const [SteroideSexuel, setSteroideSexuel] = useState([]);
  const [Biopsie, setBiopsie] = useState([]);
  const [Testicule, setTesticule] = useState([]);
  const [ParaTesticule, setParaTesticule] = useState([]);
  const [Seminome, setSeminome] = useState([]);
  const [NonSeminome, setNonSeminome] = useState([]);
  const [TumeurCelluleSL, setTumeurCelluleSL] = useState([]);
  const [TumeurCelluleG, setTumeurCelluleG] = useState([]);
  const [Rhabdomyosarcome, setRhabdomyosarcome] = useState([]);
  const [Carcinome, setCarcinome] = useState([]);
  const [Dysgerminome, setDysgerminome] = useState('');
  const [Teratome, setTeratome] = useState('');
  const [Germinale, setGerminale] = useState('');
  const [Choriocarcinome, setChoriocarcinome] = useState('');
  const [TumeurSacVitellin, setTumeurSacVitellin] = useState('');
  const [CordonSexuel, setCordonSexuel] = useState('');
  const [KraBrafPik, setKraBrafPik] = useState([]);
  const [Fox, setFox] = useState('');
  const [PcinquanteTrois, setPcinquanteTrois] = useState([]);
  const [Adenok, setAdenok] = useState('');
  const [ConsultationOncogetique, setConsultationOncogetique] = useState([]);
  const [ResultatFacteur, setResultatFacteur] = useState('');
  const [metanephrinesPlasma, setMetanephrinesPlasma] = useState('');
  const [normetanephrinesPlasma, setNormetanephrinesPlasma] = useState('');
  const [metanephrinesUrine, setMetanephrinesUrine] = useState('');
  const [normetanephrinesUrine, setNormetanephrinesUrine] = useState('');
  const [creatininurie24h, setCreatininurie24h] = useState('');
  const [chromogranineA, setChromogranineA] = useState('');
  const [tauxPotassium, setTauxPotassium] = useState('');
  const [glycemie, setGlycemie] = useState('');

    const [affichAGO, setAffichAGO] = useState(false);
    
    const userData =JSON.parse(localStorage.getItem('user'));
    const role = userData.user.roles.map(item => item.name).join(', ');

  const handleDateChangeExamClinique = (date, dateString) => {
      setExamParaClinique({ ...examParaClinique, date: dateString });
  };


  const handleChangeExamParaClinique = (value) => {
      setExamParaClinique({ ...examParaClinique, type: value });
  };

  const handleChangeResultatExamParaClinique = (e) => {
      setExamParaClinique({ ...examParaClinique, resultat: e.target.value });
  };

  const handleAdd = () => {
      setSignesParacliniques([...SignesParacliniques, examParaClinique]);
      setExamParaClinique({ date: null, type: [], resultat: '' });
  };


  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setBiologie((prevBiologie) =>
          prevBiologie.map((item) =>
              item.name === name ? { ...item, value } : item
          )
      );
  };

  // Fonction de gestion du changement de niveau de risque
  const handleRiskChange = (checkedValues) => {
      setCheckboxChoice(checkedValues); // Stocke les checkboxes sélectionnées
      setRisque([]); // Réinitialise les stades
      setRisque(''); // Réinitialise Risque
  };

  // Fonction de gestion du changement des stades
  const handleRisqueChange = (selectedRisque) => {
      setRisque(selectedRisque); // Met à jour les stades sélectionnés

      // Mise à jour de Risque avec les checkboxes sélectionnées et les stades choisis
      if (selectedRisque.length > 0) {
          setRisque(`${CheckboxChoice.join(', ')}: ${selectedRisque.join(', ')}`);
      } else {
          setRisque(CheckboxChoice.join(', ')); // Si aucun stade n'est sélectionné, Risque est juste les checkboxes choisies
      }
  };

  // Fonction pour réinitialiser tous les états
  const resetStates = () => {
    setHyperleucocytose(null);
      setDatePrelevement(null);
      setDateDiagnostic(null);
      setTechniquePrelevement('');
      setEtatGeneralPatient([]);
      setMoyenDiagnosticBCRABL('');
      setCytogenetiqueT('');
      setCytogenetiqueAca('');
      setSc('');
      setIRMCP('');
      setIRMMP('');
      setIRMCPO('');
      setIRMMPO('');
      setLCR('');
      setExamenAnatomopathlogique('');
      setMargesResection("");
      setMedullogramme("");
      setDelaiRenduResultat(null);
      setMoyenDiagnosticCytologie([]);
      setMoyenDiagnosticCytologieResultats([]);
      setMoyenDiagnosticImmunophénotypageSanguin('');
      setMoyenDiagnosticImmunophenotypageSanguinResultats([]);
      setMoyenDiagnosticHistologie('');
      setMoyenDiagnosticHistologieResultats([]);
      setMoyenDiagnosticImuunohistochimie('');
      setMoyenDiagnosticImuunohistochimieResultats([]);
      setMoyenDiagnosticMedullogramme([]);
      setMoyenDiagnosticMedullogrammeResultats([]);
      setMoyenDiagnosticBiopsieOsteomedullaire('');
      setMoyenDiagnosticBiopsieOsteomedullaireResultats([]);
      setPsOms('');
      setPoids('');
      setPoidsAge('');
      setPoidsTaille('');
      setTaille('');
      setTailleAge('');
      setMedia('');
      setBiologie([]);
      setOncoGenetique('');
      setConsultationOncogetique([]);
      setSiege('');
      setImcSc('');
      setImcAge('');
      setALK('');
      setCMET('');
      setROSI('');
      setKRAS('');
      setBRAF('');
      setHER2('');
      setNTRK('');
      setNRG1('');
      setRET('');
      setTA('');
      setEndoscopieDigestive('');
      setBilanPreTherapeutique([]);
      setSignesPhysiques([]);
      setSignesCliniques([]);
      setSignesParacliniques([]);
      setTypeHistologies([]);
      setMedulloblasmeSelect([]);
      setGradeHistopronostique([]);
      setBiologieMoleculaire([]);
      setTils('');
      setBilanEvaluationMalade([]);
      setBilanEvaluationPatient([]);
      setImmunohistochimie([]);
      setMutationGenetique([]);
      setMarqueursTumoraux([]);
      setFacteursPronostiques([]);
      setStadeClinique([]);
      setStadeAnnArbor([]);
      setTypeCytologique([]);
      setCytogenetiqueConventionnelle('');
      setFISH('');
      setCytogenetiqueDel17p([]);
      setBiologieMoleculaireMutationTp53([]);
      setClassificationBinet([]);
      setStadeSokal([]);
      setScoreHasford([]);
      setScoreEUTOS([]);
      setIMWG2014([]);
      setClassificationSalmonDiurie([]);
      setClassificationSalmonDiurieStade([]);
      setClassificationISS([]);
      setClassificationAltmann([]);
      setClassificationISSStade([]);
      setClassificationISSRevisee([]);
      setClassificationISSReviseeStade([]);
      setClassificationOmsSyndromesMyelodysplasiques([]);
      setNéoplasmesCellulesLangerhansAutresCellulesDndritiques([]);
      setNéoplasmesHistiocytesMacrophages([]);
      setRisque('');
      setTypeCistologie([]);
      setAtteinteLCR('');
      setAtteinteTesticulaire('');
      setAge('');
      setPhenotype('');
      setFacteurGenetique('');
      setCorticoSensibilite('');
      setMalade([]);
      setTumeur([]);
      setAdenopathie([]);
      setMétastase([]);
      setMoyenDiagnostic([]);
      setFacteurPronostique([]);
      setImmuno('');
      setEGFR('');
      setImmuno([]);
      setScorePronostic([]);
      setValueStade();
  };

  useEffect(() => {
      // Appeler la fonction de réinitialisation lorsque le composant se monte
      resetStates();
  }, []); // Si vous souhaitez que cela se produise à chaque fois que le composant se monte, laissez le tableau de dépendances vide



  // mettre à jour dynamiquement les champs diagnostiques
  const updateMoyensDiagnostiques = (key, value) => {
      setMoyenDiagnostic((prev) => ({
          ...prev,
          [key]: value,
      }));
  };

  // mettre à jour dynamiquement les champs StadeAnnboor
  const [stadeAnnBoor, setStadeAnnBoor] = useState([]);


   

  // mettre à jour dynamiquement les champs bilan
  const updateBilanPreTherapeutique = (field, value) => {
      setAddBilanPreTherapeutique((prev) => ({
        ...prev,
        [field]: value,
    }));
  };




  // mettre à jour dynamiquement les champs cytogenetique
  const Cytogenetique = (key, value) => {
      setCytogenetiqueDel17p((prev) => ({
          ...prev,
          [key]: value,
      }));
  };

  const handleFacteurChange = (value) => {
      setFacteursPronostiques(value);
      setAfficheChexbox27(value.includes('CRP'));
      setAfficheChexbox28(value.includes('Anomalies cytogénétiques défavorable'));


  };


  // Fonction pour changer l'état d'un facteur
  const toggleFacteurStatus = (key, value) => {
      setFacteurPronostique((prev) => ({
          ...prev,
          [key]: value,
      }));
  };

  const Biologie = (key, value) => {
      setAddBiolologie((prev) => ({
          ...prev,
          [key]: value,
      }));
  };


  // mettre à jour dynamiquement les champs Medulloblasme
  const Medulloblasme = (key, value) => {
      setMedulloblasmeSelect((prev) => ({
          ...prev,
          [key]: value,
      }));
  };
  // mettre à jour dynamiquement les champs mutation
  const Mutation = (key, value) => {
      setBiologieMoleculaireMutationTp53((prev) => ({
          ...prev,
          [key]: value,
      }));

  };
  //paraclinique

  const handleCheckboxSigneParaclinque = (e) => {
      const { value, checked } = e.target;
      if (checked) {
          setSignesParacliniques((prev) => [...prev, value]);
      } else {
          setSignesParacliniques((prev) => prev.filter((item) => item !== value));
      }
  };

  const optionTypeTesticles = [
      { value: "Germinale", label: "Germinale" },
      { value: "Cordons sexuels", label: "Cordons sexuels" },

  ]

  const handleChangeType = (value) => {
      setTypeTesticule(value);
      if (Specialite === 'Pédiatrie' && Organe === 'Testicule/para-testicule' && (value === 'Germinale')) {
          setAfficheChexbox25(true);
          setAfficheChexbox26(false);

      }
      else if (Specialite === 'Pédiatrie' && Organe === 'Testicule/para-testicule' && (value === 'Cordons sexuels')) {
          setAfficheChexbox26(true);
          setAfficheChexbox25(false);


      } else {
          setAfficheChexbox25(false);
          setAfficheChexbox26(false);
      }


  };

  const handleChangeBiomoleculaire = (value) => {
      setBiologieMoleculaire(value);
      if (Specialite === 'Hématologie' && Organe === 'Leucémie Lymphoïde Chronique') {
          setAfficheChexbox8(true);
      } else {
          setAfficheChexbox8(false);
      };
      if ((Specialite === 'Thorax' && Organe === 'Poumon') && (value === 'patient naïf da chimiotérapie')) {
          setAfficheChexbox13(true);
      } else {
          setAfficheChexbox13(false);
      };

      if ((Specialite === 'Thorax' && Organe === 'Poumon') && (value === 'patient ayant reçu une chimiotérapie')) {
          setAfficheChexbox14(true);
      } else {
          setAfficheChexbox14(false);
      }

      if ((Specialite === 'Cancers Rares' && (Organe === 'Medullosurrenale' || Organe === 'Ganglion sympathique' || Organe === 'Ganglion parasympathique')) && (value === 'IHC')) {
          setAfficheChexbox32(true);
      } else {
          setAfficheChexbox32(false);
      };

      if ((Specialite === 'Cancers Rares' && (Organe === 'Parathyroïde')) && (value === 'IHC')) {
          setAfficheChexbox33(true);
      } else {
          setAfficheChexbox33(false);
      };
  };


  const handleFacteurProsnostique = (value) => {
      setFacteurPronostique(value);
      if ((Specialite === 'ORL' && (Organe === 'Cavité buccale' || Organe === 'Pharynx' || Organe === 'Larynx' || Organe === 'Cavité nasale' || Organe === 'Lévre' || Organe === 'Sinus' || Organe === 'glandes salivaire')) && (value === 'Surexpression EGF')) {
          setAfficheChexbox15(true);
      } else {
          setAfficheChexbox15(false);
      };

      if ((Specialite === 'ORL' && (Organe === 'Cavité buccale' || Organe === 'Pharynx' || Organe === 'Larynx' || Organe === 'Cavité nasale' || Organe === 'Lévre' || Organe === 'Sinus' || Organe === 'glandes salivaire')) && (value === 'HPV +')) {
          setAfficheChexbox16(true);
      } else {
          setAfficheChexbox16(false);
      }

      if ((Specialite === 'ORL' && (Organe === 'Thyroide')) && (value === 'Caractéristiques en rapport avec le patient')) {
          setAfficheChexbox17(true);
      } else {
          setAfficheChexbox17(false);
      };

      if ((Specialite === 'ORL' && (Organe === 'Thyroide')) && (value === 'Caractéristiques en rapport avec la tumeur')) {
          setAfficheChexbox18(true);
      } else {
          setAfficheChexbox18(false);
      };

      if ((Specialite === 'ORL' && (Organe === 'Thyroide')) && (value === 'Classification ATA 2015 du risque de rechute')) {
          setAfficheChexbox19(true);
      } else {
          setAfficheChexbox19(false);
      }
  }
  // Fonction de gestion du changement de sélection
  const handleSelectChange = (value) => {
      setMoyenDiagnostic(value);

      // Vérifier si un moyen diagnostique a été sélectionné pour afficher les checkboxes
      if (Specialite === 'Hématologie' && Organe !== 'Leucémie Lymphoïde Chronique') {
          setAfficheChexbox(value.length > 0);
      }
      if (Specialite === 'Hématologie' && Organe === 'Leucémie Lymphoïde Chronique') {
          setAfficheChexbox6(true);
      } else {
          setAfficheChexbox6(false);
          setAfficheChexbox(false);
      };


      if (Specialite === 'Pédiatrie' && Organe === 'T. Sacro-coccygienne') {
          setAfficheChexbox20(value.includes('Biologie'));
          setAfficheChexbox21(value.includes('Chirurgie'));
      } else {
          setAfficheChexbox20(false);
          setAfficheChexbox21(false);
      }
      if (Specialite === 'Hématologie' && (Organe === 'Syndromes myélodysplasiques' || Organe === 'Syndrome Myéloprolifératif Ph1 négative')) {
          setAfficheChexbox29(value.includes('Médullogramme'));
          setAfficheChexbox30(value.includes('Cytogénétique'));
          setAfficheChexbox31(value.includes('Biopsie Ostéomédullaire'));
      } else {
          setAfficheChexbox29(false);
          setAfficheChexbox30(false);
          setAfficheChexbox31(false);
      }

  };


  const handleStadePronosticChange = (value) => {
      setTumeur(value);

      if (Specialite === 'Hématologie' && Organe === 'LA Adulte' && (value === 'LAM OMS 2022')) {
          setAfficheChexbox4(true);
      } else {
          setAfficheChexbox4(false);
      };
      if (Specialite === 'Hématologie' && Organe === 'LA Adulte' && (value === 'LAL')) {
          setAfficheChexbox5(true);
      } else {
          setAfficheChexbox5(false);
      }
  };

  const handleChangeCystogénétique = (value) => {
      setTypeHistologies(value);
      if (value) {
          setAfficheChexbox7(true);
      }
  };

  const handleChangeTypeHistologies = (value) => {
    setTypeHistologies(value);

    // Si aucune valeur n'est sélectionnée, réinitialisez les états
    if (!value || value.length === 0) {
        setAfficheChexbox9(false);
        setAfficheChexbox10(false);
        setAfficheChexbox11(false);
        setAfficheChexbox12(false);
        return;
    }

    // Met à jour les états en fonction des valeurs sélectionnées
    setAfficheChexbox10(value.includes('Lymphome folliculaire'));
    setAfficheChexbox11(value.includes('Lymphome à cellules du manteau'));

    // Si aucune des valeurs spécifiques n'est sélectionnée, affiche Chexbox12
    const showChexbox12 = !value.includes('Lymphome folliculaire') && !value.includes('Lymphome à cellules du manteau');
    setAfficheChexbox12(showChexbox12);
};




  const handleSigneParaClinique = (value) => {
      setSignesParacliniques(value);
    
      if (Specialite === 'Pédiatrie' && Organe === 'Corticosurrenale' && value.includes('GlucoC')) {
          setAfficheChexbox22(true);
      } else {
          setAfficheChexbox22(false);
      }
      if (Specialite === 'Pédiatrie' && Organe === 'Corticosurrenale' && value.includes('minéralloC')) {
          setAfficheChexbox23(true);
      } else {
          setAfficheChexbox3(false);
      }
      if (Specialite === 'Pédiatrie' && Organe === 'Corticosurrenale' && value.includes('Stéroides')) {
          setAfficheChexbox24(true);
      } else {
          setAfficheChexbox24(false);
      }
  };

  const handleStadeChange = (value) => {
    setStadeAnnBoor(value);
    
    // Mettez à jour les états des checkboxes en fonction des valeurs sélectionnées
    setAfficheChexbox1(value.includes('X') || value.includes('E'));
    setAfficheChexbox2(value.includes('Stade I') || value.includes('Stade II'));
    setAfficheChexbox3(value.includes('Stade III') || value.includes('Stade IV'));
};


const handleCheckboxChangeStadeAnbor = (value, checked) => {
    if (checked) {
        setStadeAnnBoor((prev) => [...prev, value]);
    } else {
        setStadeAnnBoor((prev) => prev.filter((item) => item !== value));
    }
};


const handleCheckboxChangeStadeAnbor1 = (checked, checkboxType) => {
    // Ajoutez des conditions pour chaque type de checkbox
    setStadeAnnBoor((prevData) =>
        prevData.map((item) =>
            item.value === 'Stade III' || item.value === 'Stade IV'
                ? { ...item, [checkboxType]: checked ? 'Défavorable' : 'Favorable' }
                : item
        )
    );
};




const handleCheckboxChange1 = (e) => {
    const { value, checked } = e.target;
    if (checked) {
        setScorePronostic((prev) => [...prev, value]);
    } else {
        setScorePronostic((prev) => prev.filter((item) => item !== value));
    }
};




console.log(stadeAnnBoor);


  const handleStadeChange1 = (value) => {
      setStadeAnnBoor(value);
      // Vérifie si 'X' ou 'E' sont présents dans le tableau `value`
      if (
          Specialite === 'Hématologie' &&
          Organe === 'Lymphome non hodgkinien adulte' &&
          (value.includes('X') || value.includes('E'))
      ) {
          setAfficheChexbox1(true);
      } else {
          setAfficheChexbox1(false);
      }
  };



  // Fonction de gestion du changement de checkbox "Oui"
  const handleCheckboxChange = (e) => {
      setCheckOui(e.target.checked);
      setValueStade(e.target.value)
      const { value, checked } = e.target;
      if (checked) {
          setScorePronostic((prev) => [...prev, value]);
      } else {
          setScorePronostic((prev) => prev.filter((item) => item !== value));
      }
  };



  const handleCheckboxChangeClinique = (value, checked) => {
    if (checked) {
        setSignesCliniques((prev) => [...prev, value]);
    } else {
        setSignesCliniques((prev) => prev.filter((item) => item !== value));
    }
};


const handleScorePronosticChange = (value) => () => {
    // Met à jour la sélection basée sur la case cochée
    setScorePronostic(() => [value]); // Remplace tout par la nouvelle sélection
    console.log([value]);
};


  const optionTumeursGerminaux = [
      { value: "Bon : Testicule primaire sans métastases viscérales non pulmonaires et bons marqueurs : AFP < 1000 ng/mL et hCG < 5000 IU/L et LDH < 1,5 × LSN (Limite Supérieure de Normalité)", label: "Bon : Testicule primaire sans métastases viscérales non pulmonaires et bons marqueurs : AFP < 1000 ng/mL et hCG < 5000 IU/L et LDH < 1,5 × LSN (Limite Supérieure de Normalité)" },
      { value: "Intermédiaire : Testicule primaire sans métastases viscérales non pulmonaires et marqueurs intermédiaires : AFP ≥ 1000 ng/mL et ≤ 10 000 ng/mL ou hCG ≥ 5000 IU/L et ≤ 50 000 IU/L ou LDH ≥ 1,5 × LSN et ≤ 10 × LSN", label: "Intermédiaire : Testicule primaire sans métastases viscérales non pulmonaires et marqueurs intermédiaires : AFP ≥ 1000 ng/mL et ≤ 10 000 ng/mL ou hCG ≥ 5000 IU/L et ≤ 50 000 IU/L ou LDH ≥ 1,5 × LSN et ≤ 10 × LSN" },
      { value: "Mauvais : Primaire médiastinal ou métastases viscérales non pulmonaires ou marqueurs mauvais : AFP > 10 000 ng/mL ou hCG > 50 000 IU/L ou LDH > 10 × LSN", label: "Mauvais : Primaire médiastinal ou métastases viscérales non pulmonaires ou marqueurs mauvais : AFP > 10 000 ng/mL ou hCG > 50 000 IU/L ou LDH > 10 × LSN" },

  ];

  const optionCatecholaminesUrinaires = [
      { value: "HVA", label: "HVA" },
      { value: "VMA", label: "VMA" },
      { value: "Dopamine", label: "Dopamine" },

  ];

  const optionOsteosarcomeSH = [
      { value: "Grade I : >50 % de cellules tumorales identifiables", label: "Grade I : >50 % de cellules tumorales identifiables" },
      { value: "Grade II : 5 % < cellules tumorales identifiables ≤ 50 %", label: "Grade II : 5 % < cellules tumorales identifiables ≤ 50 %" },
      { value: "Grade III : ≤ 5 % de cellules viables ou quelques cellules tumorales résiduelles disséminées sur toute la tranche de section", label: "Grade III : ≤ 5 % de cellules viables ou quelques cellules tumorales résiduelles disséminées sur toute la tranche de section" },
      { value: "Grade IV : aucune cellule viable (absence de cellule tumorale identifiable).", label: "Grade IV : aucune cellule viable (absence de cellule tumorale identifiable)." },
      { value: "Patient bon répondeur : <10 % de cellules tumorales viables (grade III, IV et grade II <10 %)", label: "Patient bon répondeur : <10 % de cellules tumorales viables (grade III, IV et grade II <10 %)" },
      { value: "patient mauvais répondeur : ≥ 10 % de cellules tumorales viables (grade I et grade II ≥ 10%).", label: "patient mauvais répondeur : ≥ 10 % de cellules tumorales viables (grade I et grade II ≥ 10%)." },

  ];

  const optionOsteosarcomeMR = [

      { value: "R0 : la section chirurgicale passe à distance de la tumeur en laissant une marge plus ou moins épaisse de tissu sain.", label: "R0 : la section chirurgicale passe à distance de la tumeur en laissant une marge plus ou moins épaisse de tissu sain." },
      { value: "R1 : la section chirurgicale passe au ras de la tumeur. Il n'y a pas de marge de tissu sain entre l'extension microscopique du cancer (radiaire ou distal) et la section chirurgicale", label: "R1 : la section chirurgicale passe au ras de la tumeur. Il n'y a pas de marge de tissu sain entre l'extension microscopique du cancer (radiaire ou distal) et la section chirurgicale" },
      { value: "R2 : la résection macroscopique est incomplète", label: "R2 : la résection macroscopique est incomplète" },


  ];



  const optionTypeHistologies = [
      { value: "par cancer", label: "par cancer" },
      ...(Specialite === 'Hématologie' && Organe === 'Lymphome Hodgkin adulte' ? [
          { value: "Lymphome de Hodgkin classique", label: "Lymphome de Hodgkin classique" },
          { value: "Lymphome de Hodgkin nodulaire à prédominance lymphocytaire", label: "Lymphome de Hodgkin nodulaire à prédominance lymphocytaire" }

      ] : []),


      ...(Specialite === 'Hématologie' && Organe === 'Leucémie Lymphoïde Chronique' ? [
          { value: "Del17p", label: "Del17p" }

      ] : []),
      ...(Specialite === 'Urologie' && (Organe === 'Prostate' || Organe === 'Testicule' || Organe === 'Vessie/Arbre urinaire' || Organe === 'Penis' || Organe === 'Surrenale' || Organe === 'Reins') ? [
          { value: "codage OMS", label: "codage OMS" }

      ] : []),

      ...(Specialite === 'Hématologie' && Organe === 'Lymphome non hodgkinien adulte' ? [
          { value: "Lymphome de Hodgkin classique", label: "Lymphome de Hodgkin classique" },
          { value: "Lymphome de Hodgkin nodulaire à prédominance lymphocytaire", label: "Lymphome de Hodgkin nodulaire à prédominance lymphocytaire" },
          { value: "Lymphome de Burkitt", label: "Lymphome de Burkitt" },
          { value: "Lymphome B diffus à grandes cellules type GC", label: "Lymphome B diffus à grandes cellules type GC" },
          { value: "Lymphome B diffus à grandes cellules type non GC", label: "Lymphome B diffus à grandes cellules type non GC" },
          { value: "Lymphome primitif du médiastin", label: "Lymphome primitif du médiastin" },
          { value: "Lymphome anaplasique", label: "Lymphome anaplasique" },
          { value: "Leucémie/lymphome lymphoblastique B", label: "Leucémie/lymphome lymphoblastique B" },
          { value: "Leucémie/lymphome lymphoblastique T", label: "Leucémie/lymphome lymphoblastique T" },
          { value: "Lymphome plasmablastique", label: "Lymphome plasmablastique" },
          { value: "Lymphome folliculaire", label: "Lymphome folliculaire" },
          { value: "Leucémie à tricholeucocytes", label: "Leucémie à tricholeucocytes" },
          { value: "Lymphome de la zone marginale de la rate", label: "Lymphome de la zone marginale de la rate" },
          { value: "Lymphome lymphoplasmocytaire", label: "Lymphome lymphoplasmocytaire" },
          { value: "Lymphome de la zone marginale", label: "Lymphome de la zone marginale" },
          { value: "Lymphome à cellules du manteau", label: "Lymphome à cellules du manteau" },
          { value: "Lymphome T NOS", label: "Lymphome T NOS" },
          { value: "Lymphome T angio-immunoblastique", label: "Lymphome T angio-immunoblastique" },
          { value: "Leucémie/Lymphome T de l’adulte", label: "Leucémie/Lymphome T de l’adulte" },

      ] : []),
      ...(Specialite === 'Thorax' && Organe === 'Médiastin' ? [
          { value: "Tératomes bénins thymiques", label: "Tératomes bénins thymiques" },
          { value: "Thymomes malins", label: "Thymomes malins" },
          { value: "Lymphomes de hodgkin", label: "Lymphomes de hodgkin" },
          { value: "Lymphomes malins non hodgkiniens", label: "Lymphomes malins non hodgkiniens" },
          { value: "Tumeurs nerveuses", label: "Tumeurs nerveuses" },
          { value: "Tumeurs germinales séminomateuses", label: "Tumeurs germinales séminomateuses" },
          { value: "Tumeurs germinales non séminomateuses", label: "Tumeurs germinales non séminomateuses" },

      ] : []),
      ...(Specialite === 'Thorax' && Organe === 'Parois thoracique' ? [
          { value: "Sarcome d’Ewing", label: "Sarcome d’Ewing" },
          { value: "Tumeur d’Askin", label: "Tumeur d’Askin" },
          { value: "Tumeur desmoïde", label: "Tumeur desmoïde" },
          { value: "Chondrosarcome myxoïde", label: "Chondrosarcome myxoïde" },
          { value: "Leimyosarcome", label: "Leimyosarcome" },
          { value: "Lymphome pariétal anaplasique", label: "Lymphome pariétal anaplasique" },
          { value: "Autres tumeurs pariétales", label: "Autres tumeurs pariétales" },

      ] : []),
      ...(Specialite === 'Thorax' && Organe === 'Poumon' ? [
          { value: "Classification anatomo-pathologie des cancers du poumon 2015", label: "Classification anatomo-pathologie des cancers du poumon 2015" },

      ] : []),

      ...(Specialite === 'ORL' && (Organe === 'Cavité buccale' || Organe === 'Pharynx' || Organe === 'Larynx' || Organe === 'Cavité nasale' || Organe === 'Lévre' || Organe === 'Sinus' || Organe === 'glandes salivaire') ? [
          { value: "par cancer", label: "par cancer" },

      ] : []),

      ...(Specialite === 'ORL' && (Organe === 'Thyroide') ? [
          { value: "carcinome papillaire/vésiculaire", label: "carcinome papillaire/vésiculaire" },
          { value: "carcinome médullaire", label: "carcinome médullaire" },
          { value: "carcinome anaplasique", label: "carcinome anaplasique" },

      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'Tumeurs cerebrales') ? [
          { value: "5e édition de la classification OMS des tumeurs cérébrales", label: "5e édition de la classification OMS des tumeurs cérébrales" },


      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Rétropéritoine') ? [
          { value: "Tératome", label: "Tératome" },
          { value: "Tumeur vitelline", label: "Tumeur vitelline" },
          { value: "Paragangliome parasympathique", label: "Paragangliome parasympathique" },

      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Os') ? [
          { value: "Classification OMS tumeurs osseuses", label: "Classification OMS tumeurs osseuses" },


      ] : []),
      ...(Specialite === 'Cancers Rares' ? [
          { value: "codage OMS", label: "codage OMS" },

      ] : []),


  ];
  const optionMalade = [
      ...(Specialite === 'Digestif' && Organe === 'Anus' ? [
          { value: "HIV", label: "HIV" }
      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Colon et rectum' ? [
          { value: "albuminémie", label: "albuminémie" }
      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Oesophage' ? [
          { value: "EFR", label: "EFR" },
          { value: "albuminémie", label: "albuminémie" }

      ] : []),

  ];

  const optionMoyenDiagnostic = [
      ...(Specialite === 'Digestif' && Organe === 'Anus' ? [
          { value: "COLOSCOPIE", label: "COLOSCOPIE" },
          { value: "HISTOLOGIE", label: "HISTOLOGIE" }
      ] : []),

      ...(Specialite === 'Digestif' && Organe === 'Colon et rectum' ? [
          { value: "coloscopie totale", label: "coloscopie totale" },
          { value: "Histologie", label: "Histologie" }
      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Voies biliaires' ? [
          { value: "TUMEUR BILIAIRE (TDM, IRM)", label: "TUMEUR BILIAIRE (TDM, IRM)" },
          { value: "HISTOLOGIE (BIOPSIE, BROSSAGE)", label: "HISTOLOGIE (BIOPSIE, BROSSAGE)" }
      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Pancreas' ? [
          { value: 'TUMEUR RESECABLE (TDM, IRM)', label: "TUMEUR RESECABLE (TDM, IRM" },
          { value: "BIOPSIE (Checkboxlogie, EchoEndoscopie)", label: "BIOPSIE (Checkboxlogie, EchoEndoscopie)" }
      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Estomac' ? [
          { value: 'FOGD', label: "FOGD" },
          { value: "Histologie", label: "Histologie" }
      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Foie' ? [
          { value: 'Imagerie typique (TDM, IRM)', label: "Imagerie typique (TDM, IRM)" },
          { value: "PBH", label: "PBH" }
      ] : []),

      ...(Specialite === 'Digestif' && Organe === 'Oesophage' ? [
          { value: "FOGD", label: "FOGD" },
          { value: "Histologie", label: "Histologie" }
      ] : []),

      ...(Specialite === 'Gynécologie' && Organe === 'Néoplasies trophoblastiques gestationnelles' ? [
          { value: 'imagerie', label: 'imagerie' },
          { value: 'biologie (Dosage beta HCGplasmatique /Histologie)', label: 'biologie (Dosage beta HCGplasmatique /Histologie)' },
      ] : []),
      ...(Specialite === 'Gynécologie' && (Organe === 'Ovaire' || Organe === 'Col' || Organe === 'Utérus' || Organe === 'Vagin' || Organe === 'Vulve') ? [
          { value: 'cytologie', label: 'cytologie' },
          { value: 'histologie', label: 'histologie' },
          { value: 'imagerie', label: 'imagerie' },
          { value: 'biologie(CA 12-5,alpha-foetoprotéine)', label: 'biologie(CA125,alpha-foetoprotéine)' },
      ] : []),
      ...(Specialite === 'Gynécologie' && (Organe === 'Sein') ? [
          { value: 'cytologie', label: 'cytologie' },
          { value: 'histologie', label: 'histologie' },
          { value: 'imagerie', label: 'imagerie' },
          { value: 'biologie(CA 15-3,alpha-foetoprotéine)', label: 'biologie(CA125,alpha-foetoprotéine)' },
          { value: 'galactographie', label: 'galactographie' },

      ] : []),

      ...(Specialite === 'Hématologie' && (Organe === 'Néoplasmes des cellules histiocytaires/dendritiques' || Organe === 'Lymphome non hodgkinien adulte') ? [
          { value: 'cytologie', label: 'cytologie' },
          { value: 'histologie', label: 'histologie' },
          { value: 'Imuunohistochimie', label: 'Imuunohistochimie' },
          { value: 'Immunophénotypage sanguin', label: 'Immunophénotypage sanguin' },
      ] : []),

      ...((Specialite === 'Hématologie' && (Organe === 'Lymphome Hodgkin adulte') || (Specialite === 'Pédiatrie' && Organe === 'Néoplasmes des cellules histiocytaires/dendritiques')) ? [
          { value: 'cytologie', label: 'cytologie' },
          { value: 'histologie', label: 'histologie' },
          { value: 'Imuunohistochimie', label: 'Imuunohistochimie' },
      ] : []),

      ...(Specialite === 'Hématologie' && (Organe === 'LA Adulte') ? [
          { value: 'Médullogramme', label: 'Médullogramme' },
          { value: 'Immunophénotypage', label: 'Immunophénotypage' },
      ] : []),
      ...(Specialite === 'Hématologie' && (Organe === 'Leucémie Lymphoïde Chronique') ? [
          { value: 'Immunophénotypage sanguin', label: 'Immunophénotypage sanguin' },
      ] : []),
      ...(Specialite === 'Hématologie' && (Organe === 'Myélome multiple') ? [
          { value: 'Médullogramme', label: 'Médullogramme' },
          { value: 'Immunophénotypage', label: 'Immunophénotypage' },
          { value: 'histologie', label: 'histologie' },
          { value: 'Imuunohistochimie', label: 'Imuunohistochimie' },
      ] : []),
      ...(Specialite === 'Urologie' && (Organe === 'Prostate') ? [
          { value: 'Biopsie', label: 'Biopsie' },
          { value: 'Biopsie guidée par l’imagerie', label: 'Biopsie guidée par l’imagerie' },
          { value: 'Chirurgie', label: 'Chirurgie' },
      ] : []),
      ...(Specialite === 'Urologie' && (Organe === 'Testicule' || Organe === 'Vessie /Arbre urinaire' || Organe === 'Penis' || Organe === 'Surrenale') ? [
          { value: 'Biopsie', label: 'Biopsie' },
          { value: 'Biopsie guidée par l’imagerie', label: 'Biopsie guidée par l’imagerie' },
          { value: 'Chirurgie', label: 'Chirurgie' },
      ] : []),

      ...(Specialite === 'Urologie' && (Organe === 'Reins') ? [
          { value: 'Biopsie', label: 'Biopsie' },
          { value: 'Biopsie guidée par l’imagerie', label: 'Biopsie guidée par l’imagerie' },
          { value: 'Chirurgie', label: 'Chirurgie' },
          { value: 'Imagerie', label: 'Imagerie' },
      ] : []),

      ...(Specialite === 'ORL' && (Organe === 'Cavité buccale' || Organe === 'Thyroide' || Organe === 'Pharynx' || Organe === 'Larynx' || Organe === 'Cavité nasale' || Organe === 'Lévre' || Organe === 'Sinus' || Organe === 'glandes salivaire' || Organe === 'Cavum' || Organe === 'Nasopharynx') ? [
          { value: 'cytologie', label: 'cytologie' },
          { value: 'histologie', label: 'histologie' },
          { value: 'imagerie', label: 'imagerie' },
          { value: 'biologie', label: 'biologie' },
      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Tumeurs cerebrales') ? [
          { value: 'cytologie', label: 'cytologie' },
          { value: 'histologie', label: 'histologie' },
          { value: 'imagerie', label: 'imagerie' },
          { value: 'Marqueurs tumoraux LCR et/ou sanguins', label: 'Marqueurs tumoraux LCR et/ou sanguins' },
      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Tumeurs orbitraire' || Organe === 'Tumeurs médiastinales') ? [
          { value: 'cytologie', label: 'cytologie' },
          { value: 'histologie', label: 'histologie' },
          { value: 'imagerie', label: 'imagerie' },
          { value: 'Medullogramme', label: 'Medullogramme' },
          { value: 'immunohistochimie', label: 'immunohistochimie' },
          { value: 'Catécholamines urinaires', label: 'Catécholamines urinaires' },
      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'Tumeurs maxillo faciales' || Organe === 'Foie/Pédiatrie') ? [
          { value: 'cytologie', label: 'cytologie' },
          { value: 'histologie', label: 'histologie' },
          { value: 'imagerie', label: 'imagerie' },
          { value: 'Medullogramme', label: 'Medullogramme' },
          { value: 'immunohistochimie', label: 'immunohistochimie' },
      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'T. Sacro-coccygienne') ? [
          { value: 'Biopsie', label: 'Biopsie' },
          { value: 'Chirurgie', label: 'Chirurgie' },
      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Corticosurrenale') ? [
          { value: 'histologie', label: 'histologie' },
          { value: 'imagerie', label: 'imagerie' },
          { value: 'Medullogramme', label: 'Medullogramme' },
          { value: 'Catécholamines urinaires', label: 'Catécholamines urinaires' },


      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Ovaire' || Organe === 'Voie excretrice' || Organe === 'Prostate' || Organe === 'Vessie' || Organe === 'Testicule/para-testicule' || Organe === 'Os') ? [
          { value: 'histologie', label: 'histologie' },
          { value: 'imagerie', label: 'imagerie' },
          { value: 'cytologie', label: 'cytologie' },
          { value: 'biologie', label: 'biologie' },


      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'Rétropéritoine') ? [
          { value: 'histologie', label: 'histologie' },
          { value: 'imagerie', label: 'imagerie' },
          { value: 'cytologie', label: 'cytologie' },
          { value: 'biologie', label: 'biologie' },
      ] : []),


      ...(Specialite === 'Pédiatrie' && (Organe === 'Oesophage') ? [
          { value: 'Biopsie endoscopique', label: 'Biopsie endoscopique' },
          { value: 'Immunophénotypage', label: 'Immunophénotypage' },
      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Colo-rectum') ? [
          { value: 'Biopsie/Colonoscopie', label: 'Biopsie/Colonoscopie' },
          { value: 'pièce opératoire/urgence', label: 'pièce opératoire/ urgence' },
      ] : []),

      ...(Specialite === 'Hématologie' && (Organe === 'Syndromes myélodysplasiques') ? [
          { value: 'Médullogramme', label: 'Médullogramme' },
          { value: 'Cytogénétique', label: 'Cytogénétique' },
      ] : []),
      ...(Specialite === 'Hématologie' && (Organe === 'Syndrome Myéloprolifératif Ph1 négative') ? [
          { value: 'Médullogramme', label: 'Médullogramme' },
          { value: 'Cytogénétique', label: 'Cytogénétique' },
          { value: 'Biopsie Ostéomédullaire', label: 'Biopsie Ostéomédullaire' },
      ] : []),

      ...(Specialite === 'Cancers Rares' ? [
          { value: 'Biopsie', label: 'Biopsie' },
          { value: 'Biopsie guidée par l’imagerie', label: 'Biopsie guidée par l’imagerie' },
          { value: 'Chirurgie', label: 'Chirurgie' },

      ] : []),




  ];


  const optionMarqueursTumoraux = [
      ...(Specialite === 'Digestif' && Organe === 'Anus' ? [
          { value: "P16", label: "P16" },
          { value: "SCC", label: "SCC" },
      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Voies biliaires' ? [
          { value: "ACE", label: "ACE" },
          { value: "CA 19 9", label: "CA 19 9" },
      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Colon et rectum' ? [
          { value: "ACE", label: "ACE" },
      ] : []),

      ...(Specialite === 'Pédiatrie' && Organe === 'Rétropéritoine' ? [
          { value: "chromogranine A", label: "chromogranine A" },
          { value: "5-HIAA", label: "5-HIAA" },
          { value: "Echo-doppler", label: "Echo-doppler" },
          { value: "TDM", label: "TDM" },
          { value: "IRM", label: "IRM" },
          { value: "Scintigraphie (MIBG, Tc99)", label: "Scintigraphie (MIBG, Tc99)" },
          { value: "FDG", label: "FDG" },
          { value: "PET SCAN", label: "PET SCAN" },
      ] : []),



  ];


  const optionBiologieMoleculaire = [
      ...(Specialite === 'Digestif' && Organe === 'Colon et rectum' ? [
          { value: "MSI", label: "MSI" },
          { value: "Kras", label: "Kras" },
          { value: "BRAF", label: "BRAF" },
      ] : []),

      ...(Specialite === 'Digestif' && Organe === 'Pancreas' ? [
          { value: "CA 19 9", label: "CA 19 9" },

      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Estomac' ? [
          { value: "HER2", label: "HER2" },
          { value: "MSI", label: "MSI" },
          { value: "CDH1", label: "CDH1" },

      ] : []),
      ...(Specialite === 'Hématologie' && Organe === 'Leucémie Lymphoïde Chronique' ? [
          { value: "Mutation Tp53", label: "Mutation Tp53" },


      ] : []),
      ...(Specialite === 'Urologie' && Organe === 'Vessie/Arbre urinaire' ? [
          { value: "PDL1 (vessie)", label: "PDL1 (vessie)" },


      ] : []),
      ...(Specialite === 'Urologie' && Organe === 'Prostate' ? [
          { value: "BRCA (Prostate)", label: "BRCA (Prostate)" },


      ] : []),
      ...(Specialite === 'Urologie' && Organe === 'Testicule' ? [
          { value: "AFP BHCG (testicule)", label: "AFP BHCG (testicule)" },


      ] : []),
      ...(Specialite === 'Thorax' && Organe === 'Poumon' ? [
          { value: "patient naïf da chimiotérapie", label: "patient naïf da chimiotérapie)" },
          { value: "patient ayant reçu une chimiotérapie", label: "patient ayant reçu une chimiotérapie" },


      ] : []),

      ...(Specialite === 'ORL' && (Organe === 'Thyroide') ? [
          { value: "Mutation (BRAF, RAS)", label: "Mutation (BRAF, RAS)" },

      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Prostate') ? [
          { value: "Surexpression IGF-2", label: "Surexpression IGF-2" },
          { value: "Translocation PAX(3 ou 7 ) / FOX01 (FKHR)", label: "Translocation PAX(3 ou 7 ) / FOX01 (FKHR)" },
          { value: "Mutation P53", label: "Mutation P53" },
          { value: "DICER1", label: "DICER1" },

      ] : []),


      ...(Specialite === 'Pédiatrie' && Organe === 'Testicule/para-testicule' ? [
          { value: "Gains en 1q, 3, 11q, 20q, and 22", label: "Gains en 1q, 3, 11q, 20q, and 22" },
          { value: "TP53/MDM2", label: "TP53/MDM2" },
          { value: "Mutation KIT et KRAS", label: "Mutation KIT et KRAS" },


      ] : []),

      ...(Specialite === 'Cancers Rares' && (Organe === 'Parathyroïde' || Organe === 'Ganglion parasympathique' || Organe === 'Ganglion sympathique' || Organe === 'Medullosurrenale') ? [
          { value: "IHC", label: "IHC" },

      ] : []),


  ];

  const optionNéoplasmesHistiocytesMacrophages = [
      { value: "xanthogranulome juvénile", label: "xanthogranulome juvénile" },
      { value: "Malade d'Erdheim-Chester", label: "Malade d'Erdheim-Chester" },
      { value: "Malade de Rosai-Dorfman", label: "Malade de Rosai-Dorfman" },
      { value: "Histiocytose ALK-positive", label: "Histiocytose ALK-positive" },
      { value: "Sarcome histiocytaire", label: "Sarcome histiocytaire" },

  ];

  const optionCystologie = [
      ...(Specialite === 'Hématologie' && Organe === 'LA Adulte' ? [
          { value: "Leucémie aigüe lymphoblastique B", label: "Leucémie aigüe lymphoblastique B" },
          { value: "Leucémie aigüe lymphoblastique T", label: "Leucémie aigüe lymphoblastique T" },
          { value: "Leucémie aigue myéloblastique", label: "Leucémie aigue myéloblastique" },
          { value: "Leucémie aigüe bi phénotypique", label: "Leucémie aigüe bi phénotypique" },
          { value: "Forme indifférenciéeT", label: "Forme indifférenciée" }

      ] : []),
      ...(Specialite === 'Pédiatrie' && Organe === 'T. Sacro-coccygienne' ? [
          { value: "tératomes", label: "tératomes" },
          { value: "Tumeur vitelline", label: "Tumeur vitelline" },

      ] : []),
      ...(Specialite === 'Pédiatrie' && Organe === 'Corticosurrenale' ? [
          { value: "score de Weiss", label: "score de Weiss" },

      ] : []),
      ...(Specialite === 'Pédiatrie' && Organe === 'Oesophage' ? [
          { value: "Carcinome épidermoide", label: "Carcinome épidermoide" },

      ] : []),


  ];


  const optionTumeur = [
      ...(Specialite === 'Digestif' && Organe === 'Anus' ? [
          { value: "Tis: tumeur in situ", label: "Tis: tumeur in situ" },
          { value: "T1: tumeur inférieur ou égale à 5cm dans sa plus grande dimmension (de 21mm à 50 mm) ", label: "T1: tumeur inférieur ou égale à 5cm dans sa plus grande dimmension (de 21mm à 50 mm)" },
          { value: "T2: tumeur supérieur à 2cm mais inférieur ou égale à 5cm dans sa plus grande dimmension (de 21mm à 50 mm)", label: "T2: tumeur supérieur à 2cm mais inférieur ou égale à 5cm dans sa plus grande dimmension (de 21mm à 50 mm)" },
          { value: "T3: tumeur supérieur à 5cm dans sa plus grande dimmension", label: "T3:tumeur supérieur à 5cm dans sa plus grande dimmension" },
          { value: "T4: tumeur,quelle que soit sa taille,qui envahit un ou plusieurs organes adjacents (vagin,urètre,vessie) à l'exception du rectum,de la peau périnéale,du tissu cellulaire sous-cutanéet du sphincter. ", label: "Tis: tumeur,quelle que soit sa taille,qui envahit un ou plusieurs organes adjacents (vagin,urètre,vessie) à l'exception du rectum,de la peau périnéale,du tissu cellulaire sous-cutanéet du sphincter." },
          { value: "Tx: non évalué", label: "Tx: non évalué" },
      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Colon et rectum' ? [
          { value: "TX: Renseignement insuffisant pour classer la tumeur primitif", label: "TX: Renseignement insuffisant pour classer la tumeur primitif" },
          { value: "T0: Pas de signe de tumeur primitive: tumeur inférieur ou égale à 5cm dans sa plus grande dimmension (de 21mm à 50 mm)", label: "T0 Pas de signe de tumeur primitive: tumeur inférieur ou égale à 5cm dans sa plus grande dimmension (de 21mm à 50 mm)" },
          { value: "Tis: Carcinome in situ :intra-épithélial ou envahissant la lamina propria", label: "Tis: Carcinome in situ :intra-épithélial ou envahissant la lamina propria" },
          { value: "T1: Tumeur envahissant la sous-muqueuse", label: "T1: Tumeur envahissant la sous-muqueuse" },
          { value: "T2: Tumeur envahissant la musculeuse", label: "T2: Tumeur envahissant la musculeuse" },
          { value: "T3: Tumeur envahissant la sous-séreuse ou les tissus péri-rectaux non péritonisés", label: "T3: Tumeur envahissant la sous-séreuse ou les tissus péri-rectaux non péritonisés" },
          { value: "T4: Tumeur envahissant directement les autres organes ou structures evou perforant|le péritoine viscéral", label: "T4: Tumeur envahissant directement les autres organes ou structures evou perforant|le péritoine viscéral" },
          { value: "T4a: Tumeur perforant le péritoine viscéral", label: "T4a: Tumeur perforant le péritoine viscéral" },
          { value: "T4b: Tumeur envahissant directement les autres organes ou structures?", label: "T4b: Tumeur envahissant directement les autres organes ou structures?" },
      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Voies biliaires' ? [
          { value: "TX: non évalué", label: "TX: non évalué" },
          { value: "T0: pas de tumeur primitive", label: "T0: pas de tumeur primitive" },
          { value: "TIS: CIS (intra canalaire)  ", label: "TIS: CIS (intra canalaire)" },
          { value: "Tis: CIS/DHG", label: "Tis: CIS/DHG" },
          { value: "Tis: CIS", label: "Tis: CIS" },
          { value: "T1: Unique(grand axe ≤5cm:T1a;≥ 5mm T1b sans invasion vasculaire", label: "T1: Unique(grand axe ≤5cm:T1a;≥5cm T1b sans invension vasculaire" },
          { value: "T1: Envahit parois biliaire(→ muscle ou tissu fibreux)", label: "T1: Envahit parois biliaire(→ muscle ou tissu fibreux)" },
          { value: "T1: Envahit parois biliaire(< 5mm en profondeur)", label: "T1: Envahit parois biliaire(< 5mm en profondeur)" },
          { value: "T1: Envahit parois biliaire(→ lamina propria (T1a) ou musculeuse (T1b))", label: "T1: Envahit parois biliaire(→ lamina propria (T1a) ou musculeuse (T1b))" },
          { value: "T2: Unique + invasion vasculaire intra-hépatique ou multiple (± invasion vasculaire) ", label: "Unique + invasion vasculaire intra-hépatique ou multiple (± invasion vasculaire" },
          { value: "T2: Dépasse parois biliaire(→ tissu adipeux(T2a) ou fooie adjacente (T2b))", label: "T2: Dépasse parois biliaire(→ tissu adipeux(T2a) ou fooie adjacente (T2b))" },
          { value: "T2: Envahit parois biliaire (> 5mm  et <12mm en profondeur)", label: "T2: Envahit parois biliaire(> 5mm  et <12mm en profondeur)" },
          { value: "T2: Envahit tissu périmusculaire sans extensionà la séreuse (face péritonéale) (T2a) ou au fie face hépatique (T2b)", label: "T2: Envahit tissu périmusculaire sans extensionà la séreuse (face péritonéale) (T2a) ou au fie face hépatique (T2b)" },
          { value: "T3: Perfore le péritoine viscéral", label: "T3: Perfore le péritoine viscéral" },
          { value: "T3: Atteinte unilatéral BP ou AH", label: "T3: Atteinte unilatéral BP ou AH" },
          { value: "T3: Envahit parois biliaire(> 12mm en profondeur)", label: "T3: Envahit parois biliaire(> 12mm en profondeur" },
          { value: "T3: Perfore le péritoine viscéral et/ou envahit foie et/ou organe structure extra-hépatique * par contiguité", label: "T3: Perfore le péritoine viscéral et/ou envahit foie et/ou organe structure extra-hépatique * par contiguité" },
          { value: "T4: Envahit les structures extra-hépatique par contiguité hépatique", label: "T4: Envahit les structures extra-hépatique par contiguité hépatique" },
          { value: "T4: Atteinte TP,BP bilatérale AH commune ou CBS bilatérale ou CBS unilatérale VP ou AH controlatérale", label: "T4: Atteinte TP,BP bilatérale AH commune ou CBS bilatérale ou CBS unilatérale VP ou AH controlatérale" },
          { value: "T4: Atteinte TC,AMS et/ou AH commune", label: "T4: Atteinte TC,AMS et/ou AH commune" },
          { value: "T4: Atteinte TP,AH commune ou >2 organes / structures extra-hépatique (par contiguité)", label: "T4: Atteinte TP,AH commune ou >2 organes / structures extra-hépatique (par contiguité)" },
      ] : []),

      ...(Specialite === 'Digestif' && Organe === 'Pancreas' ? [
          { value: "RESECABLE", label: "RESECABLE" },
          { value: "LOCALEMENT AVANCEE", label: "LOCALEMENT AVANCEE" },
          { value: "BORDELINE", label: "BORDELINE" },
          { value: "METASTATIQUE", label: "METASTATIQUE" },

      ] : []),

      ...(Specialite === 'Digestif' && Organe === 'Estomac' ? [
          { value: "T1 : Tumeur limitée à la muqueuse ou à la sous-muqueuse (cancer superficiel)", label: "T1 : Tumeur limitée à la muqueuse ou à la sous-muqueuse (cancer superficiel)" },
          { value: "Tia m1(Tis) : Tumeur intra-Gplthélale sans invasion de la lamina propria, dysplasie de haut grade ou carcinome in situ", label: "Tia m1(Tis) : Tumeur intra-Gplthélale sans invasion de la lamina propria, dysplasie de haut grade ou carcinome in situ" },
          { value: "Tia m2 : tumeur envahissant la lamina propria", label: "Tia m2 : tumeur envahissant la lamina propria" },
          { value: "Tia m3: tumeur envahissant la musculaire muqueuse", label: "Tia m3: tumeur envahissant la musculaire muqueuse" },
          { value: "T1 smi : tumeur envahissant la sous-muqueuse sur moins de 500 microns", label: "T1 smi : tumeur envahissant la sous-muqueuse sur moins de 500 microns" },
          { value: "T1 sm2 : tumeur envahissant a sous-muqueuse sur plus de 500 microns", label: "T1 sm2 : tumeur envahissant a sous-muqueuse sur plus de 500 microns" },
          { value: "T2 : Tumeur étendue à la musculeuse", label: "T2 : Tumeur étendue à la musculeuse" },
          { value: "T3 : Tumeur envahissant la sous séreuse (y compris ligament gastro-colique ou gastro-hépatique ou grand épiploon)", label: "T3 : Tumeur envahissant la sous séreuse (y compris ligament gastro-colique ou gastro-hépatique ou grand épiploon)" },
          { value: "T4 : Tumeur envahissant la séreuse ou les organes adjacents", label: "T4 : Tumeur envahissant la séreuse ou les organes adjacents" },
          { value: "T4a : Tumeur envahissant la séreuse (pértoine viscéral)", label: "T4a : Tumeur envahissant la séreuse (pértoine viscéral)" },
          { value: "T4b : Tumeur envahissant un organe ou une structure de voisinage (rate, côlon transverse, foe, diaphragme, pancréas, paroi abdominale, surrénale, in, inestin grêle,rétropéritoine)L'envahissoment de l'æysophage ou du duodénum n'est pas considéré comme l'envahissement d'un organe adjacent.", label: "T4b : Tumeur envahissant un organe ou une structure de voisinage (rate, côlon transverse, foe, diaphragme, pancréas, paroi abdominale, surrénale, in, inestin grêle,rétropéritoine)L'envahissoment de l'æysophage ou du duodénum n'est pas considéré comme l'envahissement d'un organe adjacent." },
      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Oesophage' ? [
          { value: "Tx: non évalué", label: "Tx: non évalué" },
          { value: "TO: Pas de signe de tumeur primitive", label: "TO: Pas de signe de tumeur primitive" },
          { value: "Tis: Carcinome in situ", label: "Tis: Carcinome in situ" },
          { value: "T1: Tumeur envahissant la muqueuse ou la sous-muqueuse", label: "T1: Tumeur envahissant la muqueuse ou la sous-muqueuse" },
          { value: "Tia: tumeur envahissant la muqueuse ou la musculaire muqueuse", label: "Tia: tumeur envahissant la muqueuse ou la musculaire muqueuse" },
          { value: "Tb: tumeur envahissant la sous-muqueuse", label: "Tb: tumeur envahissant la sous-muqueuse" },
          { value: "T2: Tumeur envahissant la musculeuse", label: "T2: Tumeur envahissant la musculeuse" },
          { value: "T3: Tumeur envahissant l'adventice", label: "T3: Tumeur envahissant l'adventice" },
          { value: "T4: Tumeur envahissant les structures adjacentes.", label: "T4: Tumeur envahissant les structures adjacentes." },
          { value: "T4a: Tumeur résécable envahissant la plèvre, le péricarde, la veine azygos, le diaphragme ou le péritoine", label: "T4a: Tumeur résécable envahissant la plèvre, le péricarde, la veine azygos, le diaphragme ou le péritoine" },
          { value: "T4b: Tumeur non résécable envahissant les autres structures de voisinage telles que l'aorte, les corps vertébraux, la trachée", label: "T4b Tumeur non résécable envahissant les autres structures de voisinage telles que l'aorte, les corps vertébraux, la trachée" },
      ] : []),
      ...(Specialite === 'Gynécologie' && Organe === 'Néoplasies trophoblastiques gestationnelles' ? [
          { value: "Stade I: Malade limitée à l'utérus", label: "Stade I: Malade limitée à l'utérus" },
          { value: "Stade II: Néoplasies trophoblastiques gestationnelles étendue en dehors de l'utérus mais limitée aus structures génitales(annexe,vagin,ligaments larges)", label: "Stade II: Néoplasies trophoblastiques gestationnelles étendue en dehors de l'utérus mais limitée aus structures génitales(annexe,vagin,ligaments larges)" },
          { value: "Stade III: Néoplasies trophoblastiques gestationnelles étendue aux poumons avec ou sans atteinte connue du tractus génital", label: "Stade III: Néoplasies trophoblastiques gestationnelles étendue aux poumons avec ou sans atteinte connue du tractus génital" },
          { value: "StadeIV: Tout autre site m:étastatique", label: "StadeIV: Tout autre site m:étastatique" },

      ] : []),

      ...(Specialite === 'Gynécologie' && (Organe === 'Ovaire' || Organe === 'Col' || Organe === 'Utérus' || Organe === 'Vagin' || Organe === 'Vulve') ? [
          { value: "TX: tumeur primitive non évaluable", label: "TX: tumeur primitive non évaluable" },
          { value: "T0: pas de lésion du col", label: "T0: pas de lésion du col" },
          { value: "Tis: Stade 0: Cancer in situ", label: "TIS:  Cancer in situ" },
          { value: "T1: Stade I: tumeur limité du col", label: "T1: tumeur limité du col" },
          { value: "T1a: Stade IA: tumeur non visible diagniostiqué par histologie", label: "T1a: Stade IA: tumeur non visible diagniostiqué par histologie" },
          { value: "T1a1: StadeIA1: profondeur d'invansion ≤  3 mm et extension horizonale ≤  7mm ", label: "T1a1: Stade1A1: profondeur d'invansion ≤  3 mm et extension horizonale ≤  7mm" },
          { value: "T1a2: StadeIA2: invansion ≥  3 mm et ≤  5mm et  extension horizonale ≤  7mm", label: "T1a2: Stade1A2: invansion ≥  3 mm et ≤  5mm et  extension horizonale ≤  7mm" },
          { value: "T1b: StadeIB: tumeur clinique ou tumeur > stade Ia", label: "T1b: StadeIB: tumeur clinique ou tumeur > stade Ia" },
          { value: "T1b1: StadeIB1: Diamètre maximale ≤ 4cm mesuré à l'IRM", label: "T1b1: StadeIB1: Diamètre maximale ≤ 4cm mesuré à l'IRM" },
          { value: "T1b2: StadeIB2: Diamètre maximale ≤ 4cm", label: "T1b2: StadeIB2: Diamètre maximale ≤ 4cm" },
          { value: "T2: StadeII: Invansion en dehors du col mais ne touchant pas le paroi pelvienne ou le tiers inférieur du vagin", label: "T2: StadeII: Invansion en dehors du col mais ne touchant pas le paroi pelvienne ou le tiers inférieur du vagin" },
          { value: "T2a: StadeIIA : Dome vaginal (2/3) supérieur sans extension paramétriale", label: "T2a: StadeIIA : Dome vaginal (2/3) supérieur sans extension paramétriale" },
          { value: "StadeIIA 1:  ≤ 4cm", label: "StadeIIA 1:  ≤ 4cm" },
          { value: "StadeIIA 2:  ≥  4cm", label: "StadeIIA 2:  ≥  4cm" },
          { value: "T2b: StadeIIB: Attente paramétriale évidente", label: "T2b: StadeIIB: Attente paramétriale évidente" },
          { value: "T3: StadeIII: invansion du tiers inférieur du vagin et/ou juqu à la paroi pelvienne et/ou obstruction urétrale", label: "T3: StadeIII: invansion du tiers inférieur du vagin et/ou juqu à la paroi pelvienne et/ou obstruction urétrale" },
          { value: "T3a: StadeIIIA: Fourreau vaginale (1/3) inférieur ", label: "T3a: StadeIIIA: Fourreau vaginale (1/3) inférieur " },
          { value: "T3b: StadeIIB:paramétre jusqu'à la paroi ou obst", label: "T2b: StadeIIB: Attente paramétriale évidente" },
          { value: "StadeIVA: Envahissment base vésicale et/ou paroi rectal", label: "StadeIVA: Envahissment base vésicale et/ou paroi rectal" },
          { value: "T2b: StadeIIB: Attente paramétriale évidente", label: "T2b: StadeIIB: Attente paramétriale évidente" },
      ] : []),
      ...(Specialite === 'Gynécologie' && Organe === 'Sein' ? [
          { value: "TX: Détermination de la tumeur primitive impossible", label: "TX: Détermination de la tumeur primitive impossible" },
          { value: "T0: Pas de signes de tumeur primitive", label: "T0: Pas de signes de tumeur primitive" },
          { value: "Tis: Carcinome in situ", label: "Tis: Carcinome in situ" },
          { value: "T1a:  Dimension inférieure à 0,5 cm", label: "T1a:  Dimension inférieure à 0,5 cm" },
          { value: "T1b: Dimension supérieure à 0.5cm et inférieure à 1cm", label: "T1b: Dimension supérieure à 0.5cm et inférieure à 1cm" },
          { value: "T1c: Dimension supérieure à 1cm et inférieure à 2cm", label: "T1c: Dimension supérieure à 1cm et inférieure à 2cm" },
          { value: "T2:  Tumeur supérieure ou égale à 2cm et inférieure à 5cm dans sa plus grande dimension", label: "T2:  Tumeur supérieure ou égale à 2cm et inférieure à 5cm dans sa plus grande dimension" },
          { value: "T3: Tumeur supérieure à Scm dans sa plus grande dimension", label: "T3: Tumeur supérieure à Scm dans sa plus grande dimension" },
          { value: "T4a: Tumeur de toute taille avec extension directe à la paroi thoracique", label: "T4a: Tumeur de toute taille avec extension directe à la paroi thoracique" },
          { value: "T4b: Tumeur de toute taille avec extension directe à la peau", label: "T4b: Tumeur de toute taille avec extension directe à la peau" },
          { value: "T4c: Tumeur inflammatoire", label: "T4c: Tumeur inflammatoire" },



      ] : []),

      ...(Specialite === 'Hématologie' && Organe === 'Néoplasmes des cellules histiocytaires/dendritiques' ? [
          { value: "Histiocytose à cellules de Langerhans", label: "Histiocytose à cellules de Langerhans" },
          { value: "Sarcome à cellules de Langerhans", label: "Sarcome à cellules de Langerhans" },


      ] : []),
      

      ...(Specialite === 'Hématologie' && Organe === 'LA Adulte' ? [
          { value: "LAM OMS 2022", label: "LAM OMS 2022" },
          { value: "LAL", label: "LAL" },


      ] : []),

      ...(Specialite === 'Urologie' && Organe === 'Vessie/Arbre urinaire' ? [
          { value: "TNM", label: "TNM" },
          { value: "TVNIM", label: "TVNIM" },
          { value: "TVIM", label: "TVIM" },
          { value: "M1", label: "M1" },


      ] : []),

      ...(Specialite === 'Thorax' && Organe === 'Poumon' ? [

          { value: "Tx:Tumeur primutive non connu ou tumeur prouvé par la présence de cellules malignes dans les secrétions btoncho-pulmonaire mais non visible aus exemens Checkboxlogiques et endoscopiques", label: "Tx:Tumeur primutive non connu ou tumeur prouvé par la présence de cellules malignes dans les secrétions btoncho-pulmonaire mais non visible aus exemens Checkboxlogiques et endoscopiques" },
          { value: "T0:Absence de tumeur identifiable", label: "Absence de tumeur identifiable" },
          { value: "Tis: Carcinome in situ", label: "Tis: Carcinome in situ" },
          { value: "T1: Tumeur de 3cm ou moins dans ses plus grands dimensions,entouré par du poumon et de la plèvre viscérale, sans évidence évansion plus proximale que les bronches lobaires à bronchoscopies", label: "T1: Tumeur de 3cm ou moins dans ses plus grands dimensions,entouré par du poumon et de la plèvre viscérale, sans évidence évansion plus proximale que les bronches lobaires à bronchoscopies" },
          { value: "T1a(mi) : Adénocarcinome minimalement-invasif", label: "T1a(mi) : Adénocarcinome minimalement-invasif" },
          { value: "T1a : ≤ 1cm", label: "T1a : ≤ 1cm" },
          { value: "T1b : ≥1cm et ≤ 2cm", label: "T1b : ≥1cm et ≤ 2cm" },
          { value: "T1c : > 2cm et ≤ 3cm", label: "T1c : > 2cm et ≤ 3cm" },
          { value: "T2: Tumeur de plus de 3cm, mais de 5cm au moins,avec quelconques des élements", label: "T2: Tumeur de plus de 3cm, mais de 5cm au moins,avec quelconques des élements" },
          { value: "T2a : > 3cm mais ≤ 4cm", label: "T2a : > 3cm mais ≤ 4cm" },
          { value: "T2b : > 4cm mais ≤ 5cm", label: "T2b : > 4cm mais ≤ 5cm" },
          { value: "T3 : Tumeur de plus de 7cm ou associé a un (des) nodules(s) tumoral(aux) distinct(s) et dans le meme lobe,ou ayant au moins l'un caractère invasif", label: "T3 : Tumeur de plus de 7cm ou associé a un (des) nodules(s) tumoral(aux) distinct(s) et dans le meme lobe,ou ayant au moins l'un caractère invasif" },
          { value: "T4 : Tumeur de plus de 7cm ou associé a un (des) nodules(s) pulmonaire(s) distinct(s) comportant un envahissement quelconques", label: "T4 : Tumeur de plus de 7cm ou associé a un (des) nodules(s) pulmonaire(s) distinct(s) comportant un envahissement quelconques" },

      ] : []),

      ...(Specialite === 'ORL' && (Organe === 'Cavité buccale' || Organe === 'Pharynx' || Organe === 'Larynx' || Organe === 'Cavité nasale' || Organe === 'Lévre' || Organe === 'Sinus' || Organe === 'glandes salivaire') ? [
          { value: "T1 : Tumeur ≤ 2cm dans son plus grand axe", label: "T1 : Tumeur ≤ 2cm dans son plus grand axe" },
          { value: "T2 : Tumeur entre 2 et 4 dans son plus grand axe", label: "T1 : Tumeur ≤ 2cm dans son plus grand axe" },
          { value: "T3 : Tumeur > 4cm ou extension à la face lingale de l'épiglotte", label: "T3 : Tumeur > 4cm ou extension à la face lingale de l'épiglotte" },
          { value: "T4 : Invasion de larynx,des muscles extrincsèque de la langue(géniglosse,hyoglosse,palatoglosse et styloglosse),du palais de dur,de la mandibule,des muscles ptérygoidiens,du nasopharyncs,de la base du crane ou de la carotide", label: "T1 : Tumeur ≤ 2cm dans son plus grand axe" },

      ] : []),

      ...(Specialite === 'ORL' && Organe === 'Thyroide' ? [
          { value: "Tx, pTx : tumeur primitive non retrouvée", label: "Tx, pTx : tumeur primitive non retrouvée" },
          { value: "T1, pT1 : tumeur ≤ 20 mm, limitée à la thyroïde", label: "T1, pT1 : tumeur ≤ 20 mm, limitée à la thyroïde" },
          { value: "T1a : ≤10mm", label: "T1a : ≤10mm" },
          { value: "T1b : >10mm et ≤20mm", label: "T1b : >10mm et ≤20mm" },
          { value: "T2, pT2 : tumeur > 20 mm et < 40 mm, limitée à la thyroïde", label: "T2, pT2 : tumeur > 20 mm et < 40 mm, limitée à la thyroïde" },
          { value: "T3, pT3 : tumeur > 40 mm limitée à la thyroïde ou avec extension extrathryoïdienne minime (muscle sternothyroïdien ou tissus périthyroïdiens)", label: "T3, pT3 : tumeur > 40 mm limitée à la thyroïde ou avec extension extrathryoïdienne minime (muscle sternothyroïdien ou tissus périthyroïdiens)" },
          { value: "T3a : >4cm limitée à la thyroide", label: "T3a : >4cm limitée à la thyroide" },
          { value: "T3b : extension extrathryoïdienne minime", label: "T3b : extension extrathryoïdienne minime" },
          { value: "T4, pT4 : tumeur qui s’étend au-delà de la capsule thyroïdienne", label: "T4, pT4 : tumeur qui s’étend au-delà de la capsule thyroïdienne" },
          { value: "T4a : envahi les tissus sous cutanés, le larynx, la trachée, l’œsophage ou le récurrent.", label: "T4a : envahi les tissus sous cutanés, le larynx, la trachée, l’œsophage ou le récurrent." },
          { value: "T4b : envahi le fascia para-vertébral, le médiastin ou la carotide.", label: "T4b : envahi le fascia para-vertébral, le médiastin ou la carotide." },

      ] : []),

      ...(Specialite === 'ORL' && (Organe === 'Cavum' || Organe === 'Nasopharynx') ? [
          { value: "T1: tumeur confiné au nasopharynx ou s'étendant à l'oropharynx et/ou à la cavité nasale sans extension parapharyngée", label: "T1: tumeur confiné au nasopharynx ou s'étendant à l'oropharynx et/ou à la cavité nasale sans extension parapharyngée" },
          { value: "T2 : Parapharyngée et/ou infiltration des muscles ptérigoydiens médial et/ou latérale et/ou prévertébraux", label: "T2 : Parapharyngée et/ou infiltration des muscles ptérigoydiens médial et/ou latérale et/ou prévertébraux" },
          { value: "T3 : Envahissement des structures osseuses de la base du crâne, des sinus paranasaux des vertèbres crébrales et/ou des apophytes ptérygoydiennes", label: "T3 : Envahissement des structures osseuses de la base du crâne, des sinus paranasaux des vertèbres crébrales et/ou des apophytes ptérygoydiennes" },
          { value: "T4: Extension intracranienne et/ou atteinte des nerf craniennes,de l'hypopharynx", label: "T4: Extension intracranienne et/ou atteinte des nerf craniennes,de l'hypopharynx" },


      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Tumeurs orbitaires' || Organe === 'Tumeurs médiastinales' || Organe === 'Corticosurrenale') ? [
          { value: "Stade L1 – La tumeur se trouve seulement dans la région du corps où elle a pris naissance, comme le cou, le thorax, l’abdomen ou le bassin, et il n’y a aucun facteur de risque défini par l’imagerie.", label: "Stade L1 – La tumeur se trouve seulement dans la région du corps où elle a pris naissance, comme le cou, le thorax, l’abdomen ou le bassin, et il n’y a aucun facteur de risque défini par l’imagerie." },
          { value: "Stade L2 – La tumeur s’est propagée à une région voisine et on observe 1 ou plusieurs facteurs de risque définis par l’imagerie.", label: "Stade L2 – La tumeur s’est propagée à une région voisine et on observe 1 ou plusieurs facteurs de risque définis par l’imagerie." },
          { value: "Stade M – Le cancer s’est propagé à des parties du corps éloignées de la tumeur (métastases à distance), mais ce stade ne comprend pas les tumeurs de stade MS.", label: "Stade M – Le cancer s’est propagé à des parties du corps éloignées de la tumeur (métastases à distance), mais ce stade ne comprend pas les tumeurs de stade MS." },
          { value: "Stade MS – Ce stade est réservé aux enfants de moins de 18 mois qui présentent des métastases à distance. Le cancer s’est propagé seulement à la peau, au foie ou à la moelle osseuse. S’il s’est propagé à la moelle osseuse, moins de 10 % des cellules dans la moelle sont cancéreuses.", label: "Stade MS – Ce stade est réservé aux enfants de moins de 18 mois qui présentent des métastases à distance. Le cancer s’est propagé seulement à la peau, au foie ou à la moelle osseuse. S’il s’est propagé à la moelle osseuse, moins de 10 % des cellules dans la moelle sont cancéreuses." },

      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'Tumeurs maxillo faciales') ? [
          { value: "Stade 1 – Le cancer est dans une région favorable. Il ne s’est pas propagé à une partie du corps plus éloignée de son lieu d’origine", label: "Stade 1 – Le cancer est dans une région favorable. Il ne s’est pas propagé à une partie du corps plus éloignée de son lieu d’origine" },
          { value: "Stade 2 – Le cancer est dans une région défavorable. La tumeur mesure 5 cm ou moins. Elle ne s’est pas propagée aux ganglions lymphatiques voisins ou à d’autres parties du corps.", label: "Stade 2 – Le cancer est dans une région défavorable. La tumeur mesure 5 cm ou moins. Elle ne s’est pas propagée aux ganglions lymphatiques voisins ou à d’autres parties du corps." },
          { value: "Stade 3 – Le cancer est dans une région défavorable. La tumeur mesure 5 cm ou moins. Elle peut avoir envahi les régions voisines. Le cancer s’est propagé aux ganglions lymphatiques voisins.", label: "Stade 3 – Le cancer est dans une région défavorable. La tumeur mesure 5 cm ou moins. Elle peut avoir envahi les régions voisines. Le cancer s’est propagé aux ganglions lymphatiques voisins." },
          { value: "Stade 3 – Le cancer est dans une région défavorable. La tumeur mesure plus de 5 cm. Elle peut avoir envahi les régions voisines. Le cancer peut s’être propagé aux ganglions lymphatiques voisins.", label: "Stade 3 – Le cancer est dans une région défavorable. La tumeur mesure plus de 5 cm. Elle peut avoir envahi les régions voisines. Le cancer peut s’être propagé aux ganglions lymphatiques voisins." },
          { value: "Stade 4 – Le cancer est dans n’importe quelle région et de n’importe quelle taille. Il s’est propagé à d’autres parties du corps (métastases à distance), comme aux poumons, au foie ou à la moelle osseuse. On parle aussi de RMS métastatique.", label: "Stade 4 – Le cancer est dans n’importe quelle région et de n’importe quelle taille. Il s’est propagé à d’autres parties du corps (métastases à distance), comme aux poumons, au foie ou à la moelle osseuse. On parle aussi de RMS métastatique." },


      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Foie/Pédiatrie') ? [
          { value: "Pretext 1: Une section est concernée ; trois sections adjacentes sont exemptes de tumeurs.", label: "Pretext 1: Une section est concernée ; trois sections adjacentes sont exemptes de tumeurs." },
          { value: "Pretext 2: Une ou deux sections concernées ; deux sections adjacentes sont exemptes de tumeurs.", label: "Pretext 2: Une ou deux sections concernées ; deux sections adjacentes sont exemptes de tumeurs." },
          { value: "Pretext 3: Deux ou trois sections concernées ; une section adjacente est exempte de tumeur.", label: "Pretext 3: Deux ou trois sections concernées ; une section adjacente est exempte de tumeur." },
          { value: "Pretext 4: Quatre sections impliquées.", label: "Pretext 4: Quatre sections impliquées." },


      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'T. Sacro-coccygienne') ? [
          { value: "I: tumeur du sillon interfessier", label: "I: tumeur du sillon interfessier" },
          { value: "II: tumeur exo-pelvienne", label: "II: tumeur exo-pelvienne" },
          { value: "III: tumeur endo et exo-pelvienne", label: "III: tumeur endo et exo-pelvienne" },
          { value: "IV: tumeur endopelvienne", label: "IV: tumeur endopelvienne" },

      ] : []),



      ...(Specialite === 'Pédiatrie' && (Organe === 'Ovaire') ? [
          { value: " IA : Cancer limité à un ovaire ou une trompe de Fallope.", label: "IA : Cancer limité à un ovaire ou une trompe de Fallope." },
          { value: " IB : Cancer présent dans les deux ovaires ou trompes de Fallope.", label: "IB : Cancer présent dans les deux ovaires ou trompes de Fallope." },
          { value: " IC : Cancer dans un ou les deux ovaires ou trompes de Fallope avec des caractéristiques supplémentaires :", label: "IC : Cancer dans un ou les deux ovaires ou trompes de Fallope avec des caractéristiques supplémentaires :" },
          { value: " IC1 : Rupture chirurgicale.", label: "IC1 : Rupture chirurgicale." },
          { value: "IC2 : Rupture de la capsule avant la chirurgie ou tumeur à la surface de l'ovaire ou de la trompe de Fallope.", label: "IC2 : Rupture de la capsule avant la chirurgie ou tumeur à la surface de l'ovaire ou de la trompe de Fallope." },
          { value: "IC3 : Cellules malignes dans les ascites ou les prélèvements péritonéaux.", label: "IC3 : Cellules malignes dans les ascites ou les prélèvements péritonéaux." },

      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'Voie excretrice' || Organe === 'Prostate') ? [
          { value: "TX : La tumeur primaire ne peut pas être évaluée.", label: "TX : La tumeur primaire ne peut pas être évaluée." },
          { value: "T0 : Pas de signe de tumeur primaire.", label: "T0 : Pas de signe de tumeur primaire." },
          { value: "T1 : Tumeur limitée à la muqueuse urothéliale ou sous-muqueuse.", label: "T1 : Tumeur limitée à la muqueuse urothéliale ou sous-muqueuse." },
          { value: "T2 : Tumeur envahit la musculeuse.", label: "T2 : Tumeur envahit la musculeuse." },
          { value: "T3 : Tumeur envahit la graisse péri-urétérale.", label: "T3 : Tumeur envahit la graisse péri-urétérale." },
          { value: "T4 : Tumeur envahit les organes adjacents (par exemple, le rein ou la paroi pelvienne).", label: "T4 : Tumeur envahit les organes adjacents (par exemple, le rein ou la paroi pelvienne)." },

      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Voie excretrice') ? [

          { value: "I : Résection complète, marges négatives", label: "I : Résection complète, marges négatives" },
          { value: "IIa : Résection complète, marges négatives", label: "IIa : Résection complète, marges négatives" },
          { value: "IIb : Résection complète, marges négatives, ganglions réséqués positifs", label: "IIb : Résection complète, marges négatives, ganglions réséqués positifs" },
          { value: "IIc : Résection complète, marges négatives, ganglions réséqués positifs", label: "IIc : Résection complète, marges négatives, ganglions réséqués positifs" },
          { value: "III : Reste de tumeur visible (inclut les ganglions régionaux non réséqués)", label: "III : Reste de tumeur visible (inclut les ganglions régionaux non réséqués)" },
          { value: "IV : Métastases distantes", label: "IV : Métastases distantes" },


      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'Os') ? [
          { value: "Localisé", label: "Localisé" },
          { value: "Métastatique pulmonaire et/ou osseux", label: "Métastatique pulmonaire et/ou osseux" },
          { value: "Classification TNM UICC 7e edition", label: "Classification TNM UICC 7e edition" },

      ] : []),




  ];

  const optionStadeAnnBoor=[

    ...(Specialite === 'Hématologie' && (Organe === 'Lymphome Hodgkin adulte' || Organe === 'Lymphome non hodgkinien adulte') ? [
        { value: "Stade I", label: "Stade I" },
        { value: "Stade II", label: "Stade II" },
        { value: "Stade III", label: "Stade III" },
        { value: "Stade IV", label: "Stade IV" },
        { value: "A ou B", label: "A ou B" },
        { value: "X", label: "X" },
        { value: "E", label: "E" },


    ] : []),
  ];


  const optionClassificationOMS = [
      ...(Specialite === 'Hématologie' && (Organe === 'Syndromes myélodysplasiques') ? [

          { value: "SMD avec dysplasie unilignée", label: "SMD avec dysplasie unilignée" },
          { value: "SMD avec dysplasie multilignées", label: "SMD avec dysplasie multilignées" },
          { value: "SMD avec sidéroblaste en couronne (SMD-RS)", label: "SMD avec sidéroblaste en couronne (SMD-RS)" },
          { value: "SMD – RS avec dysplasie unilignée", label: "SMD – RS avec dysplasie unilignée" },
          { value: "SMD – RS avec dysplasie multilignées", label: "SMD – RS avec dysplasie multilignées" },
          { value: "SMD avec délétion 5q isolée", label: "SMD avec délétion 5q isolée" },
          { value: "SMD avec Excès de Blastes de type 1", label: "SMD avec Excès de Blastes de type 1" },
          { value: "SMD avec Excès de Blastes de type 2", label: "SMD avec Excès de Blastes de type 2" },
          { value: "SMD inclassables", label: "SMD inclassables" },

      ] : []),

      ...(Specialite === 'Hématologie' && (Organe === 'Syndrome Myéloprolifératif Ph1 négative') ? [
          { value: "Polyglobulie de Vaquez", label: "Polyglobulie de Vaquez" },
          { value: "Thrombocytémie essentielle", label: "Thrombocytémie essentielle" },
          { value: "Myélofibrose primitive", label: "Myélofibrose primitive" },
          { value: "Leucémie chronique à neutrophiles", label: "Leucémie chronique à neutrophiles" },
          { value: "Leucémie chronique à éosinophiles", label: "Leucémie chronique à éosinophiles" },

      ] : []),


  ];
  const optionAdenophatie = [
      ...(Specialite === 'Digestif' && Organe === 'Anus' ? [
          { value: "Nx: ganglions non évalués", label: "Nx: ganglions non évalués" },
          { value: "N0: absence de ganglion métastatique", label: "N0. absence de ganglion Métastatique" },
          { value: "N1a: Métas ganglionnaires inguinales et/ou dans le mesorectum et/ou iliaque interne", label: "N1a: Métas ganglionnaires inguinales et/ou dans le mesorectum et/ou iliaque interne" },
          { value: "N1b: Métas ganglionnaires iliaques externes", label: "N1b: Métas ganglionnaires iliaques externes" },
          { value: "N1c: Métas ganglionnaires iliaques externes et inguinales et/ou dans le mesorectum et/ou iliaque interne", label: "N1c: Métas ganglionnaires iliaques externes et inguinales et/ou dans le mesorectum et/ou iliaque interne" },

      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Colon et rectum' ? [
          { value: "NX: Renseignements insuffisants pour classer les adénopathies régionales", label: "NX: Renseignements insuffisants pour classer les adénopathies régionales" },
          { value: "NO: Pas de métastase ganglionnaire régionale", label: "NO: Pas de métastase ganglionnaire régionale" },
          { value: "NI: Métastase dans 1 à 3 ganglions lymphatiques régionaux", label: "NI: Métastase dans 1 à 3 ganglions lymphatiques régionaux" },
          { value: "Nta: Métastases dans 1 ganglion lymphatique régional", label: "Nta: Métastases dans 1 ganglion Iymphatique régional" },
          { value: "Nib: Métastases dans 2-3 ganglions lymphatiques régionau)", label: "Nib: Métastases dans 2-3 ganglions lymphatiques régionau)" },
          { value: "Nic: Noduie(s) Lmoral, satellt(s} dans la sous-séreuse, ou dans le tissus non-péritonisés péri-coliques ou péri-rectaux sans métastase ganglionnaire régionale", label: "Nic: Noduie(s) Lmoral, satellt(s} dans la sous-séreuse, ou dans le tissus non-péritonisés péri-coliques ou péri-rectaux sans métastase ganglionnaire régionale" },
          { value: "N2: Métastase dans 2 4 ganglions lymphatiques régionaux", label: "N2: Métastase dans 2 4 ganglions lymphatiques régionaux" },
          { value: "N2a: Métastase dans 4-6 ganglions lymphatiques régionaux", label: "N2a: Métastase dans 4-6 ganglions lymphatiques régionaux" },
          { value: "N2b: _Métastase dans z 7 ganglions lymphatiques régionaux", label: "N2b: _Métastase dans z 7 ganglions lymphatiques régionaux" },
      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Voies biliaires' ? [
          { value: "Nx: non évalués", label: "Nx: non évalués" },
          { value: "N0: pas de ganglion métastatique", label: "N0. pas de ganglion Métastatique" },
          { value: "N1: Métastase (s) ganglionnaire (s) régionale (s)", label: "N1: Métastase (s) ganglionnaire (s) régionale (s)" },
          { value: "N1: 1 à 3 Métastases ganglionnaires régionnales", label: "N1: 1 à 3 Métastases ganglionnaires régionnales" },
          { value: "N2: ≥4 Métastases ganglionnaires régionnales", label: "N2: ≥4 Métastases ganglionnaires régionnales" },

      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Estomac' ? [
          { value: "Nx: non évalués", label: "Nx: non évalués" },
          { value: "N0: pas d'envahissement ganglionnaire (noter combien ganglions ont été examinés)", label: "N0: pas d'envahissement ganglionnaire (noter combien ganglions ont été examinés)" },
          { value: "N1: 1 ou 2 plus ganglions régionaux métastatiques", label: "N1: 1 àu 2 plus ganglions régionaux métastatiques" },
          { value: "N2: 3 à 6 plus ganglions régionaux métastatiques", label: "N2: 3 à 6 plus ganglions régionaux métastatiques" },
          { value: "N3: 7 ou plus ganglions régionaux métastatiques", label: "N3: 7 ou plus ganglions régionaux métastatiques" },
          { value: "N3a : 7 à 15 ganglions régionaux métastatiques", label: "N3a : 7 à 15 ganglions régionaux métastatiques" },
          { value: "N3b : 16 ou plus ganglions régionaux métastatiques", label: "N3b : 16 ou plus ganglions régionaux métastatiques" },


      ] : []),

      ...(Specialite === 'Digestif' && Organe === 'Oesophage' ? [
          { value: "Nx: ganglions non évalués", label: "Nx: ganglions non évalués" },
          { value: "N0: pas de signe d'atteinte des ganglions lymphatiques régionnaux", label: "N0: pas de signe d'atteinte des ganglions lymphatiques régionnaux" },
          { value: "N1: 1 ou 2 adénopaties envahies", label: "N1: 1 ou 2 adénopaties envahies" },
          { value: "N2: 3 à 6 adénopaties envahies", label: "N2: 3 à 6 adénopaties envahies" },
          { value: "N3: 7 adénopaties ou plus envahies", label: "N3: 7 adénopaties ou plus envahies" },

      ] : []),
      ...(Specialite === 'Gynécologie' && Organe === 'Sein' ? [
          { value: "Nx: Appréciation impossible de atteinte ganglionnaire", label: "Nx: Appréciation impossible de atteinte ganglionnaire" },
          { value: "N0:  Absence de signes d'envahissement ganglionnaire régional.", label: " N0: Absence de signes d'envahissement ganglionnaire régional." },
          { value: "N1:  Ganglions axilaires homolatéraux mobiles", label: "N1:  Ganglions axilaires homolatéraux mobiles" },
          { value: "N2:   Ganglions axilaires homolatéraux fixés entre eux ou à d'autres structures où ganglions mammaires internes sans ganglion axillaire", label: "N2:  Ganglions axilaires homolatéraux fixés entre eux ou à d'autres structures où ganglions mammaires internes sans ganglion axillaire" },
          { value: "N3:  Ganglions sous ou sus claviculaires ou axilaires et mammaires internes", label: "N1:  Ganglions sous ou sus claviculaires ou axilaires et mammaires internes" },


      ] : []),
      ...(Specialite === 'Hématologie' && Organe === 'Néoplasmes des cellules histiocytaires/dendritiques' ? [
          { value: "Tumeur indéterminée des cellules dendritiques", label: "Tumeur indéterminée des cellules dendritiques" },
          { value: "Sarcome à cellules dendritiques interdigitées", label: "Sarcome à cellules dendritiques interdigitées" },

      ] : []),

      ...(Specialite === 'Thorax' && Organe === 'Poumon' ? [
          { value: "Nx: Envahissement locorégionale inconnu", label: "Nx: Envahissement locorégionale inconnu" },
          { value: "N0: Absence de métastase dans les ganglions lymphatiques régionnaux", label: "N0: Absence de métastase dans les ganglions lymphatiques régionnaux" },
          { value: "N1: Métastases ganglionnaires péri-rochiques homolatérales et/ou hillaires homolatérales incluant une extensions directes", label: "N1: Métastases ganglionnaires péri-rochiques homolatérales et/ou hillaires homolatérales incluant une extensions directes" },
          { value: "N2: Métastases dans les ganglions médiastinaux homolatéraux ou dans les ganglions sous-carénaires", label: "N2: Métastases dans les ganglions médiastinaux homolatéraux ou dans les ganglions sous-carénaires" },
          { value: "N3: Métastases ganglionnaires médiastinales controlatérales ou hilaires controlatérals ou scaléniques sus-caviculaires homo ou controlatérales", label: "N3: Métastases ganglionnaires médiastinales controlatérales ou hilaires controlatérals ou scaléniques sus-caviculaires homo ou controlatérales" },

      ] : []),
      ...(Specialite === 'ORL' && (Organe === 'Cavité buccale' || Organe === 'Pharynx' || Organe === 'Larynx' || Organe === 'Cavité nasale' || Organe === 'Lévre' || Organe === 'Sinus' || Organe === 'glandes salivaire') ? [
          { value: "Nx: Ne peut pas être déterminé", label: "Nx:  Ne peut pas être déterminé" },
          { value: "N1: Une ou plusieurs adénopathies ipsilatérales < 6cm", label: "N1: Une ou plusieurs adénopathies ipsilatérales < 6cm" },
          { value: "N2: Adénopathies controlatérales ou bilatérales < 6cm", label: "N2: Adénopathies controlatérales ou bilatérales < 6cm" },
          { value: "N3: Adénopathies < 6cm", label: "N3: Adénopathies < 6cm" },

      ] : []),

      ...(Specialite === 'ORL' && Organe === 'Thyroide' ? [
          { value: "Nx, pNx : envahissement ganglionnaire non précisé", label: "Nx, pNx : envahissement ganglionnaire non précisé" },
          { value: "N0, pN0 : pas d’envahissement ganglionnaire", label: "N0, pN0 : pas d’envahissement ganglionnaire" },
          { value: "N0a : Ganglions bénins avec preuve histologique", label: "N0a : Ganglions bénins avec preuve histologique" },
          { value: "N0b : Pas d’atteinte gg clinique ou Checkboxlogique", label: "N0b : Pas d’atteinte gg clinique ou Checkboxlogique" },
          { value: "N1, pN1 : envahissement ganglionnaire", label: "N1, pN1 : envahissement ganglionnaire" },
          { value: "N1a, pN1a : métastases ganglionnaires homolatérales du groupe VI ou VII", label: "N1a, pN1a : métastases ganglionnaires homolatérales du groupe VI ou VII" },
          { value: "N1b, pN1b : autres métastases ganglionnaires cervicales (zones I à V) homolatérales controlatérales ou métastases rétropharyngées ou médiastinales supérieures.", label: "N1b, pN1b : autres métastases ganglionnaires cervicales (zones I à V) homolatérales controlatérales ou métastases rétropharyngées ou médiastinales supérieures." },


      ] : []),

      ...(Specialite === 'ORL' && (Organe === 'Cavum' || Organe === 'Nasopharynx') ? [
          { value: "Nx:  Atteinte ganglionnaire non évaluable", label: "Nx: Atteinte ganglionnaire non évaluable" },
          { value: "N0: pas de signe d'atteinte des ganglions lymphatiques régionnaux", label: "N0: pas de signe d'atteinte des ganglions lymphatiques régionnaux" },
          { value: "N1: Métastases unilatérale et/ou métatases uni/bilatérales retropharynguées, ≤ 6cm, au dessus du rebord inférieur du cartillage coricode ", label: "N1: Métastases unilatérale et/ou métatases uni/bilatérales retropharynguées, ≤ 6cm, au dessus du rebord inférieur du cartillage coricode" },
          { value: "N2: Métastases , ≤ 6cm, au dessus du rebord inférieur du cartillage coricode", label: "N2: Métastases , ≤ 6cm, au dessus du rebord inférieur du cartillage coricode" },
          { value: "N3: Métastases > 6cm et/ou extension jusqu'à calvicule", label: "N3: Métastases > 6cm et/ou extension jusqu'à calvicule" },


      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'Ovaire') ? [
          { value: "IIA : Cancer étendu à l'utérus ou aux trompes de Fallope ou aux ovaires.", label: "IIA : Cancer étendu à l'utérus ou aux trompes de Fallope ou aux ovaires.." },
          { value: " IIB : Cancer étendu à d'autres organes pelviens tels que la vessie ou le rectum.", label: "IIB : Cancer étendu à d'autres organes pelviens tels que la vessie ou le rectum." },

      ] : []),


      ...(Specialite === 'Pédiatrie' && (Organe === 'Voie excretrice') ? [
          { value: "NX : Les ganglions lymphatiques régionaux ne peuvent pas être évalués.", label: "NX : Les ganglions lymphatiques régionaux ne peuvent pas être évalués." },
          { value: "N0 : Pas de métastase dans les ganglions lymphatiques régionaux.", label: "N0 : Pas de métastase dans les ganglions lymphatiques régionaux." },
          { value: "N1 : Métastase dans un ganglion lymphatique régional.", label: "N1 : Métastase dans un ganglion lymphatique régional." },

      ] : []),


  ];
  const optionMétastase = [
      ...(Specialite === 'Digestif' && Organe === 'Anus' ? [
          { value: "Mx: non évaluées", label: "Mx; non évaluées" },
          { value: "M0: pas de localisation secondaire à distance de la tumeur primitive", label: "M0: pas de localisation secondaire à distance de la tumeur primitive" },
          { value: "M1: métastases à distance", label: "M1: métastases à distance" },
      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Colon et rectum' ? [
          { value: "MO : pas de métastase", label: "MO : pas de métastase" },
          { value: "M1: présence de métastase(s) à distance", label: "M1: présence de métastase(s) à distance" },
          { value: "Mia: Métastase(s) localisée(s) à un seul organe (foie, poumon, ovaire, ganglion(s) Iymphatique(s) autre que régional) sans métastase péritonéale", label: "Mia_Métastase(s) localisée(s) à un seul organe (foie, poumon, ovaire, ganglion(s) Iymphatique(s) autre que régional) sans métastase péritonéale" },
          { value: "Mib: Métastases dans plusieurs organes", label: "Mib: Métastases dans plusieurs organes" },
          { value: "Mic: Métastases péritonéales avec ou sans métastases dans les autres organes", label: "Mic: Métastases péritonéales avec ou sans métastases dans les autres organes" },

      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Voies biliaires' ? [
          { value: "MO : pas de métastase", label: "MO : pas de métastase" },
          { value: "M1 : métastase (s) à distance", label: "MO : métastase (s) à distance" },

      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Estomac' ? [
          { value: "MO : pas de métastase", label: "MO : pas de métastase" },
          { value: "M1 : métastase (s) à distance (dont ganglions rétro-pancréatiques, mésentériques, para-aortiques, sus-<laviculaires)", label: "MO : métastase à distance(dont ganglions rétro-pancréatiques, mésentériques, para-aortiques, sus-<laviculaires)" },

      ] : []),
      ...(Specialite === 'Digestif' && Organe === 'Oesophage' ? [
          { value: "MO : pas de métastase à distance", label: "MO : pas de métastase à distance" },
          { value: "M1 :Présence de métastase (s) à distance", label: "MO :Présence de  métastase (s) à distance" },

      ] : []),

      ...(Specialite === 'Gynécologie' && (Organe === 'Ovaire' || Organe === 'Col' || Organe === 'Utérus' || Organe === 'Vagin' || Organe === 'Vulve') ? [
          { value: "M1  StadeIVB: métastase (s) à distance", label: "M1  StadeIVB : métastase (s) à distance" },

      ] : []),
      ...(Specialite === 'Gynécologie' && Organe === 'Sein' ? [
          { value: "Mx : Détermination impossible de l'extension métastatique", label: "Mx : Détermination impossible de l'extension métastatique" },
          { value: "MO : absence de métastase à distance", label: "MO :  absence de métastase à distance" },
          { value: "M1 :Présence de métastase (s) à distance (comprenant des métastases ganglionnaires sus claviculaires)", label: "MO :Présence de  métastase (s) à distance (comprenant des métastases ganglionnaires sus claviculaires)" },


      ] : []),
      ...(Specialite === 'Thorax' && Organe === 'Poumon' ? [
          { value: "MO : pas de métastase à distance", label: "MO : pas de métastase à distance" },
          { value: "M1: Existence de métastase(s) ", label: "M1: Existence de métastase(s) " },
          { value: "M1a: Nodules tumoraux séparés dans un lobe controlatérale ou nodules pleuraux ou pleurésie maligne ou péricardite maligne", label: "M1a: Nodules tumoraux séparés dans un lobe controlatérale ou nodules pleuraux ou pleurésie maligne ou péricardite maligne" },
          { value: "M1b: 1 seule métastase dans un seul site métastatique ", label: "M1b: 1 seule métastase dans un seul site métastatique" },
          { value: "M1c: plusieurs métastases dans un seul ou plusieurs site atteints ", label: "M1c: plusieurs métastases dans un seul ou plusieurs site atteints" },

      ] : []),

      ...(Specialite === 'ORL' && (Organe === 'Cavité buccale' || Organe === 'Pharynx' || Organe === 'Larynx' || Organe === 'Cavité nasale' || Organe === 'Lévre' || Organe === 'Sinus' || Organe === 'glandes salivaire') ? [
          { value: "MO : pas de métastase à distance", label: "MO : pas de métastase à distance" },
          { value: "M1 :Présence de métastase (s) à distance", label: "MO :Présence de  métastase (s) à distance" },

      ] : []),

      ...(Specialite === 'ORL' && Organe === 'Thyroide' ? [
          { value: "MO : pas de métastase à distance", label: "MO : pas de métastase à distance" },
          { value: "M1 :Présence de métastase (s) à distance", label: "MO :Présence de  métastase (s) à distance" },
      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'Tumeurs cerebrales') ? [
          { value: "M0: Pas de dissémination", label: "M0: Pas de dissémination" },
          { value: "M1: cytologie positive du LCR.", label: "M1: cytologie positive du LCR." },
          { value: "M2: Ensemencement nodulaire macroscopique dans l'espace sous-arachnoïdien cérébelleux-cérébral et/ou dans le ventricule latéral ou le troisième ventricule.", label: "M2: Ensemencement nodulaire macroscopique dans l'espace sous-arachnoïdien cérébelleux-cérébral et/ou dans le ventricule latéral ou le troisième ventricule." },
          { value: "M3: Ensemencement nodulaire dans l'espace sous-arachnoïdien de la colonne vertébrale", label: "M3: Ensemencement nodulaire dans l'espace sous-arachnoïdien de la colonne vertébrale" },
          { value: "M4: Métastases extraneurales.", label: "M4: Métastases extraneurales." },

      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'Ovaire') ? [
          { value: "IIIA1 : Cancer étendu uniquement aux ganglions lymphatiques rétropéritonéaux.", label: "IIIA1 : Cancer étendu uniquement aux ganglions lymphatiques rétropéritonéaux." },
          { value: "IIIA1(i) : Métastase ≤10 mm dans sa plus grande dimension.", label: "IIIA1(i) : Métastase ≤10 mm dans sa plus grande dimension." },
          { value: "IIIA1(ii) : Métastase >10 mm dans sa plus grande dimension.", label: "IIIA1(ii) : Métastase >10 mm dans sa plus grande dimension." },
          { value: "IIIA2 : Cancer microscopique en dehors du pelvis avec ou sans ganglions lymphatiques rétropéritonéaux positifs.", label: "IIIA2 : Cancer microscopique en dehors du pelvis avec ou sans ganglions lymphatiques rétropéritonéaux positifs." },
          { value: "IIIB : Métastase péritonéale macroscopique au-delà du pelvis ≤2 cm dans sa plus grande dimension.", label: "IIIB : Métastase péritonéale macroscopique au-delà du pelvis ≤2 cm dans sa plus grande dimension." },
          { value: "IIIC : Métastase péritonéale au-delà du pelvis >2 cm dans sa plus grande dimension, pouvant impliquer la capsule du foie ou de la rate mais non leur parenchyme.", label: "IIIC : Métastase péritonéale au-delà du pelvis >2 cm dans sa plus grande dimension, pouvant impliquer la capsule du foie ou de la rate mais non leur parenchyme." },

      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'Voie excretrice') ? [
          ...(Specialite === 'ORL' && Organe === 'Thyroide' ? [
              { value: "MO : pas de métastase à distance", label: "MO : pas de métastase à distance" },
              { value: "M1 :Présence de métastase (s) à distance", label: "MO :Présence de  métastase (s) à distance" },
          ] : []),

      ] : []),


  ];

  const optionClassification = [
      ...(Specialite === 'Pédiatrie' && (Organe === 'Ovaire') ? [
          { value: "IVA : Cellules cancéreuses dans le liquide pleural.", label: "IVA : Cellules cancéreuses dans le liquide pleural." },
          { value: "· IVB : Métastases à distance incluant les métastases parenchymateuses au foie ou à la rate, métastases à des organes extra-abdominaux (incluant les ganglions lymphatiques inguinaux et les ganglions lymphatiques hors de la cavité abdominale).", label: "· IVB : Métastases à distance incluant les métastases parenchymateuses au foie ou à la rate, métastases à des organes extra-abdominaux (incluant les ganglions lymphatiques inguinaux et les ganglions lymphatiques hors de la cavité abdominale)." },

      ] : []),
  ]

  const optionSignesPhysiques = [

      ...(Specialite === 'Digestif' && Organe === 'Oesophage' ? [
          { value: "Adénopathie cervicale", label: "Adénopathie cervicale" },
          { value: "sus claviculaire", label: "sus claviculaire" },


      ] : []),

  ];

  const optionFacteurPronostique = [
      ...(Specialite === 'ORL' && (Organe === 'Cavité buccale' || Organe === 'Pharynx' || Organe === 'Larynx' || Organe === 'Cavité nasale' || Organe === 'Lévre' || Organe === 'Sinus' || Organe === 'glandes salivaire') ? [
          { value: 'Stades III et IV', label: 'Stades III et IV' },
          { value: 'Envahissement GG et rupture capsulaire', label: 'Envahissement GG et rupture capsulaire' },
          { value: 'Emboles vasculaires', label: 'Emboles vasculaires' },
          { value: 'Marges positives après chirurgie', label: 'Marges positives après chirurgie' },
          { value: 'Surexpression EGFR : mauvais pronostic', label: 'Surexpression EGFR' },
          { value: 'HPV +', label: 'HPV +' },
      ] : []),
      ...(Specialite === 'ORL' && (Organe === 'Thyroide') ? [
          { value: 'Caractéristiques en rapport avec le patient', label: 'Caractéristiques en rapport avec le patient' },
          { value: 'Caractéristiques en rapport avec la tumeur', label: 'Caractéristiques en rapport avec la tumeur' },
          { value: 'Classification ATA 2015 du risque de rechute', label: 'Classification ATA 2015 du risque de rechute' },

      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Ovaire') ? [
          { value: 'Préménarche', label: 'Préménarche' },
          { value: 'Stade > I', label: 'Stade > I' },
          { value: 'résection chirurgicale incomplète', label: 'résection chirurgicale incomplète' },
          { value: 'histologie de tumeur vitelline', label: 'histologie de tumeur vitelline' },
          { value: 'Taille > 5cm', label: 'Taille > 5cm' },

      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'Prostate') ? [
          { value: 'Forme alvéolaire', label: 'Forme alvéolaire' },
          { value: 'Exérèse incomplète', label: 'Exérèse incomplète' },
          { value: 'Taille > 5 cm', label: 'Taille > 5 cm' },
          { value: 'âge < 1an ou > 10 ans', label: 'âge < 1an ou > 10 ans' },
          { value: 'métastases', label: 'métastases' },

      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Rein') ? [
          { value: 'Type histologique', label: 'Type histologique' },
          { value: 'poids de la tumeur', label: 'poids de la tumeur' },
          { value: 'volume tumorale', label: 'volume tumorale' },
          { value: 'stade chirurgicale', label: 'stade chirurgicale' },

      ] : []),


  ];

  const optionSignesParacliniques = [
      ...(Specialite === 'ORL' && (Organe === 'Cavité buccale' || Organe === 'Pharynx' || Organe === 'Larynx' || Organe === 'Cavité nasale' || Organe === 'Lévre' || Organe === 'Sinus' || Organe === 'glandes salivaire') ? [
          { value: "Endoscopie ORL", label: "Endoscopie ORL" },
          { value: "Pan-endoscopique", label: "Pan-endoscopique" },
          { value: "FOGD", label: "FOGD" },
          { value: "TDM", label: "TDM" },
          { value: "IRM", label: "IRM" },
          { value: "Pet-scan", label: "Pet-scan" },
          { value: "HPV", label: "HPV" },
          { value: "P16", label: "P16" },
          { value: "EBV", label: "EBV" },
          { value: "Panoramique dentaire", label: "Panoramique dentaire" },
      ] : []),
      ...(Specialite === 'ORL' && (Organe === 'Cavum' || Organe === 'Nasopharynx') ? [
          { value: "Checkbox thorax", label: "Checkbox thorax" },
          { value: "TDM", label: "TDM" },
          { value: "IRM", label: "IRM" },
          { value: "Scintigraphie", label: "Scintigraphie" },
          { value: "Pet-scan", label: "Pet-scan" },
          { value: "BB", label: "BB" },
          { value: "EVB", label: "EVB" },
          { value: "HLA", label: "HLA" },
          { value: "Nasofibroscopie", label: "Nasofibroscopie" },
          { value: "bilan cardiaque", label: "bilan cardiaque" },

      ] : []),
      ...(Specialite === 'ORL' && (Organe === 'Thyroide') ? [
          { value: "Endoscopie", label: "Endoscopie" },
          { value: "TDM", label: "TDM" },
          { value: "IRM", label: "IRM" },
          { value: "Pet-scan", label: "Pet-scan" },
          { value: "Scintigraphie", label: "Scintigraphie" },
          { value: "TSH", label: "TSH" },
          { value: "T3", label: "T3" },
          { value: "T4", label: "T4" },
          { value: "NEM 2", label: "NEM 2" },
          { value: "Calcitonine", label: "Calcitonine" },
          { value: "Thyréoglobuline", label: "Thyréoglobuline" },
      ] : []),

      ...(Specialite === 'Pédiatrie' && (Organe === 'Tumeurs orbitaires' || Organe === 'Tumeurs maxillo faciales' || Organe === 'Tumeurs médiastinales') ? [
          { value: "TDM CTAP", label: "TDM CTAP" },
          { value: "TD", label: "TD" },
          { value: "IRM Massif facial", label: "IRM Massif facial" },
          { value: "orbitocerebrale", label: "orbitocerebrale" },
          { value: "Examen anatomopathlogique", label: "Examen anatomopathlogique" },
          { value: "Medullogramme", label: "Medullogramme" },
          { value: "BOM", label: "BOM" },
          { value: "Scintigraphie osseuse", label: "Scintigraphie osseuse" },
          { value: "LCR", label: "LCR" },
          { value: "Scintigraphie au MIBG", label: "Scintigraphie au MIBG" },
          { value: "PET Scan", label: "PET Scan" },

      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'Foie/Pédiatrie') ? [

          { value: "TDM", label: "TDM" },
          { value: "IRM Abdominale", label: "IRM Abdominale" },
          { value: "TDM thoracique", label: "TDM thoracique" },
          { value: "Scintigraphie osseuse", label: "Scintigraphie osseuse" },
          { value: "Examen anatomopathlogique", label: "Examen anatomopathlogique" },
          { value: "Medullogramme", label: "Medullogramme" },
          { value: "BOM", label: "BOM" },
      ] : []),
      ...(Specialite === 'Pédiatrie' && (Organe === 'T. Sacro-coccygienne') ? [
          { value: "TDM", label: "TDM" },
          { value: "IRM ", label: "IRM " },
      ] : []),

      ...(Specialite === 'Pédiatrie' && Organe === 'Corticosurrenale' ? [
          { value: "GlucoC", label: "GlucoC" },
          { value: "minéralloC", label: "minéralloC" },
          { value: "Stéroides", label: "Stéroides" },
          { value: "TDM", label: "TDM" },
          { value: "IRM", label: "IRM" },
          { value: "Examen anatomopathlogique", label: "Examen anatomopathlogique" },
          { value: "Medullogramme", label: "Medullogramme" },
          { value: "Scintigraphie au MIBG", label: "Scintigraphie au MIBG" },
          { value: "PET Scan", label: "PET Scan" },
      ] : []),
      ...(Specialite === 'Cancers Rares' && (Organe === 'Parathyroïde ') ? [
          { value: "GlucoC", label: "GlucoC" },

      ] : []),



  ];

  const optionGradeHistopronostique = [

      ...(Specialite === 'Urologie' && Organe === 'Prostate' ? [
          { value: "Gleason score", label: "Gleason score" },

      ] : []),

      ...(Specialite === 'ORL' && Organe === 'Thyroide' ? [
          { value: "IHC (différenciation et Ki 67)", label: "IHC (différenciation et Ki 67)" },

      ] : []),

      ...(Specialite === 'ORL' && (Organe === 'Cavum' || Organe === 'Nasopharynx') ? [
          { value: "IHC", label: "IHC" },

      ] : []),

      ...(Specialite === 'Cancers Rares' && (Organe === 'Cortex de la glande surrénale') ? [
          { value: "score de Weiss", label: "score de Weiss" },

      ] : []),

  ];

  const [selectedCheckbox, setSelectedCheckbox] = useState([]);

  const handleChangeOption = (e) => {
      const value = e.target.value;
      setSelectedCheckbox((prev) =>
          prev.includes(value)
              ? prev.filter((item) => item !== value) // Retire la valeur si elle est déjà sélectionnée
              : [...prev, value] // Ajoute la valeur si elle n'est pas sélectionnée
      );
  };

  const allOptions = () => {
      let options = [];
      if (selectedCheckbox.includes('Rhabdomyosarcome')) {
          options = [
              { value: "Alvéolaire", label: "Alvéolaire" },
              { value: "Embryonnaire (fusiformes, botryoïde)", label: "Embryonnaire (fusiformes, botryoïde)" },
          ];
      };
      if (selectedCheckbox.includes('Carcinome')) {
          options = [
              { value: 'Urothélial', label: 'Urothélial' },
              { value: 'Transitionnel', label: 'Transitionnel' },
          ]
      };
      return options;
  };

  // Filtrer les options en fonction des cases cochées
  const filteredOptions = allOptions();
  const optionImmuno = [
      ...(Specialite === 'Gynécologie' && (Organe === 'Ovaire' || Organe === 'Col' || Organe === 'Utérus' || Organe === 'Vagin' || Organe === 'Vulve') ? [
          { value: 'Ovaire', label: 'Ovaire' },
          { value: 'Endométre', label: 'Endométre' },
          { value: 'Col', label: 'Col' },
          { value: 'Vagin', label: 'Vagin' },
          { value: 'Utérus', label: 'Utérus' },
      ] : []),
      ...(Specialite === 'Gynécologie' && Organe === 'Sein' ? [
          { value: 'RH', label: 'RH' },
          { value: 'Her 2', label: 'Her 2' },
          { value: 'Ki 67', label: 'Ki 67' }

      ] : []),

      ...(Specialite === 'Thorax' && Organe === 'Plèvre' ? [
          { value: 'Calrétinine', label: 'Calrétinine' },
          { value: 'Antigène 1 de la tumeur de Wilms (WT1)', label: 'Antigène 1 de la tumeur de Wilms (WT1)' },
          { value: 'Antigène membranaire anti-épithélial (EMA)', label: 'Antigène membranaire anti-épithélial (EMA)' },
          { value: 'Cytokératine 5/6 (CK 5/6)', label: 'Cytokératine 5/6 (CK 5/6)' },
          { value: 'TTF1', label: 'TTF1' },
          { value: 'ACE', label: 'ACE' },
          { value: 'Anti-récepteurs œstrogène et progestérone (sexe féminin).', label: 'Anti-récepteurs œstrogène et progestérone (sexe féminin).' },


      ] : []),

      ...(Specialite === 'Thorax' && Organe === 'Poumon' ? [
          { value: 'TTF1', label: 'TTF1' },
          { value: 'P40', label: 'P40' },
          { value: 'Expression de PD-L1', label: 'Expression de PD-L1' }

      ] : []),


  ];

  const optionMedulloblasme = [
      { value: 'Médulloblasme classique', label: 'Medulloblasme classique' },
      { value: 'Médulloblastopme nodulaire/desmoplastique', label: 'Medulloblastopme nodulaire/desmoplastique' },
      { value: 'Médulloblasme à nodularité étendue', label: 'Médulloblasme à nodularité étendue' },
      { value: 'Médulloblasme à grandes cellule/anaplastique', label: 'Médulloblasme à grandes cellule/anaplastique' },

  ];


  const optionMutation = [
      ...(Specialite === 'Gynécologie' && Organe === 'Sein' ? [
          { value: 'BRCA1', label: 'BRCA1' },
          { value: 'BRCA2', label: 'BRCA2' },


      ] : []),

  ];
  const optionInvasion = [
      { value: '(nerfs crâniens, base du crâne) FDR de persistance tumorale', label: '(nerfs crâniens, base du crâne) FDR de persistance tumorale' },

  ];
  const optionEnvahissement = [

  ];
  const optionTailleTumoral = [

  ];
  const optionSexe = [
      { value: 'femme meilleur pronostic', label: 'femme meilleur pronostic' },

  ];
  const optionStade = [
      { value: 'stade I : tumeur totalement réséquée, de petite taille (< 100 g et < 200 cm3), et normalisation des dosages hormonaux en postopératoire ;', label: 'stade I : tumeur totalement réséquée, de petite taille (< 100 g et < 200 cm3), et normalisation des dosages hormonaux en postopératoire ;' },
      { value: 'stade II : tumeur totalement réséquée, de taille plus importante (≥ 100 g et ≥ 200 cm3), et', label: 'stade II : tumeur totalement réséquée, de taille plus importante (≥ 100 g et ≥ 200 cm3), et' },

  ];
  const optionAgeJeune = [
      { value: 'controversé mais semble être de bon pronostic', label: 'controversé mais semble être de bon pronostic' },

  ];
  const optionTaux = [];
  const optionHemoglobine = [
      { value: 'chute de l’Hb en cours de RT de mauvais pronostic', label: 'chute de l’Hb en cours de RT de mauvais pronostic' },

  ];
  const optionFacteurMoleculaire = [];

  const [inputDataDiagnostique, setInputDataDiagnostique] = useState({});
  const handleChangeDiagnostique = (value, name) => {
      setInputDataDiagnostique((prevInput) => ({
          ...prevInput,
          [name]: value,
      }));

  };

  const handleCheckboxMedullogramme = (value) => {
      setMoyenDiagnosticMedullogramme(value);
  };

  const handleCheckboxCytogenetique = (value) => {
      setMoyenDiagnosticCytogenetique(value);
  };
  const handleCheckboxBiopsie = (value) => {
      setMoyenDiagnosticBiopsieOsteomedullaire(value);
  };

  const handleChangeResultat = (e) => {
      setMoyenDiagnosticCytogenetiqueResultats(e.target.value);
      setMoyenDiagnosticMedullogrammeResultats(e.target.value);
      setMoyenDiagnosticBiopsieOsteomedullaireResultats(e.target.value)
  };


//   const handleSubmitDiagnostique = async (e) => {
//       e.preventDefault();
//       // Vérifiez si tous les champs sont vides

//       if (!datePrelevement &&
//           !dateDiagnostic &&
//           !MoyenDiagnostic.length &&
//           !psOms &&
//           !poids &&
//           !poidsAge &&
//           !poidsTaille &&
//           !taille &&
//           !tailleAge &&
//           !media &&
//           !bilanPreTherapeutique.length &&
//           !SignesPhysiques.length &&
//           !SignesCliniques.length &&
//           !SignesParacliniques.length &&
//           !TypeHistologies.length &&
//           !GradeHistopronostique.length &&
//           !biologieMoleculaire.length) {
//           notification.warning({
//               message: 'Saisir d\'abord les informations requises',
//               description: 'Veuillez remplir tous les champs obligatoires avant de soumettre.',
//               duration: 6,
//           });
//           return;
//       }



//       inputDataDiagnostique.DatePrelevement = datePrelevement;
//       inputDataDiagnostique.DateDiagnostic = dateDiagnostic;
//       inputDataDiagnostique.TechniquePrelevement = TechniquePrelevement;
//       inputDataDiagnostique.EtatGeneralPatient = EtatGeneralPatient;
//       inputDataDiagnostique.MoyenDiagnostic = MoyenDiagnostic;
//       inputDataDiagnostique.MoyenDiagnosticCytologie = moyenDiagnosticCytologie === true ? 'Oui' : 'Non';
//       inputDataDiagnostique.MoyenDiagnosticImmunophénotypageSanguin = moyenDiagnosticImmunophénotypageSanguin === true ? 'Oui' : 'Non';
//       inputDataDiagnostique.MoyenDiagnosticImmunophenotypageSanguinResultats = moyenDiagnosticImmunophenotypageSanguinResultats;
//       inputDataDiagnostique.MoyenDiagnosticHistologie = moyenDiagnosticHistologie === true ? 'Oui' : 'Non';
//       inputDataDiagnostique.MoyenDiagnosticHistologieResultats = moyenDiagnosticHistologieResultats;
//       inputDataDiagnostique.MoyenDiagnosticImuunohistochimie = moyenDiagnosticImuunohistochimie === true ? 'Oui' : 'Non';
//       inputDataDiagnostique.MoyenDiagnosticImuunohistochimieResultats = moyenDiagnosticImuunohistochimieResultats;
//       inputDataDiagnostique.MoyenDiagnosticMedullogramme = moyenDiagnosticMedullogramme === true ? 'Oui' : 'Non';
//       inputDataDiagnostique.MoyenDiagnosticBCRAB = MoyenDiagnosticBCRABL;
//       inputDataDiagnostique.CytogenetiqueT = CytogenetiqueT;
//       inputDataDiagnostique.CytogenetiqueAca = CytogenetiqueAca;
//       inputDataDiagnostique.MoyenDiagnosticMedullogrammeResultats = moyenDiagnosticMedullogrammeResultats;
//       inputDataDiagnostique.MoyenDiagnosticBiopsieOsteomedullaire = moyenDiagnosticBiopsieOsteomedullaire;
//       inputDataDiagnostique.MoyenDiagnosticBiopsieOsteomedullaireResultats = moyenDiagnosticBiopsieOsteomedullaireResultats;
//       inputDataDiagnostique.PsOms = psOms;
//       inputDataDiagnostique.MutationGenetique = mutationGenetique;
//       inputDataDiagnostique.Poids = poids;
//       inputDataDiagnostique.Taille = taille;
//       inputDataDiagnostique.PoidsAge = poidsAge;
//       inputDataDiagnostique.TailleAge = tailleAge;
//       inputDataDiagnostique.ImcSc = imcSc;
//       inputDataDiagnostique.PoidsTaille = poidsTaille;
//       inputDataDiagnostique.ImcAge = imcAge;
//       inputDataDiagnostique.SignesPhysiques = SignesPhysiques;
//       inputDataDiagnostique.SignesCliniques = SignesCliniques;
//       inputDataDiagnostique.Siege = siege;
//       inputDataDiagnostique.SignesParacliniques = SignesParacliniques;
//       inputDataDiagnostique.IRMCP = IRMCP;
//       inputDataDiagnostique.IRMMP = IRMMP;
//       inputDataDiagnostique.IRMCPO = IRMCPO;
//       inputDataDiagnostique.IRMMPO = IRMMPO;
//       inputDataDiagnostique.LCR = LCR;
//       inputDataDiagnostique.Biologie = biologie;
//       inputDataDiagnostique.ExamenAnatomopathlogique = ExamenAnatomopathlogique;
//       inputDataDiagnostique.MargesResection = MargesResection;
//       inputDataDiagnostique.Medullogramme = Medullogramme;
//       inputDataDiagnostique.BilanPreTherapeutique = addBilanPreTherapeutique;
//       inputDataDiagnostique.Media = media;
//       inputDataDiagnostique.NéoplasmesHistiocytesMacrophages = NéoplasmesHistiocytesMacrophages;
//       inputDataDiagnostique.NéoplasmesCellulesLangerhansAutresCellulesDndritiques = NéoplasmesCellulesLangerhansAutresCellulesDndritiques;
//       inputDataDiagnostique.Malade = Malade;
//       inputDataDiagnostique.TypeHistologies = TypeHistologies || CytogenetiqueDel17p;
//       inputDataDiagnostique.Medulloblasme = MedulloblasmeSelect || medulloblasme;
//       inputDataDiagnostique.TypeCystologie = TypeCistologie;
//       inputDataDiagnostique.TypeCynetogenetique = CytogenetiqueDel17p;
//       inputDataDiagnostique.GradeHistopronostique = GradeHistopronostique;
//       inputDataDiagnostique.BiologieMoleculaire = biologieMoleculaire || BiologieMoleculaireMutationTp53;
//       inputDataDiagnostique.Tils = Tils;
//       inputDataDiagnostique.CytogenetiqueConventionnelle = cytogenetiqueConventionnelle;
//       inputDataDiagnostique.FISH = FISH;
//       inputDataDiagnostique.MarqueursTumoraux = MarqueursTumoraux;
//       inputDataDiagnostique.Immunohistochimie = immuno;
//       inputDataDiagnostique.EGFR = EGFR;
//       inputDataDiagnostique.Pdl1mTor = pdl1mTor;
//       inputDataDiagnostique.Invasionlocoregionale = invasion;
//       inputDataDiagnostique.EnvahissementGanglionnaire = Envahissement;
//       inputDataDiagnostique.TailleVolume = tailleTumoral;
//       inputDataDiagnostique.AgeJeune = ageJeune;
//       inputDataDiagnostique.Taux = taux;
//       inputDataDiagnostique.Sexe = Sexe;
//       inputDataDiagnostique.Hemoglobine = Hemoglobine;
//       inputDataDiagnostique.AutreFacteurMoleculaire = facteurMoleculaire;
//       inputDataDiagnostique.ALK = ALK;
//       inputDataDiagnostique.CMET = CMET;
//       inputDataDiagnostique.ROSI = ROSI;
//       inputDataDiagnostique.KRAS = KRAS;
//       inputDataDiagnostique.BRAF = BRAF;
//       inputDataDiagnostique.HER2 = HER2;
//       inputDataDiagnostique.NTRK = NTRK;
//       inputDataDiagnostique.NRG1 = NRG1;
//       inputDataDiagnostique.RET = RET;
//       inputDataDiagnostique.FacteursPronostiques = FacteursPronostiques || facteurPronostique;
//       inputDataDiagnostique.StadeClinique = tumeur || adenopathie || Métastase || StadeClinique;
//       inputDataDiagnostique.StadeAnnArbor = tumeur;
//       inputDataDiagnostique.ScoreHasford = ScoreHasford;
//       inputDataDiagnostique.ScoreEUTOS = ScoreEUTOS;
//       inputDataDiagnostique.ClassificationBinet = ClassificationBinet;
//       inputDataDiagnostique.StadeSokal = StadeSokal;
//       inputDataDiagnostique.AtteinteLCR = AtteinteLCR;
//       inputDataDiagnostique.AtteinteTesticulaire = AtteinteTesticulaire;
//       inputDataDiagnostique.CorticoSensibilite = CorticoSensibilite;
//       inputDataDiagnostique.FacteurGenetique = FacteurGenetique;
//       inputDataDiagnostique.Phenotype = Phenotype;
//       inputDataDiagnostique.TypeCistologie = TypeCytologique;
//       inputDataDiagnostique.Age = Age;
//       inputDataDiagnostique.IMWG2014 = IMWG2014;
//       inputDataDiagnostique.ClassificationAltmann = ClassificationAltmann;
//       inputDataDiagnostique.ClassificationISS = ClassificationISS;
//       inputDataDiagnostique.ClassificationSalmonDiurie = ClassificationSalmonDiurie;
//       inputDataDiagnostique.ClassificationISSRevisee = ClassificationISSRevisee;
//       inputDataDiagnostique.ClassificationISSReviseeStade = ClassificationISSReviseeStade;
//       inputDataDiagnostique.ClassificationSalmonDiurieStade = ClassificationSalmonDiurieStade;
//       inputDataDiagnostique.ClassificationISSStade = ClassificationISSStade;
//       inputDataDiagnostique.ClassificationOmsSyndromesMyelodysplasique = ClassificationOmsSyndromesMyelodysplasiques;
//       inputDataDiagnostique.Risque = Risque;
//       inputDataDiagnostique.StadePronostic = tumeur;
//       inputDataDiagnostique.MoyenDiagnosticCytologieResultats = moyenDiagnosticCytologieResultats
//       // inputDataDiagnostique.PatientId = PatientId;
//       // inputDataDiagnostique.RcpId = RcpId;
//       inputDataDiagnostique.UserId = UserId;
//       inputDataDiagnostique.BilanEvaluationPatient = BilanEvaluationPatient;
//       inputDataDiagnostique.BilanEvaluationMalade = BilanEvaluationMalade;
//       inputDataDiagnostique.Glucocorticoïdes = Glucocorticoïdes;
//       inputDataDiagnostique.Minéralocorticoïdes = Minéralocorticoïdes;
//       inputDataDiagnostique.SteroideSexuel = SteroideSexuel;
//       inputDataDiagnostique.Biologie = addBiologie;
//       inputDataDiagnostique.Biopsie = Biopsie;
//       inputDataDiagnostique.CatecholaminesUrinaires = CatecholaminesUrinaires;
//       inputDataDiagnostique.TA = TA;
//       inputDataDiagnostique.EndoscopieDigestive = endoscopieDigestive;
//       inputDataDiagnostique.Testicule = selectedCheckbox;
//       inputDataDiagnostique.ParaTesticule = selectedCheckbox;
//       inputDataDiagnostique.Testicule = Testicule;
//       inputDataDiagnostique.ParaTesticule = ParaTesticule;
//       inputDataDiagnostique.Seminome = Seminome;
//       inputDataDiagnostique.NonSeminome = NonSeminome;
//       inputDataDiagnostique.TumeurCelluleSL = TumeurCelluleSL;
//       inputDataDiagnostique.TumeurCelluleG = TumeurCelluleG;
//       inputDataDiagnostique.Stage = Risque;
//       inputDataDiagnostique.TypeTesticule = TypeTesticule;
//       inputDataDiagnostique.TumeursGerminaux = TumeursGerminaux;
//       inputDataDiagnostique.OsteosarcomeMR = OsteosarcomeMR;
//       inputDataDiagnostique.OsteosarcomeSH = OsteosarcomeSH;
//       inputDataDiagnostique.Classification = tumeur;
//       inputDataDiagnostique.Rhabdomyosarcome = selectedCheckbox;
//       inputDataDiagnostique.Carcinome = selectedCheckbox;
//       inputDataDiagnostique.Dysgerminome = Dysgerminome;
//       inputDataDiagnostique.Teratome = Teratome;
//       inputDataDiagnostique.Germinale = Germinale;
//       inputDataDiagnostique.Choriocarcinome = Choriocarcinome;
//       inputDataDiagnostique.TumeurSacVitellin = TumeurSacVitellin;
//       inputDataDiagnostique.CordonSexuel = CordonSexuel;
//       inputDataDiagnostique.ClassificationFigo = tumeur;
//       inputDataDiagnostique.KraBrafPik = KraBrafPik;
//       inputDataDiagnostique.Fox = Fox;
//       inputDataDiagnostique.PcinquanteTrois = PcinquanteTrois;
//       inputDataDiagnostique.Adenok = Adenok;
//       inputDataDiagnostique.ConsultationOncogetique = ConsultationOncogetique;
//       inputDataDiagnostique.ScorePronostic = ScorePronostic;
//       inputDataDiagnostique.ResultatFacteur = ResultatFacteur;


//       try {
//           const reponse = await dispatch(createDiagniosticRcp(inputDataDiagnostique)).unwrap();
//           if (reponse.data) {
//               // close();
//               resetStates();
//               notification.success({
//                   message: reponse?.message,
//                   duration: 6,
//               });
//           }

//       } catch (error) {
//           // Gestion de l'erreur lors de la création
//           notification.error({
//               message: error,
//           });
//       }
//   };
  

    const [showModalDiagniostique, setShowModalDiagniostique] = useState(false);

    

    const [inputData, setInputData] = useState({
      DateCreation: null,
      Prenom: '',
      Nom: '',
      Dob: '',
      Age: '',
      Sexe: '',
      AdresseHabituelle: '',
      AdresseActuelle: '',
      Professsion: '',
      ProfesssionMere: '',
      ProfesssionPere: '',
      Scolarisation: '',
      Specialite: '',
      Organe: '',
      Email: '',
      Telephone: '',
      CentreId: '',
      NomCentre: '',
      TerrainCommorbidites: '',
      TerrainCommorbiditesFacteursEnvironnementaux: '',
      TerrainCommorbiditesFacteursInfectieux: '',
      TerrainCommorbiditesFacteursGenetiques: '',
      StatutVaccinal: '',
      ExpositionToxique: '',
      PrecisionExpositionToxique: '',
      Tabac: '',
      TabagismeActif: '',
      StatusTabagismeActif: '',
      TabagismePassif: '',
      AutresModeVie: '',
      Alcool: '',
      GrossesseEvolutive: '',
      TermeGrossesseEvolutive: '',
      ChanvreCannabis: '',
      SituationMatrimoniale: '',
      AgeConjointe: '',
      DesirFertilite: '',
      PreservationFertiliteProposee: '',
      InfectionHpvConnu: '',
      MultiPartenariat: '',
      AgePremierRapportSexuel: '',
      StressEnvironnementSocial: '',
      UserId: '',
      ScoreG8: '',
      contraceptionOr: '',
      ModVie: '',
    });

   

  const handleOpenModalConfirmArchiv = (record) => {
    setArchivPatientId(record.id);
    setOpenModalConfirmArchiv(true);
  }
  const handleCancelArchiv = () => {
    setOpenModalConfirmArchiv(false);
    setArchivPatientId(null);
  }

  useEffect(() => {
    const aujourdhui = dayjs();
    const aujourdhuiString = aujourdhui.format('YYYY-MM-DD');
    setDateCreation(aujourdhuiString);
  }, []);

  const dispatch = useDispatch();
  // Fetch specialities from API on component mount
  useEffect(() => {
    dispatch(fetchSpecialite());
  }, [dispatch]);

  // Get specialities from Redux state
  const specialites = useSelector((state) => state.specialite.data);
 
  // Mapper les spécialités
  const specialitesOptions = specialites?.data?.map((specialite) => ({
    label: specialite.NomSpecialite, // Assurez-vous que 'nom' est la propriété correcte pour le label
    value: specialite.id,  // Assurez-vous que 'id' est la propriété correcte pour la valeur
  }));

  const handleChangeSpecialite = (label, value) => {
    setSpecialite(value.children);
    setOrgane("");
  }
 

  const handleChangeCentre = (label, value) => {
    setNomCentre(value.children);
    setCentreId(value.value);
  };
  const handleChangeOrgane = (label, value) => {
    setOrgane(value.children);
  };
  const handleCancelDiagnostique = () => {
    setShowModalDiagniostique(false);
};

  let options = [];
  if (Specialite === "Digestif") {        
    options = SelectOrganeDigestifOptions;
  }
  else if (Specialite === "Hématologie") {
    options = SelectOrganeHematologieOptions;
  }
  else if (Specialite === "Gynécologie") {
    options = SelectOrganeGynecologieOptions;
  }
  else if (Specialite === "ORL") {
    options = SelectOrganeORLOptions;
  }
  else if (Specialite === "Pédiatrie") {
    options = SelectOrganeOncoPediatrieOptions;
  }
  else if (Specialite === "Thorax") {
    options = SelectOrganeThoraxOptions;
  }
  else if (Specialite === "Urologie") {
    options = SelectOrganeUrologieOptions;
  }
  else if (Specialite === `Cancers Rares`) {
    options = SelectOrganeCancersRaresOptions;
  }
//   else {
//     options = [];
//   }

  useEffect(() => {
    if (Specialite === "Pédiatrie" && Organe === 'Oesophage') {
      setTerrainComorbiditesOptions(OesophageOptions);
    } else if (Organe === 'Anus') {
      setTerrainComorbiditesOptions(TerrainComorbiditesOptionsAnus);
    } else if (Organe === 'Colon et rectum') {
      setTerrainComorbiditesOptions(TerrainComorbiditesOptionsConRectum);
    } else if (Organe === 'Foie') {
      setTerrainComorbiditesOptions(TerrainComorbiditesOptionsFoie);
    } else if (Organe === 'Oesophage') {
      setTerrainComorbiditesOptions(TerrainComorbiditesOptionsOesophage);
    } else if (Organe === 'Voies biliaires') {
      setTerrainComorbiditesOptions(TerrainComorbiditesOptionsVoiesBiliaire);
    } 
    // else if (Specialite === 'Cancers Rares') {
    //   setTerrainComorbiditesOptions(TerrainComorbiditesOptionsCancers);
    // }

    else if (Organe === 'Estomac') {
      setFacteursEnvironnementauxOptions(FacteursEnvironnementauxOptions);
      setFacteursInfectieuxOptions(FacteursInfectieuxOptions);
      setFacteursGenetiquesOptions(FacteursGenetiquesOptions);
    } else if (Organe === 'Sein') {
      setTerrainComorbiditesOptions(SeinOptions);
    } else if (Organe === 'Ovaires' ||
      Organe === 'Col' ||
      Organe === 'Utérus' ||
      Organe === 'Vagin' ||
      Organe === 'Vulve') {
      setTerrainComorbiditesOptions(OvairColUtVaVuOptions);
    } else if (Organe === 'Cavum' ||
      Organe === 'Nasopharynx') {
      setFacteursEnvironnementauxOptions(FacteursEnvironnementauxCavumNasopharynxOptions);
    } else if (Organe === 'Cavité buccale' ||
      Organe === 'Pharynx' ||
      Organe === 'Larynx' ||
      Organe === 'Cavité nasale' ||
      Organe === 'Lévre' ||
      Organe === 'Sinus' ||
      Organe === 'Glandes salivaire') {
      setTerrainComorbiditesOptions(cavitePharynxLarynxCaviteNLevreSinusGlandOptions);
    } else if (Organe === 'Thyroide') {
      setTerrainComorbiditesOptions(TyroideOptions);
    } else if (Organe === 'Tumeurs cerebrales') {
      setTerrainComorbiditesOptions(TumeursCerebralesOptions);
    } else if (Organe === 'Tumeurs orbitaires') {
      setTerrainComorbiditesOptions(TumeursOrbitairesOptions);
    } else if (Organe === 'Tumeurs maxillo faciales') {
      setTerrainComorbiditesOptions(TumeursMaxilloFacialesOptions);
    } else if (Organe === 'Tumeurs mediastinales') {
      setTerrainComorbiditesOptions(TumeursMediastinalesOptions);
    } else if (Organe === 'Foie/Pédiatrie') {
      setTerrainComorbiditesOptions(FoiePédiatrieOptions);
    } else if (Organe === 'Ovaire') {
      setTerrainComorbiditesOptions(OvaireOptions);
    } else if (Organe === 'Prostate') {
      setTerrainComorbiditesOptions(ProstateOptions);
    } else if (Organe === 'Rein') {
      setTerrainComorbiditesOptions(ReinOptions);
    } else if (Organe === 'Testicul/para-testicule') {
      setTerrainComorbiditesOptions(ProstateOptions);
    } else if (Organe === 'Vessie') {
      setTerrainComorbiditesOptions(ProstateOptions);
    } else if (Organe === 'Rétropéritoine') {
      setTerrainComorbiditesOptions(RetroperitoineOptions);
    } else if (Organe === 'OS') {
      setTerrainComorbiditesOptions(OSOptions);
    } else if (Organe === 'Corticosurrenale') {
      setTerrainComorbiditesOptions(CorticosurrenaleOptions);
    } else if (Organe === 'Colo-rectum') {
      setTerrainComorbiditesOptions(ColoRectumOptions);
    } else if (Organe === 'Médiastin' ||
      Organe === 'Poumon' ||
      Organe === "Parois thoracique" ||
      Organe === "Plèvre") {
      setTerrainComorbiditesOptions(MediastinOptions);
    } else {
      setTerrainComorbiditesOptions(TerrainComorbiditeVideOptions);
    }
  }, [Organe, Specialite]);


  // Fonction pour calculer l'âge à partir de la date de naissance
  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Si la date d'anniversaire n'est pas encore passée cette année, soustraire 1 an
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  // Fonction pour calculer la date de naissance à partir de l'âge donné
  const calculateDob = (age) => {
    const currentYear = new Date().getFullYear();
    const birthYear = currentYear - age;

    // Fixer la date de naissance au 1er janvier de l'année calculée
    const calculatedDob = new Date(birthYear, 0, 1); // Mois 0 = Janvier
    return calculatedDob.toISOString().split('T')[0]; // Formatage de la date en 'yyyy-mm-dd'
  };

  // Gestionnaire pour mettre à jour la date de naissance et calculer l'âge
  const handleDobChange = (e) => {
    const dob = e.target.value;
    setDob(dob);
    const calculatedAge = calculateAge(dob);
    setAge(calculatedAge);
  };

  // Gestionnaire pour mettre à jour l'âge et calculer la date de naissance
  const handleAgeChange = (e) => {
    const age = e.target.value;
    setAge(age);
    const calculatedDob = calculateDob(age);
    setDob(calculatedDob);
  };
  const handleOpenModalConfirmation = (record) => {
    setUpdatePatientdata(record);
    setOpenModalConfirmation(true);
  }
  const handleCancel = () => {
    setOpenModalConfirmation(false);
    setUpdatePatientdata(null);
  }
  const handleChangeSexe = (value) => {
    setSexe(value);
    if (value ==='Féminin') {
        setAffichAGO(true);
    }else{
        setAffichAGO(false);
    }
  };
  const handleChangeSituationMat = (value) => {
    setSituationMatrimoniale(value);
  };
  const handleChangeDesirFertilite = (value) => {
    setDesirFertilite(value);
  };
  const handleChangePreservation = (value) => {
    setPreservationFertiliteProposee(value);
  };
  const handleChangeExposition = (value) => {
    setExpositionToxique(value);
  };
  const handleChangeTerrainComorbite = (value) => {
    setTerrainCommorbidites(value);
  };
  const handleChangeMedcin = (value) => {
    setUserId(value);
  };
  const handleChangeFacteursEnvironnementaux = (value) => {
    setTerrainCommorbiditesFacteursEnvironnementaux(value);
  };
  const handleChangeFacteursInfectieux = (value) => {
    setTerrainCommorbiditesFacteursInfectieux(value);
  };
  const handleChangeFacteursGenetiques = (value) => {
    setTerrainCommorbiditesFacteursGenetiques(value);
  };
  const handleChangeAlcool = (value) => {
    setAlcool(value);
  };
  // 
  const handleChangeTabac = (value) => {
    setTabac(value);
  };
  const handleChangeTabacActif = (value) => {
    setTabagismeActif(value);
  };
  const handleChangeTabacPassif = (value) => {
    setTabagismePassif(value);
  };
  const handleChangeStatut = (value) => {
    setStatusTabagismeActif(value);
  };
  const handleChangeChanvreCannabis = (value) => {
    setChanvreCannabis(value);
  };
  const handleChangeScolarisation = (value) => {
    setScolarisation(value);
  };
  const handleChangeMenopause = (value) => {
    setMenopause(value);
 }

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setOrgane(null);
    setSpecialite(null);
  };
  const onCancel = () => {
    setOpen(false);
    setOrgane(null);
    setSpecialite(null);
    // resetFormValues();
  };

  const resetFormValues = () => {
    // setOrgane(null);
    // setSpecialite(null);
    setScolarisation("");
    setAgeConjointe("");
    setTabagismePassif("");
    setTabagismeActif("");
    setStatutVaccinal("");
    setStatusTabagismeActif("");
    setMultiPartenariat("");
    // setExpositionAncienne(null);
    setTermeGrossesseEvolutive("");
    setGrossesseEvolutive("");
    setAutresModeVie("");
    setScoreG8("");
    setContraceptionOr("");
    setProfesssionMere("");
    setProfesssionPere("");
    setStressEnvironnementSocial("");
    setAgePremierRapportSexuel("");
    setModVie("");
    setInfectionHpvConnu("");
    setProfesssion("");
    setAge("");
    setAdresseActuelle("");
    setAdresseHabituelle("");
    // setDateCreation("");
    setDob("");
    setEmail("");
    setNom("");
    // setNumeroIdentification("");
    setTelephone("");
    setPrenom("");
    setSexe("");
    setSituationMatrimoniale("");
    setPreservationFertiliteProposee("");
    setDesirFertilite("");
    setTabac("");
    setAlcool("");
    setChanvreCannabis("");
    setTerrainCommorbidites([]);
    setTerrainCommorbiditesFacteursEnvironnementaux([]);
    setTerrainCommorbiditesFacteursInfectieux([]);
    setTerrainCommorbiditesFacteursGenetiques([]);
    setUserId("");
    setCentreId("");
    setExpositionToxique("");
    
  };
  const specialiteOrganeData = {
    Specialite: Specialite,
    Organe: Organe,
};
  const handleOpenForm = () => {
    setOpenForm(true);
    setOpen(false);
    resetFormValues();
  };

  const [patientData, setPatientData] = useState();
  const [activeTabPatient, setActiveTabPatient] = useState('1'); 
  const [activeTabPatientAntecedent, setActiveTabPatientAntecedent] = useState('2-1'); 

  const resetValues = () => {
    setMenopause("");
    setEnfantVivant("");
    setGestite("");
    setParite("");
    setAntecedentChirurgical([]);
    setAntecedentFamilial([]);
    setAntecedentMedical([]);
    setAntecedentPerinatal([]);
    setAntecedentTuberculosePulmonaire([]);
   }

 


  const handleOpenUpdateForm = (record) => {
    setOpenModalConfirmation(false);
    setOpenUpdateForm(true);
    setInputData(
      {
        DateCreation: record.DateCreation || '',
        Prenom: record.Prenom || '',
        Nom: record.Nom || '',
        Dob: record.Dob || '',
        Age: record.Age || '',
        Sexe: record.Sexe || '',
        AdresseHabituelle: record.AdresseHabituelle || '',
        AdresseActuelle: record.AdresseActuelle || '',
        Professsion: record.Professsion || '',
        ProfesssionMere: record.ProfesssionMere || '',
        ProfesssionPere: record.ProfesssionPere || '',
        Scolarisation: record.Scolarisation || '',
        Specialite: record.Specialite,
        Organe: record.Organe,
        Email: record.Email || '',
        Telephone: record.Telephone || '',
        CentreId: record.CentreId || '',
        NomCentre: record.NomCentre || '',
        TerrainCommorbidites: record.TerrainCommorbidites || '',
        TerrainCommorbiditesFacteursEnvironnementaux: record.TerrainCommorbiditesFacteursEnvironnementaux || '',
        TerrainCommorbiditesFacteursInfectieux: record.TerrainCommorbiditesFacteursInfectieux || '',
        TerrainCommorbiditesFacteursGenetiques: record.TerrainCommorbiditesFacteursGenetiques || '',
        StatutVaccinal: record.StatutVaccinal || '',
        ExpositionToxique: record.ExpositionToxique || '',
        PrecisionExpositionToxique: record.PrecisionExpositionToxique || '',
        Tabac: record.Tabac || '',
        TabagismeActif: record.TabagismeActif || '',
        StatusTabagismeActif: record.StatusTabagismeActif || '',
        TabagismePassif: record.TabagismePassif || '',
        AutresModeVie: record.AutresModeVie || '',
        Alcool: record.Alcool || '',
        GrossesseEvolutive: record.GrossesseEvolutive || '',
        TermeGrossesseEvolutive: record.TermeGrossesseEvolutive || '',
        ChanvreCannabis: record.ChanvreCannabis || '',
        SituationMatrimoniale: record.SituationMatrimoniale || '',
        AgeConjointe: record.AgeConjointe || '',
        DesirFertilite: record.DesirFertilite || '',
        PreservationFertiliteProposee: record.PreservationFertiliteProposee || '',
        InfectionHpvConnu: record.InfectionHpvConnu || '',
        MultiPartenariat: record.MultiPartenariat || '',
        AgePremierRapportSexuel: record.AgePremierRapportSexuel || '',
        StressEnvironnementSocial: record.StressEnvironnementSocial || '',
        UserId: record.UserId || '',
        ScoreG8: record.ScoreG8 || '',
        contraceptionOr: record.contraceptionOr || '',
        ModVie: record.ModVie || '',
      }
    );

    setUpdatingDataId(record.id);
    // resetFormValues();
  };


  const handleChange = (value, field) => {
    setInputData({
      ...inputData,
      [field]: value,
    });
  };

  const handleUpdateForm = async (e) => {
    e.preventDefault();

    try {
      const result = await dispatch(updatePatient({ id: updatingDataId, body: inputData })).unwrap();
      notification.success({
        message: "Patient mis à jour avec succès",
        description: result.message,
        duration: 6,
      });
      setPatientData(inputData);
      setOpenUpdateForm(false);
      await dispatch(fetchPatients());
    } catch (err) {
      notification.error({
        message: "Erreur de mise à jour",
        description: err.message,
        duration: 6,
      });
    }
  };


  const [DataResPatient, setDataResPatient] = useState();

  const { data: dataMedecins } = useSelector((state) => state.medecins.data);
  // Fetch medecin from API
  useEffect(() => {
    dispatch(fetchMedecins());
  }, [dispatch]);

  const medecinOptions = dataMedecins?.map((medecins) => ({
    label: medecins.Prenom + " " + medecins.Nom, // Concaténation avec l'opérateur +
    value: medecins.id,
  }));

  // const handleSubmiteForm = async (e) => {
  //   e.preventDefault();
  //   const formattedTerrainComorbidites = TerrainCommorbidites.join(' ,  '); // Joindre les options sélectionnées en une chaîne
  //   const formattedTerrainComorbiditesFacteurEnvi = TerrainCommorbiditesFacteursEnvironnementaux.join(' ,  ');
  //   const formattedTerrainComorbiditesFacteurInf = TerrainCommorbiditesFacteursInfectieux.join(' ,  ');
  //   const formattedTerrainComorbiditesFacteurGen = TerrainCommorbiditesFacteursGenetiques.join(' ,  ');
  //   // Traitement des données du formulaire
  //   let patientData = {
  //     NumeroIdentification: "",
  //     DateCreation: DateCreation,
  //     Prenom: Prenom,
  //     Nom: Nom,
  //     Dob: Dob,
  //     Age: Age,
  //     Sexe: Sexe,
  //     AdresseHabituelle: AdresseHabituelle,
  //     AdresseActuelle: AdresseActuelle,
  //     Professsion: Professsion,
  //     ProfesssionMere: ProfesssionMere,
  //     ProfesssionPere: ProfesssionPere,
  //     Scolarisation: Scolarisation,
  //     Specialite: Specialite,
  //     Organe: Organe,
  //     Email: Email,
  //     Telephone: Telephone,
  //     CentreId: CentreId,
  //     NomCentre: NomCentre,
  //     TerrainCommorbidites: formattedTerrainComorbidites,
  //     TerrainCommorbiditesFacteursEnvironnementaux: formattedTerrainComorbiditesFacteurEnvi,
  //     TerrainCommorbiditesFacteursInfectieux: formattedTerrainComorbiditesFacteurInf,
  //     TerrainCommorbiditesFacteursGenetiques: formattedTerrainComorbiditesFacteurGen,
  //     StatutVaccinal: StatutVaccinal,
  //     ExpositionToxique: ExpositionToxique,
  //     PrecisionExpositionToxique: PrecisionExpositionToxique,
  //     Tabac: Tabac,
  //     TabagismeActif: TabagismeActif,
  //     StatusTabagismeActif: StatusTabagismeActif,
  //     TabagismePassif: TabagismePassif,
  //     AutresModeVie: AutresModeVie,
  //     Alcool: Alcool,
  //     GrossesseEvolutive: GrossesseEvolutive,
  //     TermeGrossesseEvolutive: TermeGrossesseEvolutive,
  //     ChanvreCannabis: ChanvreCannabis,
  //     SituationMatrimoniale: SituationMatrimoniale,
  //     AgeConjointe: AgeConjointe,
  //     DesirFertilite: DesirFertilite,
  //     PreservationFertiliteProposee: PreservationFertiliteProposee,
  //     InfectionHpvConnu: InfectionHpvConnu,
  //     MultiPartenariat: MultiPartenariat,
  //     AgePremierRapportSexuel: AgePremierRapportSexuel,
  //     StressEnvironnementSocial: StressEnvironnementSocial,
  //     // UserId: userData.user.id,
  //     UserId: UserId,
  //     ScoreG8: ScoreG8,
  //     contraceptionOr: contraceptionOr,
  //     ModVie: ModVie,
  //     // Medecin: `${userData.user.Prenom}  ${userData.user.Nom}`,

  //     // Adresse:Adresse,
  //     // Oncogenetique:Oncogenetique,
  //     // Indication:Indication,
  //     // Proposition:Proposition,
  //   };

  //   setPatientGlobale(patientData);

  //   if (!Sexe) {
  //     setError('Le sexe est requis');
  //     notification.error({
  //       message: 'Erreur',
  //       description: 'Veuillez sélectionner le sexe.',
  //       duration: 4,
  //     });
  //     return;
  //   }
  //   if (!CentreId) {
  //     setError('Le centre est requis');
  //     notification.error({
  //       message: 'Erreur',
  //       description: 'Veuillez sélectionner un centre.',
  //       duration: 4,
  //     });
  //     return;
  //   }

  //   // console.log(patientData);
  //   try {
  //     const result = await dispatch(createPatient(patientData))
  //       .unwrap(); // Unwrap pour accéder directement à la réponse
  //     // notification.success({
  //     //   message: "Création de patient réussie",
  //     //   description: result.message,
  //     //   duration: 5,
  //     // });

  //     if (result.data || result.payload.data) {
  //       setDataResPatient(result.data); // Enregistrer les données du patient
  //       // setFilteredRows(result.data); 
  //       // setOpenForm(false);
  //       // setOpenDetailPatient(true);
  //     }
  //     await dispatch(fetchPatients());
  //     setActiveTabPatient('2'); 
  //     // setActiveTabPatientAntecedent('2-1');
  //   }
  //   catch (err) {
  //     notification.error({
  //       message: "Erreur d'enregistrement",
  //       description: err.message,
  //       duration: 5,
  //     });
  //   }
  // };


  const handleCloseForm = () => {
    setOpenForm(false);
    resetFormValues();
    resetStates();
    resetValues();
    setOrgane(null);
    setSpecialite(null);
  };

  const handleCloseUpdateForm = () => {
    setOpenUpdateForm(false);
  };

  // tranfert de données vers detailpatient
  const [donneePatient, setDonneePatient] = useState('');

  const  handleOpenDetailPatient = (record) => {
    setDonneePatient(record);
    setOpenDetailPatient(true);
     
  };
  // const PatientId = record.id;

  const handleClose = () => {
    setOpenDetailPatient(false)
    setDataResPatient(null);
    setDonneePatient(null);
    setSearchValue("");
  };

  const handleFerme = () => {
    setOpenDetailPatient(false)

  };


  // Récupérer les centres depuis l'API
  useEffect(() => {
    dispatch(fetchCentre());
  }, [dispatch]);

  // Récupérer les centres depuis le state de Redux
  const centres = useSelector((state) => { return state.centre.data; });

  // / Mapper les centres
  const centresOptions = centres?.data?.map((centre) => ({
    // label: centre.NomCentre, 
    label: centre.NomCentre,
    value: centre.id,
  }
  ));

  const { data: dataPatients, loading } = useSelector((state) => state.patients.data);
  useEffect(() => {
    dispatch(fetchPatients());
    dispatch(fetchRcps());
  }, [dispatch]);



  const columnsPatients = [
    {
      title: <span style={{ fontSize:"11px" }}>Date création</span>,
      dataIndex: 'DateCreation',
      key: 'DateCreation',
      width: 150,
      render: (text) => (
        <p style={{fontSize:"13px"}}>{text}</p>
      )
    },
    {
      title: <span style={{ fontSize:"11px" }}>N° Identification</span>,
      dataIndex: 'NumeroIdentification',
      key: 'NumeroIdentification',
      className:"colonnPatientTablo",
      width: 130,
      render: (text) => (
        <p style={{fontSize:"12px"}}>{text}</p>
      )
      // maxWidth: 25,
      // width:40,
    },
    {
      title: <span style={{ fontSize:"12px" }}>Prénom</span>,
      dataIndex: 'Prenom',
      key: 'Prenom',
      render: (text) => (
        <p style={{fontSize:"13px"}}>{text}</p>
      )
    }, 
    {
      title: <span style={{ fontSize:"12px" }}>Nom</span>,
      dataIndex: 'Nom',
      key: 'Nom',
      render: (text) => (
        <p style={{fontSize:"13px"}}>{text}</p>
      )
    },
    {
      title: <span style={{ fontSize:"11px" }}>Date naissance</span>,
      key: 'Dob',
      dataIndex: 'Dob',
      width: 150,
      render: (text) => (
        <p style={{fontSize:"13px"}}>{text}</p>
      ),className:"colonnPatientTablo",
      // maxWidth: 25,
      // width:40,
    },
    {
      title: <span style={{ fontSize:"12px" }}>Age</span>,
      key: 'Age',
      dataIndex: 'Age',
      render: (text) => (
        <p style={{fontSize:"13px"}}>{text}</p>
      )
    },
    {
      title: <span style={{ fontSize:"12px" }}>Sexe</span>,
      key: 'Sexe',
      dataIndex: 'Sexe',
      // width: 8,
      render: (text) => (
        <p style={{fontSize:"13px"}}>{text}</p>
      )
    },
    {
      title: <span style={{ fontSize:"12px" }}>Téléphone</span>,
      key: 'Telephone',
      dataIndex: 'Telephone',
      render: (text) => (
        <p style={{fontSize:"13px"}}>{text}</p>
      )
    },
    {
      title: <span style={{ fontSize:"12px" }}>Email</span>,
      key: 'Email',
      dataIndex: 'Email',
      maxWidth: 25,
      render: (text) => (
        <p style={{fontSize:"11px"}}>{text}</p>
      )
    },
    {
      title: <span style={{ fontSize:"12px" }}>Options</span>,
      key: 'options',
      render: (record) => (
        <Space size="large">
          <Tooltip title="Éditer">
            <EditFilled
              style={{ color: "#4096FF", fontSize: "15px" }}
              // onClick={() => handleOpenUpdateForm(record)} 
              onClick={() => handleOpenModalConfirmation(record)}
            />
          </Tooltip>

          {role === "Admin" && (
            <Tooltip title="Archiver">
                <DeleteFilled
                style={{ color: "#E00B0B", fontSize: "15px" }}
                onClick={() => handleOpenModalConfirmArchiv(record)}
                />
            </Tooltip>
            )}
          <div>
            <BtnSubmit buttonText="Détails" border_="none" color="#ffff"
              type_="button" width_="4vw" height_="3.5vh" borderRadius_="0.2rem"
              bgNormalColor="linear-gradient( 310deg, rgb(33, 202, 253), rgb(33, 82, 255))"
              onClick={() => handleOpenDetailPatient(record)}
            />
          </div>
        </Space>
      ),
    },
  ];

  const isBtnSubmitDisabled = !Specialite || !Organe;

  const [searchValue, setSearchValue] = useState('');

  const filtrData = dataPatients?.filter(item =>
    item.Prenom.toLowerCase().includes((searchValue || '').toLowerCase()) ||
    item.Nom.toLowerCase().includes((searchValue || '').toLowerCase()) ||
    item.Email.toLowerCase().includes((searchValue || '').toLowerCase()) ||
    item.Telephone.toLowerCase().includes((searchValue || '').toLowerCase()) ||
    item.NumeroIdentification.toLowerCase().includes((searchValue || '').toLowerCase()) ||
    String(item.Age).toLowerCase().includes((searchValue || '').toLowerCase()) || // Conversion en chaîne
    item.Dob.toLowerCase().includes((searchValue || '').toLowerCase())
  );

  const handleArchivPatient = async (id) => {
    try {
      // Ajout de 'await' ici, avant l'appel à 'unwrap()'
      const result = await dispatch(updateArchivPatient(id)).unwrap();
      notification.success({
        message: "Patient archivé avec succès",
        description: result.message,
        duration: 5,
      });
      setOpenModalConfirmArchiv(false);
      // Rafraîchir la liste des patients
      await dispatch(fetchPatients());
    } catch (errorMessage) {
      notification.error({
        message: "Erreur de mise à jour",
        description: errorMessage.message,
        duration: 5,
      });
    }
  };
  const handleChangeAntecedentMedicaux = (value) => {
    setAntecedentMedical(value);
  };
  const handleChangeAntecedentFamilial = (value) => {
    setAntecedentFamilial(value);
  };
  const handleChangeAntecedentChirurgical = (value) => {
    setAntecedentChirurgical(value);
  };
  const handleChangeAntecedentPerinatal = (value) => {
    setAntecedentPerinatal(value);
  };
  const handleChangeAntecedentTuberculoseP = (value) => {
    setAntecedentTuberculosePulmonaire(value);
  };
  // const PatientId = donneePatient.id || DataResPatient.id;

  // useEffect(() => {
  //   if (PatientId) {
  //     dispatch(fetchAntecedentsPatient(PatientId)); 
  //   dispatch(fetchAgos(PatientId));
      
  //   }
  //   }, [dispatch,PatientId]);

  const handleSubmitAntecedentPatient = async (e) => {
    e.preventDefault();
    const formattedAntecedentMedical = AntecedentMedical.join(' ,  ');
    const formattedAntecedentChirurgical = AntecedentChirurgical.join(' ,  ');
    const formattedAntecedentFamilial = AntecedentFamilial.join(' ,  ');
    const formattedAntecedentPerinatal = AntecedentPerinatal.join(' ,  ');
    const formattedAntecedentTuberculosePulmonaire = AntecedentTuberculosePulmonaire.join(' ,  ');

    const PatientId = DataResPatient.id;
    let antecedantPatientData = {
      AntecedentMedical: formattedAntecedentMedical,
      AntecedentChirurgical: formattedAntecedentChirurgical,
      AntecedentFamilial: formattedAntecedentFamilial,
      AntecedentPerinatal: formattedAntecedentPerinatal,
      AntecedentTuberculosePulmonaire: formattedAntecedentTuberculosePulmonaire,
      PatientId: PatientId,
    };
   
    try {
      await dispatch(createAntecedantPatient(antecedantPatientData)).unwrap();
  
      // notification.success({
      //   message: "Creation antécédent",
      //   description:result.message,
      //   duration: 6,
      // });
      await dispatch(fetchAntecedentsPatient(PatientId));
      setActiveTabPatient('3'); 
      // onCancel();
    } catch (err) {
      console.log("Erreur :", err); // Ajout de ce log pour mieux comprendre l'erreur
      notification.error({
        message: "Erreur d'enregistrement",
        description: err.message,
        duration: 6,
      });
    }
  };

  const handleSubmitAntecedentAGO= async (e) => {
    e.preventDefault();
    const PatientId = DataResPatient.id;
    let aGOData = {
      Gestite: Gestite,
      Parite: Parite,
      EnfantVivant: EnfantVivant,
      Menopause: Menopause,
      PatientId: PatientId,
    };

    try {
      await dispatch(createAGO(aGOData)).unwrap();

      // notification.success({
      //   message: "Creation d'antécédent gynécologique",
      //   description:result.message,
      //   duration: 5,
      // });
    await dispatch(fetchAgos(PatientId));
      // onCancel();
      setActiveTabPatient('2'); 
      setActiveTabPatientAntecedent('2-2');
    } catch (err) {
      notification.error({
        message: "Erreur d'enregistrement",
        description: err.message,
        duration: 5,
      });
    }
  };
  

 


  const itemsCollapsePatients = [
    {
      key: '1',
      label: <span style={{fontSize:"16px", }}>Informations générales</span>,
      children:
        <>
          <form>   
          {/* onSubmit={handleSubmiteForm} */}
            {/* <div>Informations générales</div> */}
            <Row gutter={6}>
              <Col span={8}>
                <p className="inputPatient" >Date de création</p>
                <DatePicker
                  allowClear={false}
                  style={{ width: "100%" }}
                  type="date"
                  placeholder="Date de création"
                  name="DateCreation"
                  value={DateCreation ? dayjs(DateCreation) : null} // Convert string to dayjs object
                  onChange={(date, dateString) => setDateCreation(dateString)} // Set the date string
                />
              </Col>
              {/* <Col span={6}>
                    <p className="inputPatient" >N° d’identification</p>
                    <InputText name="NumeroIdentification" value={NumeroIdentification} type="text" placeholder_="N° d’identification" onChange={(e) => setNumeroIdentification(e.target.value)} />
                  </Col> */}
              <Col span={8}>
                <p className="inputPatient" >Prénom</p>
                <InputText required name="Prenom" value={Prenom} type="text" placeholder_="Prénom" onChange={(e) => setPrenom(e.target.value)} />
              </Col>
              <Col span={8}>
                <p className="inputPatient">Nom</p>
                <InputText required name="Nom" value={Nom} type="text" placeholder_=" Nom" onChange={(e) => setNom(e.target.value)}
                />
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={6}>
                <p className="inputPatient">Sexe </p>
                <SelectInput name="Sexe" placeholder_="Sexe" options={SelectSexeOptions} value={Sexe} onChange={handleChangeSexe} />
              </Col>
              <Col span={6}>
                <p className="inputPatient">Date de naissance </p>
                <InputText
                  name="Dob"
                  value={Dob}
                  type="Date"
                  placeholder_="Date de naissance"
                  onChange={handleDobChange}
                />
              </Col>
              <Col span={6}>
                <p className="inputPatient">Age </p>
                <InputText required name="Age" value={Age} type="number" placeholder_="Age" onChange={handleAgeChange}
                />
              </Col>
              <Col span={6}>
                <p className="inputPatient">Médecin responsable </p>
                <SelectInput name="UserId" placeholder_="Médecin responsable"
                  options={medecinOptions}
                  value={UserId} onChange={handleChangeMedcin} />
                {/* <InputText  value={`${userData.user.Prenom} ${userData.user.Nom}`} */}
                {/* /> */}
              </Col>

            </Row>
            <Row gutter={6}>
              <Col span={6}>
                <p className="inputPatient">Adresse habituelle </p>
                <InputText required name="AdresseHabituelle" value={AdresseHabituelle} type="text" placeholder_="Adresse habituelle"
                  onChange={(e) => setAdresseHabituelle(e.target.value)} />
              </Col>
              <Col span={6}>
                <p className="inputPatient">Adresse actuelle </p>
                <InputText required name="AdresseActuelle" value={AdresseActuelle} type="text" placeholder_="Adresse actuelle" onChange={(e) => setAdresseActuelle(e.target.value)}
                />
              </Col>
              <Col span={6}>
                <p className="inputPatient">Téléphone </p>
                <InputText name="Telephone" value={Telephone} onChange={(e) => setTelephone(e.target.value)}
                  type="text"
                  placeholder_="Téléphone"
                />
              </Col>
              <Col span={6}>
                <p className="inputPatient">Email </p>
                <InputText name="Email" value={Email} onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder_="Email"
                />
              </Col>
            </Row>

            {/* Pédiatrie */}
            {((Specialite === "Urologie" && Organe !== "Prostate" && Organe !== "Vessie/Arbre urinaire" && Organe !== "Testicule"
              && Organe !== "Penis" && Organe !== "Surrenale" && Organe !== "Reins"
            ) ||
              (Specialite === "Pédiatrie" && Organe === "Prostate") ||
              (Organe === "Tumeurs cerebrales" ||
                Organe === "Tumeurs orbitaires" ||
                Organe === "Tumeurs maxillo faciales" ||
                Organe === "Tumeurs mediastinales" ||
                Organe === "Ovaire" ||
                Organe === "Voie excretrice" ||
                Organe === "Rein" ||
                Organe === "Testicul/para-testicule" ||
                Organe === "Vessie" ||
                Organe === "Rétropéritoine" ||
                Organe === "Foie/Pédiatrie" ||
                Organe === "OS"
              )) && (
                <>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Profession père </p>
                      <InputText name="ProfesssionPere" value={ProfesssionPere} onChange={(e) => setProfesssionPere(e.target.value)}
                        type="text"
                        placeholder_="Profession père"
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Profession mère </p>
                      <InputText name="ProfesssionMere" value={ProfesssionMere} onChange={(e) => setProfesssionMere(e.target.value)}
                        type="text"
                        placeholder_="Profession mère"
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Scolarisation</p>
                      <SelectInput name="Scolarisation" placeholder_="Scolarisation"
                        options={SelectScolarisationOptions}
                        value={Scolarisation}
                        onChange={handleChangeScolarisation}
                      />
                    </Col>
                  </Row>
                </>
              )}


            {(
              (Specialite === "Digestif" && Organe === "Oesophage") ||
              (Organe !== "Cortex de la glande surrénale" &&
                Organe !== "Ganglion parasympathique" &&
                Organe !== "Ganglion sympathique" &&
                Organe !== "Medullosurrenale" &&
                Organe !== "Parathyroïde" &&
                Organe !== "Oesophage" &&
                Organe !== "Tumeurs cerebrales" &&
                Organe !== "Tumeurs orbitaires" &&
                Organe !== "Tumeurs maxillo faciales" &&
                Organe !== "Tumeurs mediastinales" &&
                Organe !== "Voie excretrice" &&
                Organe !== "Prostate" &&
                Organe !== "Rein" &&
                Organe !== "Testicul/para-testicule" &&
                Organe !== "Vessie" &&
                Organe !== "OS" &&
                Organe !== "Corticosurrenale" &&
                Organe !== "Foie/Pédiatrie" &&
                Organe !== "Rétropéritoine" &&
                Organe !== "T. Sacro-coccygienne" &&
                Organe !== "Estomac" &&
                Organe !== "Ovaires" &&
                Organe !== "Col" &&
                Organe !== "Utérus" &&
                Organe !== "Vagin" &&
                Organe !== "Vulve" &&
                Organe !== "Sein" &&
                Organe !== "Néoplasies trophoblastiques gestationnelles" &&
                Organe !== "Ovaire" &&
                Organe !== "Vessie/Arbre urinaire" &&
                Organe !== "Testicule" &&
                Organe !== "Penis" &&
                Organe !== "Surrenale" &&
                Organe !== "Reins" &&
                Organe !== "Colo-rectum")
            ) && (
                <>
                  <Row gutter={6}>
                    <Col span={6}>
                      <p className="inputPatient">Exposition aux toxiques</p>
                      <SelectInput
                        name="ExpositionToxique"
                        placeholder_="Exposition aux toxiques"
                        options={SelectOuiNon}
                        value={ExpositionToxique}
                        onChange={handleChangeExposition}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="inputPatient">Situation matrimoniale</p>
                      <SelectInput
                        name="SituationMatrimoniale"
                        placeholder_="Situation matrimoniale"
                        options={SelectSituationMatOptions}
                        value={SituationMatrimoniale}
                        onChange={handleChangeSituationMat}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="inputPatient">Désir de fertilité ultérieure</p>
                      <SelectInput
                        name="DesirFertilite"
                        placeholder_="Désir de fertilité ultérieure"
                        options={SelectDesirF}
                        value={DesirFertilite}
                        onChange={handleChangeDesirFertilite}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="inputPatient">Préservation de fertilité proposée</p>
                      <SelectInput
                        name="PreservationFertiliteProposee"
                        placeholder_="Préservation de fertilité proposée"
                        options={SelectPreservation}
                        value={PreservationFertiliteProposee}
                        onChange={handleChangePreservation}
                      />
                    </Col>
                  </Row>
                </>
              )}


            {((["Vessie/Arbre urinaire", "Surrenale", "Reins"].includes(Organe) && Specialite === "Urologie") || (Specialite === "Cancers Rares")) && (
              <Row>
                <Col span={24}>
                  <p className="inputPatient">Exposition aux toxiques</p>
                  <SelectInput
                    name="ExpositionToxique"
                    placeholder_="Exposition aux toxiques"
                    options={SelectOuiNon}
                    value={ExpositionToxique}
                    onChange={handleChangeExposition}
                  />
                </Col>
              </Row>
            )}

            {/* POUR GYNECOLOGIE.OVAIRES COL UTERUS VAGIN VULVE */}
            {(Organe === "Ovaires" ||
              Organe === "Col" ||
              Organe === "Utérus" ||
              Organe === "Vagin" ||
              Organe === "Vulve" ||
              Organe === "Sein"
            ) && (
                <>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Situation matrimoniale</p>
                      <SelectInput
                        name="SituationMatrimoniale"
                        placeholder_="Situation matrimoniale"
                        options={SelectSituationMatOptions}
                        value={SituationMatrimoniale}
                        onChange={handleChangeSituationMat}
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Désir de fertilité ultérieure</p>
                      <SelectInput
                        name="DesirFertilite"
                        placeholder_="Désir de fertilité ultérieure"
                        options={SelectDesirF}
                        value={DesirFertilite}
                        onChange={handleChangeDesirFertilite}
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Préservation de fertilité proposée</p>
                      <SelectInput
                        name="PreservationFertiliteProposee"
                        placeholder_="Préservation de fertilité proposée"
                        options={SelectPreservation}
                        value={PreservationFertiliteProposee}
                        onChange={handleChangePreservation}
                      />
                    </Col>
                  </Row></>
              )}


            {((Specialite === "Urologie" &&
              Organe !== "Prostate" &&
              Organe !== "Vessie/Arbre urinaire" &&
              Organe !== "Testicule" &&
              Organe !== "Penis" &&
              Organe !== "Surrenale" &&
              Organe !== "Reins") ||
              (Specialite === "Pédiatrie" && Organe === "Prostate") ||
              (Organe === "Tumeurs cerebrales" ||
                Organe === "Tumeurs orbitaires" ||
                Organe === "Tumeurs maxillo faciales" ||
                Organe === "Tumeurs mediastinales" ||
                Organe === "Ovaire" ||
                Organe === "Voie excretrice" ||
                Organe === "Rein" ||
                Organe === "Testicul/para-testicule" ||
                Organe === "Vessie" ||
                Organe === "OS" ||
                Organe === "Corticosurrenale"
              )) && (
                <>
                  <Row gutter={6}>
                    <Col span={12}>
                      <p className="inputPatient">Exposition aux toxiques</p>
                      <SelectInput name="ExpositionToxique" placeholder_="Exposition aux toxiques" options={SelectOuiNon} value={ExpositionToxique} onChange={handleChangeExposition} />
                    </Col>
                    <Col span={12}>
                      <p className="inputPatient">Préservation de fertilité proposée</p>
                      <SelectInput name="PreservationFertiliteProposee" placeholder_="Préservation de fertilité proposée" options={SelectPreservation} value={PreservationFertiliteProposee} onChange={handleChangePreservation} />
                    </Col>
                  </Row>
                </>
              )}


            {((Specialite === "Urologie" &&
              (Organe === "Prostate" ||
                Organe === "Vessie/Arbre urinaire" ||
                Organe === "Testicule" ||
                Organe === "Penis" ||
                Organe === "Surrenale" ||
                Organe === "Reins")) ||
              (Organe !== "Néoplasies trophoblastiques gestationnelles" &&
                Organe !== "Foie/Pédiatrie" &&
                Organe !== "Tumeurs cerebrales" &&
                Organe !== "Tumeurs orbitaires" &&
                Organe !== "Tumeurs maxillo faciales" &&
                Organe !== "Ovaire" &&
                Organe !== "Voie excretrice" &&
                Organe !== "Rein" &&
                Organe !== "Testicul/para-testicule" &&
                Organe !== "Vessie" &&
                Organe !== "Rétropéritoine" &&
                Organe !== "OS" &&
                Organe !== "T. Sacro-coccygienne" &&
                Organe !== 'Prostate' &&
                Organe !== "Tumeurs mediastinales")) &&
              (
                <>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Score G8 </p>
                      <InputText name="ScoreG8" value={ScoreG8} type="text" placeholder_="Score G8"
                        onChange={(e) => setScoreG8(e.target.value)} />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Profession </p>
                      <InputText name="Professsion" value={Professsion} onChange={(e) => setProfesssion(e.target.value)}
                        type="text" placeholder_="Profession" />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Centre d’inclusion </p>
                      <SelectInput name="CentreId" placeholder_="Centre d’inclusion" options={centresOptions}
                        value={CentreId} onChange={handleChangeCentre} />
                    </Col>
                  </Row>
                </>
              )}


            {(Specialite === "Urologie" &&
              (Organe === "Prostate" ||
                Organe === "Testicule")
            )
              && (
                <>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Exposition aux toxiques</p>
                      <SelectInput name="ExpositionToxique" placeholder_="Exposition aux toxiques" options={SelectOuiNon} value={ExpositionToxique} onChange={handleChangeExposition} />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Situation matrimoniale</p>
                      <SelectInput
                        name="SituationMatrimoniale"
                        placeholder_="Situation matrimoniale"
                        options={SelectSituationMatOptions}
                        value={SituationMatrimoniale}
                        onChange={handleChangeSituationMat}
                      />
                    </Col>

                    {SituationMatrimoniale === 'Marié(e)'  && (<Col span={8}>
                      <p className="inputPatient">Age de la conjointe </p>
                      <InputText name="AgeConjointe" value={AgeConjointe} type="text" placeholder_="Age de la conjointe" onChange={(e) => setAgeConjointe(e.target.value)}
                      />
                    </Col>)}

                  </Row></>
              )}


            {
            (Specialite === 'Thorax') &&
              (Sexe === "Féminin") &&
            (
                <>
                  <Row gutter={6}>
                    <Col span={12}>
                      <p className="inputPatient">Grossesse évolutive </p>
                       <SelectInput
                        name="GrossesseEvolutive"
                        placeholder_="Grossesse évolutive"
                        options={SelectGrossesseEvoOptions}
                        value={GrossesseEvolutive}
                        onChange={(value) => setGrossesseEvolutive(value)}
                      />
                    </Col>


                   {( GrossesseEvolutive === "oui" &&
                    <Col span={12}>
                      <p className="inputPatient">Terme </p>
                      <InputText name="TermeGrossesseEvolutive" 
                      value={TermeGrossesseEvolutive} 
                      onChange={(e) => setTermeGrossesseEvolutive(e.target.value)}
                        type="text"
                        placeholder_="Terme"
                      />
                    </Col>
                )}


                  </Row>
                </>
              )}

            {/* MODE DE VIE */}
            {(Organe === "Parois thoracique" ||
              Organe === "Plèvre"
            ) && (
                <>
                  <Row gutter={6}>
                    <Col span={12}>
                      <p className="inputPatient">Exposition ancienne à l’amiante++ </p>
                      <InputText name="ExpositionAncienne" value={ExpositionAncienne} onChange={(e) => setExpositionAncienne(e.target.value)}
                        type="text"
                        placeholder_="Exposition ancienne à l’amiante++ "
                      />
                    </Col>
                    <Col span={12}>
                      <p className="inputPatient">Autre </p>
                      <InputText name="PrecisionExpositionToxique" value={PrecisionExpositionToxique} type="text" placeholder_="Autre"
                        onChange={(e) => setPrecisionExpositionToxique(e.target.value)} />
                    </Col>
                  </Row>
                </>
              )}
            {/* MODE DE VIE */}
            {
             (Specialite === 'Thorax') && (
                <>
                  <h4 style={{ marginTop: "9px", marginBottom: "0px" }}>Mode de vie </h4>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Tabagisme actif </p>
                      <SelectInput
                        name="TabagismeActif"
                        placeholder_="Tabagisme actif "
                        options={SelectTabacOptions}
                        value={TabagismeActif}
                        onChange={handleChangeTabacActif}
                      />
                    </Col>


                   {( TabagismeActif === "oui" &&
                    <Col span={8}>
                      <p className="inputPatient">Statut </p>
                      <SelectInput
                        name="StatusTabagismeActif"
                        placeholder_="Statut"
                        options={SelectStatutOptions}
                        value={StatusTabagismeActif}
                        onChange={handleChangeStatut}
                      />
                    </Col>
                )}


                    <Col span={8}>
                      <p className="inputPatient">Tabagisme passif </p>
                      <SelectInput
                        name="TabagismePassif"
                        placeholder_="Tabagisme passif"
                        options={SelectTabacOptions}
                        value={TabagismePassif}
                        onChange={handleChangeTabacPassif}
                      />
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Alcool </p>
                      <SelectInput
                        name="Alcool"
                        placeholder_="Alcool"
                        options={SelectAlcoolOptions}
                        value={Alcool}
                        onChange={handleChangeAlcool}
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Chanvre/Cannabis </p>
                      <SelectInput name="ChanvreCannabis" placeholder_="Chanvre/Cannabis" options={SelectChanvreCannabisOptions} value={ChanvreCannabis} onChange={handleChangeChanvreCannabis} />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Autre </p>
                      <InputText name="AutresModeVie" value={AutresModeVie} type="text" placeholder_="Autre"
                        onChange={(e) => setAutresModeVie(e.target.value)} />
                    </Col>
                  </Row>
                </>
              )}
            {(Organe === "Néoplasies trophoblastiques gestationnelles" ||
              Organe === "T. Sacro-coccygienne"
            ) && (
                <>
                  <Row gutter={6}>
                    <Col span={12}>
                      <p className="inputPatient">Profession </p>
                      <InputText name="Professsion" value={Professsion} onChange={(e) => setProfesssion(e.target.value)}
                        type="text"
                        placeholder_="Profession"
                      />
                    </Col>
                    <Col span={12}>
                      <p className="inputPatient">Centre d’inclusion </p>
                      <SelectInput name="CentreId" placeholder_="Centre d’inclusion" options={centresOptions} value={CentreId} onChange={handleChangeCentre} />
                    </Col>
                  </Row></>
              )}
            {((Specialite === "Urologie" &&
              Organe !== "Prostate" &&
              Organe !== "Vessie/Arbre urinaire" &&
              Organe !== "Testicule" &&
              Organe !== "Penis" &&
              Organe !== "Surrenale" &&
              Organe !== "Reins"
            ) ||
              (Specialite === "Pédiatrie" && Organe === "Prostate") ||
              (Organe === "Foie/Pédiatrie" ||
                Organe === "Tumeurs cerebrales" ||
                Organe === "Tumeurs orbitaires" ||
                Organe === "Tumeurs maxillo faciales" ||
                Organe === "Ovaire" ||
                Organe === "Voie excretrice" ||
                Organe === "Rein" ||
                Organe === "Testicul/para-testicule" ||
                Organe === "Vessie" ||
                Organe === "Rétropéritoine" ||
                Organe === "OS" ||
                Organe === "Tumeurs mediastinales"
              )) && (
                <Row>
                  <Col span={24}>
                    <p className="inputPatient">Centre d’inclusion </p>
                    <SelectInput name="CentreId" placeholder_="Centre d’inclusion" options={centresOptions} value={CentreId} onChange={handleChangeCentre} />
                  </Col>
                </Row>)}
            {(Organe === "Néoplasies trophoblastiques gestationnelles"
            ) && (
                <>
                  <Row gutter={6}>
                    <Col span={12}>
                      <p className="inputPatient">Situation matrimoniale</p>
                      <SelectInput
                        name="SituationMatrimoniale"
                        placeholder_="Situation matrimoniale"
                        options={SelectSituationMatOptions}
                        value={SituationMatrimoniale}
                        onChange={handleChangeSituationMat}
                      />
                    </Col>
                    <Col span={12}>
                      <p className="inputPatient">Désir de fertilité ultérieure</p>
                      <SelectInput
                        name="DesirFertilite"
                        placeholder_="Désir de fertilité ultérieure"
                        options={SelectDesirF}
                        value={DesirFertilite}
                        onChange={handleChangeDesirFertilite}
                      />
                    </Col>
                  </Row></>
              )}
            {/* Statut vaccinal */}
            {(Organe === "Cavum" || Organe === "Nasopharynx") && (
              <Row>
                <Col span={24}>
                  <p className="inputPatient">Statut vaccinal</p>
                  <InputText name="StatutVaccinal" value={StatutVaccinal} onChange={(e) => setStatutVaccinal(e.target.value)}
                    type="text"
                    placeholder_="Statut vaccinal"
                  />
                </Col>
              </Row>
            )}
            {/* POUR GYNECOLOGIE.OVAIRES COL UTERUS VAGIN VULVE */}
            {(Organe === "Ovaires" ||
              Organe === "Col" ||
              Organe === "Utérus" ||
              Organe === "Vagin" ||
              Organe === "Vulve"
            ) && (
                <>
                  <Row>
                    <Col span={24}>
                      <p className="inputPatient">Infection HPV connue</p>
                      <InputText name="InfectionHpvConnu" value={InfectionHpvConnu} onChange={(e) => setInfectionHpvConnu(e.target.value)}
                        type="text"
                        placeholder_="Infection HPV connue"
                      />
                    </Col>
                  </Row>
                </>
              )}
            {(
              (Specialite === "Pédiatrie" && Organe !== "Néoplasmes des cellules histiocytaires/dendritiques" &&
                Organe !== "Oesophage" && Organe !== "Tumeurs mediastinales" &&
                Organe !== "Tumeurs cerebrales" && Organe !== "Tumeurs orbitaires" &&
                Organe !== "Tumeurs maxillo faciales" && Organe !== "Foie/Pédiatrie" &&
                Organe !== "T. Sacro-coccygienne" && Organe !== "Corticosurrenale" &&
                Organe !== "Ovaire" && Organe !== "Voie excretrice" &&
                Organe !== "Prostate" && Organe !== "Rein" &&
                Organe !== "Testicul/para-testicule" && Organe !== "Vessie" &&
                Organe !== "Rétropéritoine" && Organe !== "Colo-rectum" && Organe !== "OS") ||
              (Specialite === "Digestif" && Organe === "Oesophage") ||
              (Specialite === "Cancers Rares") ||
              (Organe === "Anus" ||
                Organe === "Colon et rectum" ||
                //  Organe !== "Estomac" ||
                Organe === "Foie" ||
                Organe === "Pancreas" ||
                Organe === "Voies biliaires" ||
                Organe === "Ovaires" ||
                Organe === "Col" ||
                Organe === "Utérus" ||
                Organe === "Vagin" ||
                Organe === "Vulve" ||
                Organe === "Prostate" ||
                Organe === "Vessie/Arbre urinaire" ||
                // Organe === "Testicule" ||
                Organe === "Penis" ||
                Organe === "Surrenale" ||
                Organe === "Reins" ||
                Organe === "Sein" ||
                Organe === "Oesophage")
            ) && (
                <>
                  <h4 style={{ marginTop: "9px", marginBottom: "0px" }}>Mode de vie</h4>
                  <Row gutter={6}>
                    <Col span={12}>
                      <p className="inputPatient">Alcool</p>
                      <SelectInput
                        name="Alcool"
                        placeholder_="Alcool"
                        options={SelectAlcoolOptions}
                        value={Alcool}
                        onChange={handleChangeAlcool}
                      />
                    </Col>
                    <Col span={12}>
                      <p className="inputPatient">Tabac</p>
                      <SelectInput
                        name="Tabac"
                        placeholder_="Tabac"
                        options={SelectTabacOptions}
                        value={Tabac}
                        onChange={handleChangeTabac}
                      />
                    </Col>
                  </Row>
                </>
              )}
            {((Specialite === "Pédiatrie" && Organe === "Néoplasmes des cellules histiocytaires/dendritiques") ||
              (Specialite === "Hématologie" && Organe === "Néoplasmes des cellules histiocytaires/dendritiques") ||
              (Organe === "LA Adulte" ||
                Organe === "Lymphome Hodgkin adulte" ||
                Organe === "Leucémie Lymphoïde Chronique" ||
                Organe === "Leucémie myéloïde Chronique" ||
                Organe === "Lymphome non hodgkinien adulte" ||
                Organe === "Myélome multiple" ||
                Organe === "Syndromes myélodysplasiques" ||
                Organe === "Syndrome Myéloprolifératif Ph1 négative" ||
                Organe === "Cavum" ||
                Organe === "Nasopharynx" ||
                Organe === "Thyroide" ||
                Organe === "Cavité buccale" ||
                Organe === "Pharynx" ||
                Organe === "Larynx" ||
                Organe === "Cavité nasale" ||
                Organe === "Lévre" ||
                Organe === "Sinus" ||
                Organe === "Testicule" ||
                Organe === "Glandes salivaire")
            ) && (
                <>
                  <h4 style={{ marginTop: "9px", marginBottom: "0px" }}>Mode de vie </h4>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Alcool </p>
                      <SelectInput name="Alcool" placeholder_="Alcool" options={SelectAlcoolOptions} value={Alcool} onChange={handleChangeAlcool} />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Tabac </p>
                      <SelectInput name="Tabac" placeholder_="Tabac" options={SelectTabacOptions} value={Tabac} onChange={handleChangeTabac} />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Chanvre/Cannabis </p>
                      <SelectInput name="ChanvreCannabis" placeholder_="Chanvre/Cannabis" options={SelectChanvreCannabisOptions} value={ChanvreCannabis} onChange={handleChangeChanvreCannabis} />
                    </Col>
                  </Row>
                </>)}
            {/* POUR GYNECOLOGIE.NEOPLASIES TROPHOBLASTIQUE */}
            {Organe === "Néoplasies trophoblastiques gestationnelles" && (
              <Row><h4 style={{ marginTop: "9px", marginBottom: "5px" }}>Mode de vie </h4>
                <Col span={24}>
                  {/* <p className="inputPatient">Mode de vie </p> */}
                  <InputText name="ModVie" value={ModVie} onChange={(e) => setModVie(e.target.value)}
                    type="text"
                    placeholder_="Mode de vie " />
                </Col>
              </Row>)}
            {/* POUR GYNECOLOGIE.OVAIRES COL UTERUS VAGIN VULVE  */}
            <Row gutter={6}>
              {(Organe === "Ovaires" ||
                Organe === "Col" ||
                Organe === "Utérus" ||
                Organe === "Vagin" ||
                Organe === "Vulve"
              ) && (
                  <>
                    <Col span={8}>
                      <p className="inputPatient">Multi partenariat </p>
                      <InputText name="MultiPartenariat" value={MultiPartenariat} onChange={(e) => setMultiPartenariat(e.target.value)}
                        type="text"
                        placeholder_="Multi partenariat"
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Age du premier rapport sexuel</p>
                      <InputText name="AgePremierRapportSexuel" value={AgePremierRapportSexuel} onChange={(e) => setAgePremierRapportSexuel(e.target.value)}
                        type="text"
                        placeholder_="Age du premier rapport sexuel"
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Stress / Environnement social </p>
                      <InputText name="StressEnvironnementSocial" value={StressEnvironnementSocial} onChange={(e) => setStressEnvironnementSocial(e.target.value)}
                        type="text"
                        placeholder_="Stress / Environnement social"
                      />
                    </Col>
                  </>
                )}
            </Row>
            {/* POUR GYNECOLOGIE.SEIN  */}
            <Row gutter={6}>
              {Organe === "Sein" && (
                <>
                  <Col span={12}>
                    <p className="inputPatient">Contraception orale </p>
                    <InputText name="contraceptionOr" value={contraceptionOr} onChange={(e) => setContraceptionOr(e.target.value)}
                      type="text"
                      placeholder_="Contraception orale"
                    />
                  </Col>
                  <Col span={12}>
                    <p className="inputPatient">Stress / Environnement social </p>
                    <InputText name="StressEnvironnementSocial" value={StressEnvironnementSocial} onChange={(e) => setStressEnvironnementSocial(e.target.value)}
                      type="text"
                      placeholder_="Stress / Environnement social"
                    />
                  </Col>
                </>
              )}
            </Row>
            <Row>
              {(
                // Organe === "Pancreas" ||
                Organe === "LA Adulte" ||
                Organe === "Néoplasmes des cellules histiocytaires/dendritiques" ||
                Organe === "Lymphome Hodgkin adulte" ||
                Organe === "Leucémie Lymphoïde Chronique" ||
                Organe === "Leucémie myéloïde Chronique" ||
                Organe === "Lymphome non hodgkinien adulte" ||
                Organe === "Myélome multiple" ||
                Organe === "Syndromes myélodysplasiques" ||
                Organe === "Syndrome Myéloprolifératif Ph1 négative" ||
                Organe === "Néoplasies trophoblastiques gestationnelles" ||
                Organe === "LA" ||
                Organe === "Lymphome" ||
                Organe === "T. Sacro-coccygienne" ||
                Organe === "Voie excretrice" ||
                Organe === "Vessie/Arbre urinaire" ||
                Organe === "Reins" ||
                Organe === "Surrenale" ||
                Organe === "Penis" ||
                Organe === "Testicule"
              )
                && (
                  <>
                    <h4 style={{ marginTop: "9px", marginBottom: "0px" }}>Terrain/comorbidités </h4>
                    <Col span={24}>
                      <p className="inputPatient">Maladies prédisposantes</p>
                      <SelectInput
                        mode="tags"
                        tokenSeparators={[',']}
                        height_="10vh"
                        name="TerrainCommorbidites"
                        value={TerrainCommorbidites}
                        onChange={handleChangeTerrainComorbite}
                        options={TerrainComorbiditesOptions.map(option => ({ value: option, label: option }))}
                        placeholder_="Terrain/comorbidités/ maladies prédisposantes"
                      />
                    </Col>
                  </>
                )}
            </Row>

            {(Organe === "Cavum" || Organe === "Nasopharynx")
              && (
                <>
                  <h4 style={{ marginTop: "9px", marginBottom: "3px" }}>Terrain/comorbidités </h4>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Maladies prédisposantes</p>
                      <SelectInput
                        mode="tags"
                        tokenSeparators={[',']}
                        height_="10vh"
                        name="TerrainCommorbidites"
                        value={TerrainCommorbidites}
                        onChange={handleChangeTerrainComorbite}
                        options={TerrainComorbiditesOptions.map(option => ({ value: option, label: option }))}
                        placeholder_="Terrain/comorbidités/ maladies prédisposantes"
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Facteurs environnementaux</p>
                      <SelectInput
                        mode="tags"
                        tokenSeparators={[',']}
                        height_="10vh"
                        options={FacteursEnvironnementauxCavumNasopharynxOptions.map(option => ({ value: option, label: option }))}
                        name="TerrainCommorbiditesFacteursEnvironnementaux"
                        value={TerrainCommorbiditesFacteursEnvironnementaux}
                        onChange={handleChangeFacteursEnvironnementaux}
                        placeholder_="Facteurs environnementaux"
                      />

                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Facteurs génétiques</p>
                      <SelectInput
                        mode="tags"
                        tokenSeparators={[',']}
                        height_="10vh"
                        options={TerrainComorbiditesOptions.map(option => ({ value: option, label: option }))}
                        name="TerrainCommorbiditesFacteursGenetiques"
                        value={TerrainCommorbiditesFacteursGenetiques}
                        onChange={handleChangeTerrainComorbite}
                        placeholder_="Facteurs génétiques"
                      />
                    </Col>
                  </Row>
                </>
              )}
            {(
              //     (Specialite === "Urologie" && 
              //   Organe !== "Prostate" &&
              //   Organe !== "Vessie/Arbre urinaire" &&
              //   Organe !== "Testicule" &&
              //   Organe !== "Penis" &&
              //   Organe !== "Surrenale" &&
              //   Organe !== "Reins"
              // ) ||
              (Specialite === "Pédiatrie" && Organe === "Prostate") ||
              (Specialite === "Cancers Rares" ) ||
              (Specialite === 'Thorax') ||
              (Organe === "Anus" ||
                Organe === "Colon et rectum" ||
                Organe === "Foie" ||
                // Organe === "Oesophage" || 
                Organe === "Voies biliaires" ||
                Organe === "Ovaires" ||
                Organe === "Col" ||
                Organe === "Utérus" ||
                Organe === "Vagin" ||
                Organe === "Vulve" ||
                Organe === "Sein" ||
                Organe === 'Cavité buccale' ||
                Organe === 'Pharynx' ||
                Organe === 'Larynx' ||
                Organe === 'Cavité nasale' ||
                Organe === 'Lévre' ||
                Organe === 'Sinus' ||
                Organe === 'Glandes salivaire' ||
                Organe === 'Thyroide' ||
                Organe === "Tumeurs cerebrales" ||
                Organe === "Tumeurs orbitaires" ||
                Organe === "Tumeurs maxillo faciales" ||
                Organe === "Tumeurs mediastinales" ||
                Organe === "Foie/Pédiatrie" ||
                Organe === "Ovaire" ||
                Organe === "Rein" ||
                Organe === "Testicul/para-testicule" ||
                Organe === "Vessie" ||
                Organe === "Rétropéritoine" ||
                Organe === "Corticosurrenale" ||
                Organe === "Oesophage" ||
                Organe === "Colo-rectum" ||
                
                // Organe === "Pancreas" ||
                // Organe === "LA Adulte" ||
                // Organe === "Néoplasmes des cellules histiocytaires/dendritiques" ||
                // Organe === "Lymphome Hodgkin adulte" ||
                // Organe === "Leucémie Lymphoïde Chronique" ||
                // Organe === "Leucémie myéloïde Chronique" ||
                // Organe === "Lymphome non hodgkinien adulte" ||
                // Organe === "Myélome multiple" ||
                // Organe === "Syndromes myélodysplasiques" ||
                // Organe === "Syndrome Myéloprolifératif Ph1 négative" ||
                Organe === "Néoplasies trophoblastiques gestationnelles" ||
                Organe === "LA" ||
                Organe === "Lymphome" ||
                // Organe === "T. Sacro-coccygienne" ||
                // Organe === "Voie excretrice" ||
                // Organe === "Vessie/Arbre urinaire" ||
                // Organe === "Reins" ||
                // Organe === "Surrenale" ||
                // Organe === "Penis" ||
                // Organe === "Testicule" ||
                Organe === "OS"
              )) && (
                <>
                  <h4 style={{ marginTop: "9px", marginBottom: "0px" }}>Terrain/comorbidités </h4>
                  <Row>
                    <Col span={24}>
                      <p className="inputPatient">Maladies prédisposantes</p>
                      <SelectInput
                        mode="tags"
                        tokenSeparators={[',']}
                        height_="10vh"
                        name="TerrainCommorbidites"
                        placeholder_="Terrain/comorbidités/ maladies prédisposantes"
                        options={TerrainComorbiditesOptions.map(option => ({ value: option, label: option }))}
                        value={TerrainCommorbidites}
                        onChange={handleChangeTerrainComorbite}
                      />
                    </Col>
                  </Row>
                </>
              )}
            {Organe === "Estomac" && (
              <>
                <h4 style={{ marginTop: "9px", marginBottom: "0px" }}>Terrain/comorbidités </h4>
                <Row gutter={6}>
                  <Col span={8}>
                    <p className="inputPatient">Facteurs environnementaux</p>
                    <SelectInput
                      mode="tags"
                      tokenSeparators={[',']}
                      height_="10vh"
                      name="TerrainCommorbiditesFacteursEnvironnementaux"
                      placeholder_="Facteurs environnementaux"
                      options={SelectFacteursEnvironnementauxOptions.map(option => ({ value: option, label: option }))}
                      value={TerrainCommorbiditesFacteursEnvironnementaux}
                      onChange={handleChangeFacteursEnvironnementaux}
                    />
                  </Col>
                  <Col span={8}>
                    <p className="inputPatient">Facteurs infectieux</p>
                    <SelectInput
                      mode="tags"
                      tokenSeparators={[',']}
                      height_="10vh"
                      name="TerrainCommorbiditesFacteursInfectieux"
                      placeholder_="Facteurs infectieux"
                      options={SelectFacteursInfectieuxOptions.map(option => ({ value: option, label: option }))}
                      value={TerrainCommorbiditesFacteursInfectieux}
                      onChange={handleChangeFacteursInfectieux}
                    />
                  </Col>
                  <Col span={8}>
                    <p className="inputPatient">Facteurs génétiques</p>
                    <SelectInput
                      mode="tags"
                      tokenSeparators={[',']}
                      height_="10vh"
                      name="TerrainCommorbiditesFacteursGenetiques"
                      placeholder_="Facteurs génétiques"
                      options={SelectFacteursGenetiquesOptions.map(option => ({ value: option, label: option }))}
                      value={TerrainCommorbiditesFacteursGenetiques}
                      onChange={handleChangeFacteursGenetiques}
                    />
                  </Col>
                </Row>
              </>
            )}

            

            {/* <BtnSubmit
              type_="button"
              width_="86px"
              float_="right"
              bgNormalColor="linear-gradient(310deg, rgb(33, 212, 253), rgb(33, 82, 255))"
              buttonText="Suivant"
              color="#fff"
              boldColor={400}
              height_="37px"
              border_="none"
              position_="relative"
              top_="13px"
              borderRadius_="0.5rem"
              onClick={handleSubmiteForm}
            /> */}
          </form>
          <br/>
        </>
    },
    {
      key: '2',
      label:  <span style={{fontSize:"16px"}}>Antécédents</span>,
      children: (
        <Tabs  className="tabsCreationPatient" defaultActiveKey="2-1" activeKey={activeTabPatientAntecedent} onChange={setActiveTabPatientAntecedent}>
                
                <Tabs.TabPane key="2-1" tab="Autres antécédents">
                    <>
                    {/* onSubmit={handleSubmitAntecedentPatient} */}
                    <form>
                                    <Row gutter={6}>
                                            <Col span={12} >
                                                    <p className="inputPatient2">Antécédents médicaux</p>
                                                    <SelectInput required
                                                    mode="tags"
                                                    tokenSeparators={[',']}
                                                    height_="12vh"
                                                    name="AntecedentMedical"
                                                    placeholder_="Antécédents médicaux"
                                                    options={AntecedentMedicauxOptions}
                                                    value={AntecedentMedical}
                                                    onChange={handleChangeAntecedentMedicaux}
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <p className="inputPatient2">Antécédents familiaux</p>
                                                    <SelectInput
                                                    required
                                                    mode="tags"
                                                    tokenSeparators={[',']}
                                                    height_="12vh"
                                                    name="AntecedentFamilial"
                                                    placeholder_="Antécédents familiaux"
                                                    options={AntecedentFamilialOptions}
                                                    value={AntecedentFamilial}
                                                    onChange={handleChangeAntecedentFamilial}
                                                    />
                                                </Col>
                                                
                                        </Row>
                                        <Row gutter={6}>
                                        <Col span={12}>
                                                    <p className="inputPatient2">Antécédents chirurgicaux</p>
                                                    <SelectInput
                                                    required
                                                    mode="tags"
                                                    tokenSeparators={[',']}
                                                    height_="12vh"
                                                    name="AntecedentChirurgical"
                                                    placeholder_="Antécédents chirurgicaux"
                                                    options={AntecedentChirurgicalOptions}
                                                    value={AntecedentChirurgical}
                                                    onChange={handleChangeAntecedentChirurgical}
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <p className="inputPatient2">Antécédents périnataux</p>
                                                    <SelectInput
                                                    mode="tags"
                                                    required
                                                    tokenSeparators={[',']}
                                                    height_="12vh"
                                                    name="AntecedentPerinatal"
                                                    placeholder_="Antécédents périnataux"
                                                    options={AntecedentPerinatalOptions}
                                                    value={AntecedentPerinatal}
                                                    onChange={handleChangeAntecedentPerinatal}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                            <Col span={24}>
                                                    <p className="inputPatient2">Antécédents de tuberculose pulmonaire</p>
                                                    <SelectInput
                                                    required
                                                    mode="tags"
                                                    tokenSeparators={[","]}
                                                    height_="12vh"
                                                    name="AntecedentTuberculosePulmonaire"
                                                    placeholder_="Antécédents de tuberculose pulmonaire"
                                                    options={AntecedentTuberculosePOptions}
                                                    value={AntecedentTuberculosePulmonaire}
                                                    onChange={handleChangeAntecedentTuberculoseP}
                                                    />
                                                </Col>
                                            </Row>
                                            
                                                    {/* <BtnSubmit
                                                    type_="button"
                                                    width_="86px"
                                                    float_="right"
                                                    bgNormalColor="linear-gradient(310deg, rgb(33, 212, 253), rgb(33, 82, 255))"
                                                    buttonText="Suivant"
                                                    color="#fff"
                                                    boldColor={400}
                                                    height_="37px"
                                                    border_="none"
                                                    position_="relative"
                                                    top_="13px"
                                                    borderRadius_="0.5rem"
                                                    onClick={handleSubmitAntecedentPatient}
                                                    />
                                                <br/> */}
                    </form>
                    <br/>
                    </>
                </Tabs.TabPane>

                {affichAGO && (
                    <Tabs.TabPane key="2-2" tab="Antécédents gynécologiques et obstétricaux">
                    <>
                    {/* onSubmit={handleSubmitAntecedentAGO} */}
                    <form>
                                    <Row gutter={8}>
                                    <Col span={12}>
                                        <p className='inputPatient2'>
                                        Gestite
                                        </p>
                                        <InputText 
                                        height_="5vh"
                                        required
                                        type="number"
                                        name="Gestite"
                                        value={Gestite}
                                        placeholder_="Gestite"
                                        onChange={(e) => setGestite(e.target.value)}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <p className='inputPatient2'>Parite</p>
                                        <InputText
                                        height_="5vh"
                                        required
                                        type="number"
                                        name="Parite"
                                        value={Parite}
                                        placeholder_="Parite"
                                        onChange={(e) => setParite(e.target.value)}
                                        />
                                    </Col>
                                    </Row>
                                    <Row gutter={8}>
                                    <Col span={12}>
                                        <p className='inputPatient2'>
                                        Nombre d'enfants Vivant
                                        </p>
                                        <InputText
                                        height_="5vh"
                                        required
                                        type="number"
                                        name="EnfantVivant"
                                        value={EnfantVivant}
                                        placeholder_="Nombre d'enfants Vivant"
                                        onChange={(e) => setEnfantVivant(e.target.value)}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <p className='inputPatient2'>Ménopause</p>
                                        <SelectInput
                                        height_="5vh"
                                        required
                                        options={SelectMenopauseOptions}
                                        name="Menopause"
                                        value={Menopause}
                                        placeholder_="Ménopause"
                                        onChange={handleChangeMenopause}
                                        />
                                    </Col>
                                    </Row>
                                    {/* <BtnSubmit
                                    type_="button"
                                    width_="86px"
                                    float_="right"
                                    bgNormalColor="linear-gradient(310deg, rgb(33, 212, 253), rgb(33, 82, 255))"
                                    buttonText="Suivant"
                                    color="#fff"
                                    boldColor={400}
                                    height_="37px"
                                    border_="none"
                                    position_="relative"
                                    top_="13px"
                                    borderRadius_="0.5rem"
                                    onClick={handleSubmitAntecedentAGO}
                                    />
                                    <br/> */}
                    </form>
                    <br/>
                    </>
                </Tabs.TabPane>
                )}   
                
               
        </Tabs>
      ),
    },
    {
      key: '3',
      label: <span style={{fontSize:"16px"}}>Diagnostics</span>,
      children:
            <>
            {/* onSubmit={handleSubmitDiagnostique} */}
                <form >
            <Row gutter={6}>
                <Col span={(Specialite === 'Hématologie' || Specialite === 'Pédiatrie' && Organe === 'Néoplasmes des cellules histiocytaires/dendritiques') ? 24 : 12}>
                    <p className='inputPatient2'>Date du diagnostic</p>
                    <DatePicker name='DateDiagnostic' placeholder="Date du diagnostic" value={dateDiagnostic ? dayjs(dateDiagnostic) : null}
                        onChange={(date, dateString) => {
                          handleChangeDiagnostique(dateString, 'DateDiagnostic');
                            setDateDiagnostic(dateString);
                        }}
                        style={{ width: '100%' }} />
                </Col>
                {Specialite === 'Hématologie' || Specialite === 'Pédiatrie' && Organe === 'Néoplasmes des cellules histiocytaires/dendritiques' ? (
                    <div></div>
                ) : (
                    <Col span={12}>
                        <p className='inputPatient2'>Date de prélèvement</p>

                        <DatePicker name='DatePrelevement' placeholder="Date de prélèvement" value={datePrelevement ? dayjs(datePrelevement) : null}
                            onChange={(date, dateString) => {
                              handleChangeDiagnostique(dateString, 'DatePrelevement');
                                setDatePrelevement(dateString);
                            }}
                            style={{ width: '100%' }} />
                    </Col>
                )}
            </Row>

            {Specialite === 'Pédiatrie' && Organe === 'T. Sacro-coccygienne' && (
                <Row>
                    <Col span={24}>
                        <DatePicker name='DelaiRenduResultat' placeholder="Délai rendu résultat" value={delaiRenduResultat ? dayjs(delaiRenduResultat) : null}
                            onChange={(date, dateString) => {
                              handleChangeDiagnostique(dateString, 'DelaiRenduResultat');
                                setDelaiRenduResultat(dateString);
                            }}
                            style={{ width: '100%' }} />
                    </Col>
                </Row>

            )}

            {Specialite === 'Thorax' && (
                <Row gutter={6}>
                    <Col span={12}>
                        <p className='inputPatient2'>Technique de prélèvemen</p>

                        <Input name='TechniquePrelevement' value={TechniquePrelevement} onChange={(e) => setTechniquePrelevement(e.target.value)} placeholder="Technique de prélèvement" />
                    </Col>
                    <Col span={12}>
                        <p className='inputPatient2'>État général du patient</p>
                        <Select name='EtatGeneralPatient' value={EtatGeneralPatient} onChange={(value) => setEtatGeneralPatient(value)} style={{ width: "470px" }}>
                            <Option value="PS OMS">PS OMS</Option>
                            <Option value="IMC">IMC</Option>
                            <Option value="SC">SC</Option>
                        </Select>

                    </Col>
                </Row>
            )}

            {Specialite === 'Thorax' ? (
                <div></div>
            ) : (
                <div>
                    {Specialite === 'Hématologie' && Organe === 'Leucémie myéloïde Chronique' ? (
                        <>
                            <p style={{ fontWeight: "650" }}>Moyen diagnostique</p>
                            <Row gutter={6}>
                                {/* Affiche Médullogramme */}
                                <Col span={5}>
                                    <p style={{ fontWeight: "600" }}>Médullogramme</p>
                                    <Select
                                        name='MoyenDiagnosticMedullogramme'
                                        value={moyenDiagnosticMedullogramme}
                                        onChange={(value) => {
                                            setMoyenDiagnosticMedullogramme(value);
                                            updateMoyensDiagnostiques('Medullogramme', value);
                                        }}
                                        style={{ width: "185px" }}
                                    >
                                        <Option value="Chronique">Chronique</Option>
                                        <Option value="Accélérée">Accélérée</Option>
                                        <Option value="Acuitisée">Acuitisée</Option>
                                    </Select>
                                </Col>

                                {/* Affiche BCR-ABL */}
                                <Col span={5}>
                                    <p style={{ fontWeight: "600" }}>BCR-ABL</p>
                                    <Input
                                        name='MoyenDiagnosticBCRABL'
                                        placeholder='BCR-ABL'
                                        value={MoyenDiagnosticBCRABL}
                                        onChange={(e) => {
                                            setMoyenDiagnosticBCRABL(e.target.value);
                                            updateMoyensDiagnostiques('BCR_ABL', e.target.value);
                                        }}
                                    />
                                </Col>

                                {/* Affiche Cytogénétique */}
                                <Col span={4.5}>
                                    <p style={{ fontWeight: "600" }}>Cytogénétique</p>
                                    <Select
                                        name='MoyenDiagnosticCytologie'
                                        value={moyenDiagnosticCytologie}
                                        onChange={(value) => {
                                            setMoyenDiagnosticCytologie(value);
                                            updateMoyensDiagnostiques('Cytogenetique', value);
                                        }}
                                        style={{ width: "170px" }}
                                    >
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>

                                {/* Affiche t(9 ;22) */}
                                <Col span={5}>
                                    <p style={{ fontWeight: "600" }}>t(9 ;22)</p>
                                    <Select
                                        name='CytogenetiqueT'
                                        value={CytogenetiqueT}
                                        onChange={(value) => {
                                            setCytogenetiqueT(value);
                                            updateMoyensDiagnostiques('CytogenetiqueT', value);
                                        }}
                                        style={{ width: "190px" }}
                                    >
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>

                                {/* Affiche ACA */}
                                <Col span={4}>
                                    <p style={{ fontWeight: "600" }}>ACA</p>
                                    <Select
                                        name='CytogenetiqueAca'
                                        value={CytogenetiqueAca}
                                        onChange={(value) => {
                                            setCytogenetiqueAca(value);
                                            updateMoyensDiagnostiques('CytogenetiqueAca', value);
                                        }}
                                        style={{ width: "170px" }}
                                    >
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </>
                    ) : (Specialite === 'Hématologie' && Organe === 'Syndromes myélodysplasiques') ? (
                        <Row>
                            <Col span={24}>
                                <p className='inputPatient2'>Moyen diagnostique</p>

                                <Select
                                    mode="tags"
                                    value={MoyenDiagnostic}
                                    onChange={handleSelectChange}
                                    style={{ width: "100%", height: "33px" }}
                                >
                                    {optionMoyenDiagnostic.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            {/* Affichage des checkboxes si les conditions sont remplies */}
                            {AfficheChexbox29 && (
                                <Col span={24} style={{ marginTop: "20px" }}>
                                    <Checkbox
                                        checked={moyenDiagnosticMedullogramme === true}
                                        onChange={() => handleCheckboxMedullogramme(true)}>
                                        Oui
                                    </Checkbox>
                                    <Checkbox
                                        checked={moyenDiagnosticMedullogramme === false}
                                        onChange={() => handleCheckboxMedullogramme(false)}>
                                        Non
                                    </Checkbox>
                                </Col>
                            )}

                            {AfficheChexbox30 && (
                                <Col span={24} style={{ marginTop: "20px" }}>
                                    <Checkbox
                                        checked={moyenDiagnosticCytogenetique === true}
                                        onChange={() => handleCheckboxCytogenetique(true)}>
                                        Oui
                                    </Checkbox>
                                    <Checkbox
                                        checked={moyenDiagnosticCytogenetique === false}
                                        onChange={() => handleCheckboxCytogenetique(false)}>
                                        Non
                                    </Checkbox>
                                </Col>
                            )}

                            {AfficheChexbox31 && (
                                <Col span={24} style={{ marginTop: "20px" }}>
                                    <Checkbox
                                        checked={moyenDiagnosticBiopsieOsteomedullaire === true}
                                        onChange={() => handleCheckboxBiopsie(true)}>
                                        Oui
                                    </Checkbox>
                                    <Checkbox
                                        checked={moyenDiagnosticBiopsieOsteomedullaire === false}
                                        onChange={() => handleCheckboxBiopsie(false)}>
                                        Non
                                    </Checkbox>
                                </Col>
                            )}

                            {/* Affiche le champ de texte si "Oui" est coché */}
                            {(moyenDiagnosticMedullogramme === true || moyenDiagnosticCytogenetique === true || moyenDiagnosticBiopsieOsteomedullaire === true) && (
                                <Row>
                                    <Col span={24}>
                                        <p>Décrire le résultat ici</p>
                                        <TextArea
                                            name='MoyenDiagnosticCytologieResultats'
                                            value={moyenDiagnosticCytologieResultats || moyenDiagnosticMedullogramme || moyenDiagnosticBiopsieOsteomedullaire}
                                            onChange={handleChangeResultat}
                                            placeholder="Décrire ici..."
                                            style={{ width: '950px' }}
                                        />
                                    </Col>
                                </Row>

                            )}
                        </Row>)
                        : (
                            <Row>
                                <Col span={24}>
                                    <p className='inputPatient2'>Moyen diagnostique</p>

                                    <Select
                                        mode="tags"
                                        value={MoyenDiagnostic}
                                        onChange={handleSelectChange}
                                        style={{ width: "100%", height: "33px", }}
                                    >
                                        {optionMoyenDiagnostic.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                {AfficheChexbox && (
                                    <Col span={24} style={{ marginTop: "20px" }}>
                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('Oui')}
                                            onChange={() => handleCheckboxChange('Oui')}
                                        >
                                            Oui
                                        </Checkbox>
                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('Non')}
                                            onChange={() => handleCheckboxChange('Non')}
                                        >
                                            Non
                                        </Checkbox>

                                    </Col>
                                )}

                                {AfficheChexbox6 && (
                                    <Col span={24} style={{ marginTop: "20px" }}>
                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('Matutes 4-5')}
                                            onChange={() => handleCheckboxChange('Matutes 4-5')}
                                        >
                                            Matutes 4-5
                                        </Checkbox>
                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('Matutes 3')}
                                            onChange={() => handleCheckboxChange('Matutes 3')}
                                        >
                                            Matutes 3
                                        </Checkbox>

                                    </Col>
                                )}
                                {AfficheChexbox20 && (
                                    <Col span={24} style={{ marginTop: "20px" }}>

                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('AFP')}
                                            onChange={() => handleCheckboxChange('AFP')}
                                        >
                                            AFP
                                        </Checkbox>
                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('HCG')}
                                            onChange={() => handleCheckboxChange('HCG')}
                                        >
                                            HCG
                                        </Checkbox>

                                    </Col>
                                )}

                                {AfficheChexbox21 && (
                                    <Col span={24} style={{ marginTop: "20px" }}>
                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('pièce d’exérèse')}
                                            onChange={() => handleCheckboxChange('pièce d’exérèse')}
                                        >
                                            pièce d’exérèse
                                        </Checkbox>

                                    </Col>
                                )}

                                {MoyenDiagnostic.includes('Oui') && (
                                    <Row>
                                        <p>Décrire le résultat ici</p>
                                        <Col span={24} >
                                            <TextArea name='MoyenDiagnosticCytologieResultats' value={moyenDiagnosticCytologieResultats} onChange={(e) => setMoyenDiagnosticCytologieResultats(e.terget.value)} placeholder="Décrire ici..." style={{ width: '950px' }} />
                                        </Col>
                                    </Row>
                                )}
                            </Row>
                        )}

                    {Specialite === 'Pédiatrie' && Organe === 'Rétropéritoine' && (
                        <Row gutter={6}>
                            <Col span={12}>
                                <p className='inputPatient2'>Catécholamines urinaires</p>
                                <Select
                                    mode="tags"
                                    value={CatecholaminesUrinaires}
                                    onChange={(value) => setCatecholaminesUrinaires(value)}
                                    style={{ width: "100%", height: "33px", }}
                                >
                                    {optionCatecholaminesUrinaires.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>                                </Col>
                            <Col span={12}>
                                <p className='inputPatient2'>
                                    Biopsie
                                </p>
                                <Select mode='tags'
                                    name='Biopsie'
                                    value={Biopsie}
                                    onChange={(value) => setBiopsie(value)}

                                    style={{ width: "100%", height: "33px", }}>
                                    <Option value="Biopsie échoguidée">Biopsie échoguidée</Option>
                                    <Option value="biopsie chirurgicale">Biopsie chirurgicale</Option>

                                </Select>
                            </Col>
                        </Row>
                    )}
                    <Row gutter={6}>
                        <Col span={((Specialite === 'Digestif' && Organe === 'Estomac') || (Specialite === 'Hématologie') || (Specialite === 'Urologie') ||
                            (Specialite === 'Gynécologie')) ? 8 : 12}>
                            <p className='inputPatient2'>PS OMS</p>

                            <Input name='PsOms' value={psOms} onChange={(e) => setPsOms(e.target.value)} placeholder="PS OMS" />

                        </Col>
                        {((Specialite === 'Digestif' && Organe === 'Estomac') ||
                            Specialite === 'Gynécologie' || Specialite === 'Hématologie' || (Specialite === 'Urologie') || (Specialite === 'ORL')) && (
                                <>
                                    <Col span={Specialite === 'ORL' && (Organe === 'Cavum' || Organe === 'Nasopharynx' || Organe === 'Cavité buccale' || Organe === 'Thyroide' || Organe === 'Pharynx' || Organe === 'Larynx' || Organe === 'Cavité nasale' || Organe === 'Lévre' || Organe === 'Sinus' || Organe === 'glandes salivaire') ? 12 : 8}>
                                        <p className='inputPatient2'>Poids</p>

                                        <Input name='Poids' value={poids} onChange={(e) => setPoids(e.target.value)} placeholder="Poids" />

                                    </Col>
                                    <Col span={Specialite === 'ORL' && (Organe === 'Cavum' || Organe === 'Nasopharynx' || Organe === 'Cavité buccale' || Organe === 'Pharynx' || Organe === 'Larynx' || Organe === 'Cavité nasale' || Organe === 'Lévre' || Organe === 'Sinus' || Organe === 'glandes salivaire' || Organe === 'Thyroide') ? 12 : 8}>
                                        <p className='inputPatient2'>Taille</p>

                                        <Input name='Taille' value={taille} onChange={(e) => setTaille(e.target.value)} placeholder="Taille" />

                                    </Col>
                                </>
                            )}

                        {(Specialite === 'Pédiatrie') && (
                            <>
                                <Col span={12}>
                                    <p className='inputPatient2'>Poids/âge</p>

                                    <Input name='PoidsAge' value={poidsAge} onChange={(e) => setPoidsAge(e.target.value)} placeholder="Poids" />

                                </Col>
                                <Col span={12}>
                                    <p className='inputPatient2'>Taille/âge</p>

                                    <Input name='TailleAge' value={tailleAge} onChange={(e) => setTailleAge(e.target.value)} placeholder="Taille" />

                                </Col>
                            </>
                        )}


                        {(Specialite === 'Pédiatrie') ? (
                            <div></div>
                        ) : (

                            <Col span={((Specialite === 'Digestif' && Organe === 'Estomac') || (Specialite === 'Hématologie') ||
                                (Specialite === 'Gynécologie') || (Specialite === 'Urologie')) ? 24 : 12}>
                                <p className='inputPatient2'>IMC/SC</p>

                                <Input name='ImcSc' value={imcSc} onChange={(e) => setImcSc(e.target.value)} placeholder="IMC/SC" />

                            </Col>
                        )}
                        {(Specialite === 'Urologie' || Specialite === 'Cancers Rares' || Specialite === 'Pédiatrie' || Specialite === 'Hématologie' && (Organe === 'Lymphome Hodgkin adulte' || Organe === 'LA Adulte' || Organe === 'Lymphome non hodgkinien adulte')) && (
                            <>
                                <Col
                                    span={
                                        (Specialite === 'Pédiatrie' && Organe !== 'Néoplasmes des cellules histiocytaires/dendritiques') ||
                                            Specialite === 'Cancers Rares' ? 12 :
                                            (Specialite === 'Hématologie' && Organe === 'Lymphome non hodgkinien adulte') ||
                                                (Specialite === 'Pédiatrie' && Organe === 'Néoplasmes des cellules histiocytaires/dendritiques') ? 24 :
                                                8
                                    }
                                >
                                    <p className='inputPatient2'>Rapport poids/taille</p>
                                    <Input
                                        name='PoidsTaille'
                                        value={poidsTaille}
                                        onChange={(e) => setPoidsTaille(e.target.value)}
                                        placeholder="Rapport poids/taille"
                                    />
                                </Col>

                                <Col
                                    span={
                                        ((Specialite === 'Hématologie' && Organe === 'Lymphome non hodgkinien adulte') ||
                                            Specialite === 'Cancers Rares') ? 12 :
                                            Specialite === 'Pédiatrie' ? 24 :
                                                8
                                    }
                                >
                                    <p className='inputPatient2'>IMC/âge </p>
                                    <Input
                                        name='ImcAge'
                                        value={imcAge}
                                        onChange={(e) => setImcAge(e.target.value)}
                                        placeholder="IMC/âge"
                                    />
                                </Col>
                                {Specialite === 'Pédiatrie' ? (
                                    <div></div>
                                ) : (Specialite === 'Hématologie' && (Organe === 'Lymphome Hodgkin adulte' || Organe === 'Syndrome Myéloprolifératif Ph1 négative' || Organe === 'Syndromes myélodysplasiques' || Organe === 'LA Adulte')) && (
                                    <Col span={8}>
                                        <p className='inputPatient2'>SC</p>
                                        <Input name='Sc' value={Sc} onChange={(e) => setSc(e.target.value)} placeholder="SC" />
                                    </Col>
                                )}
                            </>
                        )}

                    </Row>
                </div>)}

        <Divider orientation="center" plain style={{ marginTop: "30px", marginBottom:"-3px" }}> <p style={{fontSize:"16px"}}>Signes physiques</p> </Divider>

            {((Specialite === 'Digestif' && Organe === 'Estomac' || Organe === 'Oesophage')
                || Specialite === 'Pédiatrie' || Specialite === 'Cancers Rares' ||
                (Specialite === 'Gynécologie') || (Specialite === 'Hématologie') || (Specialite === 'Urologie') || (Specialite === 'Thorax') || Specialite === 'ORL') && (
                    <Row>

                        <Col span={24}>
                          <div style={{fontSize:"16px", marginBottom:"11px"}}><strong>Signes physiques</strong></div>  

                            <Select
                                mode="tags"
                                value={SignesPhysiques}
                                onChange={(value) => setSignesPhysiques(value)}
                                style={{ width: "100%", height: "10vh", }}
                            >
                                {optionSignesPhysiques.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>

                        </Col>
                    </Row>

                )}

            {Specialite === 'Pédiatrie' && Organe === 'Rétropéritoine' && (
                <Row gutter={6}>
                    <Col span={12}>
                        <p className='inputPatient2'>TA</p>
                        <Select mode='tags' name='TA' value={TA} onChange={(value) => setTA(value)}
                            style={{ width: "100%", height: "33px", }}>

                        </Select>                                </Col>
                    <Col span={12}>
                        <p className='inputPatient2'>
                            Endoscopie digestive
                        </p>
                        <Select mode='tags' value={endoscopieDigestive} onChange={(value) => setEndoscopieDigestive(value)}
                            style={{ width: "100%", height: "33px", }}>

                        </Select>
                    </Col>
                </Row>
            )}

            {(Specialite === 'Pédiatrie' && Organe === 'Tumeurs cerebrales' || Organe === 'Tumeurs maxillo faciales') && (

                <Row gutter={6}>
                    {Specialite === 'Pédiatrie' && (Organe === 'Tumeurs orbitaires' || Organe === 'Tumeurs médiastinales') && (

                        <Col span={12}>
                            <p className='inputPatient2'>Siege</p>
                            <Input name='Siege' value={siege} onChange={(e) => setSiege(e.target.value)} placeholder='Siege' />
                        </Col>
                    )}


                    <Col span={12}>
                        <p className='inputPatient2'>Taille</p>
                        <Input name='Taille' value={taille} onChange={(e) => setTaille(e.target.value)} placeholder='Taille' />
                    </Col>
                </Row>
            )}
<Divider orientation="center" plain style={{ marginTop: "30px", marginBottom:"-3px" }}> <p style={{fontSize:"15px"}}>Signes cliniques</p> </Divider>
            {(Specialite === 'Digestif' && (Organe === 'Estomac' || Organe === 'Oesophage')
                || Specialite === 'Pédiatrie' || Specialite === 'Cancers Rares' ||
                (Specialite === 'Gynécologie') || (Specialite === 'Hématologie') || (Specialite === 'Urologie') || Specialite === 'Thorax' || Specialite === 'ORL') ? (
                <></>
            )
                : (

                    <Row>
                        <Col>
                        <div style={{fontSize:"16px", marginBottom:"11px"}}><strong>Signes cliniques</strong></div> 

                            {(Specialite === 'Digestif' && Organe === 'Anus') ? (
                                <>
                                    <Checkbox
                                        checked={SignesCliniques.includes('STENOSE')}
                                        onChange={(e) => handleCheckboxChangeClinique('STENOSE', e.target.checked)}
                                    >
                                        STENOSE
                                    </Checkbox>
                                    <Checkbox
                                        checked={SignesCliniques.includes('INCONTINENCE')}
                                        onChange={(e) => handleCheckboxChangeClinique('INCONTINENCE', e.target.checked)}
                                    >
                                        INCONTINENCE
                                    </Checkbox>
                                </>
                            ) : (Specialite === 'Digestif' && Organe === 'Voies biliaires') ? (
                                <>
                                    <Checkbox
                                        checked={SignesCliniques.includes('ictère')}
                                        onChange={(e) => handleCheckboxChangeClinique('ictère', e.target.checked)}
                                    >
                                        ictère
                                    </Checkbox>
                                    <Checkbox
                                        checked={SignesCliniques.includes('douleur')}
                                        onChange={(e) => handleCheckboxChangeClinique('douleur', e.target.checked)}
                                    >
                                        douleur
                                    </Checkbox>
                                    <Checkbox
                                        checked={SignesCliniques.includes('masse')}
                                        onChange={(e) => handleCheckboxChangeClinique('masse', e.target.checked)}
                                    >
                                        masse
                                    </Checkbox>
                                </>
                            ) : (Specialite === 'Digestif' && Organe === 'Colon et rectum') ? (
                                <>
                                    <Checkbox
                                        checked={SignesCliniques.includes('Occlusion')}
                                        onChange={(e) => handleCheckboxChangeClinique('Occlusion', e.target.checked)}
                                    >
                                        Occlusion
                                    </Checkbox>
                                </>
                            ) : (Specialite === 'Digestif' && Organe === 'Pancreas') ? (
                                <>
                                    <Checkbox
                                        checked={SignesCliniques.includes('Ictère')}
                                        onChange={(e) => handleCheckboxChangeClinique('Ictère', e.target.checked)}
                                    >
                                        Ictère
                                    </Checkbox>
                                    <Checkbox
                                        checked={SignesCliniques.includes('Sténose digestive')}
                                        onChange={(e) => handleCheckboxChangeClinique('Sténose digestive', e.target.checked)}
                                    >
                                        Sténose digestive
                                    </Checkbox>
                                    <Checkbox
                                        checked={SignesCliniques.includes('douleur')}
                                        onChange={(e) => handleCheckboxChangeClinique('douleur', e.target.checked)}
                                    >
                                        douleur
                                    </Checkbox>
                                    <Checkbox
                                        checked={SignesCliniques.includes('masse')}
                                        onChange={(e) => handleCheckboxChangeClinique('masse', e.target.checked)}
                                    >
                                        masse
                                    </Checkbox>
                                </>
                            ) : (Specialite === 'Digestif' && Organe === 'Foie') ? (
                                <>
                                    <Checkbox
                                        checked={SignesCliniques.includes('HTP')}
                                        onChange={(e) => handleCheckboxChangeClinique('HTP', e.target.checked)}
                                    >
                                        HTP
                                    </Checkbox>
                                </>
                            ) : null}
                        </Col>
                    </Row>
                )}

                <Divider orientation="center" plain style={{ marginTop: "30px", marginBottom:"-3px" }}> <p style={{fontSize:"15px"}}>Signes paracliniques</p> </Divider>
                    <div style={{fontSize:"15px", marginBottom:"11px"}}><strong>Signes paracliniques – Bilan d’évaluation Malade</strong></div>
                    
                    <Row gutter={8}>
                            <Col span={6}>
                                <p className='inputPatient2'>Date</p>
                                <DatePicker placeholder="date"
                                    onChange={handleDateChangeExamClinique}
                                    value={examParaClinique.date ? dayjs(examParaClinique.date) : null}
                                    style={{ width: '100%' }} />
                            </Col>
                            <Col span={6}>
                                <p className='inputPatient2'>Type</p>
                                <Select mode='tags'
                                    onChange={handleChangeExamParaClinique}
                                    value={examParaClinique.type}
                                    style={{ width: '100%' }}>
                                </Select>
                            </Col>
                            <Col span={6}>
                                <p className='inputPatient2'>Resultat</p>
                                <TextArea
                                    placeholder="Décrire ici..."
                                    onChange={handleChangeResultatExamParaClinique}
                                    value={examParaClinique.resultat}
                                    style={{ width: '100%', height: "33px" }}
                                />
                            </Col>
                            <Col span={6} style={{ alignItems: 'center', marginTop: "33px", }}>
                                <Button type="button" onClick={handleAdd}>
                                    Ajouter
                                </Button>
                            </Col>

                            <ul style={{ marginLeft: "50px" }}>
                                {SignesParacliniques.map((item, index) => (
                                    <li key={index}>
                                        <strong>Date :</strong> {item.date},
                                        <strong> Type :</strong> {item.type},
                                        <strong> Résultat :</strong> {item.resultat}
                                    </li>
                                ))}
                            </ul>
                            
                        </Row>
                


            {(Specialite === 'Cancers Rares' && Organe === 'Parathyroïde') && (
                <Row guter={6}>
                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Bilan d’évaluation du patient</p>
                        <Select mode='tags'
                            name='BilanEvaluationPatient'
                            value={BilanEvaluationPatient}
                            onChange={(value) => setBilanEvaluationPatient(value)}
                            style={{ width: "470px" }}>

                        </Select>
                    </Col>                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Bilan d’évaluation de la maladie</p>
                        <Select mode='tags'
                            name='BilanEvaluationMalade'
                            value={BilanEvaluationMalade}
                            onChange={(value) => setBilanEvaluationMalade(value)}
                            style={{ width: "470px" }}>
                            <Option value="Calcémie">Calcémie</Option>
                            <Option value="Parathormone (PTH)">Parathormone (PTH)</Option>
                            <Option value="PAL">PAL</Option>
                            <Option value="LDH">LDH</Option>
                            <Option value="Phosphoremie">Phosphoremie</Option>
                        </Select>
                    </Col>
                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Bilan pré thérapeutique </p>
                        <Select mode='tags'
                            name='bilanPreTherapeutique'
                            value={bilanPreTherapeutique}
                            onChange={(value) => setBilanPreTherapeutique(value)}
                            style={{ width: "950px" }}>

                        </Select>
                    </Col>
                </Row>

            )}

            {(Specialite === 'Cancers Rares' && Organe === 'Cortex de la glande surrénale') && (
                <Row gutter={6}>
                    <Col span={8}>
                        <p style={{ fontWeght: "650px" }}>Bilan d’évaluation du patient </p>
                        <Select mode='tags'
                            name='BilanEvaluationPatient'
                            value={BilanEvaluationPatient}
                            onChange={(value) => setBilanEvaluationPatient(value)}
                            style={{ width: "310px" }}>

                        </Select>                    </Col>
                    <Col span={8}>
                        <p style={{ fontWeght: "650px" }}>Bilan d’évaluation de la maladie</p>
                        <Select mode='tags'
                            name='BilanEvaluationMalade'
                            value={BilanEvaluationMalade}
                            onChange={(value) => setBilanEvaluationMalade(value)}
                            style={{ width: "310px" }}>

                        </Select>
                    </Col>
                    <Col span={8}>
                        <p style={{ fontWeght: "650px" }}>Glucocorticoïdes</p>
                        <Select mode='tags'
                            name='Glucocorticoïdes'
                            value={Glucocorticoïdes}
                            onChange={(value) => setGlucocorticoïdes(value)}
                            style={{ width: "310px" }}>
                            <Option value="cortisol +/-  test de freinage à la dexaméthasone">cortisol +/-  test de freinage à la dexaméthasone</Option>
                        </Select>
                    </Col>
                    <Col span={8}>
                        <p style={{ fontWeght: "650px" }}>Minéralocorticoïdes </p>
                        <Select mode='tags'
                            name='Minéralocorticoïdes'
                            value={Minéralocorticoïdes}
                            onChange={(value) => setMinéralocorticoïdes(value)}
                            style={{ width: "310px" }}>
                            <Option value="aldostérone">aldostérone</Option>
                            <Option value="rénine">rénine</Option>

                        </Select>
                    </Col>
                    <Col span={8}>
                        <p style={{ fontWeght: "650px" }}>Stéroïdes sexuels </p>
                        <Select mode='tags'
                            name='SteroideSexuel'
                            value={SteroideSexuel}
                            onChange={(value) => setSteroideSexuel(value)}
                            style={{ width: "310px" }}>
                            <Option value="testostérone">testostérone</Option>
                            <Option value="estradiol">estradiol</Option>
                            <Option value="leurs précurseurs (DHEAS, androstènedione, 17 hydroxy-progestérone…)">leurs précurseurs (DHEAS, androstènedione, 17 hydroxy-progestérone…)</Option>

                        </Select>
                    </Col>

                    <Col span={8}>
                        <p style={{ fontWeght: "650px" }}>Bilan pré thérapeutique </p>
                        <Select mode='tags'
                            name='bilanPreTherapeutique'
                            value={bilanPreTherapeutique}
                            onChange={(value) => setBilanPreTherapeutique(value)}
                            style={{ width: "310px" }}>

                        </Select>
                    </Col>
                </Row>

            )}

            {(Specialite === 'Cancers Rares' && (Organe === 'Medullosurrenale' || Organe === 'Ganglion parasympathique' || Organe === 'Ganglion sympathique')) && (
                <Row gutter={6}>
                    <Col>
                        <p style={{ fontWeght: "650px" }}>Bilan d’évaluation du patient </p>
                        <Select mode='tags'
                            name='BilanEvaluationPatient'
                            value={BilanEvaluationPatient}
                            onChange={(value) => setBilanEvaluationPatient(value)}
                            style={{ width: "470px" }}>

                        </Select>                    </Col>
                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Bilan d’évaluation de la maladie</p>
                        <Select mode='tags'
                            name='BilanEvaluationMalade'
                            value={BilanEvaluationMalade}
                            onChange={(value) => setBilanEvaluationMalade(value)}
                            style={{ width: "470px" }}>

                        </Select>
                    </Col>
                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Dosage des métanéphrines libres plasmatiques</p>
                        <Input
                            name="metanephrinesPlasma"
                            placeholder="Dosage des métanéphrines plasmatique (ng/mL)"
                            value={metanephrinesPlasma}
                            onChange={(e) => {
                                setMetanephrinesPlasma(e.target.value);
                                Biologie('metanephrinesPlasma', e.target.value);
                            }}

                        />
                    </Col>

                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Dosage des normétanéphrines libres plasmatiques</p>
                        <Input
                            name="normetanephrinesPlasma"
                            placeholder="Dosage des normétanéphrines plasmatique (ng/mL)"
                            value={normetanephrinesPlasma}
                            onChange={(e) => {
                                setNormetanephrinesPlasma(e.target.value);
                                Biologie('normetanephrinesPlasma', e.target.value);
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Dosage des métanéphrines dans les urines de 24h</p>
                        <Input
                            name="metanephrinesUrine"
                            placeholder="Dosage des métanéphrines dans les urines (ng/24h)"
                            value={metanephrinesUrine}
                            onChange={(e) => {
                                setMetanephrinesUrine(e.target.value);
                                Biologie('metanephrinesUrine', e.target.value);
                            }} />
                    </Col>

                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Dosage des normétanéphrines dans les urines de 24h</p>
                        <Input
                            name="normetanephrinesUrine"
                            placeholder="Dosage des normétanéphrines dans les urines (ng/24h)"
                            value={normetanephrinesUrine}
                            onChange={(e) => {
                                setNormetanephrinesUrine(e.target.value);
                                Biologie('normetanephrinesUrine', e.target.value);
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Créatininurie des 24 heures</p>
                        <Input
                            name="creatininurie24h"
                            placeholder="Créatininurie des 24h (mg/24h)"
                            value={creatininurie24h}
                            onChange={(e) => {
                                setCreatininurie24h(e.target.value);
                                Biologie('creatininurie24h', e.target.value);
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Dosage de la chromogranine A dans le sang</p>
                        <Input
                            name="chromogranineA"
                            placeholder="Dosage de la chromogranine A (ng/mL)"
                            value={chromogranineA}
                            onChange={(e) => {
                                setChromogranineA(e.target.value);
                                Biologie('chromogranineA', e.target.value);
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Taux de potassium </p>
                        <Input
                            name="tauxPotassium"
                            placeholder="Taux de potassium "
                            value={tauxPotassium}
                            onChange={(e) => {
                                setTauxPotassium(e.target.value);
                                Biologie('tauxPotassium', e.target.value);
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Glycémie</p>
                        <Input
                            name="glycemie"
                            placeholder="Glycémie"
                            value={glycemie}
                            onChange={(e) => {
                                setGlycemie(e.target.value);
                                Biologie('glycemie', e.target.value);
                            }}
                        />
                    </Col>


                </Row>

            )}

            {Specialite === 'Pédiatrie' && Organe === 'Rétropéritoine' && (
                <Row gutter={6}>
                    <Col span={24}>
                        <p style={{ fontWeight: "950px" }}>Marqueurs</p>
                        <Select mode='tags' name='TA' value={MarqueursTumoraux} onChange={(value) => setMarqueursTumoraux(value)}
                            style={{ width: "950px", height: "33px", }}>
                            {optionMarqueursTumoraux.map((signe) => (
                                <Option key={signe} value={signe}>{signe}</Option>
                            ))}

                        </Select>                                </Col>

                </Row>
            )}

            {(Specialite === 'Pédiatrie' && Organe === 'Corticosurrenale') && (
                <Row>
                    <Col span={24}>
                        <p className='inputPatient2'>Stade</p>
                        <Select
                            mode="tags"
                            value={StadeClinique}
                            onChange={(value) => setStadeClinique(value)}
                            style={{ width: '100%' }}
                        >
                            {optionStade.map((stade) => (
                                                    <Option key={stade.value} value={stade.value}>{stade.label}</Option>
                                                ))}
                        </Select>

                    </Col>
                </Row>
            )}


            {Specialite === 'Pédiatrie' && Organe === 'Tumeurs cerebrales' && (
                <>
                    <Row gutter={6}>
                        <Col span={6}>
                            <p className='inputPatient2'>IRM cérébrale préopératoire</p>
                            <Input name='IRMCP' value={IRMCP} onChange={(e) => setIRMCP(e.target.value)} placeholder='IRM cérébrale préopératoire' />
                        </Col>

                        <Col span={6}>
                            <p className='inputPatient2'>IRM médullaire préopératoire</p>
                            <Input name='IRMMP' value={IRMMP} onChange={(e) => setIRMMP(e.target.value)} placeholder='IRM médullaire préopératoire' />
                        </Col>
                        <Col span={6}>
                            <p className='inputPatient2'>IRM cérébrale post opératoire</p>
                            <Input name='IRMCPO' value={IRMCPO} onChange={(e) => setIRMCPO(e.target.value)} placeholder='IRM cérébrale post opératoire' />
                        </Col>
                        <Col span={6}>
                            <p className='inputPatient2'>IRM médullaire post opératoire</p>
                            <Input name='IRMMPO' value={IRMMPO} onChange={(e) => setIRMMPO(e.target.value)} placeholder='IRM médullaire post opératoire' />
                        </Col>
                    </Row>
                    <Row gutter={6}>
                        <Col span={6}>
                            <p className='inputPatient2'>LCR</p>
                            <Input name='LCR' value={LCR} onChange={(e) => setLCR(e.target.value)} placeholder='LCR' />
                        </Col>

                        <Col span={6}>
                            <p className='inputPatient2'>Examen anatomopathlogique</p>
                            <Input name='ExamenAnatomopathlogique' value={ExamenAnatomopathlogique} onChange={(e) => setExamenAnatomopathlogique(e.target.value)} placeholder='Examen anatomopathlogique' />
                        </Col>
                        <Col span={6}>
                            <p className='inputPatient2'>Marges de résection : Saine/Infiltrées</p>
                            <Input name='MargesResection' value={MargesResection} onChange={(e) => setMargesResection(e.target.value)} placeholder='Marges de résection : Saine/Infiltrées' />
                        </Col>
                        <Col span={6}>
                            <p className='inputPatient2'>Médullogramme</p>
                            <Input name='Medullogramme' value={Medullogramme} onChange={(e) => setMedullogramme(e.target.value)} placeholder='Médullogramme' />
                        </Col>
                    </Row>
                </>
            )}


            {(Specialite === 'Urologie' && (Organe === 'Prostate' || Organe === 'Testicule' || Organe === 'Penis')) && (
                <>
                    <p className='inputPatient2'>Bilan pré thérapeutique</p>

                    <Row gutter={6}>
                        <Col span={8} >
                            <p className='inputPatient2'>Désir de fertilité ultérieure</p>
                            <Select
                                value={DesirFertiliteUlterieur}
                                onChange={(value) => {
                                    setDesirFertiliteUlterieur(value);
                                    updateBilanPreTherapeutique('DesirFertiliteUlterieur', value);
                                }}
                                style={{ width: "310px" }}>
                                <option value="Oui">Oui</option>
                                <option value="Non">Non</option>
                            </Select>
                        </Col>
                        <Col span={8} >
                            <p className='inputPatient2'>Préservation de fertilité proposée</p>
                            <Select
                                value={PreservationFertiliteProposer}
                                onChange={(value) => {
                                    setPreservationFertiliteProposer(value);
                                    updateBilanPreTherapeutique('PreservationFertiliteProposer', value);
                                }}
                                style={{ width: "310px" }}>
                                <option value="Oui">Oui</option>
                                <option value="Non">Non</option>
                            </Select>
                        </Col>
                        <Col span={8}>
                            <p className='inputPatient2'>Onco sexualité</p>
                            <Input value={OncoSexualite}
                                onChange={(e) => {
                                    setOncoSexualite(e.targetvalue);
                                    updateBilanPreTherapeutique('OncoSexualite', e.target.value);
                                }}
                                placeholder='Onco sexualité' />
                        </Col>
                        <Col span={8} >
                            <p className='inputPatient2'>Onco génétique</p>
                            <Input
                                value={OncoGenetique}
                                onChange={(e) => {
                                    setOncoGenetique(e.targetvalue);
                                    updateBilanPreTherapeutique('OncoGenetique', e.target.value);
                                }}
                                placeholder='Oncogénétique' />

                        </Col>
                        <Col span={8} >
                            <p className='inputPatient2'>Indication</p>
                            <Input
                                value={Indication}
                                onChange={(e) => {
                                    setIndication(e.targetvalue);
                                    updateBilanPreTherapeutique('Indication', e.target.value);
                                }}
                                placeholder='Indication' />

                        </Col>
                        <Col span={8} >
                            <p className='inputPatient2'>Proposition</p>
                            <Input
                                value={Proposition}
                                onChange={(e) => {
                                    setProposition(e.targetvalue);
                                    updateBilanPreTherapeutique('Proposition', e.target.value);
                                }}
                                placeholder='Proposition' />

                        </Col>

                    </Row>
                </>
            )}


            {(((Specialite === 'Digestif' && Organe === 'Estomac') || (Specialite === 'Gynécologie') || (Specialite === 'Hématologie') || (Specialite === 'Urologie' && Organe === 'Penis') || Specialite === 'Thorax') || (Specialite === 'ORL') || Specialite === 'Pédiatrie') && (

                <Row>

                    <Col span={24}>
                        <p className='inputPatient2'>Media +++</p>

                        <Input name='Media' value={media} onChange={(e) => setMedia(e.target.value)} placeholder="Media +++" />

                    </Col>
                </Row>

            )}

            {Specialite === 'Pédiatrie' && (Organe === 'Testicule/para-testicule') && (
                <Row>
                    <Col span={24} style={{ marginTop: "20px" }}>
                        <Checkbox
                            value={Testicule}
                            checked={selectedCheckbox.includes('Testicule')}
                            onChange={handleChangeOption}
                        >
                            Testicule
                        </Checkbox>
                        <Checkbox
                            value={ParaTesticule}
                            checked={selectedCheckbox.includes('Para-testiculaire')}
                            onChange={handleChangeOption}
                        >
                            Para-testiculaire
                        </Checkbox>

                    </Col>

                    {selectedCheckbox.includes('Testicule') && (
                        <>
                            <Col span={24}>
                                <p className='inputPatient2'>Choisissez le type </p>
                                <Select
                                    // mode='tags'
                                    value={TypeTesticule}
                                    onChange={handleChangeType}
                                    style={{ width: "100%", height: "33px" }}
                                >
                                    {optionTypeTesticles.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            {AfficheChexbox25 && (
                                <Row gutter={6}>
                                    <Col span={12}>
                                        <p className='inputPatient2'>Seminome</p>
                                        <Input placeholder='Seminome' name='Seminome' value={Seminome} onChange={(value) => setSeminome(value)} />
                                    </Col>
                                    <Col span={12}>
                                        <p className='inputPatient2'> Non Séminomateuse</p>
                                        <Select
                                            mode='tags'
                                            value={NonSeminome} onChange={(value) => setNonSeminome(value)}
                                            style={{ width: "570px", height: "33px" }}
                                        >
                                            <Option key='Tératome' value="Tératome"></Option>
                                            <Option key='Choriocarcinome' value="Choriocarcinome"> Choriocarcinome</Option>
                                            <Option key='Tumeur du sac vitellin' value="Tumeur du sac vitellin">Tumeur du sac vitellin </Option>
                                            <Option key='Carcinome embryonnaire' value="Carcinome embryonnaire">Carcinome embryonnaire </Option>
                                        </Select>
                                    </Col>


                                </Row>
                            )}
                            {AfficheChexbox26 && (
                                <Row gutter={6}>
                                    <Col span={12}>
                                        <p className='inputPatient2'>Tumeur à Cellules de Sertoli-Leydig</p>
                                        <Select
                                            mode='tags'
                                            value={TumeurCelluleSL} onChange={(value) => setTumeurCelluleSL(value)}
                                            style={{ width: "460px", height: "33px" }}
                                        ></Select>
                                    </Col>
                                    <Col span={12}>
                                        <p className='inputPatient2'>Tumeur à Cellules de la granulosa</p>
                                        <Select
                                            mode='tags'
                                            value={TumeurCelluleG} onChange={(value) => setTumeurCelluleG(value)}
                                            style={{ width: "475px", height: "33px" }}
                                        ></Select>
                                    </Col>

                                </Row>
                            )}

                            <Row gutter={6}>
                                <Col span={8}>
                                    <p className='inputPatient2'>Stade clinique</p>
                                    <Select
                                        name="stade clinique"
                                        // placeholder="Sélectionnez le Tumeur primitive"
                                        value={tumeur}
                                        style={{ width: "310px" }}
                                        onChange={(value) => setTumeur(value)}
                                    >
                                        <OptGroup label="T">
                                            {optionTumeur.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </OptGroup>

                                        <OptGroup label="N">
                                            {optionAdenophatie.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </OptGroup>

                                        <OptGroup label="M">
                                            {optionMétastase.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </OptGroup>
                                    </Select>
                                </Col>

                                <Col span={8}>
                                    <p className='inputPatient2'>Biologie moléculaire</p>
                                    <Select
                                        name="BiologieMoleculaire"
                                        // placeholder="Sélectionnez le Tumeur primitive"
                                        value={biologieMoleculaire}
                                        style={{ width: "310px" }}
                                        onChange={(value) => setBiologieMoleculaire(value)}>

                                        {optionBiologieMoleculaire.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}

                                    </Select>
                                </Col>
                                <Col span={8}>
                                    <p className='inputPatient2'>Facteurs pronostics</p>
                                    <Select
                                        mode='tags'
                                        name="FacteursPronostiques"
                                        // placeholder="Sélectionnez le Tumeur primitive"
                                        value={FacteursPronostiques}
                                        style={{ width: "310px" }}
                                        onChange={(value) => setFacteursPronostiques(value)}>

                                        {optionFacteurPronostique.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}

                                    </Select>
                                </Col>

                                <Col span={24}>
                                    <p className='inputPatient2'>Tumeurs germinales non séminomateuses</p>
                                    <Select
                                        name=""
                                        // placeholder="Sélectionnez le Tumeur primitive"
                                        value={TumeursGerminaux}
                                        style={{ width: "950px" }}
                                        onChange={(value) => setTumeursGerminaux(value)}>

                                        {optionTumeursGerminaux.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}

                                    </Select>
                                </Col>
                            </Row>

                        </>

                    )}

                    {selectedCheckbox.includes('Para-testiculaire') && (
                        <>
                            <>

                                <Col span={24}>
                                    <p className='inputPatient2'> Type Histologie</p>
                                    <Select
                                        mode='tags'
                                        value={TypeHistologies}
                                        onChange={(value) => setTypeHistologies(value)}
                                        style={{ width: "100%", height: "33px" }}
                                    >
                                        {filteredOptions.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={24}>
                                    <p className='inputPatient2'>Classification</p>
                                    <Select
                                        mode='tags'
                                        value={tumeur}
                                        onChange={(value) => setTumeur(value)}
                                        style={{ width: "100%", height: "33px" }}
                                    >
                                        {optionTumeur.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col span={24}>
                                    <p className='inputPatient2'>Risque</p>
                                    <Checkbox.Group onChange={handleRiskChange} value={CheckboxChoice}>
                                        <Checkbox value="Risque standard, sous-groupe A">Risque standard, sous-groupe A</Checkbox>
                                        <Checkbox value="Risque standard, sous-groupe B">Risque standard, sous-groupe B</Checkbox>
                                        <Checkbox value="Risque intermédiaire">Risque intermédiaire</Checkbox>
                                        <Checkbox value="Risque élevé">Risque élevé</Checkbox>
                                    </Checkbox.Group>

                                    {CheckboxChoice.includes('Risque standard, sous-groupe A') && (
                                        <div>
                                            <h4>Sous-groupe A - Stades et Groupes</h4>
                                            <Select
                                                mode="multiple"
                                                value={Risque}
                                                onChange={handleRisqueChange}
                                                placeholder="Sélectionnez les stades"
                                                style={{ width: '100%' }}
                                            >
                                                <Option value="Stade 1, Groupe I">Stade 1, Groupe I</Option>
                                                <Option value="Stade 1, Groupe II">Stade 1, Groupe II</Option>
                                                <Option value="Stade 2, Groupe I">Stade 2, Groupe I</Option>
                                            </Select>
                                        </div>
                                    )}

                                    {CheckboxChoice.includes('Risque standard, sous-groupe B') && (
                                        <div>
                                            <h4>Sous-groupe B - Stades et Groupes</h4>
                                            <Select
                                                mode="multiple"
                                                value={Risque}
                                                onChange={handleRisqueChange}
                                                placeholder="Sélectionnez les stades"
                                                style={{ width: '100%' }}
                                            >
                                                <Option value="Stade 1, Groupe III">Stade 1, Groupe III</Option>
                                                <Option value="Stade 2, Groupe II">Stade 2, Groupe II</Option>
                                                <Option value="Stade 3, Groupes I et II">Stade 3, Groupes I et II</Option>
                                            </Select>
                                        </div>
                                    )}

                                    {CheckboxChoice.includes('Risque intermédiaire') && (
                                        <div>
                                            <h4>Risque intermédiaire - Stades et Groupes</h4>
                                            <Select
                                                mode="multiple"
                                                value={Risque}
                                                onChange={handleRisqueChange}
                                                placeholder="Sélectionnez les stades"
                                                style={{ width: '100%' }}
                                            >
                                                <Option value="Stades 2 et 3, Groupe III">Stades 2 et 3, Groupe III</Option>
                                                <Option value="Stade 4 groupe IV">Stade 4 groupe IV</Option>
                                                <Option value="RMS alvéolaire non métastatique">RMS alvéolaire non métastatique</Option>
                                            </Select>
                                        </div>
                                    )}

                                    {CheckboxChoice.includes('Risque élevé') && (
                                        <div>
                                            <h4>Risque élevé - Stades et Groupes</h4>
                                            <Select
                                                mode="multiple"
                                                value={Risque}
                                                onChange={handleRisqueChange}
                                                placeholder="Sélectionnez les stades"
                                                style={{ width: '100%' }}
                                            >
                                                <Option value="RMS alvéolaire métastatique">RMS alvéolaire métastatique</Option>
                                                <Option value="RMS embryonnaire métastatiques > 10 ans ou < 1 an">
                                                    RMS embryonnaire métastatiques {'>'} 10 ans ou {'<'} 1 an
                                                </Option>
                                            </Select>
                                        </div>
                                    )}
                                </Col>
                                <Col span={12}>
                                    <p className='inputPatient2'>Biologie moléculaire</p>
                                    <Select
                                        mode="tags"
                                        value={biologieMoleculaire}
                                        onChange={handleChangeBiomoleculaire}
                                        style={{ width: "470px", height: "33px", }}
                                    >
                                        {optionBiologieMoleculaire.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col span={12}>
                                    <p className='inputPatient2'>Facteurs pronostique</p>
                                    <Select
                                        mode="tags"
                                        value={facteurPronostique}
                                        onChange={handleFacteurProsnostique}
                                        style={{ width: "470px", height: "33px", }}
                                    >
                                        {optionFacteurPronostique.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            </>

                        </>

                    )}


                </Row>
            )}


            {Specialite === 'Pédiatrie' && (Organe === 'Prostate' || Organe === 'Vessie') && (
                <Row>
                    <Col span={24} style={{ marginTop: "20px" }}>
                        <Checkbox
                            value={Rhabdomyosarcome}
                            checked={selectedCheckbox.includes('Rhabdomyosarcome')}
                            onChange={handleChangeOption}
                        >
                            Rhabdomyosarcome
                        </Checkbox>
                        <Checkbox
                            value={Carcinome}
                            checked={selectedCheckbox.includes('Carcinome')}
                            onChange={handleChangeOption}
                        >
                            Carcinome
                        </Checkbox>

                    </Col>
                    {selectedCheckbox.includes('Rhabdomyosarcome') && (
                        <>
                            <Col span={24}>
                                <p className='inputPatient2'> Type Histologie</p>
                                <Select
                                    mode='tags'
                                    value={TypeHistologies}
                                    onChange={(value) => setTypeHistologies(value)}
                                    style={{ width: "100%", height: "33px" }}
                                >
                                    {filteredOptions.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={24}>
                                <p className='inputPatient2'>Classification</p>
                                <Select
                                    mode='tags'
                                    value={tumeur}
                                    onChange={(value) => setTumeur(value)}
                                    style={{ width: "100%", height: "33px" }}
                                >
                                    {optionTumeur.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col span={24}>
                                <p className='inputPatient2'>Risque</p>
                                <Checkbox.Group onChange={handleRiskChange} value={CheckboxChoice}>
                                    <Checkbox value="Risque standard, sous-groupe A">Risque standard, sous-groupe A</Checkbox>
                                    <Checkbox value="Risque standard, sous-groupe B">Risque standard, sous-groupe B</Checkbox>
                                    <Checkbox value="Risque intermédiaire">Risque intermédiaire</Checkbox>
                                    <Checkbox value="Risque élevé">Risque élevé</Checkbox>
                                </Checkbox.Group>

                                {CheckboxChoice.includes('Risque standard, sous-groupe A') && (
                                    <div>
                                        <h4>Sous-groupe A - Stades et Groupes</h4>
                                        <Select
                                            mode="multiple"
                                            value={Risque}
                                            onChange={handleRisqueChange}
                                            placeholder="Sélectionnez les stades"
                                            style={{ width: '100%' }}
                                        >
                                            <Option value="Stade 1, Groupe I">Stade 1, Groupe I</Option>
                                            <Option value="Stade 1, Groupe II">Stade 1, Groupe II</Option>
                                            <Option value="Stade 2, Groupe I">Stade 2, Groupe I</Option>
                                        </Select>
                                    </div>
                                )}

                                {CheckboxChoice.includes('Risque standard, sous-groupe B') && (
                                    <div>
                                        <h4>Sous-groupe B - Stades et Groupes</h4>
                                        <Select
                                            mode="multiple"
                                            value={Risque}
                                            onChange={handleRisqueChange}
                                            placeholder="Sélectionnez les stades"
                                            style={{ width: '100%' }}
                                        >
                                            <Option value="Stade 1, Groupe III">Stade 1, Groupe III</Option>
                                            <Option value="Stade 2, Groupe II">Stade 2, Groupe II</Option>
                                            <Option value="Stade 3, Groupes I et II">Stade 3, Groupes I et II</Option>
                                        </Select>
                                    </div>
                                )}

                                {CheckboxChoice.includes('Risque intermédiaire') && (
                                    <div>
                                        <h4>Risque intermédiaire - Stades et Groupes</h4>
                                        <Select
                                            mode="multiple"
                                            value={Risque}
                                            onChange={handleRisqueChange}
                                            placeholder="Sélectionnez les stades"
                                            style={{ width: '100%' }}
                                        >
                                            <Option value="Stades 2 et 3, Groupe III">Stades 2 et 3, Groupe III</Option>
                                            <Option value="Stade 4 groupe IV">Stade 4 groupe IV</Option>
                                            <Option value="RMS alvéolaire non métastatique">RMS alvéolaire non métastatique</Option>
                                        </Select>
                                    </div>
                                )}

                                {CheckboxChoice.includes('Risque élevé') && (
                                    <div>
                                        <h4>Risque élevé - Stades et Groupes</h4>
                                        <Select
                                            mode="multiple"
                                            value={Risque}
                                            onChange={handleRisqueChange}
                                            placeholder="Sélectionnez les stades"
                                            style={{ width: '100%' }}
                                        >
                                            <Option value="RMS alvéolaire métastatique">RMS alvéolaire métastatique</Option>
                                            <Option value="RMS embryonnaire métastatiques > 10 ans ou < 1 an">
                                                RMS embryonnaire métastatiques {'>'} 10 ans ou {'<'} 1 an
                                            </Option>
                                        </Select>
                                    </div>
                                )}
                            </Col>
                            <Col span={12}>
                                <p className='inputPatient2'>Biologie moléculaire</p>
                                <Select
                                    mode="tags"
                                    value={biologieMoleculaire}
                                    onChange={handleChangeBiomoleculaire}
                                    style={{ width: "470px", height: "33px", }}
                                >
                                    {optionBiologieMoleculaire.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col span={12}>
                                <p className='inputPatient2'>Facteurs pronostique</p>
                                <Select
                                    mode="tags"
                                    value={facteurPronostique}
                                    onChange={handleFacteurProsnostique}
                                    style={{ width: "470px", height: "33px", }}
                                >
                                    {optionFacteurPronostique.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </>
                    )}
                    {selectedCheckbox.includes('Carcinome') && (
                        <Row gutter={6}>
                            <Col span={12}>
                                <p className='inputPatient2'> Type Histologie</p>
                                <Select
                                    mode='tags'
                                    value={TypeHistologies}
                                    onChange={(value) => setTypeHistologies(value)}
                                    style={{ width: "470px", height: "33px" }}
                                >
                                    {filteredOptions.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={12}>
                                <p className='inputPatient2'>Stadification selon le TNM</p>
                                <Select
                                    value={tumeur}
                                    onChange={(value) => setTumeur(value)}
                                    style={{ width: "470px", height: "33px" }}
                                    mode="multiple"
                                >
                                    <OptGroup label="T : Tumeur Primaire">
                                        {optionTumeur.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </OptGroup>

                                    <OptGroup label="N : Ganglions Lymphatiques Régionaux">
                                        {optionAdenophatie.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </OptGroup>

                                    <OptGroup label="M : Métastases à Distance">
                                        {optionMétastase.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </OptGroup>
                                </Select>
                            </Col>
                        </Row>
                    )}


                </Row>
            )}


            {Specialite === 'Pédiatrie' && Organe === 'Ovaire' && (
                <Row gutter={6}>
                    <Col span={6}>
                        <p className='inputPatient2'>Germinale</p>
                        <Input placeholder='Germinale' name='Germinale' value={Germinale} onChange={(e) => setGerminale(e.target.value)} />
                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Dysgerminome</p>
                        <Input placeholder='Dysgerminome' name='Dysgerminome' value={Dysgerminome} onChange={(e) => setDysgerminome(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Tératome</p>
                        <Input placeholder='Tératome' name='Teratome' value={Teratome} onChange={(e) => setTeratome(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Choriocarcinome</p>
                        <Input placeholder='Choriocarcinome' name='Choriocarcinome' value={Choriocarcinome} onChange={(e) => setChoriocarcinome(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Tumeur du sac vitellin</p>
                        <Input placeholder='Tumeur du sac vitellin' name='TumeurSacVitellin' value={TumeurSacVitellin} onChange={(e) => setTumeurSacVitellin(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Carcinome embryonnaire</p>
                        <Input placeholder='Carcinome embryonnaire' name='Carcinome' value={Carcinome} onChange={(e) => setCarcinome(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Cordons sexuels</p>
                        <Input placeholder='Cordons sexuels' name='CordonSexuel' value={CordonSexuel} onChange={(e) => setCordonSexuel(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Tumeur à Cellules de Sertoli-Leydi </p>
                        <Input placeholder='Tumeur à Cellules de Sertoli-Leydi' name='TumeurCelluleSL' value={TumeurCelluleSL} onChange={(e) => setTumeurCelluleSL(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Tumeur à Cellules de la granulosa </p>
                        <Input placeholder='Tumeur à Cellules de la granulosa' name='TumeurCelluleG' value={TumeurCelluleG} onChange={(e) => setTumeurCelluleG(e.target.value)} />

                    </Col>
                    <Col span={18}>
                        <p className='inputPatient2'>Classification de FIGO</p>

                        <Select
                            mode='tags'
                            name="ClassificationFigo"
                            // placeholder="Sélectionnez le Tumeur primitive"
                            value={tumeur}
                            style={{ width: "100%" }}
                            onChange={(value) => setTumeur(value)}
                        >
                            <OptGroup label="Stade I">
                                {optionTumeur.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </OptGroup>

                            <OptGroup label="Stade II">
                                {optionAdenophatie.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </OptGroup>
                            <OptGroup label="Stade III">
                                {optionMétastase.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </OptGroup>
                            <OptGroup label="Stade IV">
                                {optionClassification.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </OptGroup>


                        </Select>

                    </Col>
                </Row>
            )}



            {(Specialite === 'Hématologie' && (Organe === 'Néoplasmes des cellules histiocytaires/dendritiques') || (Specialite === 'Pédiatrie' && Organe === 'Néoplasmes des cellules histiocytaires/dendritiques')) ? (
                <>
                    <Row gutter={6}>
                        <Col span={12}>
                            <p>Néoplasmes des cellules de Langerhans et autres cellules dendritiques</p>
                            <Select
                                name="NéoplasmesCellulesLangerhansAutresCellulesDndritiques"
                                // placeholder="Sélectionnez le Tumeur primitive"
                                value={tumeur}
                                style={{ width: "100%" }}
                                onChange={(value) => setTumeur(value)}
                            >
                                <OptGroup label="Néoplasmes des cellules de Langerhans">
                                    {optionTumeur.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </OptGroup>

                                <OptGroup label="Autres néoplasmes des cellules dendritiques">
                                    {optionAdenophatie.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </OptGroup>


                            </Select>
                        </Col>
                        <Col span={12}>
                            <p>Néoplasmes histiocytes/macrophages</p>
                            <Select
                                name="NéoplasmesHistiocytesMacrophages"
                                // placeholder="Sélectionnez le Tumeur primitive"
                                value={NéoplasmesHistiocytesMacrophages}
                                style={{ width: "100%" }}
                                onChange={(value) => setNéoplasmesHistiocytesMacrophages(value)}
                            >
                                <OptGroup label="Néoplasmes histiocytaires">
                                    {optionNéoplasmesHistiocytesMacrophages.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </OptGroup>


                            </Select>
                        </Col>
                    </Row>

                </>
            ) : (
                <>
                    <Row gutter={6}>
                        {(Specialite === 'Gynécologie') || Specialite === 'Cancers Rares' || (Specialite === 'Hématologie') || (Specialite === 'Urologie') || Specialite === 'Pédiatrie' || (Specialite === "Thorax") || (Specialite === 'ORL') ?
                            (
                                <div></div>
                            ) : (

                                <Col span={8}>
                                    <p className='inputPatient2'>MALADE</p>

                                    <Select
                                        mode="tags"
                                        value={Malade}
                                        onChange={(value) => setMalade(value)}
                                        style={{ width: "315px", height: "33px", }}
                                    >
                                        {optionMalade.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            )}

                        {(Specialite === 'Hématologie' &&
                            ['LA Adulte', 'Leucémie Lymphoïde Chronique', 'Syndrome Myéloprolifératif Ph1 négative', 'Syndromes myélodysplasiques', 'Leucémie myéloïde Chronique', 'Myélome multiple'].includes(Organe)) ||
                            (Specialite === 'Pédiatrie' &&
                                ['Voie excretrice', 'Rein', 'Rétropéritoine', 'Os'].every(organe => organe !== Organe)) ||
                            (Specialite === 'Thorax' && Organe === 'Plèvre') ? (
                            <div></div>
                        ) : (
                            <Col span={8}>
                                <p className='inputPatient2'>Type histologique</p>

                                <Select
                                    mode='tags'
                                    value={TypeHistologies}
                                    onChange={handleChangeTypeHistologies}
                                    style={{
                                        width:
                                            (Specialite === 'Gynécologie' &&
                                                (Organe === 'Néoplasies trophoblastiques gestationnelles' || Organe === 'Sein')) ||
                                                Specialite === 'Hématologie' ||
                                                Specialite === 'Urologie' && Organe === 'Vessie/Arbre urinaire' ||
                                                Specialite === 'Thorax' ||
                                                (Specialite === 'Pédiatrie' &&
                                                    (Organe !== 'Voie excretrice' && Organe !== 'Rein')) ||
                                                Specialite === 'Cancers Rares'
                                                ? "950px"
                                                : "310px",
                                        height: "33px",
                                    }}
                                >
                                    {optionTypeHistologies.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>              </Col>

                        )}

                        {Specialite === 'Pédiatrie' && (Organe === 'Tumeurs cerebrales') && (
                            <Col span={8}>
                                <p className='inputPatient2'>Medulloblasme</p>

                                <Select
                                    // mode="tags"
                                    value={medulloblasme}
                                    onChange={(value) => setMedulloblasme(value)}
                                    style={{ width: "630px", height: "33px", }}
                                >
                                    {optionMedulloblasme.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>                </Col>

                        )}


                        {AfficheChexbox9 && (
                            <Col span={24} style={{ marginTop: '20px' }}>
                                <Checkbox
                                    checked={MedulloblasmeSelect[medulloblasme] === "Oui"}
                                    onChange={(e) => Medulloblasme(medulloblasme, e.target.checked ? "Oui" : null)}
                                >
                                    Oui
                                </Checkbox>
                                <Checkbox
                                    checked={MedulloblasmeSelect[medulloblasme] === "Non"}
                                    onChange={(e) => Medulloblasme(medulloblasme, e.target.checked ? "Non" : null)}
                                >
                                    Non
                                </Checkbox>
                            </Col>
                        )}


                        {Specialite === 'Thorax' && Organe === 'Plèvre' &&
                            (
                                <>
                                    <p className='inputPatient2'>Type histologique</p>

                                    <Col span={8}>
                                        <p className='inputPatient2'>Si mésothéliome pleural malin</p>

                                        <Select style={{ width: "470px" }}
                                            name='TypeHistologies'
                                            value={TypeHistologies}
                                            onChange={(value) => setTypeHistologies(value)}
                                        >
                                            <Option value="Epithélioïde">Epithélioïde</Option>
                                            <Option value="Sarcomatoïde">Sarcomatoïde</Option>
                                            <Option value="Mixte ou biphasique">Mixte ou biphasique</Option>
                                            <Option value="Desmoplasique">Desmoplasique</Option>
                                        </Select>
                                    </Col>

                                    <Col span={12}>
                                    <p>Autres tumeurs pleurales, preciser type histologique</p>
                                        <Input
                                        name='TypeHistologies'
                                        value={TypeHistologies}
                                        onChange={(value) => setTypeHistologies(value)}
                                        className='inputPatient2' />
                                    </Col>


                                </>

                            )}

                        {(Specialite === 'Hématologie' && Organe === 'LA Adulte') || (Specialite === 'Pédiatrie' && (Organe === 'T. Sacro-coccygienne' || Organe === 'Corticosurrenale' || Organe === 'Oesophage')) && (
                            <>
                                <Col span={8}>
                                    <p className='inputPatient2'>Type cistologique</p>

                                    <Select
                                        mode="tags"
                                        value={TypeCistologie}
                                        onChange={(value) => setTypeCistologie(value)}
                                        style={{ width: "950px", height: "33px", }}
                                    >
                                        {optionCystologie.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>

                            </>

                        )}

                        {(Specialite === 'Hématologie' && Organe === 'Leucémie Lymphoïde Chronique') && (
                            <>
                                <Col span={8}>
                                    <p className='inputPatient2'>Cytogénétique</p>

                                    <Select
                                        // mode="tags"

                                        value={TypeHistologies}
                                        onChange={handleChangeCystogénétique}
                                        style={{ width: "950px", height: "33px", }}
                                    >
                                        {optionTypeHistologies.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>

                                {AfficheChexbox7 && (
                                    <Col span={24} style={{ marginTop: '20px' }}>
                                        <Checkbox
                                            checked={CytogenetiqueDel17p[TypeHistologies] === "Oui"}
                                            onChange={(e) => Cytogenetique(TypeHistologies, e.target.checked ? "Oui" : null)}
                                        >
                                            Oui
                                        </Checkbox>
                                        <Checkbox
                                            checked={CytogenetiqueDel17p[TypeHistologies] === "Non"}
                                            onChange={(e) => Cytogenetique(TypeHistologies, e.target.checked ? "Non" : null)}
                                        >
                                            Non
                                        </Checkbox>
                                    </Col>
                                )}

                            </>

                        )}


                        {(Specialite === 'Gynécologie' && (Organe === 'Néoplasies trophoblastiques gestationnelles' || Organe === 'Sein') || Specialite === 'Cancers Rares' || (Specialite === 'Hématologie') || Specialite === 'Pédiatrie' || (Specialite === 'Urologie' && (Organe === 'Vessie/Arbre urinaire' || Organe === 'Testicule' || Organe === 'Prostate' || Organe === 'Penis' || Organe === 'Surrenale' || Organe === 'Reins') || Specialite === 'Thorax')) ?
                            (
                                <div></div>
                            ) : (
                                <Col span={Specialite === 'Gynécologie' && (Organe === 'Ovaire' || Organe === 'Col' || Organe === 'Utérus' || Organe === 'Vagin' || Organe === 'Vulve') || (Specialite === 'Urologie' && Organe === 'Prostate') || (Specialite === 'ORL') ? 16 : 8}>
                                    <p className='inputPatient2'>Grade histopronostique</p>

                                    <Input name='GradeHistopronostique' value={GradeHistopronostique} onChange={(e) => setGradeHistopronostique(e.target.value)} placeholder="Grade histopronostique" />
                                </Col>
                            )}
                        {Specialite === 'Urologie' && Organe === 'Prostate' || (Specialite === 'ORL' && Organe === 'Thyroide') || Specialite === 'Cancers Rares' && (Organe !== 'Parathyroïde') || (Specialite === 'Pédiatrie' && (Organe === 'Rein' || Organe === 'Rétropéritoine')) && (
                            <Col span={24}>
                                <p className='inputPatient2'>Grade histopronostique</p>
                                <Select
                                    // mode="tags"
                                    value={GradeHistopronostique}
                                    onChange={(value) => setGradeHistopronostique(value)}
                                    style={{ width: '950px', height: "33px", }}
                                >
                                    {optionGradeHistopronostique.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        )}
                    </Row>

                    <Row gutter={6}>
                        {(Specialite === 'Hématologie' && (Organe === 'Leucémie Lymphoïde Chronique' || Organe === 'Syndrome Myéloprolifératif Ph1 négative' || Organe === 'Syndromes myélodysplasiques' || Organe === 'Leucémie myéloïde Chronique' || Organe === 'Lymphome non hodgkinien adulte' || Organe === 'Myélome multiple') || (Specialite === 'Urologie' && (Organe === 'Penis' || Organe === 'Surrenale') || (Specialite === 'Thorax' && Organe !== 'Poumon') || Specialite === 'Pédiatrie' || Specialite === 'Cancers Rares' || (Specialite === 'ORL' && (Organe === 'Cavité buccale' || Organe === 'Pharynx' || Organe === 'Larynx' || Organe === 'Cavité nasale' || Organe === 'Lévre' || Organe === 'Sinus' || Organe === 'glandes salivaire' || Organe === 'Thyroide')))) ?
                            (
                                <div></div>
                            ) : (

                                <>
                                    <Col span={8}>
                                        <p className='inputPatient2'>Biologie moléculaire/Autre</p>


                                        <Select
                                            mode="tags"
                                            value={biologieMoleculaire}
                                            onChange={handleChangeBiomoleculaire}
                                            style={{ width: (Specialite === 'Urologie' && Organe === 'Vessie/Arbre urinaire') || (Specialite === 'Thorax' && Organe === 'Poumon') || Specialite === 'ORL' && (Organe === 'Cavum' || Organe === 'Nasopharynx') ? '950px' : "310px", height: "33px", }}
                                        >
                                            {optionBiologieMoleculaire.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </Select>                </Col>




                                    {Specialite === 'Thorax' && Organe === 'Poumon' || Specialite === 'ORL' && (Organe === 'Cavum' || Organe === 'Nasopharynx') ? (
                                        <div></div>
                                    ) : (
                                        <>
                                            <Col span={16}>
                                                <p className='inputPatient2'>Tils</p>
                                                <Input name='Tils' value={Tils} onChange={(e) => setTils(e.target.value)} placeholder="" />                                    </Col>

                                            {(Specialite === 'Gynécologie' && Organe === 'Néoplasies trophoblastiques gestationnelles' || Specialite === 'Urologie') ?
                                                (
                                                    <div></div>
                                                ) : (
                                                    <>
                                                        {Specialite === 'Pédiatrie' && (Organe === 'Oesophage' || Organe === 'Colo-rectum') && (
                                                            <Col span={8}>
                                                                <p className='inputPatient2'>Cytogénétique conventionnelle</p>
                                                                <Input name='CytogenetiqueConventionnelle' value={cytogenetiqueConventionnelle} onChange={(e) => setCytogenetiqueConventionnelle(e.target.value)} placeholder="Cytogénétique conventionnelle" />

                                                            </Col>
                                                        )}
                                                        <Col span={8}>
                                                            <p className='inputPatient2'>FISH</p>
                                                            <Input name='FISH' value={FISH} onChange={(e) => setFISH(e.target.value)} placeholder="FISH" />

                                                        </Col>

                                                        {(Specialite === 'Gynécologie' || Specialite === 'Hématologie') ?
                                                            (
                                                                <div></div>
                                                            ) : (

                                                                <Col span={8}>
                                                                    <p className='inputPatient2'>Marqueurs tumoraux</p>

                                                                    <Select
                                                                        mode="tags"
                                                                        value={MarqueursTumoraux}
                                                                        onChange={(value) => setMarqueursTumoraux(value)}
                                                                        style={{ width: "310px", height: "33px", }}
                                                                    >
                                                                        {optionMarqueursTumoraux.map((option) => (
                                                                            <Option key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </Option>
                                                                        ))}
                                                                    </Select>
                                                                </Col>

                                                            )}

                                                    </>
                                                )}

                                        </>
                                    )}
                                </>
                            )}

                        {(Specialite === 'Gynécologie' && (Organe === 'Ovaire' || Organe === 'Sein' || Organe === 'Col' || Organe === 'Utérus' || Organe === 'Vagin' || Organe === 'Vulve') || (Specialite === 'Thorax' && (Organe === 'Parois thoracique' || Organe === 'Plèvre'))) &&
                            (

                                <Col span={12}>
                                    <p className='inputPatient2'>immunohistochimie</p>

                                    <Select
                                        // mode="tags"
                                        value={immuno}
                                        onChange={(value) => setImmuno(value)}
                                        style={{ width: (Specialite === 'Thorax' && (Organe === 'Parois thoracique' || Organe === 'Plèvre' || Organe === 'Poumon')) ? "950px" : "470px", height: "33px", }}
                                    >
                                        {optionImmuno.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            )}
                        {(Specialite === 'Gynécologie' && Organe === 'Sein') && (
                            <>
                                <Col span={12}>
                                    <p className='inputPatient2'>Mutation génétique</p>
                                    <Select
                                        // mode="tags"
                                        value={mutationGenetique}
                                        onChange={(value) => setMutationGenetique(value)}
                                        style={{ width: "470px", height: "33px", }}
                                    >
                                        {optionMutation.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={16}>
                                    <p className='inputPatient2'>PDL1/mTOR</p>
                                    <Input placeholder="PDL1/mTOR"
                                        name="pdl1"
                                        value={pdl1mTor}
                                        onChange={(e) => setPdl1mTor(e.target.value)} />
                                </Col>
                            </>
                        )}

                       


                            {(Specialite === 'ORL' && ['Cavum', 'Nasopharynx'].includes(Organe)) ||
                            (Specialite === 'Pédiatrie' || 
                            Specialite === 'Cancers Rares' || 
                            (Specialite === 'Hématologie' && 
                            (Organe === 'Syndromes myélodysplasiques' || 
                                Organe === 'Syndrome Myéloprolifératif Ph1 négative' || 
                                Organe === 'LA Adulte' || 
                                Organe === 'Myélome multiple' || 
                                Organe === 'Lymphome Hodgkin adulte' || 
                                Organe === 'Leucémie Lymphoïde Chronique' || 
                                Organe === 'Lymphome non hodgkinien adulte')) || 
                            (Specialite === 'Urologie' && 
                            (Organe === 'Vessie/Arbre urinaire' || 
                                Organe === 'Penis' || 
                                Organe === 'Surrenale')) || 
                            Specialite === 'Thorax' || 
                            Specialite === 'ORL') || 
                            (Specialite === 'Pédiatrie' && Organe !== 'Rein') || 
                            (Specialite === 'Hématologie' && 
                            (Organe === 'Lymphome Hodgkin adulte' || 
                                Organe === 'Leucémie myéloïde Chronique' || 
                                Organe === 'Myélome multiple' || 
                                Organe === 'Syndrome Myéloprolifératif Ph1 négative' || 
                                Organe === 'Syndromes myélodysplasiques')) ? (
                                <div></div>
                            ) : (
                                <Col span={24}>
                                    <p className='inputPatient2'>Facteurs pronostiques</p>
                                    <Select
                                        mode="tags"
                                        value={facteurPronostique}
                                        onChange={handleFacteurProsnostique}
                                        style={{ width: "100%", height: "33px" }}
                                    >
                                        {optionFacteurPronostique.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            )}


                        {AfficheChexbox15 && (
                            <Col span={24} style={{}}>
                                <Checkbox onChange={handleCheckboxChange}>mauvais pronostique</Checkbox>
                            </Col>
                        )}

                        {AfficheChexbox16 && (
                            <Col span={24} style={{}}>
                                <Checkbox onChange={handleCheckboxChange}>bon pronostique</Checkbox>
                            </Col>
                        )}

                        {AfficheChexbox17 && (
                            <>
                                <Col span={12} style={{}}>
                                    <p>{'âge > 40 ans chez l’homme et > 50 ans chez la femme'}</p>
                                    <Input placeholder='age' />
                                </Col>
                                <Col span={12} style={{}}>
                                    <p>Sexe : Pc légèrement meilleur chez les femmes</p>
                                    <Input name='' placeholder='Sex' />
                                </Col>
                            </>
                        )}

                        {AfficheChexbox18 && (
                            <>
                                <Col span={8} style={{}}>
                                    <p>Taille</p>
                                    <Select style={{ width: "310px" }}>
                                        <Option value="T3">T3</Option>
                                        <Option value="T4">T4</Option>
                                    </Select>
                                </Col>
                                <Col span={8} style={{}}>
                                    <p>Histologie</p>
                                    <Select style={{ width: '310px' }}>
                                        <Option value="faible degré de différenciation tumorale">faible degré de différenciation tumorale</Option>
                                        <Option value="formes agressives ( les cancers anaplasiques, cellules hautes, cylindriques) extension tumorale au-delà de la capsule thyroïdienne">formes agressives ( les cancers anaplasiques, cellules hautes, cylindriques) extension tumorale au-delà de la capsule thyroïdienne</Option>
                                    </Select>
                                </Col>
                                <Col span={8} style={{}}>
                                    <p>Atypies nucléaires, Emboles vasculaires, nécrose</p>
                                    <Input placeholder='Atypies nucléaires, Emboles vasculaires, nécrose' />
                                </Col>
                                <Col span={12} style={{}}>
                                    <p>Métastases ganglionnaires Métastases à distance initiales</p>
                                    <Input placeholder='' />
                                </Col>
                                <Col span={12} style={{}}>
                                    <p>Mutation BRAF-V600E</p>
                                    <Input placeholder='Mutation BRAF-V600E' />
                                </Col>
                            </>
                        )}
                        {AfficheChexbox19 && (
                            <>
                                <Col span={8} style={{}}>
                                    <p>Haut Risque (30-55%)</p>
                                    <Select style={{ width: "310px" }}>
                                        <Option value="Métastases, Gg> 3 cm">{'Métastases, Gg> 3 cm'}</Option>
                                        <Option value="Extension extra-thyroïdienne massive">Extension extra-thyroïdienne massive</Option>
                                    </Select>
                                </Col>
                                <Col span={8} style={{}}>
                                    <p>Risque intermédiaire (3-40%)</p>
                                    <Select style={{ width: '310px' }}>
                                        <Option value="Histologie aggressive Ext extra-thyr mineure > 5 Gg de 0,2 à 3 cm">{'Histologie aggressive Ext extra-thyr mineure > 5 Gg de 0,2 à 3 cm'}</Option>
                                    </Select>
                                </Col>
                                <Col span={8} style={{}}>
                                    <p>Bas Risque (1-6%)</p>
                                    <Select style={{ width: '310px' }}>
                                        <Option value="Cancer intra-thyroidien">Cancer intra-thyroidien</Option>
                                        <Option value="<5 meta gg">{'<5 meta gg'}</Option>
                                        <Option value="<2mm">{'<2mm'}</Option>
                                    </Select>                                </Col>
                            </>
                        )}



                    </Row>

                    {Specialite === 'Hématologie' && Organe === 'Leucémie Lymphoïde Chronique' || Specialite === 'Cancers Rares' || Specialite === 'Pédiatrie' && (Organe === 'Corticosurrenale' || Organe === 'Ovaire' || Organe === 'Rein' || Organe === 'Rétropéritoine' || Organe === 'Oesophage' || Organe === 'Colo-rectum') && (
                        <>
                            <Col span={8}>
                                <p className='inputPatient2'>Biologie moléculaire/Autre</p>


                                <Select
                                    value={biologieMoleculaire}
                                    onChange={handleChangeBiomoleculaire}
                                    style={{ width: '950px', height: "33px", }}
                                >
                                    {optionBiologieMoleculaire.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>                </Col>


                            {AfficheChexbox8 && (
                                <Col span={24} style={{ marginTop: '20px' }}>
                                    <Checkbox
                                        checked={BiologieMoleculaireMutationTp53['Mutation Tp53'] === "Oui"}
                                        onChange={(e) => Mutation('Mutation Tp53', e.target.checked ? "Oui" : "Non")}
                                    >
                                        Oui
                                    </Checkbox>

                                    <Checkbox
                                        checked={BiologieMoleculaireMutationTp53['Mutation Tp53'] === "Non"}
                                        onChange={(e) => Mutation('Mutation Tp53', e.target.checked ? "Non" : "Oui")}
                                    >
                                        Non
                                    </Checkbox>
                                </Col>
                            )}


                            {AfficheChexbox32 && (
                                <Col span={24} style={{ marginTop: '20px' }}>
                                    <Checkbox
                                        checked={BiologieMoleculaireMutationTp53['IHC'] === "KI 67"}
                                        onChange={(e) => Mutation('IHC', e.target.checked ? "KI 67" : "AC anti-bcl2")}
                                    >
                                        KI 67
                                    </Checkbox>

                                    <Checkbox
                                        checked={BiologieMoleculaireMutationTp53['IHC'] === "AC anti-bcl2"}
                                        onChange={(e) => Mutation('IHC', e.target.checked ? "AC anti-bcl2" : "KI 67")}
                                    >
                                        AC anti-bcl2
                                    </Checkbox>
                                </Col>
                            )}

                            {AfficheChexbox33 && (
                                <Col span={24} style={{ marginTop: '20px' }}>
                                    <Checkbox
                                        checked={BiologieMoleculaireMutationTp53['IHC'] === "chromogranine A"}
                                        onChange={(e) => Mutation('IHC', e.target.checked ? "chromogranine A" : "synaptophysine")}
                                    >
                                        chromogranine A
                                    </Checkbox>

                                    <Checkbox
                                        checked={BiologieMoleculaireMutationTp53['IHC'] === "synaptophysine"}
                                        onChange={(e) => Mutation('IHC', e.target.checked ? "synaptophysine" : "chromogranine A")}
                                    >
                                        synaptophysine
                                    </Checkbox>
                                </Col>
                            )}

                        </>

                    )}

                    {Specialite === 'Pédiatrie' && Organe === 'Ovaire' && (
                        <Row gutter={6}>
                            <Col span={6}>
                                <p className='inputPatient2'>K-ras, BRAF et PIK3CA</p>
                                <Select mode="tags"
                                    name='KraBrafPik'
                                    value={KraBrafPik}
                                    onChange={(value) => setKraBrafPik(value)}
                                    style={{ width: "230px" }}>
                                    <Option key="K-ras" value="K-ras">K-ras</Option>
                                    <Option key="BRAF" value="BRAF">BRAF</Option>
                                    <Option key="PIK3CA" value="K-ras">PIK3CA</Option>
                                </Select>
                            </Col>
                            <Col span={6}>
                                <p className='inputPatient2'>FOXL2</p>
                                <Input placeholder='FOXL2'
                                    name='Fox'
                                    value={Fox}
                                    onChange={(value) => setFox(value)} /></Col>
                            <Col span={6}>
                                <p className='inputPatient2'>BRCA</p>
                                <Select mode="tags" style={{ width: "230px" }}>
                                    <Option key="BRCA1" value="BRCA1">BRCA1</Option>
                                    <Option key="BRCA2" value="BRCA2">BRCA2</Option>                                </Select>
                            </Col>
                            <Col span={6}>
                                <p className='inputPatient2'>p53</p>
                                <Input placeholder='p53'
                                    name='PcinquanteTrois'
                                    value={PcinquanteTrois}
                                    onChange={(value) => setPcinquanteTrois(value)} /></Col>
                        </Row>
                    )}

                    {(Specialite === 'ORL' && (Organe === 'Cavum' || Organe === 'Nasopharynx')) && (
                        <>
                            <p className='inputPatient2'>Facteurs pronostiques</p>
                            <Row gutter={10}>
                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Invasion locorégionale</p>
                                    <Select
                                        mode="tags"
                                        value={invasion}
                                        onChange={(value) => setInvasion(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionInvasion.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Envahissement ganglionnaire N+</p>
                                    <Select
                                        mode="tags"
                                        value={Envahissement}
                                        onChange={(value) => setEnvahissement(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionEnvahissement.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Taille & le volume tumoral</p>
                                    <Select
                                        mode="tags"
                                        value={tailleTumoral}
                                        onChange={(value) => setTailleTumoral(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionTailleTumoral.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Sexe</p>
                                    <Select
                                        mode="tags"
                                        value={Sexe}
                                        // onChange={(value) => setSexe(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionSexe.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                            <Row gutter={6}>

                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Age jeune</p>
                                    <Select
                                        mode="tags"
                                        value={ageJeune}
                                        onChange={(value) => setAgeJeune(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionAgeJeune.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Taux des AC préTTT et post-TTT</p>
                                    <Select
                                        mode="tags"
                                        value={taux}
                                        onChange={(value) => setTaux(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionTaux.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Hémoglobine</p>
                                    <Select
                                        mode="tags"
                                        value={Hemoglobine}
                                        onChange={(value) => setHemoglobine(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionHemoglobine.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>D’autres facteurs moléculaires</p>
                                    <Select
                                        mode="tags"
                                        value={facteurMoleculaire}
                                        onChange={(value) => setfacteurMoleculaire(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionFacteurMoleculaire.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                        </>

                    )}


                    {(Specialite === 'Thorax' && Organe === 'Poumon') &&
                        (

                            <Col span={24}>
                                <p className='inputPatient2'>immunohistochimie</p>

                                <Select
                                    // mode="tags"
                                    value={immuno}
                                    onChange={(value) => setImmuno(value)}
                                    style={{ width: (Specialite === 'Thorax' && (Organe === 'Parois thoracique' || Organe === 'Plèvre' || Organe === 'Poumon')) ? "950px" : "470px", height: "33px", }}
                                >
                                    {optionImmuno.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        )}

                    {AfficheChexbox13 && (
                        <>
                            <Row gutter={6}>
                                <Col span={6}>
                                    <p className='inputPatient2'>EGFR</p>
                                    <Select value={EGFR} onChange={(value) => setEGFR(value)} style={{ width: "230px" }}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <p className='inputPatient2'>ALK</p>
                                    <Select value={ALK} onChange={(value) => setALK(value)} style={{ width: "230px" }}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <p className='inputPatient2'>ROSI</p>
                                    <Select style={{ width: "230px" }} value={ROSI} onChange={(value) => setROSI(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <p className='inputPatient2'>c-MET</p>
                                    <Select style={{ width: "230px" }} value={CMET} onChange={(value) => setCMET(value)} >
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row gutter={6}>
                                <Col span={6}>
                                    <p className='inputPatient2'>HER2</p>
                                    <Select style={{ width: "230px" }} value={HER2} onChange={(value) => setHER2(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <p className='inputPatient2'>KRAS G12C</p>
                                    <Select style={{ width: "230px" }} value={KRAS} onChange={(value) => setKRAS(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <p className='inputPatient2'>BRAF</p>
                                    <Select style={{ width: "230px" }} value={BRAF} onChange={(value) => setBRAF(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <p className='inputPatient2'>NTRK</p>
                                    <Select style={{ width: "230px" }} value={NTRK} onChange={(value) => setNTRK(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                            </Row>

                        </>
                    )}

                    {AfficheChexbox14 && (
                        <>
                            <Row gutter={6}>
                                <Col span={12}>
                                    <p className='inputPatient2'>RET</p>
                                    <Select style={{ width: "470px" }} value={RET} onChange={(value) => setRET(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={12}>
                                    <p className='inputPatient2'>NRG1</p>
                                    <Select style={{ width: "470px" }} value={NRG1} onChange={(value) => setNRG1(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                            </Row>

                        </>
                    )}

                    <Row gutter={6}>
                        {(Specialite === 'Gynécologie' && Organe === 'Néoplasies trophoblastiques gestationnelles') ?
                            (
                                <>
                                    <Col span={24}>
                                        <p className='inputPatient2'>Stade clinique</p>
                                        <Select
                                            mode='tags'
                                            name="stade clinique"
                                            // placeholder="Sélectionnez le Tumeur primitive"
                                            value={tumeur}
                                            style={{ width: "100%" }}
                                            onChange={(value) => setTumeur(value)}
                                        >
                                            <OptGroup label="Stade">
                                                {optionTumeur.map((option) => (
                                                    <Option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </Option>
                                                ))}
                                            </OptGroup>

                                            <OptGroup label="Score">
                                                {optionAdenophatie.map((option) => (
                                                    <Option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </Option>
                                                ))}
                                            </OptGroup>

                                            <OptGroup label="OMS FIGO">
                                                {optionMétastase.map((option) => (
                                                    <Option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </Option>
                                                ))}
                                            </OptGroup>
                                        </Select>
                                    </Col>
                                </>
                            ) :
                            (Specialite === 'Pédiatrie' && Organe === 'Tumeurs orbitaires') ?
                                (
                                    <>
                                        <Col span={24}>
                                            <p className='inputPatient2'>Stade clinique
                                            </p>
                                            International Neuroblastoma Risk Group Staging System (INRGSS)/ système international de stadification par catégorie de risque du neuroblastome

                                            <Select
                                                mode='tags'
                                                name="stade clinique"
                                                // placeholder="Sélectionnez le Tumeur primitive"
                                                value={tumeur}
                                                style={{ width: "100%" }}
                                                onChange={(value) => setTumeur(value)}
                                            >
                                                {optionTumeur.map((option) => (
                                                    <Option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </Option>
                                                ))}


                                            </Select>
                                        </Col>
                                    </>) :
                                (Specialite === 'Pédiatrie' && Organe === 'Os') ?
                                    (
                                        <>
                                            <Col span={24}>
                                                <p className='inputPatient2'>Stade clinique
                                                </p>

                                                <Select
                                                    mode='tags'
                                                    name="stade clinique"
                                                    // placeholder="Sélectionnez le Tumeur primitive"
                                                    value={tumeur}
                                                    style={{ width: "100%" }}
                                                    onChange={(value) => setTumeur(value)}
                                                >
                                                    {optionTumeur.map((option) => (
                                                        <Option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </Option>
                                                    ))}


                                                </Select>
                                            </Col>
                                        </>):(Specialite === 'Urologie') ?
                                    (
                                        <>
                                            <Col span={24}>
                                                <p className='inputPatient2'>Stade clinique
                                                </p>

                                                <Select
                                                    mode='tags'
                                                    name="stade clinique"
                                                    // placeholder="Sélectionnez le Tumeur primitive"
                                                    value={tumeur}
                                                    style={{ width: "100%" }}
                                                    onChange={(value) => setTumeur(value)}
                                                >
                                                    {optionTumeur.map((option) => (
                                                        <Option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </Option>
                                                    ))}


                                                </Select>
                                            </Col>
                                        </>)
                                    : (Specialite === 'Pédiatrie' && Organe === 'Rétropéritoine') ?
                                        (
                                            <>
                                                <Col span={24}>
                                                    <p className='inputPatient2'>Stade clinique
                                                    </p>

                                                    <Select
                                                        mode='tags'
                                                        name="stade clinique"
                                                        // placeholder="Sélectionnez le Tumeur primitive"
                                                        value={tumeur}
                                                        style={{ width: "100%" }}
                                                        onChange={(value) => setTumeur(value)}
                                                    >
                                                        {optionTumeur.map((option) => (
                                                            <Option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </Option>
                                                        ))}


                                                    </Select>
                                                </Col>
                                            </>)
                                        :
                                        (Specialite === 'Pédiatrie' && Organe === 'Oesophage' || Organe === 'Colo-rectum') ?
                                            (
                                                <>
                                                    <Col span={12}>
                                                        <p className='inputPatient2'>Adénok
                                                        </p>
                                                        <Input
                                                            placeholder="Adénok"
                                                            name='Adenok'
                                                            value={Adenok}
                                                            onChange={(e) => setAdenok(e.target.value)}
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Col>
                                                    <Col span={12}>
                                                        <p className='inputPatient2'>Classification TNM (cf adultes)
                                                        </p>
                                                        <Select
                                                            mode='tags'
                                                            name="stade clinique"
                                                            // placeholder="Sélectionnez le Tumeur primitive"
                                                            value={tumeur}
                                                            style={{ width: "100%" }}
                                                            onChange={(value) => setTumeur(value)}
                                                        >
                                                            {optionTumeur.map((option) => (
                                                                <Option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </Option>
                                                            ))}


                                                        </Select>
                                                    </Col>
                                                </>) :
                                            (Specialite === 'Pédiatrie' && Organe === 'Oesophage') ?
                                                (
                                                    <>

                                                        <Col span={12}>
                                                            <p className='inputPatient2'>Classification TNM (cf adultes)
                                                            </p>
                                                            <Select
                                                                mode='tags'
                                                                name="stade clinique"
                                                                // placeholder="Sélectionnez le Tumeur primitive"
                                                                value={tumeur}
                                                                style={{ width: "100%" }}
                                                                onChange={(value) => setTumeur(value)}
                                                            >
                                                                {optionTumeur.map((option) => (
                                                                    <Option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </Option>
                                                                ))}


                                                            </Select>
                                                        </Col>
                                                    </>) :
                                                (Specialite === 'Hématologie' && Organe === 'Syndromes myélodysplasiques') ?
                                                    (
                                                        <>

                                                            <Col span={24}>
                                                                <p className='inputPatient2'>Classification OMS des syndromes myélodysplasiques 2016
                                                                </p>
                                                                <Select
                                                                    mode='tags'
                                                                    name="ClassificationOmsSyndromesMyelodysplasiques"
                                                                    // placeholder="Sélectionnez le Tumeur primitive"
                                                                    value={ClassificationOmsSyndromesMyelodysplasiques}
                                                                    style={{ width: "100%" }}
                                                                    onChange={(value) => setClassificationOmsSyndromesMyelodysplasiques(value)}
                                                                >
                                                                    {optionClassificationOMS.map((option) => (
                                                                        <Option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </Option>
                                                                    ))}


                                                                </Select>
                                                            </Col>
                                                        </>) :

                                                    (Specialite === 'Hématologie' && Organe === 'Syndrome Myéloprolifératif Ph1 négative') ?
                                                        (
                                                            <>

                                                                <Col span={24}>
                                                                    <p className='inputPatient2'>Classification OMS 2016 des syndromes myéloprolifératifs Philadelphie négatif
                                                                    </p>
                                                                    <Select
                                                                        mode='tags'
                                                                        name="ClassificationOmsSyndromesMyelodysplasiques"
                                                                        // placeholder="Sélectionnez le Tumeur primitive"
                                                                        value={ClassificationOmsSyndromesMyelodysplasiques}
                                                                        style={{ width: "100%" }}
                                                                        onChange={(value) => setClassificationOmsSyndromesMyelodysplasiques(value)}
                                                                    >
                                                                        {optionClassificationOMS.map((option) => (
                                                                            <Option key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </Option>
                                                                        ))}


                                                                    </Select>
                                                                </Col>
                                                            </>) :

                                                        (Specialite === 'Pédiatrie' && Organe === 'Rein') ?
                                                            (
                                                                <>
                                                                    <Col span={12}>
                                                                        <p className='inputPatient2'>Stade clinique
                                                                        </p>

                                                                        <Select
                                                                            mode='tags'
                                                                            name="stade clinique"
                                                                            // placeholder="Sélectionnez le Tumeur primitive"
                                                                            value={tumeur}
                                                                            style={{ width: "100%" }}
                                                                            onChange={(value) => setTumeur(value)}
                                                                        >
                                                                            {optionTumeur.map((option) => (
                                                                                <Option key={option.value} value={option.value}>
                                                                                    {option.label}
                                                                                </Option>
                                                                            ))}


                                                                        </Select>
                                                                    </Col>
                                                                    <Col span={12}>
                                                                        <p className='inputPatient2'>Classification
                                                                        </p>

                                                                        <Select
                                                                            mode='tags'
                                                                            name="Classification"
                                                                            // placeholder="Sélectionnez le Tumeur primitive"
                                                                            value={ClassificationAltmann}
                                                                            style={{ width: "100%" }}
                                                                            onChange={(value) => setClassificationAltmann(value)}
                                                                        >
                                                                            {optionClassification.map((option) => (
                                                                                <Option key={option.value} value={option.value}>
                                                                                    {option.label}
                                                                                </Option>
                                                                            ))}


                                                                        </Select>
                                                                    </Col>
                                                                </>) :
                                                            (Specialite === 'Pédiatrie' && Organe === 'Voie excretrice') ?
                                                                (
                                                                    <>
                                                                        <Col span={24}>
                                                                            <p className='inputPatient2'>Stade clinique
                                                                            </p>

                                                                            <Select
                                                                                mode='tags'
                                                                                name="stade clinique"
                                                                                // placeholder="Sélectionnez le Tumeur primitive"
                                                                                value={tumeur}
                                                                                style={{ width: "100%" }}
                                                                                onChange={(value) => setTumeur(value)}
                                                                            >
                                                                                <OptGroup label="T : Tumeur Primaire">
                                                                                    {optionTumeur.map((option) => (
                                                                                        <Option key={option.value} value={option.value}>
                                                                                            {option.label}
                                                                                        </Option>
                                                                                    ))}
                                                                                </OptGroup>

                                                                                <OptGroup label="N : Ganglions Lymphatiques Régionaux">
                                                                                    {optionAdenophatie.map((option) => (
                                                                                        <Option key={option.value} value={option.value}>
                                                                                            {option.label}
                                                                                        </Option>
                                                                                    ))}
                                                                                </OptGroup>

                                                                                <OptGroup label="M : Métastases à Distance">
                                                                                    {optionMétastase.map((option) => (
                                                                                        <Option key={option.value} value={option.value}>
                                                                                            {option.label}
                                                                                        </Option>
                                                                                    ))}
                                                                                </OptGroup>


                                                                            </Select>
                                                                        </Col>
                                                                    </>) :

                                                                (Specialite === 'Pédiatrie' && Organe === 'Ovaire') ?
                                                                    (
                                                                        <>
                                                                            <Col span={24}>
                                                                                <p className='inputPatient2'>Facteurs pronostiques
                                                                                </p>

                                                                                <Select
                                                                                    mode='tags'
                                                                                    name="FacteursPronostique"
                                                                                    // placeholder="Sélectionnez le Tumeur primitive"
                                                                                    value={FacteursPronostiques}
                                                                                    style={{ width: "100%" }}
                                                                                    onChange={(value) => setFacteursPronostiques(value)}
                                                                                >
                                                                                    {optionFacteurPronostique.map((option) => (
                                                                                        <Option key={option.value} value={option.value}>
                                                                                            {option.label}
                                                                                        </Option>
                                                                                    ))}


                                                                                </Select>
                                                                            </Col>
                                                                        </>) :
                                                                    (Specialite === 'Pédiatrie' && Organe === 'Corticosurrenale') ?
                                                                        (
                                                                            <>
                                                                                <Col span={24}>
                                                                                    <p className='inputPatient2'>Stade clinique
                                                                                    </p>
                                                                                    International Neuroblastoma Risk Group Staging System (INRGSS)/ système international de stadification par catégorie de risque du neuroblastome

                                                                                    <Select
                                                                                        mode='tags'
                                                                                        name="stade clinique"
                                                                                        // placeholder="Sélectionnez le Tumeur primitive"
                                                                                        value={tumeur}
                                                                                        style={{ width: "100%" }}
                                                                                        onChange={(value) => setTumeur(value)}
                                                                                    >
                                                                                        {optionTumeur.map((option) => (
                                                                                            <Option key={option.value} value={option.value}>
                                                                                                {option.label}
                                                                                            </Option>
                                                                                        ))}


                                                                                    </Select>
                                                                                </Col>
                                                                            </>) :
                                                                        (Specialite === 'Pédiatrie' && Organe === 'Foie/Pédiatrie') ?
                                                                            (
                                                                                <>
                                                                                    <Col span={24}>
                                                                                        <p className='inputPatient2'>Stade clinique:LOCALISE OU METASTATIQUE
                                                                                        </p>

                                                                                        <Select
                                                                                            mode='tags'
                                                                                            name="stade clinique"
                                                                                            // placeholder="Sélectionnez le Tumeur primitive"
                                                                                            value={tumeur}
                                                                                            style={{ width: "100%" }}
                                                                                            onChange={(value) => setTumeur(value)}
                                                                                        >
                                                                                            {optionTumeur.map((option) => (
                                                                                                <Option key={option.value} value={option.value}>
                                                                                                    {option.label}
                                                                                                </Option>
                                                                                            ))}


                                                                                        </Select>
                                                                                    </Col>
                                                                                </>) :
                                                                            (Specialite === 'Pédiatrie' && Organe === 'T. Sacro-coccygienne') ?
                                                                                (
                                                                                    <>
                                                                                        <Col span={24}>
                                                                                            <p className='inputPatient2'>Classification de Altmann:
                                                                                            </p>

                                                                                            <Select
                                                                                                mode='tags'
                                                                                                name="Classification de Altmann"
                                                                                                // placeholder="Sélectionnez le Tumeur primitive"
                                                                                                value={tumeur}
                                                                                                style={{ width: "100%" }}
                                                                                                onChange={(value) => setTumeur(value)}
                                                                                            >
                                                                                                {optionTumeur.map((option) => (
                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                        {option.label}
                                                                                                    </Option>
                                                                                                ))}


                                                                                            </Select>
                                                                                        </Col>
                                                                                    </>) :
                                                                                (Specialite === 'Pédiatrie' && Organe === 'Tumeurs maxillo faciales') ?
                                                                                    (
                                                                                        <>
                                                                                            <Col span={24}>
                                                                                                <p className='inputPatient2'>Stade clinique
                                                                                                </p>
                                                                                                Système de stadification pré-traitement basé sur le TNM
                                                                                                <Select
                                                                                                    mode='tags'
                                                                                                    name="stade clinique"
                                                                                                    // placeholder="Sélectionnez le Tumeur primitive"
                                                                                                    value={tumeur}
                                                                                                    style={{ width: "100%" }}
                                                                                                    onChange={(value) => setTumeur(value)}
                                                                                                >
                                                                                                    {optionTumeur.map((option) => (
                                                                                                        <Option key={option.value} value={option.value}>
                                                                                                            {option.label}
                                                                                                        </Option>
                                                                                                    ))}


                                                                                                </Select>
                                                                                            </Col>
                                                                                        </>) :
                                                                                    (Specialite === 'Pédiatrie' && Organe === 'Tumeurs médiastinales') ?
                                                                                        (
                                                                                            <>
                                                                                                <Col span={24}>
                                                                                                    <p className='inputPatient2'>Stade clinique
                                                                                                    </p>
                                                                                                    International Neuroblastoma Risk Group Staging System (INRGSS)/ système international de stadification par catégorie de risque du neuroblastome                                                     <Select
                                                                                                        mode='tags'
                                                                                                        name="stade clinique"
                                                                                                        // placeholder="Sélectionnez le Tumeur primitive"
                                                                                                        value={tumeur}
                                                                                                        style={{ width: "100%" }}
                                                                                                        onChange={(value) => setTumeur(value)}
                                                                                                    >
                                                                                                        {optionTumeur.map((option) => (
                                                                                                            <Option key={option.value} value={option.value}>
                                                                                                                {option.label}
                                                                                                            </Option>
                                                                                                        ))}


                                                                                                    </Select>
                                                                                                </Col>
                                                                                            </>)

                                                                                        : (Specialite === 'Pédiatrie' && Organe === 'Tumeurs cerebrales') ?
                                                                                            (
                                                                                                <>
                                                                                                    <Col span={24}>
                                                                                                        <p className='inputPatient2'>Stade clinique</p>
                                                                                                        <Select
                                                                                                            mode='tags'
                                                                                                            name="stade clinique"
                                                                                                            // placeholder="Sélectionnez le Tumeur primitive"
                                                                                                            value={tumeur}
                                                                                                            style={{ width: "100%" }}
                                                                                                            onChange={(value) => setTumeur(value)}
                                                                                                        >
                                                                                                            <OptGroup label="T">
                                                                                                                {optionTumeur.map((option) => (
                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                        {option.label}
                                                                                                                    </Option>
                                                                                                                ))}
                                                                                                            </OptGroup>

                                                                                                            <OptGroup label="N">
                                                                                                                {optionAdenophatie.map((option) => (
                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                        {option.label}
                                                                                                                    </Option>
                                                                                                                ))}
                                                                                                            </OptGroup>

                                                                                                            <OptGroup label="M">
                                                                                                                {optionMétastase.map((option) => (
                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                        {option.label}
                                                                                                                    </Option>
                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        </Select>
                                                                                                    </Col>
                                                                                                </>
                                                                                            )
                                                                                            : (Specialite === 'Cancers Rares') ?
                                                                                                (
                                                                                                    <>
                                                                                                        <Col span={24}>
                                                                                                            <p className='inputPatient2'>Stade clinique</p>
                                                                                                            <Select
                                                                                                                mode='tags'
                                                                                                                name="stade clinique"
                                                                                                                // placeholder="Sélectionnez le Tumeur primitive"
                                                                                                                value={tumeur}
                                                                                                                style={{ width: "100%" }}
                                                                                                                onChange={(value) => setTumeur(value)}
                                                                                                            >
                                                                                                                <OptGroup label="T">
                                                                                                                    {optionTumeur.map((option) => (
                                                                                                                        <Option key={option.value} value={option.value}>
                                                                                                                            {option.label}
                                                                                                                        </Option>
                                                                                                                    ))}
                                                                                                                </OptGroup>

                                                                                                                <OptGroup label="N">
                                                                                                                    {optionAdenophatie.map((option) => (
                                                                                                                        <Option key={option.value} value={option.value}>
                                                                                                                            {option.label}
                                                                                                                        </Option>
                                                                                                                    ))}
                                                                                                                </OptGroup>

                                                                                                                <OptGroup label="M">
                                                                                                                    {optionMétastase.map((option) => (
                                                                                                                        <Option key={option.value} value={option.value}>
                                                                                                                            {option.label}
                                                                                                                        </Option>
                                                                                                                    ))}
                                                                                                                </OptGroup>
                                                                                                            </Select>
                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <p className='inputPatient2'>Consultation oncogénétique </p>

                                                                                                            <Select mode='tags'
                                                                                                                name='ConsultationOncogetique'
                                                                                                                value={ConsultationOncogetique}
                                                                                                                onChange={(value) => setConsultationOncogetique(value)}
                                                                                                                style={{ width: "950px" }}>
                                                                                                                <Option value=""></Option>
                                                                                                            </Select>
                                                                                                        </Col>
                                                                                                    </>
                                                                                                )
                                                                                                : (Specialite === 'Hématologie' && (Organe === 'Lymphome Hodgkin adulte')) ? (
                                                                                                    <>
                                                                                                        <Col span={24}>
                                                                                                            <p className='inputPatient2'>Stade Ann Arbor</p>
                                                                                                            <Select
                                                                                                                mode='tags'
                                                                                                                // name="stade clinique"
                                                                                                                placeholder="Stade Ann Arbor"
                                                                                                                value={stadeAnnBoor}
                                                                                                                style={{ width: "100%" }}
                                                                                                                onChange={handleStadeChange}
                                                                                                            >
                                                                                                                {optionStadeAnnBoor.map((option) => (
                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                        {option.label}
                                                                                                                    </Option>
                                                                                                                ))}
                                                                                                            </Select>
                                                                                                            <p className='inputPatient2'>Facteurs pronostiques</p>
                                                                                                            {AfficheChexbox1 && (
                                                                                                                <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                                <Checkbox value="Oui"  checked={stadeAnnBoor.includes('X')}
                                                                                                                onChange={(e) => handleCheckboxChangeStadeAnbor('X', e.target.checked)}>Oui</Checkbox>
                                                                                                                <Checkbox value="Non" checked={stadeAnnBoor.includes('X')}
                                                                                                                onChange={(e) => handleCheckboxChangeStadeAnbor('X', e.target.checked)}>Non</Checkbox>
                                                                                                            </Col>
                                                                                                            )}

                                                                                                            {CheckOui && (
                                                                                                                <Row>
                                                                                                                    <p style={{ fontWeight: 650 }}>Décrire le résultat ici</p>
                                                                                                                    <Col span={24} >
                                                                                                                        <TextArea placeholder="Décrire ici..." name='ResultateFacteur' value={ResultatFacteur} onChange={(e) => setResultatFacteur(e.target.value)} style={{ width: '950px' }} />
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            )}
                                                                                                        </Col>


                                                                                                        {AfficheChexbox2 && (
                                                                                                            <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                                <p className='inputPatient2'>EORTC</p>
                                                                                                                <Checkbox value="Favorable"  onChange={(e) => handleCheckboxChangeStadeAnbor1(e.target.checked, 'EORTC')}>Favorable</Checkbox>
                                                                                                                <Checkbox value="Défavorable" onChange={(e) => handleCheckboxChangeStadeAnbor1(e.target.checked, 'EORTC')}>Défavorable</Checkbox>
                                                                                                            </Col>
                                                                                                        )}

                                                                                                        {AfficheChexbox3 && (
                                                                                                            <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                                <p className='inputPatient2'>IPS</p>
                                                                                                                <Checkbox value="Favorable"  onChange={(e) => handleCheckboxChangeStadeAnbor1(e.target.checked, 'IPS')}>Favorable</Checkbox>
                                                                                                                <Checkbox value="Défavorable" onChange={(e) => handleCheckboxChangeStadeAnbor1(e.target.checked, 'IPS')}>Défavorable</Checkbox>
                                                                                                            </Col>
                                                                                                        )}

                                                                                                            <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                        <p className='inputPatient2'>Facteurs pronostiques</p>

                                                                                                            <Select
                                                                                                                mode='tags'
                                                                                                                name="FacteursPronostique"
                                                                                                                // placeholder="Sélectionnez le Tumeur primitive"
                                                                                                                value={facteurPronostique}
                                                                                                                style={{ width: "100%" }}
                                                                                                                onChange={(value) => setFacteurPronostique(value)}
                                                                                                            >
                                                                                                                {optionFacteurPronostique.map((option) => (
                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                        {option.label}
                                                                                                                    </Option>
                                                                                                                ))}


                                                                                                            </Select>
                                                                                                            </Col>
                                                                                                        




                                                                                                    </>

                                                                                                )
                                                                                                    : (Specialite === 'Hématologie' && (Organe === 'Lymphome non hodgkinien adulte')) ? (
                                                                                                        <>
                                                                                                            <Col span={24}>
                                                                                                                <p className='inputPatient2'>Stade Ann Arbor</p>
                                                                                                                <Select
                                                                                                                    mode='tags'
                                                                                                                    // name="stade clinique"
                                                                                                                    placeholder="Stade Ann Arbor"
                                                                                                                    value={stadeAnnBoor}
                                                                                                                    style={{ width: "100%" }}
                                                                                                                    onChange={handleStadeChange1} >
                                                                                                                    {optionStadeAnnBoor.map((option) => (
                                                                                                                        <Option key={option.value} value={option.value}>
                                                                                                                            {option.label}
                                                                                                                        </Option>
                                                                                                                    ))}
                                                                                                                </Select>
                                                                                                            </Col>

                                                                                                            

                                                                                                            {AfficheChexbox1 && (
                                                                                                                <Row style={{ marginTop: "20px" }}>
                                                                                                                      <>
                                                                                                                    <Col span={24}>
                                                                                                                    <Checkbox value="Oui"  onChange={handleCheckboxChange}>Oui</Checkbox>
                                                                                                                    <Checkbox  value="Non"  onChange={() => setCheckOui(false)}>Non</Checkbox>
                                                                                                                    </Col>
                                                                                                                    </>
                                                                                                                </Row>
                                                                                                            )}

                                                                                                            {CheckOui && (
                                                                                                                <Row>
                                                                                                                    <p style={{ fontWeight: 650 }}>Décrire le résultat ici</p>
                                                                                                                    <Col span={24} >
                                                                                                                        <TextArea placeholder="Décrire ici..." name='ResultateFacteur' value={ResultatFacteur} onChange={(e) => setResultatFacteur(e.target.value)} style={{ width: '950px' }} />
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            )}

                                                                                                            <Row>
                                                                                                            {AfficheChexbox10 && (
                                                                                                                <>
                                                                                                                <p className="inputPatient2">Scores pronostiques</p>
                                                                                                                <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                                    <p className="inputPatient2">FLIPI</p>
                                                                                                                    <Checkbox value="faible" onChange={handleScorePronosticChange('faible')} checked={ScorePronostic.includes('faible')}>faible</Checkbox>
                                                                                                                    <Checkbox value="intermédiaire" onChange={handleScorePronosticChange('intermédiaire')} checked={ScorePronostic.includes('intermédiaire')}>intermédiaire</Checkbox>
                                                                                                                    <Checkbox value="défavorable" onChange={handleScorePronosticChange('défavorable')} checked={ScorePronostic.includes('défavorable')}>défavorable</Checkbox>
                                                                                                                </Col>
                                                                                                                </>
                                                                                                            )}

                                                                                                            {AfficheChexbox11 && (
                                                                                                                <>
                                                                                                                <p className="inputPatient2">Scores pronostiques</p>
                                                                                                                <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                                    <p className="inputPatient2">MIPI</p>
                                                                                                                    <Checkbox value="faible" onChange={handleScorePronosticChange('faible')} checked={ScorePronostic.includes('faible')}>faible</Checkbox>
                                                                                                                    <Checkbox value="intermédiaire" onChange={handleScorePronosticChange('intermédiaire')} checked={ScorePronostic.includes('intermédiaire')}>intermédiaire</Checkbox>
                                                                                                                    <Checkbox value="défavorable" onChange={handleScorePronosticChange('défavorable')} checked={ScorePronostic.includes('défavorable')}>défavorable</Checkbox>
                                                                                                                </Col>
                                                                                                                </>
                                                                                                            )}

                                                                                                            {AfficheChexbox12 && (
                                                                                                                <>
                                                                                                                <p className="inputPatient2">Scores pronostiques</p>
                                                                                                                <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                                    <p className="inputPatient2">IPI</p>
                                                                                                                    <Checkbox value="risque faible" onChange={handleScorePronosticChange('risque faible')} checked={ScorePronostic.includes('risque faible')}>risque faible</Checkbox>
                                                                                                                    <Checkbox value="faible intermédiaire" onChange={handleScorePronosticChange('faible intermédiaire')} checked={ScorePronostic.includes('faible intermédiaire')}>faible intermédiaire</Checkbox>
                                                                                                                    <Checkbox value="intermédiaire élevé" onChange={handleScorePronosticChange('intermédiaire élevé')} checked={ScorePronostic.includes('intermédiaire élevé')}>intermédiaire élevé</Checkbox>
                                                                                                                    <Checkbox value="haut risque" onChange={handleScorePronosticChange('haut risque')} checked={ScorePronostic.includes('haut risque')}>haut risque</Checkbox>
                                                                                                                </Col>
                                                                                                                </>
                                                                                                            )}
                                                                                                            </Row>


                                                                                                        </>


                                                                                                    )

                                                                                                        : (Specialite === 'Hématologie' && Organe === 'Leucémie Lymphoïde Chronique') ? (
                                                                                                            <>
                                                                                                                <Col span={24} >
                                                                                                                    <p className='inputPatient2'>Classification de Bine</p>
                                                                                                                    <Select mode='tags' style={{ width: "950px" }} value={ClassificationBinet} onChange={(value) => setClassificationBinet(value)}>
                                                                                                                        <option value="Stade A non actif">Stade A non actif</option>
                                                                                                                        <option value="Stade A actif">Stade A actif</option>
                                                                                                                        <option value="Stade A actif">Stade B</option>
                                                                                                                        <option value="Stade A actif">Stade C</option>
                                                                                                                    </Select>
                                                                                                                </Col>
                                                                                                            </>
                                                                                                        ) : (Specialite === 'Hématologie' && Organe === 'Leucémie myéloïde Chronique') ? (
                                                                                                            <>
                                                                                                                <Col span={8} >
                                                                                                                    <p className='inputPatient2'>Stade de Sokal</p>
                                                                                                                    <Select made='tags' style={{ width: "310px" }} value={StadeSokal} onChange={(value) => setStadeSokal(value)} >
                                                                                                                        <option value="Faible risque">Faible risque</option>
                                                                                                                        <option value="Risque intermédiaire">Risque intermédiaire</option>
                                                                                                                        <option value="Risque élevé">Risque élevé</option>
                                                                                                                    </Select>
                                                                                                                </Col>
                                                                                                                <Col span={8} >
                                                                                                                    <p className='inputPatient2'>Score de Hasford</p>
                                                                                                                    <Select style={{ width: "310px" }} value={ScoreHasford} onChange={(value) => setScoreHasford(value)}>
                                                                                                                        <option value="Faible risque">Faible risque</option>
                                                                                                                        <option value="Risque intermédiaire">Risque intermédiaire</option>
                                                                                                                        <option value="Risque élevé">Risque élevé</option>
                                                                                                                    </Select>
                                                                                                                </Col>
                                                                                                                <Col span={8} >
                                                                                                                    <p className='inputPatient2'>Score d’EUTOS</p>
                                                                                                                    <Select style={{ width: "310px" }} value={ScoreEUTOS} onChange={(value) => setScoreEUTOS(value)}>
                                                                                                                        <option value="Faible risque">Faible risque</option>
                                                                                                                        <option value="Risque élevé">Risque élevé</option>
                                                                                                                    </Select>
                                                                                                                </Col>
                                                                                                            </>
                                                                                                        )

                                                                                                            : (Specialite === 'Hématologie' && Organe === 'Myélome multiple') ? (
                                                                                                                <>
                                                                                                                    <Col span={24} >
                                                                                                                        <p className='inputPatient2'>IMWG 2014</p>
                                                                                                                        <Select style={{ width: "950px" }} value={IMWG2014} onChange={(value) => setIMWG2014(value)}>
                                                                                                                            <option value="Myélome symptomatique">Myélome symptomatique</option>
                                                                                                                            <option value="Myélome indolent">Myélome indolent</option>
                                                                                                                            <option value="Gammapathie monoclonale de signification indéterminée">Gammapathie monoclonale de signification indéterminée</option>
                                                                                                                            <option value="Plasmocytome solitaire">Plasmocytome solitaire</option>
                                                                                                                        </Select>
                                                                                                                    </Col>

                                                                                                                    <Col span={24} >
                                                                                                                        <p className='inputPatient2'>Classification de Salmon et Diurie</p>
                                                                                                                        <Select style={{ width: "950px" }} value={ClassificationSalmonDiurie} onChange={(value) => setClassificationSalmonDiurie(value)}>
                                                                                                                            <option value="Oui">Oui</option>
                                                                                                                            <option value="Non">Non</option>

                                                                                                                        </Select>
                                                                                                                        {ClassificationSalmonDiurie.includes("Oui") && (
                                                                                                                            <>
                                                                                                                                <div style={{ marginTop: "10px" }}>
                                                                                                                                    <p>Choisissez le stade :</p>
                                                                                                                                    <Select made='tags' style={{ width: "950px" }} value={ClassificationSalmonDiurieStade} onChange={(value) => setClassificationSalmonDiurieStade(value)}>
                                                                                                                                        <option value="Stade IA">Stade IA</option>
                                                                                                                                        <option value="Stade IB">Stade IB</option>
                                                                                                                                        <option value="Stade IB">Stade IIA</option>
                                                                                                                                        <option value="Stade IB">Stade IIB</option>
                                                                                                                                        <option value="Stade IB">Stade IIIA</option>
                                                                                                                                        <option value="Stade IB">Stade IIIB</option>

                                                                                                                                    </Select>
                                                                                                                                </div>

                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </Col>
                                                                                                                    <Col span={24} >
                                                                                                                        <p className='inputPatient2'>Classification ISS-révisée</p>
                                                                                                                        <Select style={{ width: "950px" }} value={ClassificationISSRevisee} onChange={(value) => setClassificationISSRevisee(value)}>
                                                                                                                            <option value="Oui">Oui</option>
                                                                                                                            <option value="Non">Non</option>

                                                                                                                        </Select>
                                                                                                                        {ClassificationISSRevisee.includes("Oui") && (
                                                                                                                            <>
                                                                                                                                <div style={{ marginTop: "10px" }}>
                                                                                                                                    <p>Choisissez le stade :</p>
                                                                                                                                    <Select style={{ width: "950px" }} made='tags' value={ClassificationISSReviseeStade} onChange={(value) => setClassificationISSReviseeStade(value)}>
                                                                                                                                        <option value="Stade IA">Stade IA</option>
                                                                                                                                        <option value="Stade IB">Stade IB</option>
                                                                                                                                        <option value="Stade IB">Stade IIA</option>
                                                                                                                                        <option value="Stade IB">Stade IIB</option>
                                                                                                                                        <option value="Stade IB">Stade IIIA</option>
                                                                                                                                        <option value="Stade IB">Stade IIIB</option>

                                                                                                                                    </Select>
                                                                                                                                </div>

                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </Col>
                                                                                                                    <Col span={24}>
                                                                                                                        <p className='inputPatient2'>Autres facteurs pronostic</p>
                                                                                                                        <Select
                                                                                                                            style={{ width: "950px" }}
                                                                                                                            placeholder="Choisissez un facteur"
                                                                                                                            onChange={handleFacteurChange}
                                                                                                                            mode="tags"
                                                                                                                            name='FactuersPronostiques'
                                                                                                                            value={FacteursPronostiques}
                                                                                                                        >
                                                                                                                            <Option value="CRP">CRP</Option>
                                                                                                                            <Option value="Anomalies cytogénétiques défavorable">Anomalies cytogénétiques défavorable</Option>
                                                                                                                        </Select>

                                                                                                                        {/* Afficher les cases à cocher si le facteur est sélectionné */}
                                                                                                                        {AfficheChexbox27 && (
                                                                                                                            <div style={{ marginTop: "10px" }}>
                                                                                                                                <Checkbox
                                                                                                                                    checked={facteurPronostique[FacteursPronostiques] === "Oui"}
                                                                                                                                    onChange={(e) => toggleFacteurStatus(FacteursPronostiques, e.target.checked ? "Oui" : null)}
                                                                                                                                >
                                                                                                                                    Oui
                                                                                                                                </Checkbox>
                                                                                                                                <Checkbox
                                                                                                                                    checked={facteurPronostique[FacteursPronostiques] === "Non"}
                                                                                                                                    onChange={(e) => toggleFacteurStatus(FacteursPronostiques, e.target.checked ? "Non" : null)}
                                                                                                                                >
                                                                                                                                    Non
                                                                                                                                </Checkbox>
                                                                                                                            </div>
                                                                                                                        )}

                                                                                                                        {AfficheChexbox28 && (
                                                                                                                            <div style={{ marginTop: "10px" }}>
                                                                                                                                <Checkbox
                                                                                                                                    checked={facteurPronostique[FacteursPronostiques] === "Oui"}
                                                                                                                                    onChange={(e) => toggleFacteurStatus(FacteursPronostiques, e.target.checked ? "Oui" : null)}
                                                                                                                                >
                                                                                                                                    Oui
                                                                                                                                </Checkbox>
                                                                                                                                <Checkbox
                                                                                                                                    checked={facteurPronostique[FacteursPronostiques] === "Non"}
                                                                                                                                    onChange={(e) => toggleFacteurStatus(FacteursPronostiques, e.target.checked ? "Non" : null)}
                                                                                                                                >
                                                                                                                                    Non
                                                                                                                                </Checkbox>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </Col>







                                                                                                                </>
                                                                                                            )


                                                                                                                : (Specialite === 'Hématologie' && Organe === 'LA Adulte') ? (
                                                                                                                    <>
                                                                                                                        <Col span={24}>
                                                                                                                            <p className='inputPatient2'>Stade pronostic</p>
                                                                                                                            <Select
                                                                                                                                made='tags'
                                                                                                                                name="stade pronostic"
                                                                                                                                placeholder="Stade pronostique"
                                                                                                                                value={tumeur}
                                                                                                                                style={{ width: "100%" }}
                                                                                                                                onChange={handleStadePronosticChange}
                                                                                                                            >
                                                                                                                                {optionTumeur.map((option) => (
                                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                                        {option.label}
                                                                                                                                    </Option>
                                                                                                                                ))}
                                                                                                                            </Select>
                                                                                                                        </Col>

                                                                                                                        {AfficheChexbox4 && (
                                                                                                                            <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                                                 <Checkbox value="Favorable" onChange={handleCheckboxChange1}>Favorable</Checkbox>
                                                                                                                                <Checkbox value="Intermédiaire" onChange={handleCheckboxChange1}>Intermédiaire</Checkbox>
                                                                                                                                <Checkbox value="Défavorable" onChange={handleCheckboxChange1}>Défavorable</Checkbox>
                                                                                                                            </Col>
                                                                                                                        )}
                                                                                                                        {AfficheChexbox5 && (
                                                                                                                            <>
                                                                                                                                <Col span={6} >
                                                                                                                                    <p className='inputPatient2'>Age</p>
                                                                                                                                    <Input placeholder="Age" value={Age} onChange={(e) => setAge(e.target.value)} />
                                                                                                                                </Col>
                                                                                                                                <Col span={6}>
                                                                                                                                    <p className='inputPatient2'>{"Hyperleucocytose(>50G/L)"}</p>
                                                                                                                                    <Input type='number' name='Hyperleucocytose' value={Hyperleucocytose} onChange={(e) => setHyperleucocytose(e.target.value)} placeholder="Hyperleucocytose" />
                                                                                                                                </Col>
                                                                                                                                <Col span={6} >
                                                                                                                                    <p className='inputPatient2'>Atteinte testiculaire</p>
                                                                                                                                    <Select style={{ width: "230px" }} value={AtteinteTesticulaire} onChange={(value) => setAtteinteTesticulaire(value)}>
                                                                                                                                        <option value="Oui">Oui</option>
                                                                                                                                        <option value="Non">Non</option>
                                                                                                                                    </Select>
                                                                                                                                </Col>
                                                                                                                                <Col span={6} >
                                                                                                                                    <p className='inputPatient2'>Atteinte LCR</p>
                                                                                                                                    <Select style={{ width: "230px" }} value={AtteinteLCR} onChange={(value) => setAtteinteLCR(value)}>
                                                                                                                                        <option value="Oui">Oui</option>
                                                                                                                                        <option value="Non">Non</option>
                                                                                                                                    </Select>
                                                                                                                                </Col>

                                                                                                                                <Col span={8} >
                                                                                                                                    <p className='inputPatient2'>Corticosensibilité</p>
                                                                                                                                    <Select style={{ width: "310px" }} value={CorticoSensibilite} onChange={(value) => setCorticoSensibilite(value)}>
                                                                                                                                        <option value="Oui">Oui</option>
                                                                                                                                        <option value="Non">Non</option>
                                                                                                                                    </Select>
                                                                                                                                </Col>
                                                                                                                                <Col span={8} >
                                                                                                                                    <p className='inputPatient2'>Facteurs génétiques de mauvais pronostic</p>
                                                                                                                                    <Select style={{ width: "310px" }} value={FacteurGenetique} onChange={(value) => setFacteurGenetique(value)}>
                                                                                                                                        <option value="Oui">Oui</option>
                                                                                                                                        <option value="Non">Non</option>
                                                                                                                                    </Select>
                                                                                                                                </Col>
                                                                                                                                <Col span={8} >
                                                                                                                                    <p className='inputPatient2'>Phénotype</p>
                                                                                                                                    <Select style={{ width: "310px" }} value={Phenotype} onChange={(value) => setPhenotype(value)}>
                                                                                                                                        <option value="Oui">B</option>
                                                                                                                                        <option value="Non">T</option>
                                                                                                                                    </Select>
                                                                                                                                </Col>
                                                                                                                            </>
                                                                                                                        )}

                                                                                                                    </>)

                                                                                                                    : (Specialite === 'Urologie' && Organe === 'Vessie/Arbre urinaire') ? (
                                                                                                                        <>
                                                                                                                            <Col span={24}>
                                                                                                                                <p className='inputPatient2'>Stade clinique :</p>
                                                                                                                                <Select
                                                                                                                                    made='tags'
                                                                                                                                    name="Stade clinique"
                                                                                                                                    placeholder="Stade clinique"
                                                                                                                                    value={tumeur}
                                                                                                                                    style={{ width: "100%" }}
                                                                                                                                    onChange={(value) => setTumeur(value)}
                                                                                                                                >
                                                                                                                                    {optionTumeur.map((option) => (
                                                                                                                                        <Option key={option.value} value={option.value}>
                                                                                                                                            {option.label}
                                                                                                                                        </Option>
                                                                                                                                    ))}
                                                                                                                                </Select>
                                                                                                                            </Col>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                        : (Specialite === 'ORL' && (Organe === 'Cavité buccale' || Organe === 'Pharynx' || Organe === 'Larynx' || Organe === 'Cavité nasale' || Organe === 'Lévre' || Organe === 'Sinus' || Organe === 'glandes salivaire')) ? (
                                                                                                                            <>
                                                                                                                                <Col span={24}>
                                                                                                                                    <p className='inputPatient2'>Stade clinique : TNM Classification UICC</p>
                                                                                                                                    <Select
                                                                                                                                        made='tags'
                                                                                                                                        name="stade clinique"
                                                                                                                                        placeholder="Sélectionnez le Tumeur primitive"
                                                                                                                                        value={tumeur}
                                                                                                                                        style={{ width: "100%" }}
                                                                                                                                        onChange={(value) => setTumeur(value)}
                                                                                                                                    >
                                                                                                                                        <OptGroup label="T">
                                                                                                                                            {optionTumeur.map((option) => (
                                                                                                                                                <Option key={option.value} value={option.value}>
                                                                                                                                                    {option.label}
                                                                                                                                                </Option>
                                                                                                                                            ))}
                                                                                                                                        </OptGroup>

                                                                                                                                        <OptGroup label="N">
                                                                                                                                            {optionAdenophatie.map((option) => (
                                                                                                                                                <Option key={option.value} value={option.value}>
                                                                                                                                                    {option.label}
                                                                                                                                                </Option>
                                                                                                                                            ))}
                                                                                                                                        </OptGroup>

                                                                                                                                        <OptGroup label="M">
                                                                                                                                            {optionMétastase.map((option) => (
                                                                                                                                                <Option key={option.value} value={option.value}>
                                                                                                                                                    {option.label}
                                                                                                                                                </Option>
                                                                                                                                            ))}
                                                                                                                                        </OptGroup>
                                                                                                                                    </Select>
                                                                                                                                </Col>
                                                                                                                            </>

                                                                                                                        )
                                                                                                                            : (Specialite === 'ORL' && (Organe === 'Thyroide' || Organe === 'Cavum' || Organe === 'Nasopharynx')) ? (
                                                                                                                                <>
                                                                                                                                    <Col span={24}>
                                                                                                                                        <p className='inputPatient2'>Stade clinique : TNM </p>
                                                                                                                                        <Select
                                                                                                                                            made='tags'
                                                                                                                                            name="stade clinique"
                                                                                                                                            placeholder="Sélectionnez le Tumeur primitive"
                                                                                                                                            value={tumeur}
                                                                                                                                            style={{ width: "100%" }}
                                                                                                                                            onChange={(value) => setTumeur(value)}
                                                                                                                                        >
                                                                                                                                            <OptGroup label="T">
                                                                                                                                                {optionTumeur.map((option) => (
                                                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                                                        {option.label}
                                                                                                                                                    </Option>
                                                                                                                                                ))}
                                                                                                                                            </OptGroup>

                                                                                                                                            <OptGroup label="N">
                                                                                                                                                {optionAdenophatie.map((option) => (
                                                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                                                        {option.label}
                                                                                                                                                    </Option>
                                                                                                                                                ))}
                                                                                                                                            </OptGroup>

                                                                                                                                            <OptGroup label="M">
                                                                                                                                                {optionMétastase.map((option) => (
                                                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                                                        {option.label}
                                                                                                                                                    </Option>
                                                                                                                                                ))}
                                                                                                                                            </OptGroup>
                                                                                                                                        </Select>
                                                                                                                                    </Col>
                                                                                                                                </>

                                                                                                                            ) : null}

                        {Specialite === 'Pédiatrie' && Organe === 'Os' && (
                            <>
                                <Col span={12}>
                                    <p className='inputPatient2'>Osteosarcome : Score de Huvos permet d'adapter le traitement en post-chirurgie</p>
                                    <Select
                                        name="OsteosarcomeSH"
                                        placeholder="OsteosarcomeSH"
                                        value={OsteosarcomeSH}
                                        style={{ width: "100%" }}
                                        onChange={(value) => setOsteosarcomeSH(value)}
                                    >
                                        {optionOsteosarcomeSH.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={12}>
                                    <p className='inputPatient2'>Osteosarcome : Marges de résection</p>
                                    <Select
                                        name="OsteosarcomeSH"
                                        placeholder="OsteosarcomeSH"
                                        value={OsteosarcomeMR}
                                        style={{ width: "100%" }}
                                        onChange={(value) => setOsteosarcomeMR(value)}
                                    >
                                        {optionOsteosarcomeMR.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            </>
                        )}
                        
                    </Row>

                </>
            )
            }
            <br />
           
          
                    {/* <BtnSubmit 
                        type_="Submit"
                         float_="right"
                         top_="-18px"
                        buttonText="Enregistrer"
                        position_="relative"
                        border_='none'
                        padding_='10px 20px'
                        borderRadius_='0.5rem'
                        color='#fff'
                        bgNormalColor='linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))'
                    /> */}
                 </form >

            {/* <Diagniostigue data={specialiteOrganeData} /> */}
            </>,
    },
  ];
 

  // fonction creer patient
  const [PatientDataGlobale, setPatientDataGlobale] = useState({});


  const handleSavePatient = async (e)  => {
e.preventDefault();
const patientData = [];
const agoData = [];
const antecedentsData = [];
const diagnosticData = [];

const formattedAntecedentMedical = AntecedentMedical.join(' ,  ');
  const formattedAntecedentChirurgical = AntecedentChirurgical.join(' ,  ');
  const formattedAntecedentFamilial = AntecedentFamilial.join(' ,  ');
  const formattedAntecedentPerinatal = AntecedentPerinatal.join(' ,  ');
  const formattedAntecedentTuberculosePulmonaire = AntecedentTuberculosePulmonaire.join(' ,  ');

   const formattedTerrainComorbidites = TerrainCommorbidites.join(' ,  '); // Joindre les options sélectionnées en une chaîne
    const formattedTerrainComorbiditesFacteurEnvi = TerrainCommorbiditesFacteursEnvironnementaux.join(' ,  ');
    const formattedTerrainComorbiditesFacteurInf = TerrainCommorbiditesFacteursInfectieux.join(' ,  ');
    const formattedTerrainComorbiditesFacteurGen = TerrainCommorbiditesFacteursGenetiques.join(' ,  ');

    // push data patient
   
        patientData.push({
      NumeroIdentification: "",
      DateCreation: DateCreation,
      Prenom: Prenom.toUpperCase(),
      Nom: Nom.toUpperCase(),
      Dob: Dob,
      Age: Age,
      Sexe: Sexe,
      AdresseHabituelle: AdresseHabituelle,
      AdresseActuelle: AdresseActuelle,
      Professsion: Professsion,
      ProfesssionMere: ProfesssionMere,
      ProfesssionPere: ProfesssionPere,
      Scolarisation: Scolarisation,
      Specialite: Specialite,
      Organe: Organe,
      Email: Email,
      Telephone: Telephone,
      CentreId: CentreId,
      NomCentre: NomCentre,
      TerrainCommorbidites: formattedTerrainComorbidites,
      TerrainCommorbiditesFacteursEnvironnementaux: formattedTerrainComorbiditesFacteurEnvi,
      TerrainCommorbiditesFacteursInfectieux: formattedTerrainComorbiditesFacteurInf,
      TerrainCommorbiditesFacteursGenetiques: formattedTerrainComorbiditesFacteurGen,
      StatutVaccinal: StatutVaccinal,
      ExpositionToxique: ExpositionToxique,
      PrecisionExpositionToxique: PrecisionExpositionToxique,
      Tabac: Tabac,
      TabagismeActif: TabagismeActif,
      StatusTabagismeActif: StatusTabagismeActif,
      TabagismePassif: TabagismePassif,
      AutresModeVie: AutresModeVie,
      Alcool: Alcool,
      GrossesseEvolutive: GrossesseEvolutive,
      TermeGrossesseEvolutive: TermeGrossesseEvolutive,
      ChanvreCannabis: ChanvreCannabis,
      SituationMatrimoniale: SituationMatrimoniale,
      AgeConjointe: AgeConjointe,
      DesirFertilite: DesirFertilite,
      PreservationFertiliteProposee: PreservationFertiliteProposee,
      InfectionHpvConnu: InfectionHpvConnu,
      MultiPartenariat: MultiPartenariat,
      AgePremierRapportSexuel: AgePremierRapportSexuel,
      StressEnvironnementSocial: StressEnvironnementSocial,
      // UserId: userData.user.id,
      UserId: UserId,
      ScoreG8: ScoreG8,
      contraceptionOr: contraceptionOr,
      ModVie: ModVie,
      // Medecin: `${userData.user.Prenom}  ${userData.user.Nom}`,

      // Adresse:Adresse,
      // Oncogenetique:Oncogenetique,
      // Indication:Indication,
      // Proposition:Proposition,
    });
    
    if (!Prenom || !Nom ||!Sexe || !Age || !AdresseActuelle || !AdresseHabituelle || !CentreId || !UserId) {
        // setError('Le  est requis');
        notification.warning({
          message: 'Erreur',
          description: 'Les champs prénom, nom,sexe, age, adresse actuelle, habituelle, Medecin Responsable et le centre sont obligatoire',
          duration: 6,
        });
        return;
      }
    
  // push data antecedent
  if (AntecedentMedical.length || AntecedentChirurgical.length || AntecedentFamilial.length || AntecedentPerinatal.length || AntecedentTuberculosePulmonaire.length) {
    antecedentsData.push({
    AntecedentMedical: formattedAntecedentMedical,
    AntecedentChirurgical: formattedAntecedentChirurgical,
    AntecedentFamilial: formattedAntecedentFamilial,
    AntecedentPerinatal: formattedAntecedentPerinatal,
    AntecedentTuberculosePulmonaire: formattedAntecedentTuberculosePulmonaire,
  });

}

//  push data agoData
if (Gestite && Parite && EnfantVivant && Menopause) {
  agoData.push({
    Gestite: Gestite,
    Parite: Parite,
    EnfantVivant: EnfantVivant,
    Menopause: Menopause,
  });
};

  const isAllFieldsEmpty = !datePrelevement && !dateDiagnostic;

if (!isAllFieldsEmpty) {
    diagnosticData.push({
    DatePrelevement: datePrelevement,
    DateDiagnostic: dateDiagnostic,
    TechniquePrelevement: TechniquePrelevement,
    EtatGeneralPatient: EtatGeneralPatient,
    MoyenDiagnostic: MoyenDiagnostic,
    MoyenDiagnosticCytologie: moyenDiagnosticCytologie === true ? 'Oui' : 'Non',
    MoyenDiagnosticImmunophénotypageSanguin: moyenDiagnosticImmunophénotypageSanguin === true ? 'Oui' : 'Non',
    MoyenDiagnosticImmunophenotypageSanguinResultats: moyenDiagnosticImmunophenotypageSanguinResultats,
    MoyenDiagnosticHistologie: moyenDiagnosticHistologie === true ? 'Oui' : 'Non',
    MoyenDiagnosticHistologieResultats: moyenDiagnosticHistologieResultats,
    MoyenDiagnosticImuunohistochimie: moyenDiagnosticImuunohistochimie === true ? 'Oui' : 'Non',
    MoyenDiagnosticImuunohistochimieResultats: moyenDiagnosticImuunohistochimieResultats,
    MoyenDiagnosticMedullogramme: moyenDiagnosticMedullogramme === true ? 'Oui' : 'Non',
    MoyenDiagnosticBCRAB: MoyenDiagnosticBCRABL,
    CytogenetiqueT: CytogenetiqueT,
    CytogenetiqueAca: CytogenetiqueAca,
    MoyenDiagnosticMedullogrammeResultats: moyenDiagnosticMedullogrammeResultats,
    MoyenDiagnosticBiopsieOsteomedullaire: moyenDiagnosticBiopsieOsteomedullaire,
    MoyenDiagnosticBiopsieOsteomedullaireResultats: moyenDiagnosticBiopsieOsteomedullaireResultats,
    PsOms: psOms,
    Poids: poids,
    Taille: taille,
    PoidsAge: poidsAge,
    TailleAge: tailleAge,
    ImcSc: imcSc,
    PoidsTaille: poidsTaille,
    ImcAge: imcAge,
    SignesPhysiques: SignesPhysiques,
    SignesCliniques: SignesCliniques,
    Siege: siege,
    SignesParacliniques: SignesParacliniques,
    IRMCP: IRMCP,
    IRMMP: IRMMP,
    IRMCPO: IRMCPO,
    IRMMPO: IRMMPO,
    LCR: LCR,
    Biologie: biologie,
    ExamenAnatomopathlogique: ExamenAnatomopathlogique,
    MargesResection: MargesResection,
    Medullogramme: Medullogramme,
    BilanPreTherapeutique: addBilanPreTherapeutique,
    Media: media,
    NéoplasmesHistiocytesMacrophages: NéoplasmesHistiocytesMacrophages,
    NéoplasmesCellulesLangerhansAutresCellulesDndritiques: tumeur,
    Malade: Malade,
    TypeHistologies: TypeHistologies || CytogenetiqueDel17p,
    Medulloblasme: MedulloblasmeSelect || medulloblasme,
    TypeCystologie: TypeCistologie,
    TypeCynetogenetique: CytogenetiqueDel17p,
    GradeHistopronostique: GradeHistopronostique,
    BiologieMoleculaire: biologieMoleculaire || BiologieMoleculaireMutationTp53,
    Tils: Tils,
    CytogenetiqueConventionnelle: cytogenetiqueConventionnelle,
    FISH: FISH,
    MarqueursTumoraux: MarqueursTumoraux,
    Immunohistochimie: immuno,
    EGFR: EGFR,
    Pdl1mTor: pdl1mTor,
    Invasionlocoregionale: invasion,
    EnvahissementGanglionnaire: Envahissement,
    TailleVolume: tailleTumoral,
    AgeJeune: ageJeune,
    Taux: taux,
    Sexe: Sexe,
    Hemoglobine: Hemoglobine,
    AutreFacteurMoleculaire: facteurMoleculaire,
    ALK: ALK,
    CMET: CMET,
    ROSI: ROSI,
    KRAS: KRAS,
    BRAF: BRAF,
    HER2: HER2,
    NTRK: NTRK,
    NRG1: NRG1,
    RET: RET,
    FacteursPronostiques: facteurPronostique || FacteursPronostiques,
    StadeClinique: tumeur || adenopathie || Métastase || StadeClinique,
    StadeAnnArbor: stadeAnnBoor || tumeur,
    ScoreHasford: ScoreHasford,
    ScoreEUTOS: ScoreEUTOS,
    ClassificationBinet: ClassificationBinet,
    StadeSokal: StadeSokal,
    AtteinteLCR: AtteinteLCR,
    AtteinteTesticulaire: AtteinteTesticulaire,
    Hyperleucocytose:Hyperleucocytose,
    CorticoSensibilite: CorticoSensibilite,
    FacteurGenetique: FacteurGenetique,
    Phenotype: Phenotype,
    TypeCistologie: TypeCytologique,
    Age: Age,
    IMWG2014: IMWG2014,
    ClassificationAltmann: ClassificationAltmann,
    ClassificationISS: ClassificationISS,
    ClassificationSalmonDiurie: ClassificationSalmonDiurie,
    ClassificationISSRevisee: ClassificationISSRevisee,
    ClassificationISSReviseeStade: ClassificationISSReviseeStade,
    ClassificationSalmonDiurieStade: ClassificationSalmonDiurieStade,
    ClassificationISSStade: ClassificationISSStade,
    ClassificationOmsSyndromesMyelodysplasique: ClassificationOmsSyndromesMyelodysplasiques,
    Risque: Risque,
    StadePronostic: tumeur,
    MoyenDiagnosticCytologieResultats: moyenDiagnosticCytologieResultats,
    UserId: UserId,
    BilanEvaluationPatient: BilanEvaluationPatient,
    BilanEvaluationMalade: BilanEvaluationMalade,
    Glucocorticoïdes: Glucocorticoïdes,
    Minéralocorticoïdes: Minéralocorticoïdes,
    SteroideSexuel: SteroideSexuel,
    Biopsie: Biopsie,
    CatecholaminesUrinaires: CatecholaminesUrinaires,
    TA: TA,
    EndoscopieDigestive: endoscopieDigestive,
    Testicule: Testicule,
    ParaTesticule: ParaTesticule,
    Seminome: Seminome,
    NonSeminome: NonSeminome,
    TumeurCelluleSL: TumeurCelluleSL,
    TumeurCelluleG: TumeurCelluleG,
    Stage: Risque,
    TypeTesticule: TypeTesticule,
    TumeursGerminaux: TumeursGerminaux,
    OsteosarcomeMR: OsteosarcomeMR,
    OsteosarcomeSH: OsteosarcomeSH,
    Classification: tumeur,
    Rhabdomyosarcome: selectedCheckbox,
    Carcinome: selectedCheckbox,
    Dysgerminome: Dysgerminome,
    Teratome: Teratome,
    Germinale: Germinale,
    Choriocarcinome: Choriocarcinome,
    TumeurSacVitellin: TumeurSacVitellin,
    CordonSexuel: CordonSexuel,
    ClassificationFigo: tumeur,
    KraBrafPik: KraBrafPik,
    Fox: Fox,
    PcinquanteTrois: PcinquanteTrois,
    Adenok: Adenok,
    ConsultationOncogetique: ConsultationOncogetique,
    ScorePronostic: ScorePronostic,
    ResultatFacteur: ResultatFacteur,
    Sc: Sc,
});
};
console.log(diagnosticData)
console.log(ScorePronostic);

  PatientDataGlobale.patientData=patientData;
  PatientDataGlobale.antecedentsData=antecedentsData;
  PatientDataGlobale.agoData=agoData;
  PatientDataGlobale.diagnosticData=diagnosticData;
  
    // try {
    //   const result = await dispatch(createPatient(PatientDataGlobale))
    //     .unwrap(); 
    //   notification.success({
    //     message: "Création de patient réussie",
    //     description: result.message,
    //     duration: 5,
    //   });
    //   if (result.data || result.payload.data) {
    //     setDataResPatient(result.data); // Enregistrer les données du patient
    //     // setFilteredRows(result.data); 
    //     setOpenForm(false);
    //     setOpenDetailPatient(true);
    //   }
    //   await dispatch(fetchPatients());
    //   resetStates();
    // }
    // catch (err) {
    //   notification.error({
    //     message: "Erreur d'enregistrement",
    //     description: err.message,
    //     duration: 5,
    //   });
    // }
  };



  return (
    <>
      <div>
        {!OpenDetailPatient ? (
          <div>
            <div className="buttons-container" style={{ marginLeft: collapsed ? 192 : 90 }}>
              <BtnSubmit onClick={handleOpen}
                className="btnAjouter"
                //   top_="120px"
                width_="100px"
                margin_="0px 65px -10px 655px"
                buttonText="Ajouter"
                title_={<h4>Veuillez choisir la spécialité et l'organe</h4>}
              />
              <ModalComponent
                _maskClosable={false}
                onCancel={onCancel}
                _top={_top}
                _open={open}
                _title={_title}
                _width={_width}
                _height={_height}
                form={<form >
                  <Row>
                    <Col span={24}>
                      <p>Spécialité</p>
                      <SelectInput name="Specialite"
                        placeholder_="Spécialité"
                        options={specialitesOptions}
                        value={Specialite}
                        onChange={handleChangeSpecialite}
                      />
                    </Col>
                    <Col span={24}>
                      <p>Organe</p>
                      <SelectInput placeholder_="Organe"
                        value={Organe}
                        options={options}
                        onChange={handleChangeOrgane} />
                    </Col>
                  </Row>
                  <BtnSubmit disabledColor="rgb(33, 82, 255)"
                    type_="button"
                    disabled={isBtnSubmitDisabled}
                    width_="75px"
                    margin_='0px 396px 12px'
                    bgNormalColor="linear-gradient( 310deg, rgb(33, 82, 255), rgb(33, 212, 253))"
                    buttonText="Suivant"
                    color="#fff"
                    boldColor={400}
                    height_="37px"
                    border_="none"
                    position_="relative"
                    top_="17px"
                    borderRadius_="0.5rem"
                    onClick={handleOpenForm}
                  />

                </form>}
              />
              <ModalComponent
                _maskClosable={false}
                _open={openForm}
                _title={<span style={{fontSize:"17px", fontWeight:"bold"}}>Créer un nouveau patient</span>}
                _width="85vw"
                _top="1.1vh"
                form={
                    <>
                        {/* <form onSubmit={handleSavePatient}> */}
                            <Collapse size="large" items={itemsCollapsePatients} defaultActiveKey={['1']} activeKey={activeTabPatient} onChange={setActiveTabPatient} />
                            <br/>
                            <br/>
                            <BtnSubmit 
                                onClick={handleSavePatient}
                                type_="submit"  
                                float_="right"
                                top_="-18px"
                                buttonText="Enregistrer"
                                position_="relative"
                                border_="none"
                                padding_="10px 15px"
                                borderRadius_="0.5rem"
                                color="#fff"
                                bgNormalColor="linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))"
                            />
                            <br/>
                        {/* </form> */}
                    </>
                }
                onCancel={handleCloseForm}
              />


              <BtnSearch
                margin_="5px 0px -11px -40px"
                height_="39px"
                bgNormalColor="#ffffff"
                border_="1.5px solid #1677FF"
                // onClick_={(e) => setSearchValue(e.target.value)}
                onChange_={(e) => setSearchValue(e.target.value)}
                allowClear
              />

            </div>
            <div className="table-container">
              <Tableau className="colonnPatientTablo"
                columns={columnsPatients}
                data={filtrData ? [...filtrData].reverse() : []}
                title={() => (
                  <div style={{ textAlign: 'left', fontWeight: "bold", marginTop: "-6px" }}>
                    Patients
                  </div>
                )}
                loading={loading}
              />
            </div>

          </div>
        ) : (
          <DetailsPatient fermeDetail={handleFerme} close={handleClose} data={DataResPatient || donneePatient} />
        )}
      </div>





      <ModalComponent
        _maskClosable={false}
        _open={openUpdateForm}
        _title="Mis à jour d'un patient"
        _width="85vw"
        _height=""
        _top="1.2vh"
        form={
          <form onSubmit={handleUpdateForm}>
            <Row gutter={6}>
              <Col span={8}>
                <p className="inputPatient" >Date de création</p>
                <DatePicker
                  style={{ width: "100%" }}
                  type="date"
                  placeholder="Date de création"
                  name="DateCreation"
                  value={(DateCreation ? dayjs(DateCreation) : null)} // Convert string to dayjs object
                  onChange={(date, dateString) => setDateCreation(dateString)} // Set the date string
                />
              </Col>
              {/* <Col span={6}>
                    <p className="inputPatient" >N° d’identification</p>
                    <InputText name="NumeroIdentification" value={NumeroIdentification} type="text" placeholder_="N° d’identification" onChange={(e) => setNumeroIdentification(e.target.value)} />
                  </Col> */}
              <Col span={8}>
                <p className="inputPatient" >Prénom</p>
                <InputText name="Prenom"
                  type="text" placeholder_="Prénom" onChange={(e) => handleChange(e.target.value, 'Prenom')}
                  value={inputData.Prenom || ""}
                />
              </Col>
              <Col span={8}>
                <p className="inputPatient">Nom</p>
                <InputText name="Nom" type="text" placeholder_=" Nom"
                  onChange={(e) => handleChange(e.target.value, 'Nom')}
                  value={inputData.Nom || ""}
                />
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={6}>
                <p className="inputPatient">Sexe </p>
                <SelectInput name="Sexe" placeholder_="Sexe" options={SelectSexeOptions} onChange={(value) => handleChange(value, 'Sexe')}
                  value={inputData.Sexe || ""} />
              </Col>
              <Col span={6}>
                <p className="inputPatient">Date de naissance </p>
                <InputText
                  name="Dob"
                  type="Date"
                  placeholder_="Date de naissance"
                  onChange={(e) => handleChange(e.target.value, 'Dob')}
                  value={inputData.Dob || ""}
                />
              </Col>
              <Col span={6}>
                <p className="inputPatient">Age </p>
                <InputText name="Age"
                  type="number" placeholder_="Age"
                  onChange={(e) => handleChange(e.target.value, 'Age')}
                  value={inputData.Age || ""}
                />
              </Col>
              <Col span={6}>
                <p className="inputPatient">Médecin responsable </p>
                <SelectInput name="UserId" placeholder_="Médecin responsable"
                  options={medecinOptions}
                  onChange={(value) => handleChange(value, 'UserId')}
                  value={inputData.UserId || ""}
                />

              </Col>

            </Row>
            <Row gutter={6}>
              <Col span={6}>
                <p className="inputPatient">Adresse habituelle </p>
                <InputText name="AdresseHabituelle" type="text" placeholder_="Adresse habituelle"
                  onChange={(e) => handleChange(e.target.value, 'AdresseHabituelle')}
                  value={inputData.AdresseHabituelle || ""}
                />
              </Col>
              <Col span={6}>
                <p className="inputPatient">Adresse actuelle </p>
                <InputText name="AdresseActuelle" type="text" placeholder_="Adresse actuelle"
                  onChange={(e) => handleChange(e.target.value, 'AdresseActuelle')}
                  value={inputData.AdresseActuelle || ""}
                />
              </Col>
              <Col span={6}>
                <p className="inputPatient">Téléphone </p>
                <InputText name="Telephone"
                  type="text"
                  placeholder_="Téléphone"
                  onChange={(e) => handleChange(e.target.value, 'Telephone')}
                  value={inputData.Telephone || ""}
                />
              </Col>
              <Col span={6}>
                <p className="inputPatient">Email </p>
                <InputText name="Email"
                  type="email"
                  placeholder_="Email"
                  onChange={(e) => handleChange(e.target.value, 'Email')}
                  value={inputData.Email || ""}
                />
              </Col>
            </Row>

            {/* Pédiatrie */}
            {((inputData.Specialite === "Urologie" && inputData.Organe !== "Prostate" && inputData.Organe !== "Vessie/Arbre urinaire" && inputData.Organe !== "Testicule"
              && inputData.Organe !== "Penis" && inputData.Organe !== "Surrenale" && inputData.Organe !== "Reins"
            ) ||
              (inputData.Specialite === "Pédiatrie" && inputData.Organe === "Prostate") ||
              (inputData.Organe === "Tumeurs cerebrales" ||
                inputData.Organe === "Tumeurs orbitaires" ||
                inputData.Organe === "Tumeurs maxillo faciales" ||
                inputData.Organe === "Tumeurs mediastinales" ||
                inputData.Organe === "Ovaire" ||
                inputData.Organe === "Voie excretrice" ||
                inputData.Organe === "Rein" ||
                inputData.Organe === "Testicul/para-testicule" ||
                inputData.Organe === "Vessie" ||
                inputData.Organe === "Rétropéritoine" ||
                inputData.Organe === "Foie/Pédiatrie" ||
                inputData.Organe === "OS"
              )) && (
                <>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Profession père </p>
                      <InputText name="ProfesssionPere"
                        type="text"
                        placeholder_="Profession père"
                        onChange={(e) => handleChange(e.target.value, 'ProfesssionPere')}
                        value={inputData.ProfesssionPere || ""}
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Profession mère </p>
                      <InputText name="ProfesssionMere"
                        type="text"
                        placeholder_="Profession mère"
                        onChange={(e) => handleChange(e.target.value, 'ProfesssionMere')}
                        value={inputData.ProfesssionMere || ""}
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Scolarisation</p>
                      <SelectInput name="Scolarisation" placeholder_="Scolarisation"
                        options={SelectScolarisationOptions}
                        onChange={(value) => handleChange(value, 'Scolarisation')}
                        value={inputData.Scolarisation || ""}
                      />
                    </Col>
                  </Row>
                </>
              )}
            {(
              (inputData.Specialite === "Digestif" && inputData.Organe === "Oesophage") ||
              (inputData.Organe !== "Oesophage" &&
                inputData.Organe !== "Tumeurs cerebrales" &&
                inputData.Organe !== "Tumeurs orbitaires" &&
                inputData.Organe !== "Tumeurs maxillo faciales" &&
                inputData.Organe !== "Tumeurs mediastinales" &&
                inputData.Organe !== "Voie excretrice" &&
                inputData.Organe !== "Prostate" &&
                inputData.Organe !== "Rein" &&
                inputData.Organe !== "Testicul/para-testicule" &&
                inputData.Organe !== "Vessie" &&
                inputData.Organe !== "OS" &&
                inputData.Organe !== "Corticosurrenale" &&
                inputData.Organe !== "Foie/Pédiatrie" &&
                inputData.Organe !== "Rétropéritoine" &&
                inputData.Organe !== "T. Sacro-coccygienne" &&
                inputData.Organe !== "Estomac" &&
                inputData.Organe !== "Ovaires" &&
                inputData.Organe !== "Col" &&
                inputData.Organe !== "Utérus" &&
                inputData.Organe !== "Vagin" &&
                inputData.Organe !== "Vulve" &&
                inputData.Organe !== "Sein" &&
                inputData.Organe !== "Néoplasies trophoblastiques gestationnelles" &&
                inputData.Organe !== "Ovaire" &&
                inputData.Organe !== "Vessie/Arbre urinaire" &&
                inputData.Organe !== "Testicule" &&
                inputData.Organe !== "Penis" &&
                inputData.Organe !== "Surrenale" &&
                inputData.Organe !== "Reins" &&
                inputData.Organe !== "Colo-rectum")
            ) && (
                <>
                  <Row gutter={6}>
                    <Col span={6}>
                      <p className="inputPatient">Exposition aux toxiques</p>
                      <SelectInput
                        name="ExpositionToxique"
                        placeholder_="Exposition aux toxiques"
                        options={SelectOuiNon}
                        onChange={(value) => handleChange(value, 'ExpositionToxique')}
                        value={inputData.ExpositionToxique || ""}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="inputPatient">Situation matrimoniale</p>
                      <SelectInput
                        name="SituationMatrimoniale"
                        placeholder_="Situation matrimoniale"
                        options={SelectSituationMatOptions}
                        onChange={(value) => handleChange(value, 'SituationMatrimoniale')}
                        value={inputData.SituationMatrimoniale || ""}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="inputPatient">Désir de fertilité ultérieure</p>
                      <SelectInput
                        name="DesirFertilite"
                        placeholder_="Désir de fertilité ultérieure"
                        options={SelectDesirF}
                        onChange={(value) => handleChange(value, 'DesirFertilite')}
                        value={inputData.DesirFertilite || ""}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="inputPatient">Préservation de fertilité proposée</p>
                      <SelectInput
                        name="PreservationFertiliteProposee"
                        placeholder_="Préservation de fertilité proposée"
                        options={SelectPreservation}
                        onChange={(value) => handleChange(value, 'PreservationFertiliteProposee')}
                        value={inputData.PreservationFertiliteProposee || ""}
                      />
                    </Col>
                  </Row>
                </>
              )}
            {(["Vessie/Arbre urinaire", "Surrenale", "Reins"].includes(inputData.Organe) && inputData.Specialite === "Urologie") && (
              <Row>
                <Col span={24}>
                  <p className="inputPatient">Exposition aux toxiques</p>
                  <SelectInput
                    name="ExpositionToxique"
                    placeholder_="Exposition aux toxiques"
                    options={SelectOuiNon}
                    onChange={(value) => handleChange(value, 'ExpositionToxique')}
                    value={inputData.ExpositionToxique || ""}
                  />
                </Col>
              </Row>
            )}

            {/* POUR GYNECOLOGIE.OVAIRES COL UTERUS VAGIN VULVE */}
            {(inputData.Organe === "Ovaires" ||
              inputData.Organe === "Col" ||
              inputData.Organe === "Utérus" ||
              inputData.Organe === "Vagin" ||
              inputData.Organe === "Vulve" ||
              inputData.Organe === "Sein"
            ) && (
                <>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Situation matrimoniale</p>
                      <SelectInput
                        name="SituationMatrimoniale"
                        placeholder_="Situation matrimoniale"
                        options={SelectSituationMatOptions}
                        onChange={(value) => handleChange(value, 'SituationMatrimoniale')}
                        value={inputData.SituationMatrimoniale || ""}
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Désir de fertilité ultérieure</p>
                      <SelectInput
                        name="DesirFertilite"
                        placeholder_="Désir de fertilité ultérieure"
                        options={SelectDesirF}
                        onChange={(value) => handleChange(value, 'DesirFertilite')}
                        value={inputData.DesirFertilite || ""}
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Préservation de fertilité proposée</p>
                      <SelectInput
                        name="PreservationFertiliteProposee"
                        placeholder_="Préservation de fertilité proposée"
                        options={SelectPreservation}
                        onChange={(value) => handleChange(value, 'PreservationFertiliteProposee')}
                        value={inputData.PreservationFertiliteProposee || ""}
                      />
                    </Col>
                  </Row></>
              )}
            {((inputData.Specialite === "Urologie" &&
              inputData.Organe !== "Prostate" &&
              inputData.Organe !== "Vessie/Arbre urinaire" &&
              inputData.Organe !== "Testicule" &&
              inputData.Organe !== "Penis" &&
              inputData.Organe !== "Surrenale" &&
              inputData.Organe !== "Reins") ||
              (inputData.Specialite === "Pédiatrie" && inputData.Organe === "Prostate") ||
              (inputData.Organe === "Tumeurs cerebrales" ||
                inputData.Organe === "Tumeurs orbitaires" ||
                inputData.Organe === "Tumeurs maxillo faciales" ||
                inputData.Organe === "Tumeurs mediastinales" ||
                inputData.Organe === "Ovaire" ||
                inputData.Organe === "Voie excretrice" ||
                inputData.Organe === "Rein" ||
                inputData.Organe === "Testicul/para-testicule" ||
                inputData.Organe === "Vessie" ||
                inputData.Organe === "OS" ||
                inputData.Organe === "Corticosurrenale"
              )) && (
                <>
                  <Row gutter={6}>
                    <Col span={12}>
                      <p className="inputPatient">Exposition aux toxiques</p>
                      <SelectInput name="ExpositionToxique" placeholder_="Exposition aux toxiques" options={SelectOuiNon}
                        onChange={(value) => handleChange(value, 'ExpositionToxique')}
                        value={inputData.ExpositionToxique || ""}
                      />
                    </Col>
                    <Col span={12}>
                      <p className="inputPatient">Préservation de fertilité proposée</p>
                      <SelectInput name="PreservationFertiliteProposee" placeholder_="Préservation de fertilité proposée" options={SelectPreservation}
                        onChange={(value) => handleChange(value, 'PreservationFertiliteProposee')}
                        value={inputData.PreservationFertiliteProposee || ""}
                      />
                    </Col>
                  </Row>
                </>
              )}
            {((inputData.Specialite === "Urologie" &&
              (inputData.Organe === "Prostate" ||
                inputData.Organe === "Vessie/Arbre urinaire" ||
                inputData.Organe === "Testicule" ||
                inputData.Organe === "Penis" ||
                inputData.Organe === "Surrenale" ||
                inputData.Organe === "Reins")) ||
              (inputData.Organe !== "Néoplasies trophoblastiques gestationnelles" &&
                inputData.Organe !== "Foie/Pédiatrie" &&
                inputData.Organe !== "Tumeurs cerebrales" &&
                inputData.Organe !== "Tumeurs orbitaires" &&
                inputData.Organe !== "Tumeurs maxillo faciales" &&
                inputData.Organe !== "Ovaire" &&
                inputData.Organe !== "Voie excretrice" &&
                inputData.Organe !== "Rein" &&
                inputData.Organe !== "Testicul/para-testicule" &&
                inputData.Organe !== "Vessie" &&
                inputData.Organe !== "Rétropéritoine" &&
                inputData.Organe !== "OS" &&
                inputData.Organe !== "T. Sacro-coccygienne" &&
                inputData.Organe !== 'Prostate' &&
                inputData.Organe !== "Tumeurs mediastinales")) &&
              (
                <>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Score G8 </p>
                      <InputText name="ScoreG8"
                        type="text" placeholder_="Score G8"
                        onChange={(e) => handleChange(e.target.value, 'ScoreG8')}
                        value={inputData.ScoreG8 || ""}
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Profession </p>
                      <InputText name="Professsion"
                        type="text" placeholder_="Profession"
                        onChange={(e) => handleChange(e.target.value, 'Professsion')}
                        value={inputData.Professsion || ""} />

                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Centre d’inclusion </p>
                      <SelectInput name="CentreId" placeholder_="Centre d’inclusion" options={centresOptions}
                        onChange={(value) => handleChange(value, 'CentreId')}
                        value={inputData.CentreId || ""} />
                    </Col>
                  </Row>
                </>
              )}
            {(inputData.Specialite === "Urologie" &&
              (inputData.Organe === "Prostate" ||
                inputData.Organe === "Testicule")
            )
              && (
                <>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Exposition aux toxiques</p>
                      <SelectInput name="ExpositionToxique" placeholder_="Exposition aux toxiques" options={SelectOuiNon}
                        onChange={(value) => handleChange(value, 'ExpositionToxique')}
                        value={inputData.ExpositionToxique || ""} />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Situation matrimoniale</p>
                      <SelectInput
                        name="SituationMatrimoniale"
                        placeholder_="Situation matrimoniale"
                        options={SelectSituationMatOptions}
                        onChange={(value) => handleChange(value, 'SituationMatrimoniale')}
                        value={inputData.SituationMatrimoniale || ""}
                      />
                    </Col>

                    {inputData.SituationMatrimoniale === 'Marié(e)'  && (<Col span={6}>
                      <p className="inputPatient">Age de la conjointe </p>
                      <InputText name="AgeConjointe"
                        type="text" placeholder_="Age de la conjointe"
                        onChange={(e) => handleChange(e.target.value, 'AgeConjointe')}
                        value={inputData.AgeConjointe || ""}
                      />
                    </Col>)}

                  </Row></>
              )}
            { 
            (inputData.Specialite === 'Thorax') && 
            (inputData.Sexe === "Féminin") &&(
                <>
                  <Row gutter={6}>
                    <Col span={12}>
                      <p className="inputPatient">Grossesse évolutive </p>
                       <SelectInput
                        name="GrossesseEvolutive"
                        placeholder_="Grossesse évolutive"
                        options={SelectGrossesseEvoOptions}
                        onChange={(value) => handleChange(value, 'GrossesseEvolutive')}
                        value={inputData.GrossesseEvolutive || ""}
                        
                      />
                    </Col>

                    {( inputData.GrossesseEvolutive === "oui" &&
                    <Col span={12}>
                      <p className="inputPatient">Terme </p>
                      <InputText name="TermeGrossesseEvolutive"
                        type="text"
                        placeholder_="Terme"
                        onChange={(e) => handleChange(e.target.value, 'TermeGrossesseEvolutive')}
                        value={inputData.TermeGrossesseEvolutive || ""}
                      />
                    </Col>
                    )}
                  </Row>
                </>
              )}

            {/* MODE DE VIE */}
            {(inputData.Organe === "Parois thoracique" ||
              inputData.Organe === "Plèvre"
            ) && (
                <>
                  <Row gutter={6}>
                    <Col span={12}>
                      <p className="inputPatient">Exposition ancienne à l’amiante++ </p>
                      <InputText name="ExpositionAncienne"
                        type="text"
                        placeholder_="Exposition ancienne à l’amiante++ "
                        onChange={(e) => handleChange(e.target.value, 'ExpositionAncienne')}
                        value={inputData.ExpositionAncienne || ""}
                      />
                    </Col>
                    <Col span={12}>
                      <p className="inputPatient">Autre </p>
                      <InputText name="PrecisionExpositionToxique"
                        type="text" placeholder_="Autre"
                        onChange={(e) => handleChange(e.target.value, 'PrecisionExpositionToxique')}
                        value={inputData.PrecisionExpositionToxique || ""} />
                    </Col>
                  </Row>
                </>
              )}
            {/* MODE DE VIE */}
            {(inputData.Specialite === 'Thorax') && (
                <>
                  <h4 style={{ marginTop: "9px", marginBottom: "0px" }}>Mode de vie </h4>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Tabagisme actif </p>
                      <SelectInput
                        name="TabagismeActif"
                        placeholder_="Tabagisme actif "
                        options={SelectTabacOptions}
                        onChange={(value) => handleChange(value, 'TabagismeActif')}
                        value={inputData.TabagismeActif || ""}
                      />
                    </Col>
                    {(inputData.TabagismeActif === "oui" &&
                    <Col span={8}>
                      <p className="inputPatient">Statut </p>
                      <SelectInput
                        name="StatusTabagismeActif"
                        placeholder_="Statut"
                        options={SelectStatutOptions}
                        onChange={(value) => handleChange(value, 'StatusTabagismeActif')}
                        value={inputData.StatusTabagismeActif || ""}
                      />
                    </Col>
                    )}
                    <Col span={8}>
                      <p className="inputPatient">Tabagisme passif </p>
                      <SelectInput
                        name="TabagismePassif"
                        placeholder_="Tabagisme passif"
                        options={SelectTabacOptions}
                        onChange={(value) => handleChange(value, 'TabagismePassif')}
                        value={inputData.TabagismePassif || ""}
                      />
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Alcool </p>
                      <SelectInput
                        name="Alcool"
                        placeholder_="Alcool"
                        options={SelectAlcoolOptions}
                        onChange={(value) => handleChange(value, 'Alcool')}
                        value={inputData.Alcool || ""}
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Chanvre/Cannabis </p>
                      <SelectInput name="ChanvreCannabis" placeholder_="Chanvre/Cannabis" options={SelectChanvreCannabisOptions}
                        onChange={(value) => handleChange(value, 'ChanvreCannabis')}
                        value={inputData.ChanvreCannabis || ""} />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Autre </p>
                      <InputText name="AutresModeVie" type="text" placeholder_="Autre"
                        onChange={(value) => handleChange(value, 'AutresModeVie')}
                        value={inputData.AutresModeVie || ""} />
                    </Col>
                  </Row>
                </>
              )}
            {(inputData.Organe === "Néoplasies trophoblastiques gestationnelles" ||
              inputData.Organe === "T. Sacro-coccygienne"
            ) && (
                <>
                  <Row gutter={6}>
                    <Col span={12}>
                      <p className="inputPatient">Profession </p>
                      <InputText name="Professsion"
                        type="text"
                        placeholder_="Profession"
                        onChange={(e) => handleChange(e.target.value, 'Professsion')}
                        value={inputData.Professsion || ""}
                      />
                    </Col>
                    <Col span={12}>
                      <p className="inputPatient">Centre d’inclusion </p>
                      <SelectInput name="CentreId" placeholder_="Centre d’inclusion" options={centresOptions}
                        onChange={(value) => handleChange(value, 'CentreId')}
                        value={inputData.CentreId || ""}
                      />
                    </Col>
                  </Row></>
              )}
            {((inputData.Specialite === "Urologie" &&
              inputData.Organe !== "Prostate" &&
              inputData.Organe !== "Vessie/Arbre urinaire" &&
              inputData.Organe !== "Testicule" &&
              inputData.Organe !== "Penis" &&
              inputData.Organe !== "Surrenale" &&
              inputData.Organe !== "Reins"
            ) ||
              (inputData.Specialite === "Pédiatrie" && inputData.Organe === "Prostate") ||
              (inputData.Organe === "Foie/Pédiatrie" ||
                inputData.Organe === "Tumeurs cerebrales" ||
                inputData.Organe === "Tumeurs orbitaires" ||
                inputData.Organe === "Tumeurs maxillo faciales" ||
                inputData.Organe === "Ovaire" ||
                inputData.Organe === "Voie excretrice" ||
                inputData.Organe === "Rein" ||
                inputData.Organe === "Testicul/para-testicule" ||
                inputData.Organe === "Vessie" ||
                inputData.Organe === "Rétropéritoine" ||
                inputData.Organe === "OS" ||
                inputData.Organe === "Tumeurs mediastinales"
              )) && (
                <Row>
                  <Col span={24}>
                    <p className="inputPatient">Centre d’inclusion </p>
                    <SelectInput name="CentreId" placeholder_="Centre d’inclusion" options={centresOptions}
                      onChange={(value) => handleChange(value, 'CentreId')}
                      value={inputData.CentreId || ""}
                    />
                  </Col>
                </Row>)}
            {(inputData.Organe === "Néoplasies trophoblastiques gestationnelles"
            ) && (
                <>
                  <Row gutter={6}>
                    <Col span={12}>
                      <p className="inputPatient">Situation matrimoniale</p>
                      <SelectInput
                        name="SituationMatrimoniale"
                        placeholder_="Situation matrimoniale"
                        options={SelectSituationMatOptions}
                        onChange={(value) => handleChange(value, 'SituationMatrimoniale')}
                        value={inputData.SituationMatrimoniale || ""}
                      />
                    </Col>
                    <Col span={12}>
                      <p className="inputPatient">Désir de fertilité ultérieure</p>
                      <SelectInput
                        name="DesirFertilite"
                        placeholder_="Désir de fertilité ultérieure"
                        options={SelectDesirF}
                        onChange={(value) => handleChange(value, 'DesirFertilite')}
                        value={inputData.DesirFertilite || ""}
                      />
                    </Col>
                  </Row></>
              )}
            {/* Statut vaccinal */}
            {(inputData.Organe === "Cavum" || inputData.Organe === "Nasopharynx") && (
              <Row>
                <Col span={24}>
                  <p className="inputPatient">Statut vaccinal</p>
                  <InputText name="StatutVaccinal"
                    type="text"
                    placeholder_="Statut vaccinal"
                    onChange={(e) => handleChange(e.target.value, 'StatutVaccinal')}
                    value={inputData.StatutVaccinal || ""}
                  />
                </Col>
              </Row>
            )}
            {/* POUR GYNECOLOGIE.OVAIRES COL UTERUS VAGIN VULVE */}
            {(inputData.Organe === "Ovaires" ||
              inputData.Organe === "Col" ||
              inputData.Organe === "Utérus" ||
              inputData.Organe === "Vagin" ||
              inputData.Organe === "Vulve"
            ) && (
                <>
                  <Row>
                    <Col span={24}>
                      <p className="inputPatient">Infection HPV connue</p>
                      <InputText name="InfectionHpvConnu"
                        type="text"
                        placeholder_="Infection HPV connue"
                        onChange={(e) => handleChange(e.target.value, 'InfectionHpvConnu')}
                        value={inputData.InfectionHpvConnu || ""}
                      />
                    </Col>
                  </Row>
                </>
              )}
            {(
              (inputData.Specialite === "Pédiatrie" && inputData.Organe !== "Néoplasmes des cellules histiocytaires/dendritiques" &&
                inputData.Organe !== "Oesophage" && inputData.Organe !== "Tumeurs mediastinales" &&
                inputData.Organe !== "Tumeurs cerebrales" && inputData.Organe !== "Tumeurs orbitaires" &&
                inputData.Organe !== "Tumeurs maxillo faciales" && inputData.Organe !== "Foie/Pédiatrie" &&
                inputData.Organe !== "T. Sacro-coccygienne" && inputData.Organe !== "Corticosurrenale" &&
                inputData.Organe !== "Ovaire" && inputData.Organe !== "Voie excretrice" &&
                inputData.Organe !== "Prostate" && inputData.Organe !== "Rein" &&
                inputData.Organe !== "Testicul/para-testicule" && inputData.Organe !== "Vessie" &&
                inputData.Organe !== "Rétropéritoine" && inputData.Organe !== "Colo-rectum" && inputData.Organe !== "OS") ||
              (inputData.Specialite === "Digestif" && inputData.Organe === "Oesophage") ||
              (inputData.Specialite === "Cancers Rares") ||
              (inputData.Organe === "Anus" ||
                inputData.Organe === "Colon et rectum" ||
                //  inputData.Organe !== "Estomac" ||
                inputData.Organe === "Foie" ||
                inputData.Organe === "Pancreas" ||
                inputData.Organe === "Voies biliaires" ||
                inputData.Organe === "Ovaires" ||
                inputData.Organe === "Col" ||
                inputData.Organe === "Utérus" ||
                inputData.Organe === "Vagin" ||
                inputData.Organe === "Vulve" ||
                inputData.Organe === "Prostate" ||
                inputData.Organe === "Vessie/Arbre urinaire" ||
                // inputData.Organe === "Testicule" ||
                inputData.Organe === "Penis" ||
                inputData.Organe === "Surrenale" ||
                inputData.Organe === "Reins" ||
                inputData.Organe === "Sein" ||
                inputData.Organe === "Oesophage")
            ) && (
                <>
                  <h4 style={{ marginTop: "9px", marginBottom: "0px" }}>Mode de vie</h4>
                  <Row gutter={6}>
                    <Col span={12}>
                      <p className="inputPatient">Alcool</p>
                      <SelectInput
                        name="Alcool"
                        placeholder_="Alcool"
                        options={SelectAlcoolOptions}
                        onChange={(value) => handleChange(value, 'Alcool')}
                        value={inputData.Alcool || ""}
                      />
                    </Col>
                    <Col span={12}>
                      <p className="inputPatient">Tabac</p>
                      <SelectInput
                        name="Tabac"
                        placeholder_="Tabac"
                        options={SelectTabacOptions}
                        onChange={(value) => handleChange(value, 'Tabac')}
                        value={inputData.Tabac || ""}
                      />
                    </Col>
                  </Row>
                </>
              )}
            {((inputData.Specialite === "Pédiatrie" && inputData.Organe === "Néoplasmes des cellules histiocytaires/dendritiques") ||
              (inputData.Specialite === "Hématologie" && inputData.Organe === "Néoplasmes des cellules histiocytaires/dendritiques") ||
              (inputData.Organe === "LA Adulte" ||
                inputData.Organe === "Lymphome Hodgkin adulte" ||
                inputData.Organe === "Leucémie Lymphoïde Chronique" ||
                inputData.Organe === "Leucémie myéloïde Chronique" ||
                inputData.Organe === "Lymphome non hodgkinien adulte" ||
                inputData.Organe === "Myélome multiple" ||
                inputData.Organe === "Syndromes myélodysplasiques" ||
                inputData.Organe === "Syndrome Myéloprolifératif Ph1 négative" ||
                inputData.Organe === "Cavum" ||
                inputData.Organe === "Nasopharynx" ||
                inputData.Organe === "Thyroide" ||
                inputData.Organe === "Cavité buccale" ||
                inputData.Organe === "Pharynx" ||
                inputData.Organe === "Larynx" ||
                inputData.Organe === "Cavité nasale" ||
                inputData.Organe === "Lévre" ||
                inputData.Organe === "Sinus" ||
                inputData.Organe === "Testicule" ||
                inputData.Organe === "Glandes salivaire")
            ) && (
                <>
                  <h4 style={{ marginTop: "9px", marginBottom: "0px" }}>Mode de vie </h4>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Alcool </p>
                      <SelectInput name="Alcool" placeholder_="Alcool" options={SelectAlcoolOptions}
                        onChange={(value) => handleChange(value, 'Alcool')}
                        value={inputData.Alcool || ""} />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Tabac </p>
                      <SelectInput name="Tabac" placeholder_="Tabac" options={SelectTabacOptions}
                        onChange={(value) => handleChange(value, 'Tabac')}
                        value={inputData.Tabac || ""} />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Chanvre/Cannabis </p>
                      <SelectInput name="ChanvreCannabis" placeholder_="Chanvre/Cannabis" options={SelectChanvreCannabisOptions}
                        onChange={(value) => handleChange(value, 'ChanvreCannabis')}
                        value={inputData.ChanvreCannabis || ""} />
                    </Col>
                  </Row>
                </>)}
            {/* POUR GYNECOLOGIE.NEOPLASIES TROPHOBLASTIQUE */}
            {inputData.Organe === "Néoplasies trophoblastiques gestationnelles" && (
              <Row><h4 style={{ marginTop: "9px", marginBottom: "5px" }}>Mode de vie </h4>
                <Col span={24}>
                  {/* <p className="inputPatient">Mode de vie </p> */}
                  <InputText name="ModVie"
                    type="text"
                    placeholder_="Mode de vie "
                    onChange={(e) => handleChange(e.target.value, 'ModVie')}
                    value={inputData.ModVie || ""} />
                </Col>
              </Row>)}
            {/* POUR GYNECOLOGIE.OVAIRES COL UTERUS VAGIN VULVE  */}
            <Row gutter={6}>
              {(inputData.Organe === "Ovaires" ||
                inputData.Organe === "Col" ||
                inputData.Organe === "Utérus" ||
                inputData.Organe === "Vagin" ||
                inputData.Organe === "Vulve"
              ) && (
                  <>
                    <Col span={8}>
                      <p className="inputPatient">Multi partenariat </p>
                      <InputText name="MultiPartenariat"
                        type="text"
                        placeholder_="Multi partenariat"
                        onChange={(e) => handleChange(e.target.value, 'MultiPartenariat')}
                        value={inputData.MultiPartenariat || ""}
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Age du premier rapport sexuel</p>
                      <InputText name="AgePremierRapportSexuel"
                        type="text"
                        placeholder_="Age du premier rapport sexuel"
                        onChange={(e) => handleChange(e.target.value, 'AgePremierRapportSexuel')}
                        value={inputData.AgePremierRapportSexuel || ""}
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Stress / Environnement social </p>
                      <InputText name="StressEnvironnementSocial"
                        type="text"
                        placeholder_="Stress / Environnement social"
                        onChange={(e) => handleChange(e.target.value, 'StressEnvironnementSocial')}
                        value={inputData.StressEnvironnementSocial || ""}
                      />
                    </Col>
                  </>
                )}
            </Row>
            {/* POUR GYNECOLOGIE.SEIN  */}
            <Row gutter={6}>
              {inputData.Organe === "Sein" && (
                <>
                  <Col span={12}>
                    <p className="inputPatient">Contraception orale </p>
                    <InputText name="contraceptionOr"
                      type="text"
                      placeholder_="Contraception orale"
                      onChange={(e) => handleChange(e.target.value, 'contraceptionOr')}
                      value={inputData.contraceptionOr || ""}
                    />
                  </Col>
                  <Col span={12}>
                    <p className="inputPatient">Stress / Environnement social </p>
                    <InputText name="StressEnvironnementSocial"
                      type="text"
                      placeholder_="Stress / Environnement social"
                      onChange={(e) => handleChange(e.target.value, 'StressEnvironnementSocial')}
                      value={inputData.StressEnvironnementSocial || ""}
                    />
                  </Col>
                </>
              )}
            </Row>
            <Row>
              {(
                // inputData.Organe === "Pancreas" ||
                inputData.Organe === "LA Adulte" ||
                inputData.Organe === "Néoplasmes des cellules histiocytaires/dendritiques" ||
                inputData.Organe === "Lymphome Hodgkin adulte" ||
                inputData.Organe === "Leucémie Lymphoïde Chronique" ||
                inputData.Organe === "Leucémie myéloïde Chronique" ||
                inputData.Organe === "Lymphome non hodgkinien adulte" ||
                inputData.Organe === "Myélome multiple" ||
                inputData.Organe === "Syndromes myélodysplasiques" ||
                inputData.Organe === "Syndrome Myéloprolifératif Ph1 négative" ||
                inputData.Organe === "Néoplasies trophoblastiques gestationnelles" ||
                inputData.Organe === "LA" ||
                inputData.Organe === "Lymphome" ||
                inputData.Organe === "T. Sacro-coccygienne" ||
                inputData.Organe === "Voie excretrice" ||
                inputData.Organe === "Vessie/Arbre urinaire" ||
                inputData.Organe === "Reins" ||
                inputData.Organe === "Surrenale" ||
                inputData.Organe === "Penis" ||
                inputData.Organe === "Testicule"
              )
                && (
                  <>
                    <h4 style={{ marginTop: "9px", marginBottom: "0px" }}>Terrain/comorbidités </h4>
                    <Col span={24}>
                      <p className="inputPatient">Maladies prédisposantes</p>
                      <SelectInput
                        mode="tags"
                        tokenSeparators={[',']}
                        height_="10vh"
                        name="TerrainCommorbidites"
                        options={TerrainComorbiditesOptions.map(option => ({ value: option, label: option }))}
                        placeholder_="Terrain/comorbidités/ maladies prédisposantes"
                        onChange={(value) => handleChange(value, 'TerrainCommorbidites')}
                        value={inputData.TerrainCommorbidites || ""}
                      />
                    </Col>
                  </>
                )}
            </Row>
            {(inputData.Organe === "Cavum" || inputData.Organe === "Nasopharynx")
              && (
                <>
                  <h4 style={{ marginTop: "9px", marginBottom: "3px" }}>Terrain/comorbidités </h4>
                  <Row gutter={6}>
                    <Col span={8}>
                      <p className="inputPatient">Maladies prédisposantes</p>
                      <SelectInput
                        mode="tags"
                        tokenSeparators={[',']}
                        height_="10vh"
                        name="TerrainCommorbidites"
                        options={TerrainComorbiditesOptions.map(option => ({ value: option, label: option }))}
                        placeholder_="Terrain/comorbidités/ maladies prédisposantes"
                        onChange={(value) => handleChange(value, 'TerrainCommorbidites')}
                        value={inputData.TerrainCommorbidites || ""}
                      />
                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Facteurs environnementaux</p>
                      <SelectInput
                        mode="tags"
                        tokenSeparators={[',']}
                        height_="10vh"
                        options={FacteursEnvironnementauxCavumNasopharynxOptions.map(option => ({ value: option, label: option }))}
                        name="TerrainCommorbiditesFacteursEnvironnementaux"
                        placeholder_="Facteurs environnementaux"
                        onChange={(value) => handleChange(value, 'TerrainCommorbiditesFacteursEnvironnementaux')}
                        value={inputData.TerrainCommorbiditesFacteursEnvironnementaux || ""}
                      />

                    </Col>
                    <Col span={8}>
                      <p className="inputPatient">Facteurs génétiques</p>
                      <SelectInput
                        mode="tags"
                        tokenSeparators={[',']}
                        height_="10vh"
                        options={TerrainComorbiditesOptions.map(option => ({ value: option, label: option }))}
                        name="TerrainCommorbiditesFacteursGenetiques"
                        placeholder_="Facteurs génétiques"
                        onChange={(value) => handleChange(value, 'TerrainCommorbiditesFacteursGenetiques')}
                        value={inputData.TerrainCommorbiditesFacteursGenetiques || ""}
                      />
                    </Col>
                  </Row>
                </>
              )}
            {(
              //     (inputData.Specialite === "Urologie" && 
              //   inputData.Organe !== "Prostate" &&
              //   inputData.Organe !== "Vessie/Arbre urinaire" &&
              //   inputData.Organe !== "Testicule" &&
              //   inputData.Organe !== "Penis" &&
              //   inputData.Organe !== "Surrenale" &&
              //   inputData.Organe !== "Reins"
              // ) ||
              (inputData.Specialite === "Pédiatrie" && inputData.Organe === "Prostate") ||
              (inputData.Specialite === "Cancers Rares" ) ||
              (inputData.Specialite === 'Thorax') ||
              (inputData.Organe === "Anus" ||
                inputData.Organe === "Colon et rectum" ||
                inputData.Organe === "Foie" ||
                // inputData.Organe === "Oesophage" || 
                inputData.Organe === "Voies biliaires" ||
                inputData.Organe === "Ovaires" ||
                inputData.Organe === "Col" ||
                inputData.Organe === "Utérus" ||
                inputData.Organe === "Vagin" ||
                inputData.Organe === "Vulve" ||
                inputData.Organe === "Sein" ||
                inputData.Organe === 'Cavité buccale' ||
                inputData.Organe === 'Pharynx' ||
                inputData.Organe === 'Larynx' ||
                inputData.Organe === 'Cavité nasale' ||
                inputData.Organe === 'Lévre' ||
                inputData.Organe === 'Sinus' ||
                inputData.Organe === 'Glandes salivaire' ||
                inputData.Organe === 'Thyroide' ||
                inputData.Organe === "Tumeurs cerebrales" ||
                inputData.Organe === "Tumeurs orbitaires" ||
                inputData.Organe === "Tumeurs maxillo faciales" ||
                inputData.Organe === "Tumeurs mediastinales" ||
                inputData.Organe === "Foie/Pédiatrie" ||
                inputData.Organe === "Ovaire" ||
                inputData.Organe === "Rein" ||
                inputData.Organe === "Testicul/para-testicule" ||
                inputData.Organe === "Vessie" ||
                inputData.Organe === "Rétropéritoine" ||
                inputData.Organe === "Corticosurrenale" ||
                inputData.Organe === "Oesophage" ||
                inputData.Organe === "Colo-rectum" ||
                
                // inputData.Organe === "Pancreas" ||
                // inputData.Organe === "LA Adulte" ||
                // inputData.Organe === "Néoplasmes des cellules histiocytaires/dendritiques" ||
                // inputData.Organe === "Lymphome Hodgkin adulte" ||
                // inputData.Organe === "Leucémie Lymphoïde Chronique" ||
                // inputData.Organe === "Leucémie myéloïde Chronique" ||
                // inputData.Organe === "Lymphome non hodgkinien adulte" ||
                // inputData.Organe === "Myélome multiple" ||
                // inputData.Organe === "Syndromes myélodysplasiques" ||
                // inputData.Organe === "Syndrome Myéloprolifératif Ph1 négative" ||
                inputData.Organe === "Néoplasies trophoblastiques gestationnelles" ||
                inputData.Organe === "LA" ||
                inputData.Organe === "Lymphome" ||
                inputData.Organe === "T. Sacro-coccygienne" ||
                inputData.Organe === "Voie excretrice" ||
                // inputData.Organe === "Vessie/Arbre urinaire" ||
                // inputData.Organe === "Reins" ||
                // inputData.Organe === "Surrenale" ||
                // inputData.Organe === "Penis" ||
                // inputData.Organe === "Testicule" ||
                inputData.Organe === "OS"
              )) && (
                <>
                  <h4 style={{ marginTop: "9px", marginBottom: "0px" }}>Terrain/comorbidités </h4>
                  <Row>
                    <Col span={24}>
                      <p className="inputPatient">Maladies prédisposantes</p>
                      <SelectInput
                        mode="tags"
                        tokenSeparators={[',']}
                        height_="10vh"
                        name="TerrainCommorbidites"
                        placeholder_="Terrain/comorbidités/ maladies prédisposantes"
                        options={TerrainComorbiditesOptions.map(option => ({ value: option, label: option }))}
                        onChange={(value) => handleChange(value, 'TerrainCommorbidites')}
                        value={inputData.TerrainCommorbidites || ""}
                      />
                    </Col>
                  </Row>
                </>
              )}
            {inputData.Organe === "Estomac" && (
              <>
                <h4 style={{ marginTop: "9px", marginBottom: "0px" }}>Terrain/comorbidités </h4>
                <Row gutter={6}>
                  <Col span={8}>
                    <p className="inputPatient">Facteurs environnementaux</p>
                    <SelectInput
                      mode="tags"
                      tokenSeparators={[',']}
                      height_="10vh"
                      name="TerrainCommorbiditesFacteursEnvironnementaux"
                      placeholder_="Facteurs environnementaux"
                      options={SelectFacteursEnvironnementauxOptions.map(option => ({ value: option, label: option }))}
                      onChange={(value) => handleChange(value, 'TerrainCommorbiditesFacteursEnvironnementaux')}
                      value={inputData.TerrainCommorbiditesFacteursEnvironnementaux || ""}
                    />
                  </Col>
                  <Col span={8}>
                    <p className="inputPatient">Facteurs infectieux</p>
                    <SelectInput
                      mode="tags"
                      tokenSeparators={[',']}
                      height_="10vh"
                      name="TerrainCommorbiditesFacteursInfectieux"
                      placeholder_="Facteurs infectieux"
                      options={SelectFacteursInfectieuxOptions.map(option => ({ value: option, label: option }))}
                      onChange={(value) => handleChange(value, 'TerrainCommorbiditesFacteursInfectieux')}
                      value={inputData.TerrainCommorbiditesFacteursInfectieux || ""}
                    />
                  </Col>
                  <Col span={8}>
                    <p className="inputPatient">Facteurs génétiques</p>
                    <SelectInput
                      mode="tags"
                      tokenSeparators={[',']}
                      height_="10vh"
                      name="TerrainCommorbiditesFacteursGenetiques"
                      placeholder_="Facteurs génétiques"
                      options={SelectFacteursGenetiquesOptions.map(option => ({ value: option, label: option }))}
                      onChange={(value) => handleChange(value, 'TerrainCommorbiditesFacteursGenetiques')}
                      value={inputData.TerrainCommorbiditesFacteursGenetiques || ""}
                    />
                  </Col>
                </Row>
              </>
            )}<br />
            <br />
            <BtnSubmit
              type_="submit"
              disable='none'
              width_="86px"
              float_="right"
              bgNormalColor="linear-gradient(310deg, rgb(33, 212, 253), rgb(33, 82, 255))"
              buttonText="Sauvegarder"
              color="#fff"
              boldColor={400}
              height_="37px"
              border_="none"
              position_="relative"
              top_="-30px"
              borderRadius_="0.5rem"
            />
          </form>
        }
        onCancel={handleCloseUpdateForm}
      />

      <Modal
        maskClosable={false}
        closable={false}
        title="Modifier un patient"
        open={openModalConfirmation}
        onOk={() => handleOpenUpdateForm(UpdatePatientdata)}
        onCancel={handleCancel}
        okText="Oui"
        cancelText="Non">
        <p>Voulez-vous modifier ce patient?</p>
      </Modal>
      <Modal
        maskClosable={false}
        closable={false}
        title="Archiver un patient"
        open={openModalConfirmArchiv}
        onOk={() => handleArchivPatient(ArchivPatientId)}
        onCancel={handleCancelArchiv}
        okText="Oui"
        cancelText="Non">
        <p>Voulez-vous archiver ce patient?</p>
      </Modal>


    </>

  );
}

export default Patients;
