import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

export const fetchResumePatient = createAsyncThunk(
  "resumePatient/getResumePatient", 
  async (id) => {
    try {
      const response = await api.getOneResumePatient(id); 
      // console.log("API Response:", response.data); 
      return response.data.data; 
    } catch (error) {
      console.error("Error fetching ResumePatient:", error);
      throw error; 
    }
  }
);


export const resumePatientSlice = createSlice({
  name: "resumePatient", 
  initialState: {
    data: {}, 
    isSuccess: false,
    loading: false,
    error: null, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResumePatient.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.error = null; 
      })
      .addCase(fetchResumePatient.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = true;
        state.data = payload;
      })
      .addCase(fetchResumePatient.rejected, (state, { error }) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = error.message; 
      });
  },
});

export default resumePatientSlice.reducer;
