import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Fonction pour récupérer les Centre depuis l'API
export const fetchCentre = createAsyncThunk(
  "Centre/getCentre",
  async () => {
    try {
      const response = await api.getAllCentre();
      
      return response.data; // Assurez-vous que `data` est bien présent
    } catch (error) {
      console.error("Error fetching Centre:", error);
      throw error; // Lancer l'erreur pour être capturée dans le reducer
    }
  }
);

// Création du slice pour les Centre
export const centreSlice = createSlice({
    name: "Centre",
    initialState: {
      data: [], // Assurez-vous que 'data' est initialisé comme un tableau vide
      isSuccess: false,
      loading: false,
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchCentre.pending, (state) => {
          state.loading = true;
          state.isSuccess = false;
        })
        .addCase(fetchCentre.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.isSuccess = true;
          state.data = payload;
        })
        .addCase(fetchCentre.rejected, (state) => {
          state.loading = false;
          state.isSuccess = false;
        });
    },
  });
  
// Exporter le réducteur pour l'utiliser dans le store
export default centreSlice.reducer;
