import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";


export const createResetPassword = createAsyncThunk(
  "creatingResetPassword/creatingResetPasswordData",
  async ({ token, PasswordData }, { rejectWithValue }) => {
    try {
      const { data } = await api.createResetPassword(token, PasswordData);  // Envoyer le token
      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || "Une erreur s'est produite";
      return rejectWithValue(errorMessage);
    }
  }
);


export const createResetPasswordSlice = createSlice({
  name: "creatingResetPassword",
  initialState: {
    data: {}, 
    isSuccess: false, // Indique si la création a réussi
    loading: false, // Indique si une création est en cours
    error: null, // Stocke l'erreur s'il y en a une
  },
  extraReducers: (builder) => {
    builder
      // Gestion de l'état pendant que la création est en cours
      .addCase(createResetPassword.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.error = null;
      })
      // Gestion de l'état lorsque la création réussit
      .addCase(createResetPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = true;
        state.data = payload;
        state.error = null;
      })
      // Gestion de l'état lorsque la création échoue
      .addCase(createResetPassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = payload; // Stocke le message d'erreur
      });
  },
});

export default createResetPasswordSlice.reducer;
