import React, {useState} from 'react';
import ModalComponent from '../modal/ModalComponent';
import "../button/btnAjout.css";


export const BtnAjout = ({form, _width, _height, _title, _top,  }) => {
    const [open, setOpen] = useState(false); 
    
    const handleOpen  = () => {
        setOpen(true);
    }
    
    return (
        <div> 
            <button className="btnAjouter" type="submit" onClick={handleOpen} >Ajouter</button>
            <ModalComponent 
            _maskClosable={false}
            _top={_top}
             _open={open} 
             _title={_title}
             _width={_width} 
             _height={_height} 
             form={form}  
            onCancel={() => setOpen(false)} />
        </div>
    );
};

