import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCentre } from '../../redux/centreSlice';
import { BtnSearch, BtnSubmit, InputText, ModalComponent, Tableau } from '../../components';
import { Col, Modal, notification, Row, Space, Tooltip } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import './centre.css';
import { createCentre } from '../../redux/CreateCentreSlice';
import { useCollapsed } from '../dashBoard/CollapsedContext';
import { updateCentre } from '../../redux/UpdateCentreSlice';
import { updateArchivCentre } from '../../redux/ArchivCentreSlice';




const Centre = () => {
  const [NomCentre, setNomCentre] = useState("");
  const [Region, setRegion] = useState("");
  const [Niveau, setNiveau] = useState("");
  const [open, setOpen] = useState(false); 
  const [openUpdateForm, setOpenUpdateForm] = useState("");
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [updatingDataId, setUpdatingDataId] = useState();
  const [centreData, setCentreData] = useState();
  const [UpdateCentredata, setUpdateCentredata] = useState(null);
  const [openModalConfirmArchiv, setOpenModalConfirmArchiv] = useState(false);
  const [ArchivCentreId, setArchivCentreId] = useState(null);


  const handleOpenModalConfirmArchiv = (record) => {
    setArchivCentreId(record.id);
    setOpenModalConfirmArchiv(true);
  }
  const handleCancelArchiv = () => {
    setOpenModalConfirmArchiv(false);
    setArchivCentreId(null);
    }
  const handleOpenModalConfirmation = (record) => {
    setUpdateCentredata(record);
    setOpenModalConfirmation(true);
  }
  const handleCancel = () => {
    setOpenModalConfirmation(false);
    setUpdateCentredata(null);
    }
        const handleOpen  = () => {
          setOpen(true);
      };
      const onCancel  = () => {
        setOpen(false);
        // Réinitialise les champs du formulaire
        setNomCentre("");
        setNiveau("");
        setRegion("");
    };
    const onCloseUpdateForm = () => {
      setOpenUpdateForm(false);
      setNomCentre("");
      setNiveau("");
      setRegion("");
  };

  const dispatch = useDispatch();
  const { data: dataCentre, loading, isSuccess } = useSelector((state) => state.centre.data);

  useEffect(() => {
    dispatch(fetchCentre());
  }, [dispatch]);

            const handleSubmit = async(e) => {
                e.preventDefault();
                let centreData= {
                  NomCentre:NomCentre,
                  Region:Region,
                  Niveau:Niveau
                }
                try {
                  const result = await dispatch(createCentre(centreData))
                  .unwrap(); // Unwrap pour accéder directement à la réponse
                  notification.success({
                    message: "Création de centre réussie",
                    description: result.message,
                    duration: 6,
                  });
                  await dispatch(fetchCentre());
                       // Ferme le modal après enregistrement
                        onCancel(); // Appel correct de la fonction pour fermer le modal
                } catch (err) {
                notification.error({
                  message: "Erreur d'enregistrement",
                  description: err.message || "Une erreur est survenue",
                  duration: 6,
                });
              }};
                 
  const collapsed = useCollapsed();

  const colomnsCentre = [
    {
      title: 'Nom',
      dataIndex: 'NomCentre',
      key: 'nom',
      // className: 'colonText',
      width:'40%',
    },
    {
      title: 'Région',
      dataIndex: 'Region',
      key: 'region',
      // className: 'colonText',
    },
    {
      title: 'Niveau du centre',
      dataIndex: 'Niveau',
      key: 'Niveau',
      // className: 'colonText',
    },
    {
      title: 'Options',
      key: 'options',
      render: (record) => (
        <Space size="large">
          <Tooltip title="Editer">
          <EditFilled 
                  style={{ color: "#4096FF", fontSize: "15px" }} 
                  onClick={() => handleOpenModalConfirmation(record)}
              />
          </Tooltip>
          <Tooltip title="Archiver">
            <DeleteFilled style={{ color: '#E00B0B', fontSize: '15px' }} 
            onClick={() => handleOpenModalConfirmArchiv(record)}/>
          </Tooltip>
        </Space>
      ),
      className: 'colonText',
    },
  ];
const [searchValue, setSearchValue] = useState('');
const filtrData = dataCentre?.filter(item =>
  item.NomCentre.toLowerCase().includes(searchValue.toLowerCase()) ||
  item.Region.toLowerCase().includes(searchValue.toLowerCase()) ||
  item.Niveau.toLowerCase().includes(searchValue.toLowerCase())
  );

  const [inputData, setInputData] = useState({
    NomCentre: '',
    Region:  '',
    Niveau:  '',
  });

  const handleOpenUpdateForm = (record) => {
    setOpenModalConfirmation(false);
    setOpenUpdateForm(true);
    setInputData(
    {
      NomCentre: record.NomCentre || '',
      Region: record.Region || '',
      Niveau: record.Niveau || '',
    });

  setUpdatingDataId(record.id);
  };
  const handleChange = (value,field) => {
    setInputData({
      ...inputData, 
      [field]: value, 
    });
  };

  const handleUpdateForm = async (e) => {
    e.preventDefault();
    try {
      const result = await dispatch(updateCentre({ id:updatingDataId,body:inputData })).unwrap();
      notification.success({
        message: "Patient mis à jour avec succès",
        description: result.message,
        duration: 6,
      });
      setCentreData(inputData);
      setOpenUpdateForm(false);
      await dispatch(fetchCentre());
    } catch (err) {
      notification.error({
        message: "Erreur de mise à jour",
        description: err.message,
        duration: 6,
      });
    }
  };

  const handleArchivCentre = async (id) => {
   
    try {
      const result = await dispatch(updateArchivCentre(id)).unwrap();
      
      notification.success({
        message: "Centre archivé avec succès",
        description: result.message,
        duration: 6,
      });
      await dispatch(fetchCentre());
      setOpenModalConfirmArchiv(false);  
    } catch (errorMessage) {
      notification.error({
        message: "Erreur de mise à jour",
        description: errorMessage.message,
        duration: 6,
      });
    }
  };

  return (
    <div>
      <div className="buttons-container" style={{ marginLeft: collapsed ? 192 : 90 }}>
                <BtnSubmit type_="button"
                  onClick={handleOpen}
                  className="btnAjouter"
                  width_="100px"
                  margin_="0px 65px -10px 655px"
                  buttonText="Ajouter"
                  />
                    <ModalComponent 
                    _maskClosable={false}
                    onCancel={onCancel}
                    _open={open} 
                    _width="420px" 
                    _title="Ajouter un centre"
                    form=
                    {
                      <form onSubmit={handleSubmit}>
                          <Row>
                            <Col span={24}>
                                <p>Nom</p>
                                <InputText required 
                                  size_="large"
                                  name="NomCentre" 
                                  value={NomCentre} 
                                  onChange={(e) => setNomCentre(e.target.value)}
                                  type="text"
                                  placeholder_="Veuillez saisir votre nom"
                                />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                                <p>Région</p>
                                <InputText 
                                  required 
                                  size_="large"
                                  name="Region" 
                                  value={Region} 
                                  onChange={(e) => setRegion(e.target.value)}
                                  type="text"
                                  placeholder_="Veuillez saisir votre région"
                                />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                                <p>Niveau</p>
                                <InputText 
                                  required 
                                  size_="large"
                                  name="Niveau" 
                                  value={Niveau} 
                                  onChange={(e) => setNiveau(e.target.value)}
                                  type="text"
                                  placeholder_="Veuillez saisir le niveau"
                                />
                            </Col>
                          </Row><br/>
                                  <BtnSubmit  
                                      type_="submit"
                                      width_="6.5vw"
                                      float_="right"
                                      bgNormalColor="#78953E"
                                      buttonText="Valider"
                                      color="#fff"
                                      boldColor={700}
                                      height_="37px"
                                      borderRadius_="0.5rem"
                                      border_="none"
                                      bgHoverColor="#4589C7"
                          /><br/><br/>
                      </form>
                                }  
                                />
        <BtnSearch
          margin_="5px 0px -11px -40px"
          height_="39px"
          bgNormalColor="#ffffff"
          border_="1.5px solid #1677FF"
          onChange_={(e) => setSearchValue(e.target.value)}
          allowClear
        />
      </div>
      <div>
        <Tableau
          columns={colomnsCentre}
          data={filtrData ? [...filtrData].reverse() : []}
          title={() => (
            <div style={{ textAlign: 'left', fontWeight: 'bold', marginTop: '-6px' }}>
              Centre
            </div>
          )}
          loading={loading}
        />
      </div>
               <ModalComponent 
                    _maskClosable={false}
                    onCancel={onCloseUpdateForm}
                    _open={openUpdateForm} 
                    _width="420px" 
                    _title="Ajouter un centre"
                    form=
                    {<form onSubmit={handleUpdateForm}>    
                          <Row>
                            <Col span={24}>
                                <p>Nom</p>
                                <InputText required 
                                  size_="large"
                                  name="NomCentre" 
                                  type="text"
                                  placeholder_="Veuillez saisir votre nom"
                                  onChange={(e) => handleChange(e.target.value, 'NomCentre')}  
                                  value={inputData.NomCentre || ""} 
                                />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                                <p>Région</p>
                                <InputText 
                                  required 
                                  size_="large"
                                  name="Region" 
                                  type="text"
                                  placeholder_="Veuillez saisir votre région"
                                  onChange={(e) => handleChange(e.target.value, 'Region')}  
                                  value={inputData.Region || ""}
                                />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                                <p>Niveau</p>
                                <InputText 
                                  required 
                                  size_="large"
                                  name="Niveau" 
                                  type="text"
                                  placeholder_="Veuillez saisir le niveau"
                                  onChange={(e) => handleChange(e.target.value, 'Niveau')}  
                                  value={inputData.Niveau || ""}
                                />
                            </Col>
                          </Row><br/>
                                  <BtnSubmit  
                                      type_="submit"
                                      width_="6.5vw"
                                      float_="right"
                                      bgNormalColor="#78953E"
                                      buttonText="Valider"
                                      color="#fff"
                                      boldColor={700}
                                      height_="37px"
                                      borderRadius_="0.5rem"
                                      border_="none"
                                      bgHoverColor="#4589C7"
                          /><br/><br/>
                      </form>
                                }  
                                />
            <Modal
              maskClosable={false}
              closable={false}
              title="Modifier un centre" 
              open={openModalConfirmation} 
              onOk={() => handleOpenUpdateForm(UpdateCentredata)}       
              onCancel={handleCancel}
              okText="Oui" 
              cancelText="Non">
              <p>Voulez-vous modifier ce centre?</p>
            </Modal>
            <Modal
              maskClosable={false}
              closable={false}
              title="Archiver un centre" 
              open={openModalConfirmArchiv} 
              onOk={() => handleArchivCentre(ArchivCentreId)}       
              onCancel={handleCancelArchiv}
              okText="Oui" 
              cancelText="Non">
              <p>Voulez-vous archiver ce centre?</p>
            </Modal>
    </div>
  );
};

export default Centre;
